import React, { forwardRef } from 'react';
import { DatePicker } from 'antd';
import { DEFAULT_DATE_FORMAT } from '@/constants';

const { MonthPicker, RangePicker, WeekPicker } = DatePicker;

const WrappedDatePicker = forwardRef((props, ref) => (
  <DatePicker ref={ref} format={DEFAULT_DATE_FORMAT} {...props} />
));

WrappedDatePicker.MonthPicker = MonthPicker;
WrappedDatePicker.RangePicker = RangePicker;
WrappedDatePicker.WeekPicker = WeekPicker;

export default WrappedDatePicker;
