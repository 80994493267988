import snakeCaseToCamelCase from './snakeCaseToCamelCase';
import isPlainObject from './isPlainObject';

export default function toCamelCaseObject(obj) {
  if (obj === null) {
    return null;
  }
  if (!isPlainObject(obj)) {
    return obj;
  }
  return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];
    const camelCased = snakeCaseToCamelCase(key);
    if (isPlainObject(value)) {
      return {
        ...acc,
        [camelCased]: toCamelCaseObject(value),
      };
    }
    if (Array.isArray(value)) {
      return {
        ...acc,
        [camelCased]: value.map(toCamelCaseObject),
      };
    }
    return {
      ...acc,
      [camelCased]: value,
    };
  }, {});
}
