import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'dva';
import { formatMessage } from 'umi/locale';

import { notification } from '@/componentsX/UIElements';

import { withLocaleContext } from '@/utils/locale';

import { NAMESPACE } from './constants';
import AppointmentModalView from './view';
import messages from './messages';

@connect(state => ({
  isSuccess: state[NAMESPACE].isSuccess,
  loading: state.loading.effects[`${NAMESPACE}createOrUpdate`],
}))
class AppointmentModalContainer extends PureComponent {
  static propTypes = {
    appointment: PropTypes.object,
    isOpen: PropTypes.bool.isRequired,
    isEdit: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    callback: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    appointment: {},
  };

  componentDidUpdate(prevProps) {
    const { isEdit, appointment, dispatch } = this.props;

    if (isEdit && appointment !== prevProps.appointment) {
      dispatch({
        type: `${NAMESPACE}/saveStepFormData`,
        payload: appointment,
      });
    }
  }

  handleClearData = () => {
    const { dispatch } = this.props;
    dispatch({ type: `${NAMESPACE}/clear` });
  };

  handleCancel = () => {
    const { onCancel } = this.props;
    onCancel();
    this.handleClearData();
  };

  handleDone = () => {
    const { callback, isEdit } = this.props;
    this.handleCancel();
    notification.success({
      message: formatMessage(isEdit ? messages.appointmentUpdated : messages.appointmentCreated),
    });
    callback();
  };

  render() {
    const { isOpen, isEdit, userTimezone, clinicTimezone } = this.props;
    return (
      <AppointmentModalView
        isOpen={isOpen}
        isEdit={isEdit}
        userTimezone={userTimezone}
        clinicTimezone={clinicTimezone}
        handleDone={this.handleDone}
        handleCancel={this.handleCancel}
      />
    );
  }
}

export default withLocaleContext(AppointmentModalContainer);

export { default as model } from './model';
export { NAMESPACE } from './constants';
export { mapToFormData } from './service';
