import React, { PureComponent } from 'react';

import { Badge, Avatar, Typography } from '@/componentsX/UIElements';

import { getAvatarURL } from '@/utils/image';
import Styles from './ResourceHeader.less';

class ResourceHeader extends PureComponent {
  render() {
    const { resource, resourceInfo } = this.props;
    const { color, avatar, firstName, lastName } = resource;
    const count = resourceInfo ? resourceInfo.count : 0;
    const name = `${firstName} ${lastName} + Nurse 1`;
    return (
      <div className={Styles.resourceHeader}>
        <Badge showZero className={Styles.avatar} count={count} style={{ backgroundColor: color }}>
          <Avatar src={getAvatarURL(avatar)} />
        </Badge>
        <div className={Styles.rightColumn}>
          <Typography.Text ellipsis title={name}>
            {name}
          </Typography.Text>
        </div>
      </div>
    );
  }
}

export default ResourceHeader;
