import { groupBy } from 'lodash';
import request from '@/utils/request';
import { trimString, convertTo24HoursFormat } from '@/utils/utils';
import { FORM } from './constants';

export async function getWorkingTimes(dentist) {
  const { id: dentistId } = dentist;
  return request(`/dentists/${dentistId}/working-hours`, {
    method: 'GET',
  });
}

export function mapToPostData(object) {
  const data = {};
  Object.keys(object).forEach(key => {
    const value = trimString(object[key]);
    if (key === FORM.selectedWeekday) {
      data[FORM.dayOfWeek] = value;
    } else {
      data[key] = convertTo24HoursFormat(value);
    }
  });
  return data;
}

export async function addHours(workingTime, dentist) {
  const { id: dentistId } = dentist;
  const postData = mapToPostData({ ...workingTime });
  return request(`/dentists/${dentistId}/working-hours`, {
    method: 'POST',
    body: postData,
  });
}

export function mapToPutData(object) {
  const { start, end, dayOfWeek } = object;
  const data = {};
  data[FORM.dayOfWeek] = dayOfWeek;
  data[FORM.start] = convertTo24HoursFormat(start);
  data[FORM.end] = convertTo24HoursFormat(end);
  return data;
}

export async function updateHours(workingTime, dentist) {
  const { id: hourId } = workingTime;
  const { id: dentistId } = dentist;
  const putData = mapToPutData({ ...workingTime });
  return request(`/dentists/${dentistId}/working-hours/${hourId}`, {
    method: 'PUT',
    body: putData,
  });
}

export async function deleteHours(hourId, dentist) {
  const { id: dentistId } = dentist;
  return request(`/dentists/${dentistId}/working-hours/${hourId}`, {
    method: 'DELETE',
  });
}

export function groupByWeekDay(workingTimes) {
  const groupedServerData = groupBy(workingTimes, 'dayOfWeek');
  const initDatas = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
  const data = {};
  initDatas.forEach(key => {
    data[key] = { weekDay: key, workingHrs: [] };
  });

  // sorting weekday.
  // sorting working hours.
  Object.keys(groupedServerData).sort((weekDay1, weekDay2) => {
    if (initDatas.indexOf(weekDay1) < initDatas.indexOf(weekDay2)) return -1;
    if (initDatas.indexOf(weekDay1) > initDatas.indexOf(weekDay2)) return 1;
    return 0;
  }).forEach(key => {
    data[key] = {
      ...data[key],
      workingHrs: groupedServerData[key].sort((hour1, hour2) => {
        if (hour1.start < hour2.start) return -1;
        if (hour1.start > hour2.start) return 1;
        return 0;
      }), weekDay: key
    };
  });
  return data;
}
