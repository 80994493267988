import { get } from 'lodash';
import moment from 'moment';

import { DATE_TIME_FORMAT } from '@/constants';
import { getNotifications, markNotificationViewed } from './service';
import { NAMESPACE } from './constants';

const model = {
  namespace: NAMESPACE,

  state: {
    notifications: [],
    totalItem: 0,
    loading: false,
  },

  effects: {
    *fetch({ payload }, { call, put }) {
      const { pagination, sort, refreshing } = payload;
      yield put({ type: 'save', payload: { loading: true } });
      const response = yield call(getNotifications, pagination, sort);
      if (response) {
        yield put({
          type: 'save',
          payload: {
            notifications: get(response, 'data.items', []),
            totalItem: get(response, 'data.total', 0),
            loading: false,
            refreshing
          },
        });
      }
    },
    *markViewed({ payload }, { call, put }) {
      const { id, callback } = payload;
      const response = yield call(markNotificationViewed, id);
      if (response) {
        callback();
      }
    },
  },

  reducers: {
    save(
      state,
      {
        payload: { notifications, totalItem, refreshing },
      }
    ) {
      if (refreshing === undefined) {
        return {
          ...state,
          totalItem
        }
      }
      return {
        ...state,
        notifications: refreshing ? notifications : [...state.notifications, ...notifications],
        totalItem
      };
    },
    pushNotification(
      state,
      {
        payload: { notification },
      }
    ) {
      const mergedNotification = {
        ...notification,
        datetime: moment().format(DATE_TIME_FORMAT),
        read: false,
      };
      const notifications = [...state.notifications, mergedNotification];
      return {
        ...state,
        notifications,
        numOfNewNotifications: state.numOfNewNotifications + 1,
      };
    },
    clearNewNotifications(state) {
      return {
        ...state,
        numOfNewNotifications: 0,
      };
    },
    clear(state) {
      return {
        ...state,
        notifications: [],
      };
    },
  },
};

export default model;
