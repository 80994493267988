import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'dva';
import { get } from 'lodash';
import { formatMessage } from 'umi/locale';
import { updateObject } from '@/utils/utils';
import { SUBMIT_BUTTON } from '@/constants';
import { notification } from '@/componentsX/UIElements';
import ResetPasswordModalView from './view';
import messages from './messages';
import { NAMESPACE } from './constants';

@connect(state => ({
  // isSuccess: state[NAMESPACE].isSuccess,
  loading:
    state.loading.effects[`${NAMESPACE}/create`] || state.loading.effects[`${NAMESPACE}/update`],
  initDataLoading: state.loading.effects[`${NAMESPACE}/initData`],
}))
class ResetPasswordModalContainer extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    isSuccess: PropTypes.bool,
    isEdit: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
    initDataLoading: PropTypes.bool,
  };

  static defaultProps = {
    loading: false,
    initDataLoading: false,
  };

  state = {
    submitButton: '',
    disableButtonUpdate: true,
    user: {},
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { isEdit, user } = nextProps;
    if (isEdit && get(user, 'id') !== get(prevState, 'user.id')) {
      return {
        user,
        disableButtonUpdate: true,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    const { isOpen, isSuccess, dispatch } = this.props;
    const { submitButton } = this.state;

    if (isOpen && !prevProps.isOpen) {
      dispatch({ type: `${NAMESPACE}/clear` });
    }

    if (isOpen && isSuccess) {
      if (submitButton === SUBMIT_BUTTON.saveAndNew) {
        this.handleClearData();
      } else {
        this.handleCancel();
      }
    }
  }

  handleClearData = () => {
    const { dispatch } = this.props;
    this.setState({
      user: {},
      disableButtonUpdate: true,
    });
    dispatch({ type: `${NAMESPACE}/clear` });
  };

  handleCancel = () => {
    const { handleCancel } = this.props;
    handleCancel();
    this.handleClearData();
  };

  handleSave = form => {
    const { dispatch, callback } = this.props;

    form.validateFieldsAndScroll((err, values) => {
      if (err) return;
      this.setState({ submitButton: SUBMIT_BUTTON.save }, () => {
        dispatch({
          type: `${NAMESPACE}/create`,
          payload: { user: values, callback },
        });
      });
    });
  };

  handleSaveAndNew = form => {
    const { dispatch, callback } = this.props;

    form.validateFieldsAndScroll((err, values) => {
      if (err) return;
      this.setState({ submitButton: SUBMIT_BUTTON.saveAndNew }, () => {
        dispatch({
          type: `${NAMESPACE}/create`,
          payload: { user: values, callback },
        });
      });
    });
  };

  handleUpdate = form => {
    const { dispatch, callback } = this.props;

    form.validateFieldsAndScroll((err, values) => {
      if (err) return;
      const {
        user: { id },
      } = this.props;
      const { new_password: newPassword, repeat_new_password: repeatNewPassword } = values;
      if (newPassword !== repeatNewPassword) {
        notification.error({ message: formatMessage(messages.passwordNotMatch) });
        return;
      }
      this.setState({ submitButton: SUBMIT_BUTTON.update }, () => {
        dispatch({
          type: `${NAMESPACE}/resetPassword`,
          payload: {
            id, user: { id, ...values },
            callback: () => {
              notification.success({ message: formatMessage(messages.updatePasswordSuccess) });
              callback();
            },
          },
        });
      });
    });
  };

  handleValuesChange = values => {
    const { user } = this.state;
    const initialValues = {};
    const updateuserInfo = updateObject(user, values);
    const userInfo = Object.assign({}, initialValues, updateuserInfo);
    this.setState({ user: userInfo, disableButtonUpdate: false });
  };

  handleAvatarChange = file => {
    const values = { avatar: file };
    this.handleValuesChange(values);
  };

  render() {
    const { initData, isOpen, isEdit, loading, initDataLoading } = this.props;
    const { user, disableButtonUpdate } = this.state;
    const { submitButton } = this.state;
    return (
      <ResetPasswordModalView
        user={user}
        disableButtonUpdate={disableButtonUpdate}
        initData={initData}
        isOpen={isOpen}
        isEdit={isEdit}
        loading={loading}
        initDataLoading={initDataLoading}
        handleValuesChange={this.handleValuesChange}
        submitButton={submitButton}
        handleSave={this.handleSave}
        handleSaveAndNew={this.handleSaveAndNew}
        handleUpdate={this.handleUpdate}
        handleCancel={this.handleCancel}
        handleUploadAvatar={this.handleAvatarChange}
      />
    );
  }
}

export default ResetPasswordModalContainer;

export { default as model } from './model';
export { NAMESPACE } from './constants';
