import { defineMessages } from 'umi/locale';
import globalMessages from '@/messages';
import { NAMESPACE } from './constants';

const messages = defineMessages({
  next: {
    id: `${NAMESPACE}.next`,
    defaultMessage: 'Next ',
  },
  prev: {
    id: `${NAMESPACE}.prev`,
    defaultMessage: 'Previous ',
  },
  markAsComplete: {
    id: `${NAMESPACE}.markAsComplete`,
    defaultMessage: 'Mark as complete',
  },
  cancelAppointment: {
    id: `${NAMESPACE}.cancelAppointment`,
    defaultMessage: 'Cancel appointment',
  },
  appointmentCompleted: {
    id: `${NAMESPACE}.appointmentCompleted`,
    defaultMessage: 'Appointment is completed!',
  },
  notAvailableSlotSelected: {
    id: `${NAMESPACE}.notAvailableSlotSelected`,
    defaultMessage:
      'Selected appointment slot is not available or slot is out of working times. Please select another!',
  },
});

export default {
  ...messages,
  delete: globalMessages.common.delete,
  edit: globalMessages.common.edit,
  close: globalMessages.common.close,
  selected: globalMessages.common.selected,
  today: globalMessages.common.todayBtn,
  month: globalMessages.common.month,
  week: globalMessages.common.week,
  day: globalMessages.common.day,
};
