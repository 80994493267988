import axios from 'axios';
import { getAccessToken } from './auth';
import { toCamelCaseObject, toSnakeCaseObject } from './common';

const baseConfig = {
  baseURL: APP_END_POINT, // eslint-disable-line
};

function onResponseFulfilled({ data, ...rest }) {
  return {
    ...rest,
    data: toCamelCaseObject(data),
  };
}

function onRequestFulfilled(config) {
  const jwt = getAccessToken();
  const { headers, data } = config;
  const newHeaders = {
    ...headers,
    Authorization: `Bearer ${jwt}`,
  };
  const normalizedData = toSnakeCaseObject(data);
  return {
    ...config,
    headers: newHeaders,
    data: normalizedData,
  };
}

function onPublicRequestFulfilled(config) {
  const { data } = config;
  const normalizedData = toSnakeCaseObject(data);
  return {
    ...config,
    data: normalizedData,
  };
}

export const publicClient = axios.create(baseConfig);
publicClient.interceptors.response.use(onResponseFulfilled);
publicClient.interceptors.request.use(onPublicRequestFulfilled);

export const privateClient = axios.create(baseConfig);
privateClient.interceptors.response.use(onResponseFulfilled);
privateClient.interceptors.request.use(onRequestFulfilled);
