import request from '@/utils/request';
import { SORT_ORDER } from '@/constants';
import { mapSortToBEPost, mapPageToBE } from '@/utils/utils';

// eslint-disable-next-line import/prefer-default-export
export async function getStaffs(
  search = '',
  currentPage = 1,
  sort = { order: SORT_ORDER.ascend, field: 'id' },
) {
  return request(`/staffs/search`, {
    method: 'POST',
    body: {
      page_num: mapPageToBE(currentPage),
      page_size: 20,
      text: search,
      ...mapSortToBEPost(sort),
      status_types: [],
    },
  });
}
