import moment from 'moment';

import request from '@/utils/request';
import { trimString } from '@/utils/utils';
import { SERVER_DATE_FORMAT } from '@/constants';

import { PATIENT_FORM } from './constants';

export function mapToPostData(object) {
  const data = {};
  Object.keys(object).forEach(key => {
    const value = trimString(object[key]);

    if (key === PATIENT_FORM.dob) {
      data[key] = moment(value).format(SERVER_DATE_FORMAT);
    } else if (key === PATIENT_FORM.defaultDentist) {
      data[PATIENT_FORM.defaultDentistId] = value ? value.key : undefined;
    } else {
      data[key] = value;
    }
  });

  // Hard code pwd
  data.password = '123456';
  return data;
}

export async function createPatient(patient, avatar) {
  const postData = mapToPostData({ ...patient, avatar });
  return request('/patients', {
    method: 'POST',
    body: postData,
  });
}

export async function updatePatient(id, patient, newAvatar) {
  const putData = mapToPostData({ ...patient, avatar: newAvatar });
  return request(`/patients/${id}`, {
    method: 'PUT',
    body: putData,
  });
}

export async function getGenders() {
  return [{ name: 'Female', value: 'female' }, { name: 'Male', value: 'male' }];
}

export function mapToFormData(patient = {}) {
  const currentPatient = {};

  Object.keys(PATIENT_FORM).forEach(key => {
    const property = PATIENT_FORM[key];
    const value = trimString(patient[key] || patient[property]);

    if (property === PATIENT_FORM.dob) {
      currentPatient[property] = value ? moment(value, SERVER_DATE_FORMAT) : undefined;
    } else if (property === PATIENT_FORM.defaultDentist) {
      currentPatient[property] = value
        ? { key: value.id, label: `${value.firstName} ${value.lastName}`, ...value }
        : undefined;
    } else {
      currentPatient[property] = value;
    }
  });
  return currentPatient;
}
