import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { debounce, isPlainObject } from 'lodash';
import { connect } from 'dva';

import { Select } from '@/componentsX/UIElements';

import SchedulerTypeFilterView from './view';
import { NAMESPACE, DEBOUNCE_TIME } from './constants';

const { LabeledValue } = Select;

@connect(state => ({
  schedulerTypes: state[NAMESPACE].schedulerTypes,
  loading: state.loading.effects[`${NAMESPACE}/fetch`],
}))
class SchedulerTypeFilterContainer extends PureComponent {
  static propTypes = {
    schedulerTypes: PropTypes.array.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.shape(LabeledValue),
      PropTypes.arrayOf(PropTypes.shape(LabeledValue)),
    ]),
    allowHidden: PropTypes.bool,
    mode: PropTypes.string,
    size: PropTypes.string,
    service: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    className: PropTypes.string,
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    loading: false,
    value: undefined,
    service: undefined,
    className: undefined,
    placeholder: undefined,
    allowHidden: true,
    size: 'default',
    mode: 'default',
  };

  // memoization here
  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.value) {
      return {
        selectedSchedulerType: props.value,
        value: props.value,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.fetchSchedulerTypeDebounce = debounce(this.fetchSchedulerType, DEBOUNCE_TIME);
    this.state = {
      selectedSchedulerType: props.value,
      value: props.value,
    };
  }

  componentDidMount() {
    this.fetchSchedulerType();
  }

  componentDidUpdate(prevProps) {
    const { service } = this.props;
    if (service !== prevProps.service) {
      this.fetchSchedulerType();
    }
  }

  fetchSchedulerType = search => {
    const { service, dispatch } = this.props;
    dispatch({
      type: `${NAMESPACE}/fetch`,
      search,
      service,
    });
  };

  handleValueChange = value => {
    const { onChange, schedulerTypes } = this.props;

    this.setState({
      selectedSchedulerType: value,
    });

    let conductedValue;

    if (Array.isArray(value)) {
      conductedValue = value.map(v => {
        const schedulerType = schedulerTypes.find(p => p.id === Number(v.key));
        return {
          ...v,
          ...schedulerType,
        };
      });
    } else if (isPlainObject(value)) {
      const schedulerType = schedulerTypes.find(p => p.id === Number(value.key));

      conductedValue = {
        ...value,
        ...schedulerType,
      };
    }

    onChange(conductedValue);
  };

  render() {
    const {
      schedulerTypes,
      loading,
      className,
      placeholder,
      size,
      mode,
      allowHidden,
      ...restProps
    } = this.props;
    const { selectedSchedulerType } = this.state;
    return (
      <SchedulerTypeFilterView
        {...restProps}
        allowHidden={allowHidden}
        mode={mode}
        size={size}
        schedulerTypes={schedulerTypes}
        selectedSchedulerType={selectedSchedulerType}
        loading={loading}
        onSearch={this.fetchSchedulerTypeDebounce}
        onChange={this.handleValueChange}
        className={className}
        placeholder={placeholder}
      />
    );
  }
}

export default SchedulerTypeFilterContainer;

export { default as model } from './model';
export { NAMESPACE } from './constants';
