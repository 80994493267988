import PropTypes from 'prop-types';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import defaultSettings from './defaultSettings';
import avatar from './assets/images/placeholder.png';
import asset from './assets/images/asset.png';

export const PREFIX_ID_FE = '@';
export const DEFAULT_PAGE_NUM = 1;
export const PAGE_SIZE_OPTIONS = ['10', '20', '40', '60'];
export const PAGE_SIZE = 60;
export const PAGE_BIG_SIZE = 1000;
export const DEBOUNCE_TIME_INPUT = 500; // 500ms
export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';
export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_AVATAR = avatar;
export const DEFAULT_ASSET = asset;
export const MINUTES_AUTO_LOGOUT = 30;
export const PRIMARY_COLOR = defaultSettings.primaryColor;
export const MOBILE_WIDTH = 767;
export const WORKING_TIME_FORMAT = 'HH:mm';
export const WORKING_TIME_PICKER_FORMAT = 'h:mm a';
export const MONTH_FORMAT = 'MMM, YYYY';
export const WORK_LOAD_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const WORK_LOAD_TIME_FORMAT_Z = 'YYYY-MM-DDTHH:mm:ss[Z]';
export const DEFAULT_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';

export const AUTHORITY = Object.freeze({
  administrator: 'administrator',
  user: 'user',
});

export const ENTITY_TYPE = Object.freeze({});

export const CONFIRM_MODAL_TYPE = Object.freeze({
  delete: 'delete',
  warning: 'warning',
  confirm: 'confirm',
  archive: 'archive',
});

export const LANGUAGES = [
  {
    id: 'vi_vn',
    name: 'Vietnamese',
  },
  {
    id: 'en_us',
    name: 'English (United States)',
  },
];

export const MODAL_TYPE = Object.freeze({});

export const SUBMIT_BUTTON = Object.freeze({
  save: 'save',
  saveAndNew: 'saveAndNew',
  update: 'update',
  delete: 'delete',
});

export const EMPTY_VALUE = '--';
export const SEPARATE_SYMBOL = '-';

export const OPTION = Object.freeze({
  yes: 'Yes',
  no: 'No',
});

export const SORT_ORDER = Object.freeze({
  ascend: 'ascend',
  descend: 'descend',
});

export const FILE_TYPE = Object.freeze({
  checklist: 'checklist',
});

export const COUNTRY = ['Vietnam', 'Singapore', 'Japan', 'Thailand'];

export const DATE_TIME_FORMAT = 'MM/DD/YYYY hh:mm a';
export const DATE_FORMAT = 'MM/DD/YYYY';
export const MIN_DATE_FORMAT = 'DD MMM YYYY';
export const MIN_DATE_FORMAT_WITHOUT_YEAR = 'DD MMM';
export const FULL_DATE_FORMAT = 'dddd, DD MMMM YYYY';
export const FULL_DATE_FORMAT_WITHOUT_YEAR = 'dddd, DD MMMM';
export const TIME_FORMAT = 'HH:mm';
export const TIME_FORMAT_MIN = 'H:mma';
export const TIME_FORMAT_12H = 'hh:mmA';
export const INIT_TIME = '00:00 AM';
export const INIT_TIME_24H = '00:00';
export const DATE_TIME_MODIFY_FORMAT = 'YYYY-MM-DD h:mm';
export const MINUTE_STEP = 15;

/* FONT NAME */
export const PP_FONT_MEDIUM = 'ppFontMedium';
export const PP_FONT_SEMIBOLD = 'ppFontSemibold';
export const PP_TEXT_UPPERCASE = 'ppTextUppercase';

/* FORM INPUT CLASS NAME */
export const PP_FORM_INPUT = 'ppFormInput';
export const PP_FORM_SELECT = 'ppFormSelect';

export const FILTER_CONTEXT = Object.freeze({
  job: 'job',
  route: 'route',
  client: 'client',
});

export const SELECTED_VALUES = Object.freeze({
  FULL: 'full',
  SOME: 'some',
  NONE: 'none',
});

export const SelectOptionPropType = PropTypes.shape({
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

export const DEFAULT_TIMEZONE = 'UTC';

export const TIMEZONES = moment.tz.names();

export const PARENT_PAGES = ['admin'];

export const WEEK_DAYS = [
  {
    value: 'sun',
    name: 'Sunday',
    abbr: 'Sun',
  },
  {
    value: 'mon',
    name: 'Monday',
    abbr: 'Mon',
  },
  {
    value: 'tue',
    name: 'Tuesday',
    abbr: 'Tue',
  },
  {
    value: 'wed',
    name: 'Wednesday',
    abbr: 'Wed',
  },
  {
    value: 'thu',
    name: 'Thursday',
    abbr: 'Thu',
  },
  {
    value: 'fri',
    name: 'Friday',
    abbr: 'Fri',
  },
  {
    value: 'sat',
    name: 'Saturday',
    abbr: 'Sat',
  },
];

export const FormPropType = PropTypes.shape({
  getFieldDecorator: PropTypes.func.isRequired,
});

export const CALENDAR_VIEW = Object.freeze({
  year: 'year',
  month: 'month',
  week: 'week',
  day: 'day',
  agenda: 'agenda',
});

export const CALENDAR_TIME_SLOT = 3; // slot per step
export const CALENDAR_STEP = 10; // minutes

export const SCHEDULER_TYPES = Object.freeze({
  THREE_MONTH: 3,
  SIX_MONTH: 6,
  TWELVE_MONTH: 12,
});

export const APPOINTMENT_STATUS = Object.freeze({
  NEW: 'NEW',
  ACCEPTED: 'ACCEPTED',
  COMPLETED: 'COMPLETED',
  CANCELED: 'CANCELED',
});

export const TABLE_SORT_ORDER = {
  ASC: 'ascend',
  DESC: 'descend',
};

export const SERVER_SORT_ORDER = {
  ascend: 'ASC',
  descend: 'DESC',
};

export const OCCUPATIONS = [
  { id: 'DENTIST', name: 'Dentist', licenseRequired: true },
  { id: 'CLEANER', name: 'Cleaner', licenseRequired: false },
  { id: 'NURSE', name: 'Nurse', licenseRequired: true },
];

export const CONTRACT_TYPES = [
  {
    id: 'FULL_TIME',
    name: 'Full-time',
    template: {
      numberOfPaidTimeOff: 3,
      numberOfHolidays: 5,
      numberOfVacationDays: 10,
    },
    multipleClinics: false,
  },
  {
    id: 'PART_TIME',
    name: 'Part-time',
    template: {
      numberOfPaidTimeOff: 3,
      numberOfHolidays: 0,
      numberOfVacationDays: 0,
    },
    multipleClinics: true,
  },
  {
    id: 'EXEMPT',
    name: 'Exempt',
    template: {
      numberOfPaidTimeOff: 3,
      numberOfHolidays: 5,
      numberOfVacationDays: 10,
    },
    multipleClinics: false,
  },
  {
    id: 'INDEPENDENT_CONTRACTOR',
    name: 'Independent Contractor',
    template: {
      numberOfPaidTimeOff: 3,
      numberOfHolidays: 0,
      numberOfVacationDays: 0,
    },
    multipleClinics: true,
  },
];

export const GENDERS = [
  {
    id: 'MALE',
    name: 'Male',
  },
  {
    id: 'FEMALE',
    name: 'Female',
  },
  {
    id: 'OTHER',
    name: 'Other',
  },
];

export const CONTRACT_TYPE_MAP = {
  fullTime: 'FULL_TIME',
  partTime: 'PART_TIME',
  exempt: 'EXEMPT',
};

export const SOCIAL_NUMBER_PATTERN = /^\d{3}-\d{2}-\d{4}$/;
export const TIN_PATTERN = /^\d{2}-\d{7}$/;

export const NA = 'N/A';

export const BASE_DAY = 8;

export const WEEKDAY_MAP = {
  SUN: 'Sunday',
  MON: 'Monday',
  TUE: 'Tuesday',
  WED: 'Wednesday',
  THU: 'Thursday',
  FRI: 'Friday',
  SAT: 'Saturday',
};

export const WEEKDAYS = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

export const WORKLOAD_STATUS_MAP = {
  rejected: 'REJECT',
  approved: 'APPROVED',
  inReview: 'IN_PREVIEW',
  draft: 'DRAFT',
};

export const ROLE_MAP = {
  user: 'ROLE_USER',
  admin: 'ROLE_ADMIN',
  manager: 'ROLE_MANAGER',
};

export const DURATIONS = [
  { id: '30', name: '30 minutes' },
  { id: '45', name: '45 minutes' },
  { id: '60', name: '60 minutes' },
];

export const daysOfWeek = [
  {
    id: 'SUN',
    name: 'Sunday',
  },
  {
    id: 'MON',
    name: 'Monday',
  },
  {
    id: 'TUE',
    name: 'Tuesday',
  },
  {
    id: 'WED',
    name: 'Wednesday',
  },
  {
    id: 'THU',
    name: 'Thursday',
  },
  {
    id: 'FRI',
    name: 'Friday',
  },
  {
    id: 'SAT',
    name: 'Saturday',
  },
];
