export const NAMESPACE = 'share.roleModal';

export const ROLE_FORM = {
  name: 'name',
  userIds: 'userIds',
  description: 'description',
  features: 'features',
  subFeatures: 'subFeatures',
  registered: 'registered',
};

export const VALUES_FEATURE = {
  FULL: 'full',
  SOME: 'some',
  NONE: 'none',
};

export const ACCESS_TYPES = {
  FULL: 'full',
  SOME: 'some',
  NONE: 'none',
};
