import moment from 'moment';
import { DEFAULT_DATE_FORMAT } from '@/constants';

export default function formatFirstDateEmployment(w4Form) {
  const { firstDateEmployment, ...rest } = w4Form;
  return {
    ...rest,
    first_date_employment: firstDateEmployment
      ? moment(firstDateEmployment, DEFAULT_DATE_FORMAT)
      : null,
  };
}
