import { defineMessages } from 'umi/locale';
import PATH from './constants';

export default defineMessages({
  newPolicy: {
    id: `${PATH}.policy.newPolicy`,
    defaultMessage: 'New policy',
  },
  updatePolicy: {
    id: `${PATH}.policy.updatePolicy`,
    defaultMessage: 'Update policy',
  },
  deletePolicyTitle: {
    id: `${PATH}.policy.deletePolicyTitle`,
    defaultMessage: 'Delete policy',
  },
  deletePolicyContent: {
    id: `${PATH}.policy.deletePolicyContent`,
    defaultMessage: 'Are you sure you want to delete this policy?',
  },
});
