import { isPlainObject } from 'lodash';
import { convertToCamelCase } from './utils';

export function convertCamelCaseResponse(response) {
  if (Array.isArray(response)) {
    return convertCamelCaseArray(response);
  }
  if (isPlainObject(response)) {
    return converCamelCaseObject(response);
  }
  return response;
}

function convertCamelCaseArray(responseArray) {
  return responseArray.map(response => {
    if (Array.isArray(response)) {
      return convertCamelCaseArray(response);
    }
    if (isPlainObject(response)) {
      return converCamelCaseObject(response);
    }
    return response;
  });
}

function converCamelCaseObject(response) {
  const object = {};
  Object.keys(response).forEach(key => {
    const newKey = convertToCamelCase(key);
    let newValue = null;
    const value = response[key];

    if (Array.isArray(value)) {
      newValue = convertCamelCaseArray(value);
    } else if (isPlainObject(value)) {
      newValue = converCamelCaseObject(value);
    } else {
      newValue = value;
    }
    object[newKey] = newValue;
  });
  return object;
}
