import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import { formatMessage } from 'umi/locale';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { PP_FORM_INPUT, SUBMIT_BUTTON } from '@/constants';
import { Modal, Button, Form, Row, Col, Input, Tag } from '@/componentsX/UIElements';
import { FORM, TYPE } from './constants';
import { mapToFormData } from './service';
import messages from './messages';
import Styles from './styles.less';

const { Item: FormItem, createFormField } = Form;

@Form.create({
  mapPropsToFields(props) {
    const template = mapToFormData(props.template);
    return Object.keys(template).reduce((acc, fieldName) => {
      acc[fieldName] = createFormField({ value: template[fieldName] });
      return acc;
    }, {});
  },
  onValuesChange(props, values) {
    props.handleValuesChange(values);
  },
})
class TemplateModalView extends PureComponent {
  static propTypes = {
    disableButtonUpdate: propTypes.bool.isRequired,
    isOpen: propTypes.bool.isRequired,
    isEdit: propTypes.bool.isRequired,
    loading: propTypes.bool.isRequired,
    initDataLoading: propTypes.bool.isRequired,
    submitButton: propTypes.string.isRequired,
    handleSave: propTypes.func.isRequired,
    handleCancel: propTypes.func.isRequired,
    handleSaveAndNew: propTypes.func.isRequired,
    handleUpdate: propTypes.func.isRequired,
  };

  handleCallback = (callBack, values) => {
    const { form } = this.props;
    callBack(form, values);
  };

  renderFooter = () => {
    const {
      isEdit,
      disableButtonUpdate,
      handleSave,
      handleSaveAndNew,
      handleUpdate,
      loading: loadingData,
      initDataLoading,
      submitButton,
      handleCancel,
    } = this.props;
    const loading = initDataLoading || loadingData;
    const handleSubmit = values => this.handleCallback(handleSave, values);
    const handleSubmitAndNew = values => this.handleCallback(handleSaveAndNew, values);
    const handleSubmitUpdate = values => this.handleCallback(handleUpdate, values);
    const submitButtons = isEdit
      ? [
        <Button
          key="submit"
          type="primary"
          loading={loading && submitButton === SUBMIT_BUTTON.update}
          disabled={disableButtonUpdate}
          onClick={handleSubmitUpdate}
        >
          {formatMessage(messages.update)}
        </Button>,
        ]
      : [
        <Button
          key="submitAndNew"
          loading={loading && submitButton === SUBMIT_BUTTON.saveAndNew}
          onClick={handleSubmitAndNew}
        >
          {formatMessage(messages.saveAndNew)}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading && submitButton === SUBMIT_BUTTON.save}
          onClick={handleSubmit}
        >
          {formatMessage(messages.save)}
        </Button>,
        ];
    return [
      <Button key="cancel" onClick={handleCancel}>
        {formatMessage(messages.cancel)}
      </Button>,
      ...submitButtons,
    ];
  };

  render() {
    const {
      form,
      isOpen,
      isEdit,
      handleCancel,
      editorState,
      onChangeEditor,
      template,
    } = this.props;
    const { getFieldDecorator } = form;
    const { models, type } = template || false;
    return (
      <Modal
        title={formatMessage(isEdit ? messages.updateTemplate : messages.newTemplate)}
        centered
        visible={isOpen}
        onCancel={handleCancel}
        destroyOnClose
        maskClosable={false}
        footer={this.renderFooter()}
        width={isEdit ? '1000px' : '600px'}
        className={Styles.modalBody}
      >
        <Form layout="vertical" className={Styles.templateResource}>
          <Row>
            <Col>
              <FormItem label={formatMessage(messages.type)} colon={false}>
                {getFieldDecorator(FORM.type, {
                  rules: [
                    {
                      message: formatMessage(messages.typeErr),
                      whitespace: true,
                    },
                  ],
                })(
                  <Input
                    className={PP_FORM_INPUT}
                    placeholder={formatMessage(messages.type)}
                    disabled={isEdit || false}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormItem label={formatMessage(messages.event)} colon={false}>
                {getFieldDecorator(FORM.event, {
                  rules: [
                    {
                      message: formatMessage(messages.eventErr),
                      whitespace: true,
                    },
                  ],
                })(
                  <Input
                    className={PP_FORM_INPUT}
                    placeholder={formatMessage(messages.event)}
                    disabled={isEdit || false}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          {isEdit && (
            <div>
              <Row>
                <Col>
                  <FormItem label={formatMessage(messages.models)} colon={false}>
                    {models &&
                      models.map(m => {
                        return <Tag>{m}</Tag>;
                      })}
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormItem label={`${formatMessage(messages.html)}`} colon={false}>
                    <Editor
                      editorClassName={Styles.editorClassName}
                      editorState={editorState}
                      onEditorStateChange={onChangeEditor}
                      // handleBeforeInput={type === TYPE.sms ? onBeforeInput : null}
                      toolbarHidden={type === TYPE.sms}
                    />
                  </FormItem>
                </Col>
              </Row>
            </div>
          )}
        </Form>
      </Modal>
    );
  }
}

export default TemplateModalView;
