import { UPLOAD_PATHS } from './constants';
import request from '../request';

export default async function uploadAvatar(file) {
  const postData = new FormData();
  postData.append('image', file);
  return request(UPLOAD_PATHS.AVATAR, {
    method: 'POST',
    body: postData,
  });
}
