import request from '@/utils/request';
import { DENTIST_FORM } from './constants';
import { trimString } from '@/utils/utils';
import { SERVER_DATE_FORMAT } from '@/constants';
import moment from 'moment';

export function mapToPostData(object) {
  const data = {};
  Object.keys(object).forEach(key => {
    const value = trimString(object[key]);
    if (key === DENTIST_FORM.dob) {
      data[key] = moment(value).format(SERVER_DATE_FORMAT);
    } else if (key === DENTIST_FORM.majors) {
      data[DENTIST_FORM.majorIds] = value.map(v => v.key);
    } else {
      data[key] = value;
    }
  });
  // hard code default password and avatar and major ids ?
  data.password = '123456';
  data[DENTIST_FORM.workingHours] = [];
  return data;
}

export async function createDentist(dentist, avatar) {
  const postData = mapToPostData({ ...dentist, avatar });
  return request('/dentists', {
    method: 'POST',
    body: postData,
  });
}

export async function updateDentist(id, dentist, newAvatar) {
  const putData = mapToPostData({ ...dentist, avatar: newAvatar });
  return request(`/dentists/${id}`, {
    method: 'PUT',
    body: putData,
  });
}

export async function getGenders() {
  return [{ name: 'Female', value: 'female' }, { name: 'Male', value: 'male' }];
}

export function mapToFormData(object = {}) {
  const data = {};

  Object.keys(DENTIST_FORM).forEach(key => {
    const formKey = DENTIST_FORM[key];
    const value = trimString(object[key] || object[formKey]);
    if (key === DENTIST_FORM.dob) {
      data[formKey] = value ? moment(value, SERVER_DATE_FORMAT) : undefined;
    } else if (key === DENTIST_FORM.majors) {
      data[formKey] = (value || []).map(major => {
        return {
          key: major.id,
          label: major.name,
          ...major,
        };
      });
    } else {
      data[formKey] = value;
    }
  });
  return data;
}
