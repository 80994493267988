export const NAMESPACE = 'share.appointment.calendar';

export const VALIDATE_EVENT = Object.freeze({
  duplicate: 'duplicate',
  outOfRange: 'outOfRange',
});

export const RIGHT_CONTEXT_MENU = Object.freeze({});

export const APPOINTMENT_FORM = {
  id: 'id',
  start: 'start',
  end: 'end',
  dentist: 'dentist',
  patient: 'patient',
  title: 'title',
  description: 'description',
  services: 'appointment_treatments',
  service: 'service',
  servicesIds: 'service_ids',
  schedulerType: 'schedulerType',
  schedulerTypeId: 'scheduler_type_id',
  dentistId: 'dentist_id',
  patientId: 'patient_id',
};

export const WEEK_DAY_NUMERIC_MAPPING = {
  SUN: 0,
  MON: 1,
  TUE: 2,
  WED: 3,
  THU: 4,
  FRI: 5,
  SAT: 6,
};

export const DEFAULT_SERVICE_DURATION = 30; // minutes

export const DENTIST_COLORS = [
  'rgb(235, 47, 150)',
  '#9254de',
  '#40a9ff',
  'rgb(255, 86, 48)',
  'rgb(89, 126, 247)',
  'rgb(78, 182, 213)',
  '#f759ab',
  'rgb(92, 176, 130)',
  'rgba(0, 0, 0, 0.65)',
  '#52c41a',
  '#36cfc9',
];
