import { createTemplate, updateTemplate } from './service';
import { NAMESPACE } from './constants';

const defaultState = {
  type: '',
  event: '',
  models: '',
  html: '',
};

const model = {
  namespace: NAMESPACE,
  state: {
    initData: defaultState,
    isSuccess: false,
  },
  effects: {
    *create({ payload }, { call, put }) {
      const { template, callback } = payload;
      const response = yield call(createTemplate, template);
      if (response) {
        yield put({ type: 'save' });
        callback();
      }
    },
    *update({ payload }, { call, put }) {
      const { id, body, callback } = payload;
      const response = yield call(updateTemplate, id, body);
      if (response) {
        yield put({ type: 'save' });
        callback();
      }
    },
  },

  reducers: {
    save(state) {
      return {
        ...state,
        isSuccess: true,
      };
    },
    clear(state) {
      return {
        ...state,
        isSuccess: false,
      };
    },
  },
};

export default model;
