import { sortBy } from 'lodash';

// eslint-disable-next-line import/prefer-default-export
export async function getSchedulerTypes(search, service) {
  if (service) {
    return sortBy(service.schedulerTypes, 'id');
  }
  return [
    {
      id: 1,
      name: 'Custom',
      period: null,
      description: '',
    },
    {
      id: 2,
      name: '3 Months',
      period: 3,
      description: '',
    },
    {
      id: 3,
      name: '6 Months',
      period: 6,
      description: '',
    },
    {
      id: 4,
      name: '12 Months',
      period: 12,
      description: '',
    },
  ];
}
