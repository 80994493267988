export default {
  'pages.admin.emailTemplates.list.allTemplates': 'All Templates',
  'pages.admin.emailTemplates.list.type': 'TYPE',
  'pages.admin.emailTemplates.list.event': 'EVENT',
  'pages.admin.emailTemplates.list.models': 'MODELS',
  'pages.admin.emailTemplates.list.html': 'PREVIEW',
  'pages.admin.emailTemplates.list.actions': 'ACTIONS',
  'share.emailTemplateModal.emailTemplate.newTemplate': 'NEW TEMPLATE',
  'share.emailTemplateModal.emailTemplate.updateTemplate': 'UPDATE TEMPLATE',
  'share.emailTemplateModal.emailTemplate.type': 'Type',
  'share.emailTemplateModal.emailTemplate.typeErr': 'Type is required',
  'share.emailTemplateModal.emailTemplate.event': 'Event',
  'share.emailTemplateModal.emailTemplate.eventErr': 'Event is required',
  'share.emailTemplateModal.emailTemplate.models': 'Models',
  'share.emailTemplateModal.emailTemplate.modelsErr': 'Models is required',
  'share.emailTemplateModal.emailTemplate.html': 'Preview',
  'share.emailTemplateModal.emailTemplate.htmlErr': 'Preview is required',
};
