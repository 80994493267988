import { defineMessages } from 'umi/locale';
import PATH from './constants';

export default defineMessages({
  archive: {
    id: `${PATH}.common.archive`,
    defaultMessage: 'Archive',
  },
  block: {
    id: `${PATH}.common.block`,
    defaultMessage: 'Block',
  },
  upload: {
    id: `${PATH}.common.upload`,
    defaultMessage: 'Upload',
  },
  checkAll: {
    id: `${PATH}.common.checkAll`,
    defaultMessage: 'Check All',
  },
  assigned: {
    id: `${PATH}.common.assigned`,
    defaultMessage: 'Assigned',
  },
  notFound: {
    id: `${PATH}.common.notFound`,
    defaultMessage: 'Not Found',
  },
  yes: {
    id: `${PATH}.common.yes`,
    defaultMessage: 'Yes',
  },
  no: {
    id: `${PATH}.common.no`,
    defaultMessage: 'No',
  },
  warning: {
    id: `${PATH}.common.warning`,
    defaultMessage: 'Warning',
  },
  cancel: {
    id: `${PATH}.common.cancel`,
    defaultMessage: 'Cancel',
  },
  update: {
    id: `${PATH}.common.update`,
    defaultMessage: 'Update',
  },
  ok: {
    id: `${PATH}.common.ok`,
    defaultMessage: 'Ok',
  },
  save: {
    id: `${PATH}.common.save`,
    defaultMessage: 'Save',
  },
  saveAs: {
    id: `${PATH}.common.saveAs`,
    defaultMessage: 'Save As',
  },
  saveAndNew: {
    id: `${PATH}.common.saveAndNew`,
    defaultMessage: 'Save & New',
  },
  delete: {
    id: `${PATH}.common.delete`,
    defaultMessage: 'Delete',
  },
  edit: {
    id: `${PATH}.common.edit`,
    defaultMessage: 'Edit',
  },
  resetPassword: {
    id: `${PATH}.common.resetPassword`,
    defaultMessage: 'Reset password',
  },
  close: {
    id: `${PATH}.common.close`,
    defaultMessage: 'Close',
  },
  select: {
    id: `${PATH}.common.select`,
    defaultMessage: 'Select',
  },
  selected: {
    id: `${PATH}.common.selected`,
    defaultMessage: 'selected',
  },
  addMore: {
    id: `${PATH}.common.addMore`,
    defaultMessage: 'Add more',
  },
  more: {
    id: `${PATH}.common.more`,
    defaultMessage: 'More',
  },
  refresh: {
    id: `${PATH}.common.refresh`,
    defaultMessage: 'Refresh',
  },
  filter: {
    id: `${PATH}.common.filter`,
    defaultMessage: 'Filter',
  },
  customizeColumns: {
    id: `${PATH}.common.customizeColumns`,
    defaultMessage: 'Customize Columns',
  },
  new: {
    id: `${PATH}.common.new`,
    defaultMessage: 'New',
  },
  on: {
    id: `${PATH}.common.on`,
    defaultMessage: 'On',
  },
  off: {
    id: `${PATH}.common.off`,
    defaultMessage: 'Off',
  },
  todayBtn: {
    id: `${PATH}.common.todayBtn`,
    defaultMessage: 'Today',
  },
  year: {
    id: `${PATH}.common.year`,
    defaultMessage: 'Year',
  },
  month: {
    id: `${PATH}.common.month`,
    defaultMessage: 'Month',
  },
  week: {
    id: `${PATH}.common.week`,
    defaultMessage: 'Week',
  },
  day: {
    id: `${PATH}.common.day`,
    defaultMessage: 'Day',
  },
  success: {
    id: `${PATH}.common.success`,
    defaultMessage: 'Success',
  },
  error: {
    id: `${PATH}.common.error`,
    defaultMessage: 'Error',
  },
  done: {
    id: `${PATH}.common.done`,
    defaultMessage: 'Done',
  },
  confirm: {
    id: `${PATH}.common.confirm`,
    defaultMessage: 'Confirm',
  },
  previous: {
    id: `${PATH}.common.previous`,
    defaultMessage: 'Previous',
  },
  next: {
    id: `${PATH}.common.next`,
    defaultMessage: 'Next',
  },
  start: {
    id: `${PATH}.common.start`,
    defaultMessage: 'Start',
  },
  end: {
    id: `${PATH}.common.end`,
    defaultMessage: 'End',
  },
});
