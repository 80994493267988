import React, { PureComponent, Fragment } from 'react';
import propTypes from 'prop-types';

import { MIN_DATE_FORMAT_WITHOUT_YEAR, PP_FORM_INPUT, TIME_FORMAT, MINUTE_STEP } from '@/constants';
import { Form, Input, TimePicker, DatePicker, Icon } from '@/componentsX/UIElements';
import PatientFilter from '@/componentsX/_share/PatientFilter';
import DentistFilter from '@/componentsX/_share/DentistFilter';
import ServiceFilter from '@/componentsX/_share/ServiceFilter';
import SchedulerTypeFilter from '@/componentsX/_share/SchedulerTypeFilter';

import { APPOINTMENT_FORM } from '../../constants';
import { mapToFormData } from '../../service';
import Styles from './styles.less';

const { Item: FormItem, createFormField } = Form;
const { TextArea } = Input;

@Form.create({
  mapPropsToFields(props) {
    const appointment = mapToFormData(props.appointment);
    return Object.keys(appointment).reduce((acc, fieldName) => {
      acc[fieldName] = createFormField({ value: appointment[fieldName] });
      return acc;
    }, {});
  },
  onValuesChange(props, values) {
    props.onChange(values);
  },
})
class AppointmentCreationForm extends PureComponent {
  static propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    onChange: propTypes.func.isRequired,
  };

  getForm() {
    const { form } = this.props;
    return form;
  }

  disabledEndDate = date => {
    const { appointment } = this.props;
    return date.isBefore(appointment.start);
  };

  disabledStartDate = date => {
    const { appointment } = this.props;
    return date.isAfter(appointment.end);
  };

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Fragment>
        <div className={Styles.title}>
          <div className={Styles.formItem}>
            <FormItem>
              {getFieldDecorator(APPOINTMENT_FORM.title, {
                rules: [
                  {
                    required: true,
                    message: 'Title is required',
                    whitespace: false,
                    type: 'string',
                  },
                ],
              })(<Input className={PP_FORM_INPUT} placeholder="Add title" />)}
            </FormItem>
          </div>
        </div>
        <div className={Styles.item}>
          <div className={Styles.icon}>
            <Icon type="clock-circle" />
          </div>
          <div className={Styles.formItem}>
            <FormItem>
              {getFieldDecorator(APPOINTMENT_FORM.start, {})(
                <DatePicker
                  disabled
                  allowClear={false}
                  format={MIN_DATE_FORMAT_WITHOUT_YEAR}
                  style={{ width: 90 }}
                  className={Styles.leftElement}
                  disabledDate={this.disabledStartDate}
                />
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator(APPOINTMENT_FORM.start, {})(
                <TimePicker
                  disabled
                  style={{ width: 90 }}
                  className={[Styles.timeInput, Styles.leftElement]}
                  placeholder="Start Time"
                  format={TIME_FORMAT}
                  minuteStep={MINUTE_STEP}
                />
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator(APPOINTMENT_FORM.end, {})(
                <TimePicker
                  disabled
                  className={[Styles.timeInput]}
                  style={{ width: 90 }}
                  placeholder="End Time"
                  format={TIME_FORMAT}
                  minuteStep={MINUTE_STEP}
                />
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator(APPOINTMENT_FORM.end, {})(
                <DatePicker
                  disabled
                  allowClear={false}
                  format={MIN_DATE_FORMAT_WITHOUT_YEAR}
                  style={{ width: 90 }}
                  className={Styles.leftElement}
                  disabledDate={this.disabledEndDate}
                />
              )}
            </FormItem>
          </div>
        </div>

        <div className={Styles.item}>
          <div className={Styles.icon}>
            <Icon type="plus-circle" />
          </div>
          <div className={Styles.formItem}>
            <FormItem colon={false}>
              {getFieldDecorator(APPOINTMENT_FORM.service, {
                rules: [
                  {
                    required: true,
                    message: 'Service is required',
                    whitespace: true,
                    type: 'object',
                  },
                ],
              })(<ServiceFilter disabled />)}
            </FormItem>
          </div>
        </div>
        <div className={Styles.item}>
          <div className={Styles.icon}>
            <Icon type="schedule" />
          </div>
          <div className={Styles.formItem}>
            <FormItem colon={false}>
              {getFieldDecorator(APPOINTMENT_FORM.schedulerType, {
                rules: [
                  {
                    required: true,
                    message: 'Scheduler Type is required',
                    whitespace: true,
                    type: 'object',
                  },
                ],
              })(<SchedulerTypeFilter disabled />)}
            </FormItem>
          </div>
        </div>
        <div className={Styles.item}>
          <div className={Styles.icon}>
            <Icon type="user-add" />
          </div>
          <div className={Styles.formItem}>
            <FormItem colon={false}>
              {getFieldDecorator(APPOINTMENT_FORM.dentist, {
                rules: [
                  {
                    required: true,
                    message: 'Dentist is required',
                    whitespace: true,
                    type: 'object',
                  },
                ],
              })(<DentistFilter disabled onChange={this.handleDentistChange} />)}
            </FormItem>
          </div>
        </div>
        <div className={Styles.item}>
          <div className={Styles.icon}>
            <Icon type="user" />
          </div>
          <div className={Styles.formItem}>
            <FormItem colon={false}>
              {getFieldDecorator(APPOINTMENT_FORM.patient, {
                rules: [
                  {
                    required: true,
                    message: 'Patient is required',
                    whitespace: true,
                    type: 'object',
                  },
                ],
              })(<PatientFilter disabled onChange={this.handlePatientChange} />)}
            </FormItem>
          </div>
        </div>
        <div className={Styles.item}>
          <div className={Styles.icon}>
            <Icon type="align-left" />
          </div>
          <div className={Styles.formItem}>
            <FormItem colon={false}>
              {getFieldDecorator(APPOINTMENT_FORM.description)(
                <TextArea rows={2} placeholder="Add description" />
              )}
            </FormItem>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default AppointmentCreationForm;
