import request from '@/utils/request';
import { SORT_ORDER } from '@/constants';
import { mapSortToBEPost, mapPageToBE } from '@/utils/utils';

// eslint-disable-next-line import/prefer-default-export
export async function getDentists(
  search = null,
  majors = null,
  currentPage = 1,
  sort = { order: SORT_ORDER.ascend, field: 'id' }
) {
  return request('/dentists/search', {
    method: 'POST',
    body: {
      page_num: mapPageToBE(currentPage),
      page_size: 20,
      ...mapSortToBEPost(sort),
      text: search,
      majors,
    },
  });
}
