import { compose } from 'redux';
import normalizeSingle from './normalizeSingle';
import normalizeMarried from './normalizeMarried';
import normalizeMarriedHigherSingleRate from './normalizeMarriedHigherSingleRate';
import normalizeLastNameDifferSsc from './normalizeLastNameDifferSsc';
import normalizeFirstDateEmployment from './normalizeFirstDateEmployment';

export default function normalizeW4Form(w4Form) {
  const normalizer = compose(
    normalizeFirstDateEmployment,
    normalizeLastNameDifferSsc,
    normalizeMarriedHigherSingleRate,
    normalizeMarried,
    normalizeSingle
  );
  const normalizedW4Form = normalizer(w4Form);
  return normalizedW4Form;
}
