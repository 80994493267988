import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'dva';
import { debounce, isPlainObject } from 'lodash';

import { Select } from '@/componentsX/UIElements';

import StaffFilterView from './view';
import { NAMESPACE, DEBOUNCE_TIME } from './constants';

const { LabeledValue } = Select;
@connect(state => ({
  staffs: state[NAMESPACE].staffs,
  loading: state.loading.effects[`${NAMESPACE}/fetch`],
}))
class StaffFilterContainer extends PureComponent {
  static propTypes = {
    staffs: PropTypes.array.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.shape(LabeledValue),
      PropTypes.arrayOf(PropTypes.shape(LabeledValue)),
    ]),
    loading: PropTypes.bool,
    allowCreateInline: PropTypes.bool,
    size: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    allowCreateInline: false,
    loading: false,
    value: undefined,
    size: 'default',
  };

  // memoization here
  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.value) {
      return {
        selectedStaff: props.value,
        value: props.value,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.fetchStaffsDebounce = debounce(this.fetchStaffs, DEBOUNCE_TIME);
    this.state = {
      selectedStaff: props.value,
      value: props.value,
      isModalOpen: false,
    };
  }

  componentDidMount() {
    this.fetchStaffs();
  }

  fetchStaffs = () => {
    const { dispatch } = this.props;
    const { search } = this;
    dispatch({
      type: `${NAMESPACE}/fetch`,
      search,
    });
  };

  handleValueChange = value => {
    const { onChange, staffs } = this.props;
    this.setState({
      selectedStaff: value,
    });
    onChange(value);
  };

  handleSearch = search => {
    this.search = search;
    this.fetchStaffsDebounce();
  };

  handleCreate = () => {
    this.setState({
      isModalOpen: true,
    });
  };

  handleCloseModal = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  handleStaffCreated = staff => {
    if (staff) {
      const { id, firstName, lastName } = staff;

      this.handleValueChange({
        ...staff,
        key: id,
        label: `${firstName} ${lastName}`,
      });
    }
  };

  render() {
    const { staffs, loading, className, size, allowCreateInline, ...restProps } = this.props;
    const { selectedStaff, isModalOpen } = this.state;
    return (
      <Fragment>
        <StaffFilterView
          {...restProps}
          allowCreateInline={allowCreateInline}
          staffs={staffs}
          selectedStaff={selectedStaff}
          className={className}
          size={size}
          loading={loading}
          onSearch={this.handleSearch}
          onChange={this.handleValueChange}
          onCreate={this.handleCreate}
        />
      </Fragment>
    );
  }
}

export default StaffFilterContainer;

export { default as model } from './model';
export { NAMESPACE } from './constants';
