import moment from 'moment';
import { get } from 'lodash';

import { SORT_ORDER } from '@/constants';
import request from '@/utils/request';
import { trimString, mapSortToBEPost } from '@/utils/utils';

import { APPOINTMENT_FORM } from '@/componentsX/_share/AppointmentCalendar';

function mapToPostData(object) {
  const data = {};
  Object.keys(object).forEach(key => {
    const value = trimString(object[key]);
    if (key === APPOINTMENT_FORM.service) {
      const services = Array.isArray(value) ? value : [value];
      data[APPOINTMENT_FORM.servicesIds] = services.map(s => s.key);
    } else if (key === APPOINTMENT_FORM.dentist) {
      data[APPOINTMENT_FORM.dentistId] = value.key;
    } else if (key === APPOINTMENT_FORM.patient) {
      data[APPOINTMENT_FORM.patientId] = value.key;
    } else if (key === APPOINTMENT_FORM.schedulerType) {
      data[APPOINTMENT_FORM.schedulerTypeId] = value.key;
    } else {
      data[key] = value;
    }
  });
  return data;
}

export async function createAppointment(appointment) {
  const postData = mapToPostData(appointment);
  return request('/appointments', {
    method: 'POST',
    body: postData,
  });
}

export async function updateAppointment(id, appointment) {
  const putData = mapToPostData(appointment);
  return request(`/appointments/${id}`, {
    method: 'PUT',
    body: putData,
  });
}

export async function getAppointments(
  start = moment()
    .startOf('day')
    .valueOf(),
  end = moment()
    .endOf('day')
    .valueOf(),
  dentistId = null,
  patientId = null,
  sort = { order: SORT_ORDER.ascend, field: 'id' }
) {
  return request('/appointments/search', {
    method: 'POST',
    body: {
      start,
      end,
      page_number: 0,
      page_size: 1000, // Assume load 1 page with 1000 appointments, calendar view doesn't have pagination
      dentist_id: dentistId,
      patient_id: patientId,
      ...mapSortToBEPost(sort),
    },
  });
}

export async function getDentistWorkingTimes(dentistId) {
  return request(`/dentists/${dentistId}/working-hours`, {
    method: 'GET',
  });
}

export async function sendSMS(appointmentId) {
  return request(`/appointments/${appointmentId}/sms`, {
    method: 'POST',
    body: {},
  });
}

export function mapToFormData(appointment = {}) {
  const currentAppointment = {};
  Object.keys(APPOINTMENT_FORM).forEach(key => {
    const property = APPOINTMENT_FORM[key];
    const value = get(appointment, property);

    if (key === APPOINTMENT_FORM.start || key === APPOINTMENT_FORM.end) {
      currentAppointment[property] = moment(value);
    } else if (property === APPOINTMENT_FORM.dentist) {
      currentAppointment[property] =
        value && value.id
          ? { key: value.id, label: `${value.firstName} ${value.lastName}`, ...value }
          : value;
    } else if (property === APPOINTMENT_FORM.patient) {
      currentAppointment[property] =
        value && value.id
          ? { key: value.id, label: `${value.firstName} ${value.lastName}`, ...value }
          : value;
    } else {
      currentAppointment[property] = value;
    }
  });

  const service = appointment.service
    ? appointment.service
    : get(appointment, 'appointmentTreatments[0].treatment', {});
  const schedulerType = appointment.schedulerType
    ? appointment.schedulerType
    : get(appointment, 'appointmentTreatments[0].schedulerType', {});

  currentAppointment[APPOINTMENT_FORM.service] =
    service && service.id ? { key: service.id, label: `${service.name}`, ...service } : service;

  currentAppointment[APPOINTMENT_FORM.schedulerType] =
    schedulerType && schedulerType.id
      ? { key: schedulerType.id, label: `${schedulerType.name}`, ...schedulerType }
      : schedulerType;
  return currentAppointment;
}
