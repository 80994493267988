import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import { formatMessage } from 'umi/locale';
import { Select, Spin } from '@/componentsX/UIElements';
import messages from './messages';

const { Option, LabeledValue } = Select;

class DentistFilterView extends PureComponent {
  static propTypes = {
    dentists: propTypes.array.isRequired,
    selectedDentist: propTypes.oneOfType([
      propTypes.string,
      propTypes.arrayOf(propTypes.string),
      propTypes.shape(LabeledValue),
      propTypes.arrayOf(propTypes.shape(LabeledValue)),
    ]),
    loading: propTypes.bool.isRequired,
    size: propTypes.string.isRequired,
    className: propTypes.string,
    placeholder: propTypes.string,
    onSearch: propTypes.func.isRequired,
    onChange: propTypes.func.isRequired,
  };

  static defaultProps = {
    className: undefined,
    placeholder: undefined,
    selectedDentist: undefined,
  };

  render() {
    const {
      dentists,
      loading,
      selectedDentist,
      onSearch,
      onChange,
      className,
      placeholder,
      size,
      ...restProps
    } = this.props;
    const placeholderComputed = placeholder || formatMessage(messages.placeholder);

    return (
      <Select
        {...restProps}
        size={size}
        style={{ width: '100%' }}
        className={className}
        labelInValue
        showSearch
        allowClear
        value={selectedDentist}
        placeholder={placeholderComputed}
        notFoundContent={loading ? <Spin size="small" /> : null}
        filterOption={false}
        onSearch={onSearch}
        onChange={onChange}
      >
        {dentists.map(d => (
          <Option key={d.id}>{`${d.firstName} ${d.lastName}`}</Option>
        ))}
      </Select>
    );
  }
}

export default DentistFilterView;
