import { defineMessages } from 'umi/locale';
import PATH from './constants';

export default defineMessages({
  newMajor: {
    id: `${PATH}.major.newMajor`,
    defaultMessage: 'New Major',
  },
  updateMajor: {
    id: `${PATH}.major.updateMajor`,
    defaultMessage: 'Update Major',
  },
  deleteMajorTitle: {
    id: `${PATH}.major.deleteMajorTitle`,
    defaultMessage: 'Delete Major',
  },
  deleteMajorContent: {
    id: `${PATH}.major.deleteMajorContent`,
    defaultMessage: 'Are you sure you want to delete this Major ?',
  },
  archiveMajorTitle: {
    id: `${PATH}.major.archiveMajorTitle`,
    defaultMessage: 'Archive Major',
  },
  archiveMajorContent: {
    id: `${PATH}.major.archiveMajorContent`,
    defaultMessage: 'Are you sure you want to archive this Major ?',
  },
});
