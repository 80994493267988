import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import { formatMessage } from 'umi/locale';
import { Modal, Steps } from '@/componentsX/UIElements';
import messages from './messages';
import Styles from './styles.less';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Step3 from './components/Step3';
import Step4 from './components/Step4';

const { Step } = Steps;

class AppointmentModalView extends PureComponent {
  static propTypes = {
    isOpen: propTypes.bool.isRequired,
    isEdit: propTypes.bool.isRequired,
    userTimezone: propTypes.string.isRequired,
    clinicTimezone: propTypes.string.isRequired,
    handleCancel: propTypes.func.isRequired,
    handleDone: propTypes.func.isRequired,
  };

  state = {
    currentStep: 0,
  };

  next = () => {
    const { currentStep } = this.state;
    this.setState({ currentStep: currentStep + 1 });
  };

  prev = () => {
    const { currentStep } = this.state;
    this.setState({ currentStep: currentStep - 1 });
  };

  done = () => {
    const { handleDone } = this.props;
    this.setState({
      currentStep: 0,
    });
    handleDone();
  };

  handleCancel = () => {
    const { handleCancel } = this.props;
    this.setState({
      currentStep: 0,
    });
    handleCancel();
  };

  render() {
    const { isOpen, isEdit, userTimezone, clinicTimezone } = this.props;
    const { currentStep } = this.state;

    return (
      <Modal
        title={formatMessage(isEdit ? messages.updateAppointment : messages.newAppointment)}
        centered
        visible={isOpen}
        onCancel={this.handleCancel}
        destroyOnClose
        maskClosable={false}
        footer={null}
        width="100%"
        className={Styles.modalBody}
      >
        <div className={Styles.wrapper}>
          <div className={Styles.steps}>
            <Steps current={currentStep} className={Styles.steps}>
              <Step
                title={formatMessage(messages.steps.step1Title)}
                description={formatMessage(messages.steps.step1Description)}
              />
              <Step
                title={formatMessage(messages.steps.step2Title)}
                description={formatMessage(messages.steps.step2Description)}
              />
              <Step
                title={formatMessage(messages.steps.step3Title)}
                description={formatMessage(messages.steps.step3Description)}
              />
              <Step
                title={formatMessage(messages.steps.step4Title)}
                description={formatMessage(messages.steps.step4Description)}
              />
            </Steps>
          </div>

          <div className={Styles.stepsContent}>
            {currentStep === 0 && <Step1 next={this.next} />}
            {currentStep === 1 &&
              <Step2
                clinicTimezone={clinicTimezone}
                userTimezone={userTimezone}
                next={this.next}
                prev={this.prev}
              />
            }
            {currentStep === 2 && <Step3 next={this.next} prev={this.prev} />}
            {currentStep === 3 && <Step4 ok={this.done} />}
          </div>
        </div>
      </Modal>
    );
  }
}

export default AppointmentModalView;
