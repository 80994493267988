/* eslint-disable */
import fetch from 'dva/fetch';
import { notification } from 'antd';
import router from 'umi/router';
import { isUndefined, isEmpty } from 'lodash';

import { isAntdPro } from './utils';
import AuthService from './auth/authService';
import { getAccessToken } from './auth';
import { convertCamelCaseResponse } from './parseResponse';

const auth = new AuthService();

const codeMessage = {
  200: 'The server successfully returned the requested data.',
  201: 'The new or modified data is successful.',
  202: 'A request has entered the background queue (asynchronous task).',
  204: 'Delete data successfully.',
  400: 'There was an error in the issued request. The server did not perform operations to create or modify data.',
  401: 'User does not have permission (token, username, password error).',
  403: 'The user is authorized but access is forbidden.',
  404: 'The request was issued for a non-existent record. The server did not perform the operation.',
  406: 'The requested format is not available.',
  409: 'An attempt was made to create an object that already exists.',
  410: 'The requested resource is permanently deleted and will no longer be available.',
  412: 'One or more conditions given in the request header fields evaluated to false when tested on the server.',
  422: 'A validation error occurred while creating an object.',
  500: 'There was an error in the server. Please check the server.',
  502: 'The gateway is wrong.',
  503: 'Service is unavailable, the server is temporarily overloaded or maintained.',
  504: 'The gateway timed out.',
};

const isResponseError = response => {
  const { status } = response;
  if (!isUndefined(status)) {
    if (
      !isUndefined(status.code) &&
      status.code !== 0 &&
      status.code !== 1 &&
      status.code !== 200 &&
      status.code !== 201 &&
      status.code !== 204
    ) {
      return true;
    }
  }
  return false;
};

const getFieldErrors = fieldErrors => {
  if (isEmpty(fieldErrors)) return null;

  const { field, message } = fieldErrors[0];
  return `${field} ${message}`;
};

const checkStatus = response => {
  const { status } = response;
  if (status >= 200 && status < 300) {
    return response;
  }

  if (status === 401) {
    // @HACK
    /* eslint-disable no-underscore-dangle */
    window.g_app._store.dispatch({
      type: 'global/logout',
      redirect: window.location.href,
    });
    return;
  }

  // environment should not be used
  if (status === 403) {
    router.push('/exception/403');
    return;
  }

  if (status === 400) {
    response.json().then(res => {
      const { message, globalError, fieldErrors, detail } = res;

      let msg;
      if (globalError) {
        msg = globalError.message;
      } else if (fieldErrors) {
        msg = getFieldErrors(fieldErrors);
      }

      notification.error({
        message: 'ERROR',
        description: msg || detail || message,
      });
    });
  } else {
    const errorMsg = codeMessage[status] || response.statusText;
    notification.error({
      message: 'ERROR',
      description: errorMsg,
    });
  }
};

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
function request(url, options = { expirys: isAntdPro() }, isDirectUrl = false) {
  /**
   * Produce fingerprints based on url and parameters
   * Maybe url has the same parameters
   */
  const defaultOptions = {
    credentials: 'include',
  };
  const newOptions = { ...defaultOptions, ...options };
  if (
    newOptions.method === 'POST' ||
    newOptions.method === 'PUT' ||
    newOptions.method === 'DELETE'
  ) {
    if (!(newOptions.body instanceof FormData)) {
      newOptions.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        ...newOptions.headers,
      };
      newOptions.body = JSON.stringify(newOptions.body);
    } else {
      // newOptions.body is FormData
      newOptions.headers = {
        Accept: 'application/json',
        ...newOptions.headers,
      };
    }
  }

  const ACCESS_TOKEN = getAccessToken();

  if (ACCESS_TOKEN) {
    if (newOptions.headers === undefined) {
      newOptions.headers = {};
    }

    newOptions.headers = Object.assign(newOptions.headers, {
      Authorization: `Bearer ${ACCESS_TOKEN}`,
    });
  }

  const fetchUrl = isDirectUrl ? url : `${APP_END_POINT}${url}`;
  return (
    fetch(fetchUrl, newOptions)
      .then(checkStatus)
      .then(response => {
        // // DELETE and 204 do not return data by default
        // // using .json will report an error.
        if (newOptions.method === 'DELETE' || response.status === 204) {
          return response.text();
        }
        const { headers } = response;
        const contentType = headers.get('Content-Type');
        if ('application/pdf' === contentType) {
          return downloadPDF(response);
        }

        if ('application/octet-stream' === contentType) {
          return downloadExcel(response);
        }
        return response.json().then(res => {
          if (isResponseError(res)) {
            // showNotification(res);
            return;
          }
          return convertCamelCaseResponse(res);
        });
      })
      // eslint-disable-next-line
      .catch(e => {
        return;
      })
  );
}

function downloadPDF(response) {
  return response.blob().then(blob => {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = 'download.pdf'; // just a workaround, will be fixed after found a solution
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  });
}

function downloadExcel(response) {
  return response.blob().then(blob => {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = 'payrollExport.xlsx';
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  });
}

export default function callApi(...params) {
  return auth.updateToken(request.bind(this, ...params));
}
