import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { formatMessage } from 'umi/locale';

import NoticeIcon from '@/componentsX/NoticeIcon';
import EmptyNotificationSVG from '@/assets/images/empty_notification.svg';
import messages from './messages';
import Styles from './styles.less';


class NotificationView extends PureComponent {
  static propTypes = {
    notifications: PropTypes.array.isRequired,
    onNoticeVisibleChange: PropTypes.func.isRequired,
    clearNewNotifications: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
  };

  onPopupVisibleChange = visible => {
    const { onNoticeVisibleChange, clearNewNotifications } = this.props;
    onNoticeVisibleChange(visible);
    clearNewNotifications();
  };

  render() {
    const { notifications, unreadNotifications, loading, onItemClick, onLoadMore, totalItem } = this.props;
    return (
      <NoticeIcon
        className={Styles.wrapper}
        count={unreadNotifications}
        bell={<i className={`material-icons ${Styles.icon}`}>notifications_none</i>}
        onItemClick={onItemClick}
        onPopupVisibleChange={this.onPopupVisibleChange}
        loading={loading}
        moreTitle={formatMessage(messages.moreTitle)}
        onLoadMore={onLoadMore}
        popupAlign={{ offset: [20, -16] }}
      >
        <NoticeIcon.Tab
          emptyText={formatMessage(messages.emptyNotification)}
          title={formatMessage(messages.title)}
          emptyImage={EmptyNotificationSVG}
          list={notifications}
          totalItem={totalItem}
          showClear={false}
          className={Styles.tabWrapper}
        />
      </NoticeIcon>
    );
  }
}

export default NotificationView;
