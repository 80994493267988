import { snakeCase, omit } from 'lodash';

export default function(field) {
  return function formatPage(w4Form) {
    const fieldValue = w4Form[field];
    const snakeCaseField = snakeCase(field);
    const formatted = {
      ...w4Form,
      [snakeCaseField]: fieldValue,
    };
    return omit(formatted, [field]);
  };
}
