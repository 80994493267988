export default {
  'global.messages.major.newMajor': 'New Major',
  'global.messages.major.updateMajor': 'Update Major',
  'global.messages.major.deleteMajorTitle': 'Delete Major',
  'global.messages.major.deleteMajorContent': 'Are you sure you want to delete this Major ?',
  'global.messages.major.archiveMajorTitle': 'Archive Major',
  'global.messages.major.archiveMajorContent': 'Are you sure you want to archive this Major ?',
  'share.majorFilter.placeholder': 'Select majors',
  'pages.admin.major.searchPlaceholder': 'Search by name',
  'pages.admin.major.list.allMajors': 'All Majors',
  'pages.admin.major.list.name': 'NAME',
  'pages.admin.major.list.description': 'DESCRIPTION',
  'pages.admin.major.list.createdDate': 'CREATED DATE',
  'pages.admin.major.list.updatedDate': 'UPDATED DATE',
  'pages.admin.major.list.actions': 'ACTIONS',
};
