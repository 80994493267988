import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { formatMessage } from 'umi/locale';
import classNames from 'classnames';
import { get } from 'lodash';
import { PP_FORM_INPUT, PP_FORM_SELECT, SUBMIT_BUTTON } from '@/constants';
import { Button, Col, Form, Input, Spin, Modal, Row, Select } from '@/componentsX/UIElements';
import Features from './components/Features';
import messages from './messages';
import { ROLE_FORM } from './constants';
import Styles from './styles.less';

const { Item: FormItem, createFormField } = Form;
const { Option } = Select;
const { TextArea } = Input;

class RoleModalView extends PureComponent {
  static propTypes = {
    role: PropTypes.object.isRequired,
    users: PropTypes.array.isRequired,
    features: PropTypes.array.isRequired,
    isOpen: PropTypes.bool.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    disableButtonUpdate: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    inputDataLoading: PropTypes.bool.isRequired,
    getRoleDetailLoading: PropTypes.bool.isRequired,
    submitButton: PropTypes.string.isRequired,
    handleSave: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleSaveAndNew: PropTypes.func.isRequired,
    handleUpdate: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      lblFeaturesCls: classNames(Styles.labelExplain, {
        [Styles.hidden]: true,
      }),
    };
  }

  get renderFooter() {
    const {
      loading,
      inputDataLoading,
      getRoleDetailLoading,
      disableButtonUpdate,
      isEditMode,
      submitButton,
      handleSave,
      handleSaveAndNew,
      handleUpdate,
      handleCancel,
    } = this.props;

    const { update, saveAndNew, save, cancel } = messages;
    const handleSubmit = () => this.validateRoles(handleSave);
    const handleSubmitAndNew = () => this.validateRoles(handleSaveAndNew);
    const handleSubmitUpdate = () => this.validateRoles(handleUpdate);

    let actionsGroup;
    if (isEditMode) {
      actionsGroup = [
        <Button
          key="submit"
          type="primary"
          disabled={
            loading ||
            inputDataLoading ||
            getRoleDetailLoading ||
            disableButtonUpdate
          }
          onClick={handleSubmitUpdate}
          loading={loading && submitButton === SUBMIT_BUTTON.update}
        >
          {formatMessage(update)}
        </Button>,
      ]
    } else {
      actionsGroup = [
        <Button
          key="submitAndNew"
          disabled={
            loading ||
            inputDataLoading ||
            getRoleDetailLoading ||
            disableButtonUpdate
          }
          onClick={handleSubmitAndNew}
          loading={loading && submitButton === SUBMIT_BUTTON.saveAndNew}
        >
          {formatMessage(saveAndNew)}
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={
            loading ||
            inputDataLoading ||
            getRoleDetailLoading ||
            disableButtonUpdate
          }
          onClick={handleSubmit}
          loading={loading && submitButton === SUBMIT_BUTTON.save}
        >
          {formatMessage(save)}
        </Button>,
      ];
    }
    return [
      <Button key="cancel" onClick={handleCancel}>
        {formatMessage(cancel)}
      </Button>,
      ...actionsGroup,
    ];
  };

  get renderBody() {
    const { form: { getFieldDecorator }, users, handleSearchUser, features, role } = this.props;
    const { lblFeaturesCls } = this.state;
    const { features: roleFeatures } = role;
    const { name, userIds, description } = ROLE_FORM;
    const {
      labelRoleName,
      roleNameRequired,
      roleNameMax,
      roleNameWhitespace,
      labelAssignedTo,
      labelDescription,
      labelFeatures,
      labelAccess,
      labelFeaturesRequired,
    } = messages;

    return (
      <Form layout="vertical">
        <Row gutter={16}>
          <Col sm={24} md={12}>
            <FormItem label={formatMessage(labelRoleName)} colon={false}>
              {getFieldDecorator(name, {
                rules: [
                  {
                    required: true, message: formatMessage(roleNameRequired),
                  },
                  {
                    max: 50, message: formatMessage(roleNameMax),
                  },
                  {
                    whitespace: true, message: formatMessage(roleNameWhitespace),
                  },
                ],
              })(<Input className={PP_FORM_INPUT} placeholder={formatMessage(labelRoleName)} />)}
            </FormItem>
          </Col>
          <Col sm={24} md={12}>
            <FormItem label={formatMessage(labelAssignedTo)} colon={false}>
              {getFieldDecorator(userIds)(
                <Select
                  className={PP_FORM_SELECT}
                  mode="multiple"
                  optionFilterProp="filterVal"
                  onSearch={handleSearchUser}
                  placeholder={formatMessage(labelAssignedTo)}
                  maxTagCount={2}
                >
                  {users.map(({ id, display }) => (
                    <Option key={id} value={id} filterVal={display}>{display}</Option>)
                  )}
                </Select>)}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={24} md={24}>
            <FormItem label={formatMessage(labelDescription)} colon={false}>
              {getFieldDecorator(description)(
                <TextArea className={PP_FORM_INPUT} placeholder={formatMessage(labelDescription)} />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row glutter={16} className={Styles.labelFeatures}>
          <Col sm={24} md={12}>
            <div className={lblFeaturesCls}>{formatMessage(labelFeaturesRequired)}</div>
            <p className={Styles.labelRequire}>{formatMessage(labelFeatures)}</p>
          </Col>
          <Col sm={24} md={12}>
            <p className={Styles.label}>{formatMessage(labelAccess)}</p>
          </Col>
        </Row>
        <Features features={features} roleFeatures={roleFeatures} />
      </Form>
    );
  }

  validateRoles = (next) => {
    const { selectedRoles, form } = this.props;
    const hasRole = selectedRoles.some(role => get(role, 'subFeatures', []).length > 0);

    this.setState({
      lblFeaturesCls: classNames(Styles.labelExplain, {
        [Styles.hidden]: hasRole,
      }),
    }, () => { if (typeof next === 'function' && hasRole) next(form); })
  };

  render() {
    const { isEditMode, isOpen, handleCancel, inputDataLoading } = this.props;
    const { updateRole, createRole } = messages;

    return (
      <Modal
        title={formatMessage(isEditMode ? updateRole : createRole)}
        visible={isOpen}
        centered
        onCancel={handleCancel}
        destroyOnClose
        maskClosable={false}
        footer={this.renderFooter}
        width="600px"
        bodyStyle={{ maxHeight: '520px', overflow: 'auto' }}
      >
        <Spin spinning={inputDataLoading}>
          {this.renderBody}
        </Spin>
      </Modal>
    )
  }
}

const mapPropsToFields = (props) => {
  const { role } = props;
  return Object.keys(role).reduce((acc, fieldName) => {
    acc[fieldName] = createFormField({ value: role[fieldName] });
    return acc;
  }, {});
};

const onValuesChange = (props, values) => {
  props.handleValuesChange(values);
};

export default Form.create({ mapPropsToFields, onValuesChange })(RoleModalView);
