import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { noop } from 'lodash';
import { Tooltip, Button } from '../UIElements';
import Styles from './index.less';

export default class IconButton extends PureComponent {
  static propTypes = {
    onClick: PropTypes.func,
    icon: PropTypes.any.isRequired,
    text: PropTypes.string,
    size: PropTypes.string,
    className: PropTypes.string,
    tooltip: PropTypes.object,
    isBorder: PropTypes.bool,
    isCircle: PropTypes.bool,
    type: PropTypes.string,
  };

  static defaultProps = {
    size: 'small',
    className: '',
    tooltip: null,
    isBorder: true,
    isCircle: false,
    onClick: noop,
    type: 'default',
    text: '',
  };

  render() {
    const { icon, onClick, tooltip, size, isBorder, isCircle, className, type, text } = this.props;
    const noBorderButton = isBorder ? '' : Styles.noBorderButton;
    const noCircleButton = !isCircle ? '' : Styles.circleShape;
    const button = text ? Styles.textButton : Styles.button;
    const classNameBtn = classNames(button, noBorderButton, noCircleButton, className);

    return (
      <Tooltip {...tooltip}>
        <Button size={size} type={type} onClick={onClick} className={classNameBtn}>
          {typeof icon === 'string' ? (
            <i className={`material-icons ${Styles.icon}`}>{icon}</i>
          ) : (
            icon
          )}
          {text && <span className={Styles.text}>{text}</span>}
        </Button>
      </Tooltip>
    );
  }
}
