import { getSchedulerTypes } from './service';
import { NAMESPACE } from './constants';

const defaultState = {
  schedulerTypes: [],
};

const model = {
  namespace: NAMESPACE,
  state: { ...defaultState },
  effects: {
    *fetch({ search, service }, { call, put }) {
      const response = yield call(getSchedulerTypes, search, service);
      if (response) {
        yield put({
          type: 'save',
          payload: {
            schedulerTypes: response,
          },
        });
      }
    },
  },
  reducers: {
    save(
      state,
      {
        payload: { schedulerTypes },
      }
    ) {
      return {
        ...state,
        schedulerTypes,
      };
    },
  },
};

export default model;
