import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'dva';
import { formatMessage } from 'umi/locale';
import get from 'lodash/get';

import { Form, Row, Col, Input, Button } from '@/componentsX/UIElements';
import PatientFilter from '@/componentsX/_share/PatientFilter';
import DentistFilter from '@/componentsX/_share/DentistFilter';
import ServiceFilter from '@/componentsX/_share/ServiceFilter';
import SchedulerTypeFilter from '@/componentsX/_share/SchedulerTypeFilter';
import { APPOINTMENT_FORM } from '@/componentsX/_share/AppointmentCalendar';

import { NAMESPACE } from '../constants';
import messages from '../messages';
import { mapToFormData } from '../service';
import Styles from './Step1.less';

const { Item: FormItem, createFormField } = Form;
const { TextArea } = Input;

@connect(state => ({
  appointment: state[NAMESPACE].appointment,
}))
@Form.create({
  mapPropsToFields(props) {
    const appointment = mapToFormData(props.appointment);
    return Object.keys(appointment).reduce((acc, fieldName) => {
      acc[fieldName] = createFormField({ value: appointment[fieldName] });
      return acc;
    }, {});
  },
})
class Step1 extends PureComponent {
  static propTypes = {
    next: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const { appointment } = props;

    this.state = {
      service: get(appointment, 'appointmentTreatments[0].treatment', undefined),
      selectedMajors: appointment.dentist ? appointment.dentist.majors.map(m => m.id) : [],
    };
  }

  handleSubmit = () => {
    const { next, form, dispatch } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (err) return;

      dispatch({
        type: `${NAMESPACE}/saveStepFormData`,
        payload: values,
      });
      next();
    });
  };

  handlePatientChange = patient => {
    if (!patient) return;

    const { defaultDentist } = patient;
    if (!defaultDentist) return;

    const { id, firstName, lastName } = defaultDentist;
    const { form } = this.props;
    form.setFieldsValue({
      [APPOINTMENT_FORM.dentist]: { key: id, label: `${firstName} ${lastName}`, ...defaultDentist },
    });

    // Change dentist to trigger filter services.
    this.handleDentistChange(defaultDentist);
  };

  handleDentistChange = dentist => {
    if (!dentist) return;
    const { majors } = dentist;
    this.setState({
      selectedMajors: majors.map(m => m.id),
    });

    // Clean service
    const { form } = this.props;
    form.setFieldsValue({
      [APPOINTMENT_FORM.service]: undefined,
      [APPOINTMENT_FORM.schedulerType]: undefined,
    });
  };

  handleServiceChange = service => {
    if (!service) return;
    this.setState({
      service,
    });

    // Clean scheduler type
    const { form } = this.props;
    form.setFieldsValue({
      [APPOINTMENT_FORM.schedulerType]: undefined,
    });
  };

  render() {
    const { form } = this.props;
    const { selectedMajors, service } = this.state;
    const { getFieldDecorator } = form;

    return (
      <Form layout="vertical" className={Styles.wrapper}>
        <Row gutter={16}>
          <Col sm={24} md={12}>
            <FormItem label={formatMessage(messages.patient)} colon={false}>
              {getFieldDecorator(APPOINTMENT_FORM.patient, {
                rules: [
                  {
                    required: true,
                    message: formatMessage(messages.patientError),
                    whitespace: true,
                    type: 'object',
                  },
                ],
              })(<PatientFilter disabled onChange={this.handlePatientChange} />)}
            </FormItem>
          </Col>
          <Col sm={24} md={12}>
            <FormItem label={formatMessage(messages.dentist)} colon={false}>
              {getFieldDecorator(APPOINTMENT_FORM.dentist, {
                rules: [
                  {
                    required: true,
                    message: formatMessage(messages.dentistError),
                    whitespace: true,
                    type: 'object',
                  },
                ],
              })(<DentistFilter onChange={this.handleDentistChange} />)}
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col sm={24} md={24}>
            <FormItem label="Service" colon={false}>
              {getFieldDecorator(APPOINTMENT_FORM.service, {
                rules: [
                  {
                    required: true,
                    message: 'Service is required',
                    whitespace: true,
                    type: 'object',
                  },
                ],
              })(
                <ServiceFilter
                  placeholder={formatMessage(messages.servicesPlaceholder)}
                  majors={selectedMajors}
                  onChange={this.handleServiceChange}
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col sm={24} md={24}>
            <FormItem label="Scheduler Type" colon={false}>
              {getFieldDecorator(APPOINTMENT_FORM.schedulerType, {
                rules: [
                  {
                    required: true,
                    message: 'Scheduler type is required',
                    whitespace: true,
                    type: 'object',
                  },
                ],
              })(<SchedulerTypeFilter service={service} />)}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={24} md={24}>
            <FormItem label={formatMessage(messages.title)} colon={false}>
              {getFieldDecorator(APPOINTMENT_FORM.title, {
                rules: [
                  {
                    required: true,
                    message: formatMessage(messages.titleError),
                    whitespace: true,
                  },
                  {
                    max: 255,
                    message: formatMessage(messages.titleMaxLengthError),
                  },
                ],
              })(<Input placeholder={formatMessage(messages.titlePlaceholder)} />)}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={24} md={24}>
            <FormItem label={formatMessage(messages.description)} colon={false}>
              {getFieldDecorator(APPOINTMENT_FORM.description, {
                rules: [
                  {
                    message: formatMessage(messages.descriptionError),
                    whitespace: true,
                  },
                ],
              })(
                <TextArea
                  size="small"
                  rows={4}
                  placeholder={formatMessage(messages.descriptionPlaceholder)}
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row>
          <FormItem>
            <Button type="primary" onClick={this.handleSubmit}>
              Next
            </Button>
          </FormItem>
        </Row>
      </Form>
    );
  }
}

export default Step1;
