import React from 'react';
import { Link } from 'dva/router';
import classNames from 'classnames';

import PageHeader from '@/componentsX/PageHeader';
import styles from './PageHeaderLayout.less';

export default ({
  children,
  wrapperClassName,
  contentClassName,
  pageHeaderWrapper,
  top,
  ...restProps
}) => (
  <div className={classNames(styles.wrapper, wrapperClassName)}>
    {top}
    <PageHeader
      key="pageheader"
      className={pageHeaderWrapper}
      {...restProps}
      linkElement={Link}
    />
    {children ? (
      <div className={classNames(styles.content, contentClassName)}>{children}</div>
    ) : null}
  </div>
);
