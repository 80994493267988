import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { formatMessage } from 'umi/locale';
import { PP_FORM_INPUT, SUBMIT_BUTTON } from '@/constants';
import PhoneInput from '@/componentsX/PhoneInput';
import { Modal, Button, Form, Row, Col, Input } from '@/componentsX/UIElements';
import { MAJOR_FORM } from './constants';
import { mapToFormData } from './service';
import messages from './messages';
import Styles from './styles.less';

const { Item: FormItem, createFormField } = Form;

@Form.create({
  mapPropsToFields(props) {
    const major = mapToFormData(props.major);
    return Object.keys(major).reduce((acc, fieldName) => {
      acc[fieldName] = createFormField({ value: major[fieldName] });
      return acc;
    }, {});
  },
  onValuesChange(props, values) {
    props.handleValuesChange(values);
  },
})
class MajorModalView extends PureComponent {
  static propTypes = {
    disableButtonUpdate: propTypes.bool.isRequired,
    isOpen: propTypes.bool.isRequired,
    isEdit: propTypes.bool.isRequired,
    loading: propTypes.bool.isRequired,
    initDataLoading: propTypes.bool.isRequired,
    submitButton: propTypes.string.isRequired,
    handleSave: propTypes.func.isRequired,
    handleCancel: propTypes.func.isRequired,
    handleSaveAndNew: propTypes.func.isRequired,
    handleUpdate: propTypes.func.isRequired,
  };

  handleCallback = (callBack, values) => {
    const { form } = this.props;
    callBack(form, values);
  };

  renderFooter = () => {
    const {
      isEdit,
      disableButtonUpdate,
      handleSave,
      handleSaveAndNew,
      handleUpdate,
      loading: loadingData,
      initDataLoading,
      submitButton,
      handleCancel,
    } = this.props;
    const loading = initDataLoading || loadingData;
    const handleSubmit = values => this.handleCallback(handleSave, values);
    const handleSubmitAndNew = values => this.handleCallback(handleSaveAndNew, values);
    const handleSubmitUpdate = values => this.handleCallback(handleUpdate, values);
    const submitButtons = isEdit
      ? [
        <Button
          key="submit"
          type="primary"
          loading={loading && submitButton === SUBMIT_BUTTON.update}
          disabled={disableButtonUpdate}
          onClick={handleSubmitUpdate}
        >
          {formatMessage(messages.update)}
        </Button>,
      ]
      : [
        <Button
          key="submitAndNew"
          loading={loading && submitButton === SUBMIT_BUTTON.saveAndNew}
          onClick={handleSubmitAndNew}
        >
          {formatMessage(messages.saveAndNew)}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading && submitButton === SUBMIT_BUTTON.save}
          onClick={handleSubmit}
        >
          {formatMessage(messages.save)}
        </Button>,
      ];
    return [
      <Button key="cancel" onClick={handleCancel}>
        {formatMessage(messages.cancel)}
      </Button>,
      ...submitButtons,
    ];
  };

  render() {
    const {
      form,
      isOpen,
      isEdit,
      handleCancel,
    } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Modal
        title={formatMessage(isEdit ? messages.updateMajor : messages.newMajor)}
        centered
        visible={isOpen}
        onCancel={handleCancel}
        destroyOnClose
        maskClosable={false}
        footer={this.renderFooter()}
        width="600px"
        className={Styles.modalBody}
      >
        <Form layout="vertical" className={Styles.majorResource}>
          <Row>
            <Col>
              <FormItem label={formatMessage(messages.name)} colon={false}>
                {getFieldDecorator(MAJOR_FORM.name, {
                  rules: [
                    {
                      required: true,
                      message: formatMessage(messages.nameRequired),
                      whitespace: true,
                    },
                  ],
                })(
                  <Input
                    className={PP_FORM_INPUT}
                    placeholder={formatMessage(messages.namePlaceholder)}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormItem label={formatMessage(messages.description)} colon={false}>
                {getFieldDecorator(MAJOR_FORM.description, {
                  rules: [
                    {
                      required: true,
                      message: formatMessage(messages.descriptionError),
                      whitespace: true,
                    },
                  ],
                })(
                  <Input
                    className={PP_FORM_INPUT}
                    placeholder={formatMessage(messages.descriptionPlaceholder)}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default MajorModalView;
