import moment from 'moment';
import { Provider, Consumer, withLocaleContext } from './context';

export function setDefaultTimezone(timezone) {
  moment.tz.setDefault(timezone);
}

export function setClinicTimezone(timezone) {
  return localStorage.setItem('clinicTimezone', timezone);
}

export function getClinicTimezone() {
  return localStorage.getItem('clinicTimezone') || 'UTC';
}

export const toTimezone = (dateTime, timeZoneName) => {
  const m = moment.tz(dateTime, timeZoneName);
  return new Date(m.year(), m.month(), m.date(), m.hour(), m.minute(), m.second());
};

export const toTimezoneAsMoment = (dateTime, timeZoneName) => {
  return moment(toTimezone(dateTime, timeZoneName));
};

export const fromTimezone = (date, timeZoneName) => {
  const dateM = moment(date);
  return moment.tz(
    {
      year: dateM.year(),
      month: dateM.month(),
      date: dateM.date(),
      hour: dateM.hour(),
      minute: dateM.minute(),
      second: dateM.second(),
    },
    timeZoneName
  );
};

export { default as withLocale } from './withLocale';
export { Provider, Consumer, withLocaleContext };
