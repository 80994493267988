import { defineMessages } from 'umi/locale';
import { NAMESPACE } from '../../constants';

const messages = defineMessages({
  addNewHours: {
    id: `${NAMESPACE}.addNewHours`,
    defaultMessage: 'Add New Hours',
  },
  addHours: {
    id: `${NAMESPACE}.addHours`,
    defaultMessage: 'Add Hours',
  },
  monday: {
    id: `${NAMESPACE}.monday`,
    defaultMessage: 'Monday',
  },
  tuesday: {
    id: `${NAMESPACE}.tuesday`,
    defaultMessage: 'Tuesday',
  },
  wednesday: {
    id: `${NAMESPACE}.wednesday`,
    defaultMessage: 'Wednesday',
  },
  thursday: {
    id: `${NAMESPACE}.thursday`,
    defaultMessage: 'Thursday',
  },
  friday: {
    id: `${NAMESPACE}.friday`,
    defaultMessage: 'Friday',
  },
  saturday: {
    id: `${NAMESPACE}.saturday`,
    defaultMessage: 'Saturday',
  },
  sunday: {
    id: `${NAMESPACE}.sunday`,
    defaultMessage: 'Sunday',
  },
  from: {
    id: `${NAMESPACE}.from`,
    defaultMessage: 'From',
  },
  to: {
    id: `${NAMESPACE}.to`,
    defaultMessage: 'to',
  },
});

export default {
  ...messages
};
