export const NAMESPACE = 'share.patientCheckinFormModal';

export const FIELD_TYPES = [
  {
    name: 'TEXT',
  },
  {
    name: 'NUMBER',
  },
  {
    name: 'SELECT',
  },
  {
    name: 'DATE',
  },
  {
    name: 'CHECKBOX',
  },
  {
    name: 'PHONE',
  },
];
export const FIELD_STATUS = [
  {
    name: 'ACTIVE',
  },
  {
    name: 'INACTIVE',
  },
];
export const FORM = {
  id: 'id',
  fieldType: 'field_type',
  fieldName: 'field_name',
  fieldOptions: 'field_options',
  fieldOrder: 'field_order',
  status: 'status',
};
