import { get } from 'lodash';
import { EditorState, convertFromHTML, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import request from '@/utils/request';
import { trimString } from '@/utils/utils';
import { FORM } from './constants';

export function mapToPostData(object) {
  const data = {};
  Object.keys(object).forEach(key => {
    const value = trimString(object[key]);
    if (key === FORM.type) {
      data[key] = value;
    } else if (key === FORM.event) {
      data[key] = value;
    }
  });
  return data;
}

export function mapToPutData(body) {
  const key = FORM.html;
  const data = {};
  data[key] = body
  return data;
}

export async function createTemplate(template) {
  const postData = mapToPostData(template);
  return request('/setting-templates', {
    method: 'POST',
    body: postData,
  });
}

export async function updateTemplate(id, body) {
  const putData = mapToPutData(body);
  return request(`/setting-templates/${id}/body/`, {
    method: 'PUT',
    body: putData
  });
}

export function getEditorStateFromHTML(html) {
  const blocksFromHTML = convertFromHTML(html);
  const state = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap,
  );
  return EditorState.createWithContent(
    state
  );
}

export function replaceNewLineWithBr(text) {
  const replacedText = text.replace(/\n/g, '<br />');
  return replacedText;
}

export function convertEditorStateToRaw(editorState) {
  const currentContent = editorState.getCurrentContent();
  const raw = convertToRaw(currentContent);
  const { blocks } = raw;
  const data = blocks[0].text;
  return data;
}

export function convertEditorStateToString(editorState) {
  const currentContent = editorState.getCurrentContent();
  const raw = convertToRaw(currentContent);
  const draft = draftToHtml(raw);
  return replaceNewLineWithBr(draft);
}


export function mapToFormData(object = {}) {
  const data = {};
  Object.keys(FORM).forEach(key => {
    const formKey = FORM[key];
    const value = trimString(object[key] || object[formKey]);
    data[formKey] = value;
  });
  return data;
}
