import { get } from 'lodash';
import { getStaffs } from './service';
import { NAMESPACE } from './constants';

const defaultState = {
  staffs: [],
};

const model = {
  namespace: NAMESPACE,
  state: { ...defaultState },
  effects: {
    *fetch({ search }, { call, put }) {
      const response = yield call(getStaffs, search);
      if (response) {
        yield put({
          type: 'save',
          payload: {
            staffs: get(response, 'data.items', []),
          },
        });
      }
    },
  },
  reducers: {
    save(
      state,
      {
        payload: { staffs },
      }
    ) {
      return {
        ...state,
        staffs,
      };
    },
  },
};

export default model;
