import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'dva';
import { formatMessage } from 'umi-plugin-react/locale';

import { CALENDAR_STEP, CALENDAR_VIEW } from '@/constants';
import { getCalendarDateRange } from '@/utils/utils';
import { withLocaleContext, fromTimezone, toTimezoneAsMoment } from '@/utils/locale';

import { message } from '@/componentsX/UIElements';

import { NAMESPACE } from './constants';
import Styles from './styles.less';
import CalendarView from './view';
import messages from './messages';
import CalendarNavigator from './components/CalendarNavigator';

import {
  getMaxDurationOfServices,
  isAppointmentSlotValid,
  isDateAvailable,
  isSlotAvailableStrictly,
} from './service';

@connect(state => ({
  workingDentists: state[NAMESPACE].workingDentists,
  appointments: state[NAMESPACE].appointments,
  busyAppointments: state[NAMESPACE].busyAppointments,
  dentistWorkingTimes: state[NAMESPACE].dentistWorkingTimes,
  lastAppointment: state[NAMESPACE].lastAppointment,
  loading:
    state.loading.effects[`${NAMESPACE}/fetch`] ||
    state.loading.effects[`${NAMESPACE}/fetchBusyAppointments`] ||
    state.loading.effects[`${NAMESPACE}/fetchDentistWorkingTimes`] ||
    state.loading.effects[`${NAMESPACE}/fetchLastAppointment`] ||
    state.loading.effects[`${NAMESPACE}/fetchWorkingDentists`],
}))
class CalendarContainer extends PureComponent {
  static propTypes = {
    allowEdit: PropTypes.bool,
    isEdit: PropTypes.bool,
    loading: PropTypes.bool,
    highlightedAppointment: PropTypes.object,
    filter: PropTypes.object.isRequired,
    viewMode: PropTypes.string.isRequired,
    currentDate: PropTypes.object.isRequired,
    draftAppointment: PropTypes.object,

    onSelectTimeSlot: PropTypes.func,
    onSelectAppointment: PropTypes.func,
    onEditAppointment: PropTypes.func,
    onDeleteAppointment: PropTypes.func,
    onAppointmentPopoverClose: PropTypes.func,
    onCreateAppointment: PropTypes.func,
  };

  static defaultProps = {
    loading: false,
    isEdit: false,
    allowEdit: true,
    draftAppointment: undefined,
    highlightedAppointment: undefined,
    onSelectTimeSlot: () => null,
    onSelectAppointment: () => null,
    onEditAppointment: () => null,
    onDeleteAppointment: () => null,
    onCreateAppointment: () => null,
    onAppointmentPopoverClose: () => null,
  };

  maxServicesDuration = 0;

  componentDidMount() {
    this.fetchWorkingDentists();
    this.fetchAppointments();
    this.fetchBusyAppointments();
    this.fetchDentistWorkingTimes();
    this.fetchLastAppointment();
    this.calculateMaxServiceDuration();
  }

  componentDidUpdate(prevProps) {
    const { filter, viewMode, currentDate } = this.props;
    const { filter: prevFilter } = prevProps;

    const isViewModeUpdated = viewMode !== prevProps.viewMode;
    const isCurrentDateUpdated = currentDate !== prevProps.currentDate;
    const isFilterUpdated = filter !== prevFilter;
    const isDentistUpdated = filter.dentist !== prevFilter.dentist;
    const isPatientUpdated = filter.patient !== prevFilter.patient;
    const isServiceUpdated = filter.service !== prevFilter.service;

    if (isFilterUpdated || isViewModeUpdated) {
      this.fetchAppointments();
      this.fetchLastAppointment();
    }

    // Only fetch appointments, last appointment if current is updated and
    // the new date is out of previous ranged that already fetched.
    if (isCurrentDateUpdated) {
      const { startDate, endDate } = getCalendarDateRange(viewMode, prevProps.currentDate);
      if (!currentDate.isBetween(startDate, endDate)) {
        this.fetchAppointments();
        this.fetchLastAppointment();
      }
    }

    if (isDentistUpdated) {
      this.fetchDentistWorkingTimes();
    }

    if (isPatientUpdated || isDentistUpdated) {
      this.fetchBusyAppointments();
    }

    if (isServiceUpdated) {
      this.calculateMaxServiceDuration();
    }
  }

  calculateMaxServiceDuration = () => {
    const { filter } = this.props;
    this.maxServicesDuration = filter.service ? getMaxDurationOfServices([filter.service]) : 0;
  };

  fetchWorkingDentists = () => {
    const { dispatch, userTimezone } = this.props;
    dispatch({
      type: `${NAMESPACE}/fetchWorkingDentists`,
      payload: {
        userTimezone,
      },
    });
  };

  fetchAppointments = () => {
    const { dispatch, userTimezone, filter, currentDate, viewMode } = this.props;
    const { startDate, endDate } = getCalendarDateRange(viewMode, currentDate);
    const { service, dentist, patient, schedulerType } = filter;

    dispatch({
      type: `${NAMESPACE}/fetch`,
      payload: {
        startDate: fromTimezone(startDate, userTimezone),
        endDate: fromTimezone(endDate, userTimezone),
        serviceId: service && service.id,
        schedulerTypeId: schedulerType && schedulerType.id,
        dentistId: dentist && dentist.id,
        patientId: patient && patient.id,
        userTimezone,
      },
    });
  };

  fetchDentistWorkingTimes = () => {
    const { dispatch, filter } = this.props;
    const { dentist } = filter;

    dispatch({
      type: `${NAMESPACE}/fetchDentistWorkingTimes`,
      dentistId: dentist && dentist.id,
    });
  };

  fetchLastAppointment = cb => {
    const { dispatch, userTimezone, filter } = this.props;
    const { service, patient, schedulerType } = filter;

    dispatch({
      type: `${NAMESPACE}/fetchLastAppointment`,
      payload: {
        serviceId: service && service.id,
        schedulerTypeId: schedulerType && schedulerType.id,
        patientId: patient && patient.id,
        userTimezone,
        callback: cb,
      },
    });
  };

  fetchBusyAppointments = () => {
    const { filter } = this.props;
    const { dentist, patient } = filter;

    const { dispatch, userTimezone, currentDate, viewMode } = this.props;
    const { startDate, endDate } = getCalendarDateRange(viewMode, currentDate);

    dispatch({
      type: `${NAMESPACE}/fetchBusyAppointments`,
      payload: {
        startDate: fromTimezone(startDate, userTimezone),
        endDate: fromTimezone(endDate, userTimezone),
        dentistId: dentist && dentist.id,
        patientId: patient && patient.id,
        userTimezone,
      },
    });
  };

  getDayStyle = date => {
    const { dentistWorkingTimes, lastAppointment, filter, isEdit } = this.props;
    const { dentist, schedulerType } = filter;

    let available = isAppointmentSlotValid(
      moment(date),
      schedulerType,
      isEdit ? null : lastAppointment
    );

    if (available) {
      available = dentist ? isDateAvailable(moment(date), dentistWorkingTimes) : true;
    }

    return available ? { className: Styles.availableDate } : { className: Styles.notAvailableDate };
  };

  getSlotStyle = date => {
    const {
      dentistWorkingTimes,
      lastAppointment,
      userTimezone,
      clinicTimezone,
      filter,
      isEdit,
    } = this.props;
    const { dentist, schedulerType } = filter;

    const dateAsMoment = moment(date);
    const now = toTimezoneAsMoment(moment(), userTimezone);
    const endSlotMoment = dateAsMoment.clone().add(CALENDAR_STEP, 'minutes');

    let available = !dentist || dateAsMoment.isAfter(now);
    if (available) {
      available = isAppointmentSlotValid(
        dateAsMoment,
        schedulerType,
        isEdit ? null : lastAppointment
      );
    }

    if (available) {
      available = dentist
        ? isSlotAvailableStrictly(
            dateAsMoment,
            endSlotMoment,
            dentistWorkingTimes,
            userTimezone,
            clinicTimezone
          )
        : true;
    }

    return available ? { className: Styles.availableSlot } : { className: Styles.notAvailableSlot };
  };

  handleSelectTimeSlot = ({ start }) => {
    const {
      dentistWorkingTimes,
      viewMode,
      userTimezone,
      clinicTimezone,
      filter,
      onSelectTimeSlot,
    } = this.props;
    const { maxServicesDuration } = this;
    const { dentist, service, schedulerType, patient } = filter;

    // Skip for MONTH mode.
    if (CALENDAR_VIEW.month === viewMode) {
      return;
    }

    // Skip for unfiltered service and dentist
    if (!service || !dentist || !schedulerType || !patient) {
      return;
    }

    const now = toTimezoneAsMoment(moment(), userTimezone);
    const startTime = moment(start);

    if (startTime.isBefore(now)) {
      return;
    }

    const endTime = startTime.clone().add(maxServicesDuration, 'minutes');

    if (
      isSlotAvailableStrictly(startTime, endTime, dentistWorkingTimes, userTimezone, clinicTimezone)
    ) {
      onSelectTimeSlot(fromTimezone(startTime, userTimezone), fromTimezone(endTime, userTimezone));
    } else {
      message.warn(formatMessage(messages.notAvailableSlotSelected));
    }
  };

  handleAppointmentPopoverClose = () => {
    const { onAppointmentPopoverClose } = this.props;
    onAppointmentPopoverClose();
  };

  handleCreateAppointment = () => {
    const { onCreateAppointment } = this.props;
    onCreateAppointment();
  };

  render() {
    const {
      allowEdit,
      currentDate,
      viewMode,
      userTimezone,
      appointments,
      busyAppointments,
      loading,
      draftAppointment,
      highlightedAppointment,
      workingDentists,
      onSelectAppointment,
      onEditAppointment,
      onDeleteAppointment,
      onCompleteAppointment,
      onCancelAppointment,
    } = this.props;

    const renderedAppointments = draftAppointment
      ? [...appointments.filter(appt => appt.id !== draftAppointment.id), draftAppointment]
      : appointments;

    return (
      <CalendarView
        loading={loading}
        allowEdit={allowEdit}
        workingDentists={workingDentists}
        appointments={renderedAppointments}
        busyAppointments={busyAppointments}
        currentDate={currentDate}
        viewMode={viewMode}
        highlightedAppointment={highlightedAppointment}
        userTimezone={userTimezone}
        onSelectTimeSlot={this.handleSelectTimeSlot}
        onSelectAppointment={onSelectAppointment}
        onEditAppointment={onEditAppointment}
        onDeleteAppointment={onDeleteAppointment}
        onCreateAppointment={this.handleCreateAppointment}
        onAppointmentPopoverClose={this.handleAppointmentPopoverClose}
        dayPropGetter={this.getDayStyle}
        slotPropGetter={this.getSlotStyle}
        onCompleteAppointment={onCompleteAppointment}
        onCancelAppointment={onCancelAppointment}
      />
    );
  }
}

export default withLocaleContext(CalendarContainer);
export { NAMESPACE, APPOINTMENT_FORM } from './constants';
export { default as model } from './model';
export { CalendarNavigator };
export {
  buildWorkingTimes,
  buildTimeString,
  calculateDentistColor,
  colorizedAppointment,
  getMaxDurationOfServices,
  isDateAvailable,
  isSlotAvailableStrictly,
  transformAppointment,
  transformAppointments,
} from './service';
