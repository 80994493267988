import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { debounce, isPlainObject } from 'lodash';
import { connect } from 'dva';
import { Select } from '@/componentsX/UIElements';
import MajorFilterView from './view';
import { NAMESPACE, DEBOUNCE_TIME } from './constants';

const { LabeledValue } = Select;

@connect(state => ({
  majors: state[NAMESPACE].majors,
  loading: state.loading.effects[`${NAMESPACE}/fetch`],
}))
class MajorFilterContainer extends PureComponent {
  static propTypes = {
    majors: PropTypes.array.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.shape(LabeledValue),
      PropTypes.arrayOf(PropTypes.shape(LabeledValue)),
    ]),
    loading: PropTypes.bool,
    size: PropTypes.string,
    mode: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    loading: false,
    size: 'default',
    mode: 'default',
    value: undefined,
    className: undefined,
    placeholder: undefined,
  };

  // memoization here
  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.value) {
      return {
        selectedMajor: props.value,
        value: props.value,
      }
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.fetchMajorDebounce = debounce(this.fetchMajor, DEBOUNCE_TIME);
    this.state = {
      selectedMajor: props.value,
      value: props.value,
    };
  }

  componentDidMount() {
    this.fetchMajor();
  }

  fetchMajor = search => {
    const { dispatch } = this.props;
    dispatch({
      type: `${NAMESPACE}/fetch`,
      search,
    });
  };

  handleValueChange = value => {
    const { onChange, majors } = this.props;
    this.setState({
      selectedMajor: value,
    });

    let conductedValue;

    if (Array.isArray(value)) {
      conductedValue = value.map(v => {
        const major = majors.find(p => p.id === Number(v.key));
        return {
          ...v,
          ...major,
        };
      });
    } else if (isPlainObject(value)) {
      const major = majors.find(p => p.id === Number(value.key));

      conductedValue = {
        ...value,
        ...major,
      };
    }

    onChange(conductedValue);
    this.fetchMajor('');
  };

  render() {
    const { majors, loading, className, size, mode, placeholder } = this.props;
    const { selectedMajor } = this.state;
    return (
      <MajorFilterView
        size={size}
        mode={mode}
        majors={majors}
        selectedMajor={selectedMajor}
        loading={loading}
        onSearch={this.fetchMajorDebounce}
        onChange={this.handleValueChange}
        className={className}
        placeholder={placeholder}
      />
    );
  }
}

export default MajorFilterContainer;

export { default as model } from './model';
export { NAMESPACE } from './constants';
