import { defineMessages } from 'umi/locale';

import common from './common';
import form from './form';
import route from './route';
import user from './user';
import patient from './patient';
import dentist from './dentist';
import appointment from './appointment';
import service from './service';
import policy from './policy';
import major from './major';
import schedulerType from './schedulerType';

export default defineMessages({
  common,
  route,
  form,
  user,
  patient,
  dentist,
  appointment,
  service,
  policy,
  major,
  schedulerType,
});
