export default function isPlainObject(obj) {
  if (obj === null) {
    return true;
  }
  if (obj === 'undefined') {
    return false;
  }
  if (typeof obj !== 'object' || obj.nodeType || obj === obj.window) {
    return false;
  }
  if (
    obj.constructor &&
    !Object.prototype.hasOwnProperty.call(obj.constructor.prototype, 'isPrototypeOf')
  ) {
    return false;
  }

  return true;
}
