export default {
  'global.messages.service.newService': 'New Service',
  'global.messages.service.updateService': 'Update Service',
  'global.messages.service.deleteServiceTitle': 'Delete Service',
  'global.messages.service.deleteServiceContent': 'Are you sure you want to delete this service?',
  'pages.admin.services.allServices': 'All Services',
  'pages.admin.services.name': 'NAME',
  'pages.admin.services.description': 'DESCRIPTION',
  'pages.admin.services.major': 'MAJOR',
  'pages.admin.services.duration': 'DURATION',
  'pages.admin.services.schedulerType': 'SCHEDULER TYPES',
  'pages.admin.services.actions': 'ACTIONS',
  'pages.admin.services.deleteServiceTitle': 'Delete Service',
  'pages.admin.services.searchPlaceholder': 'Search by name',
  'share.serviceFilter.placeholder': 'Select service',
  'share.serviceModal.duration': 'Duration (minutes)',
  'share.serviceModal.durationPlaceholder': 'Duration in minutes',
  'share.serviceModal.durationError': 'Duration is required',
  'share.serviceModal.schedulerType': 'Scheduler Types',
  'share.serviceModal.schedulerTypePlaceholder': 'Select scheduler types',
  'share.serviceModal.schedulerTypeError': 'Scheduler types is required',
  'share.serviceModal.serviceCreated': 'Service created successfully!',
  'share.serviceModal.serviceUpdated': 'Service updated successfully!',
};
