import { get } from 'lodash';

import { uploadAvatar } from '@/utils/image';
import { createDentist, getGenders, updateDentist } from './service';
import {
  getWorkingTimes,
  addHours,
  updateHours,
  deleteHours,
} from './components/WorkingTimes/service';
import { NAMESPACE } from './constants';

const defaultState = {
  initData: {
    genders: [],
  },
  isSuccess: false,
};

const model = {
  namespace: NAMESPACE,

  state: {
    ...defaultState,
  },

  effects: {
    *initData(_, { all, call, put }) {
      const [genderResponse] = yield all([call(getGenders)]);
      if (genderResponse) {
        yield put({
          type: 'saveInitData',
          payload: {
            genders: genderResponse,
          },
        });
      }
    },
    *create({ payload }, { call, put }) {
      const { dentist, callback } = payload;
      const avatarResponse = yield call(uploadAvatar, dentist.avatar);
      if (avatarResponse) {
        const response = yield call(createDentist, dentist, get(avatarResponse, 'uri'));
        if (response) {
          yield put({ type: 'save' });
          callback();
        }
      }
    },
    *update({ payload }, { call, put }) {
      const { id, dentist, callback } = payload;
      const { avatar } = dentist;

      if (avatar instanceof File) {
        const avatarResponse = yield call(uploadAvatar, avatar);
        if (avatarResponse) {
          const response = yield call(updateDentist, id, dentist, get(avatarResponse, 'uri'));
          if (response) {
            yield put({ type: 'save' });
            callback();
          }
        }
      } else {
        const response = yield call(updateDentist, id, dentist, avatar);
        if (response) {
          yield put({ type: 'save' });
          callback();
        }
      }
    },
    // workingTime
    *fetchWorkingTimes({ payload }, { call, put }) {
      const { dentist } = payload;
      const response = yield call(getWorkingTimes, dentist);
      if (response) {
        yield put({
          type: 'saveTime',
          payload: {
            workingTimes: get(response, 'data', []),
          },
        });
      }
    },
    *createWorkingTime({ payload }, { call, put }) {
      const { workingTime, dentist, callBack } = payload;
      yield call(addHours, workingTime, dentist);
      yield put({ type: 'save' });
      callBack();
    },
    *updateWorkingTime({ payload }, { call, put }) {
      const { workingTime, dentist, callBack } = payload;
      yield call(updateHours, workingTime, dentist);
      yield put({ type: 'save' });
      callBack();
    },
    *deleteWorkingTime({ payload }, { call, put }) {
      const { id, dentist, callBack } = payload;
      yield call(deleteHours, id, dentist);
      yield put({ type: 'save' });
      callBack();
    },
  },

  reducers: {
    saveInitData(
      state,
      {
        payload: { genders },
      }
    ) {
      return {
        ...state,
        genders,
      };
    },
    save(state) {
      return {
        ...state,
        isSuccess: true,
      };
    },
    clear() {
      return {
        ...defaultState,
      };
    },
    saveTime(
      state,
      {
        payload: { workingTimes },
      }
    ) {
      return {
        ...state,
        list: {
          workingTimes,
        },
      };
    },
  },
};

export default model;
