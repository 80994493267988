import moment from 'moment';
import { get } from 'lodash';
import request from '@/utils/request';
import { trimString } from '@/utils/utils';
import { WORKING_TIME_PICKER_FORMAT, WORK_LOAD_TIME_FORMAT } from '@/constants';
import { FORM } from './constants';

// Utils function
export function mapToPostData(object) {
  const data = {};
  Object.keys(object).forEach(key => {
    const value = trimString(object[key]);
    data[key] = value;
  });
  return data;
}

export function mapToFormData(object = {}) {
  const data = {};
  Object.keys(FORM).forEach(key => {
    const formKey = FORM[key];
    const value = trimString(object[key] || object[formKey]);
    if (key === FORM.start) {
      data[formKey] = value ? moment(value, WORKING_TIME_PICKER_FORMAT) : undefined;
    } else if (key === FORM.end) {
      data[formKey] = value ? moment(value, WORKING_TIME_PICKER_FORMAT) : undefined;
    } else {
      data[formKey] = value;
    }
  });
  return data;
}

export function toTimezone(dateTime, timeZoneName) {
  return moment
    .tz(dateTime, timeZoneName)
    .utc()
    .format();
}

export function modifyDataBeforeCreate(workload, selectedAvailableSlot) {
  const { start, end } = workload;
  const modSelect = selectedAvailableSlot.start;
  start
    .date(modSelect.getDate())
    .month(modSelect.getMonth())
    .year(modSelect.getFullYear());
  end
    .date(modSelect.getDate())
    .month(modSelect.getMonth())
    .year(modSelect.getFullYear());
  return workload;
}

// Request function
export async function createWorkload(workload, userTimezone) {
  const { start, end } = workload;
  return request(`/workloads/complete/range`, {
    method: 'POST',
    body: {
      start: toTimezone(start.format(WORK_LOAD_TIME_FORMAT), userTimezone),
      end: toTimezone(end.format(WORK_LOAD_TIME_FORMAT), userTimezone),
    },
  });
}

export async function updateWorkload(id, workload, userTimezone) {
  const { start, end } = workload;
  return request(`/workloads/${id}`, {
    method: 'PUT',
    body: {
      start: toTimezone(start.format(WORK_LOAD_TIME_FORMAT), userTimezone),
      end: toTimezone(end.format(WORK_LOAD_TIME_FORMAT), userTimezone),
    },
  });
}

export async function deleteWorkload(id) {
  return request(`/workloads/${id}`, {
    method: 'DELETE',
  });
}
