import { get } from 'lodash';
import {
  createSchedulerType,
  getMajors,
  updateSchedulerType,
} from './service';
import { NAMESPACE } from './constants';

const defaultState = {
  majors: [],
};

const model = {
  namespace: NAMESPACE,

  state: {
    initData: defaultState,
    isSuccess: false,
  },

  effects: {
    *initData(_, { all, call, put }) {
      const [majorResponse] = yield all([call(getMajors)]);
      if (majorResponse) {
        const majors = get(majorResponse, 'results.majors', {});

        yield put({
          type: 'saveInitData',
          payload: {
            majors,
          },
        });
      }
    },
    *create({ payload }, { call, put }) {
      const { schedulerType, callback } = payload;
      const response = yield call(createSchedulerType, schedulerType);
      if (response) {
        yield put({ type: 'save' });
        callback();
      }
    },
    *update({ payload }, { call, put }) {
      const { id, schedulerType, callback } = payload;
      const response = yield call(updateSchedulerType, id, schedulerType);
      if (response) {
        yield put({ type: 'save' });
        callback();
      }
    },
  },

  reducers: {
    saveInitData(state, { payload }) {
      return {
        ...state,
        initData: {
          ...state.initData,
          ...payload,
        },
      };
    },
    save(state) {
      return {
        ...state,
        isSuccess: true,
      };
    },
    clear(state) {
      return {
        ...state,
        isSuccess: false,
      };
    },
  },
};

export default model;
