import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import { formatMessage } from 'umi/locale';

import { PP_FORM_INPUT, PP_FORM_SELECT, SUBMIT_BUTTON } from '@/constants';
import {
  Modal,
  Button,
  Form,
  Row,
  Col,
  Input,
  InputNumber,
  Select,
} from '@/componentsX/UIElements';

import { FORM, TYPE, SHOULD_PAY } from './constants';
import { mapToFormData } from './service';
import messages from './messages';
import Styles from './styles.less';

const { Item: FormItem, createFormField } = Form;
const { TextArea } = Input;
const { Option } = Select;

@Form.create({
  mapPropsToFields(props) {
    const policy = mapToFormData(props.policy);
    return Object.keys(policy).reduce((acc, fieldName) => {
      acc[fieldName] = createFormField({ value: policy[fieldName] });
      return acc;
    }, {});
  },
})
class PolicyModalView extends PureComponent {
  static propTypes = {
    isOpen: propTypes.bool.isRequired,
    isEdit: propTypes.bool.isRequired,
    loading: propTypes.bool.isRequired,
    submitButton: propTypes.string.isRequired,
    handleSave: propTypes.func.isRequired,
    handleCancel: propTypes.func.isRequired,
    handleSaveAndNew: propTypes.func.isRequired,
    handleUpdate: propTypes.func.isRequired,
  };

  handleCallback = (callBack, values) => {
    const { form } = this.props;
    callBack(form, values);
  };

  renderFooter = () => {
    const {
      isEdit,
      disableButtonUpdate,
      handleSave,
      handleSaveAndNew,
      handleUpdate,
      loading,
      submitButton,
      handleCancel,
    } = this.props;

    const handleSubmit = values => this.handleCallback(handleSave, values);
    const handleSubmitAndNew = values => this.handleCallback(handleSaveAndNew, values);
    const handleSubmitUpdate = values => this.handleCallback(handleUpdate, values);
    const submitButtons = isEdit
      ? [
          <Button
            key="submit"
            type="primary"
            loading={loading && submitButton === SUBMIT_BUTTON.update}
            disabled={disableButtonUpdate}
            onClick={handleSubmitUpdate}
          >
            {formatMessage(messages.update)}
          </Button>,
        ]
      : [
          <Button
            key="submitAndNew"
            loading={loading && submitButton === SUBMIT_BUTTON.saveAndNew}
            onClick={handleSubmitAndNew}
          >
            {formatMessage(messages.saveAndNew)}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading && submitButton === SUBMIT_BUTTON.save}
            onClick={handleSubmit}
          >
            {formatMessage(messages.save)}
          </Button>,
        ];
    return [
      <Button key="cancel" onClick={handleCancel}>
        {formatMessage(messages.cancel)}
      </Button>,
      ...submitButtons,
    ];
  };

  render() {
    const { form, isOpen, isEdit, handleCancel } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Modal
        title={formatMessage(isEdit ? messages.updatePolicy : messages.newPolicy)}
        centered
        visible={isOpen}
        onCancel={handleCancel}
        destroyOnClose
        maskClosable={false}
        footer={this.renderFooter()}
        width="600px"
        className={Styles.modalBody}
      >
        <Form layout="vertical" className={Styles.policyResource}>
          <Row gutter={16}>
            <Col sm={24} md={12}>
              <FormItem label={formatMessage(messages.type)} colon={false}>
                {getFieldDecorator(FORM.type, {
                  rules: [{ required: true, message: formatMessage(messages.typeError) }],
                })(
                  <Select className={PP_FORM_SELECT} placeholder={formatMessage(messages.type)}>
                    {TYPE.map(type => (
                      <Option key={type.name} value={type.name}>
                        {type.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col sm={24} md={12}>
              <FormItem label={formatMessage(messages.shouldPay)} colon={false}>
                {getFieldDecorator(FORM.shouldPay, {
                  rules: [{ required: true, message: formatMessage(messages.shouldPayError) }],
                })(
                  <Select
                    className={PP_FORM_SELECT}
                    placeholder={formatMessage(messages.shouldPay)}
                  >
                    {SHOULD_PAY.map(sp => (
                      <Option key={sp.name} value={sp.name}>
                        {sp.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm={24} md={12}>
              <FormItem label={formatMessage(messages.duration)} colon={false}>
                {getFieldDecorator(FORM.duration, {
                  rules: [
                    {
                      required: true,
                      message: formatMessage(messages.durationError),
                      whitespace: true,
                      type: 'number',
                    },
                  ],
                })(
                  <InputNumber
                    className={Styles.input}
                    min={1}
                    max={1440}
                    placeholder={formatMessage(messages.duration)}
                  />
                )}
              </FormItem>
            </Col>
            <Col sm={24} md={12}>
              <FormItem label={formatMessage(messages.numberOfHours)} colon={false}>
                {getFieldDecorator(FORM.numberOfHours, {
                  rules: [
                    {
                      required: true,
                      message: formatMessage(messages.numberOfHoursError),
                      whitespace: true,
                      type: 'number',
                    },
                  ],
                })(
                  <InputNumber
                    className={Styles.input}
                    min={1}
                    max={1440}
                    placeholder={formatMessage(messages.numberOfHours)}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col sm={24} md={24}>
              <FormItem label={formatMessage(messages.description)} colon={false}>
                {getFieldDecorator(FORM.description)(
                  <TextArea
                    rows={2}
                    className={PP_FORM_INPUT}
                    placeholder={formatMessage(messages.descriptionPlaceholder)}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default PolicyModalView;
