import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode-react';

import { EMPTY_VALUE } from '@/constants';

class PatientQRCode extends PureComponent {
  static propTypes = {
    code: PropTypes.string.isRequired,
  };

  render() {
    const { code, ...restProps } = this.props;
    return <QRCode value={code || EMPTY_VALUE} {...restProps} />;
  }
}

export default PatientQRCode;
