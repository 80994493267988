import React, { PureComponent } from 'react';
import { isEmpty } from 'lodash';
import propTypes from 'prop-types';
import moment from 'moment';
import { formatMessage } from 'umi/locale';
import { isValidPhoneNumber } from 'react-phone-number-input';

import {
  PP_FORM_INPUT,
  PP_FORM_SELECT,
  SUBMIT_BUTTON,
  SERVER_DATE_FORMAT,
  DEFAULT_AVATAR,
} from '@/constants';
import { getAvatarURL } from '@/utils/image';
import { Modal, Button, Form, Row, Col, Select, Input, DatePicker } from '@/componentsX/UIElements';
import UploadAvatar from '@/componentsX/UploadAvatar';
import PhoneInput from '@/componentsX/PhoneInput';
import DentistFilter from '@/componentsX/_share/DentistFilter';
import PatientQRCode from '@/componentsX/_share/PatientQRCode';

import { PATIENT_FORM } from './constants';
import { mapToFormData } from './service';
import messages from './messages';
import Styles from './styles.less';

const { Item: FormItem, createFormField } = Form;
const { Option } = Select;
const { TextArea } = Input;

@Form.create({
  mapPropsToFields(props) {
    const patient = mapToFormData(props.patient);
    return Object.keys(patient).reduce((acc, fieldName) => {
      acc[fieldName] = createFormField({ value: patient[fieldName] });
      return acc;
    }, {});
  },
})
class PatientModalView extends PureComponent {
  static propTypes = {
    patient: propTypes.object.isRequired,
    genders: propTypes.array.isRequired,
    isEdit: propTypes.bool.isRequired,
    loading: propTypes.bool.isRequired,
    submitButton: propTypes.string.isRequired,
    handleSave: propTypes.func.isRequired,
    handleCancel: propTypes.func.isRequired,
    handleSaveAndNew: propTypes.func.isRequired,
    handleUpdate: propTypes.func.isRequired,
  };

  componentDidUpdate() {
    const { form } = this.props;
    if (isEmpty(form.getFieldValue(PATIENT_FORM.avatar)) && this.avatarUpload) {
      this.avatarUpload.clearImage();
    }
  }

  handleCallback = (callBack, values) => {
    const { form } = this.props;
    callBack(form, values);
  };

  handleUploadAvatar = file => {
    const { form } = this.props;
    form.setFieldsValue({ [PATIENT_FORM.avatar]: file });
  };

  renderFooter = () => {
    const {
      isEdit,
      handleSave,
      handleSaveAndNew,
      handleUpdate,
      loading: loadingData,
      initDataLoading,
      submitButton,
      handleCancel,
    } = this.props;
    const loading = initDataLoading || loadingData;
    const handleSubmit = values => this.handleCallback(handleSave, values);
    const handleSubmitAndNew = values => this.handleCallback(handleSaveAndNew, values);
    const handleSubmitUpdate = values => this.handleCallback(handleUpdate, values);
    const submitButtons = isEdit
      ? [
        <Button
          key="submit"
          type="primary"
          loading={loading && submitButton === SUBMIT_BUTTON.update}
          onClick={handleSubmitUpdate}
        >
          {formatMessage(messages.update)}
        </Button>,
      ]
      : [
        <Button
          key="submitAndNew"
          loading={loading && submitButton === SUBMIT_BUTTON.saveAndNew}
          onClick={handleSubmitAndNew}
        >
          {formatMessage(messages.saveAndNew)}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading && submitButton === SUBMIT_BUTTON.save}
          onClick={handleSubmit}
        >
          {formatMessage(messages.save)}
        </Button>,
      ];
    return [
      <Button key="cancel" onClick={handleCancel}>
        {formatMessage(messages.cancel)}
      </Button>,
      ...submitButtons,
    ];
  };

  validatePhoneInput = (rule, value, callback) => {
    if (value && !isValidPhoneNumber(value)) {
      callback(formatMessage(messages.invalidPhoneNumber));
    }
    callback();
  };

  disabledDob = date => {
    return moment(date).isAfter(moment());
  };

  render() {
    const { form, isOpen, isEdit, patient, genders, handleCancel } = this.props;
    const { getFieldDecorator } = form;
    const shouldClearAvatar = isEmpty(patient);
    const { code, avatar } = patient;
    const avatarSrc = patient && !isEmpty(avatar) ? getAvatarURL(avatar) : '';

    return (
      <Modal
        title={formatMessage(isEdit ? messages.updatePatient : messages.newPatient)}
        centered
        visible={isOpen}
        onCancel={handleCancel}
        destroyOnClose
        maskClosable={false}
        footer={this.renderFooter()}
        width="600px"
        bodyStyle={{ maxHeight: '520px', overflow: 'auto' }}
      >
        <Form layout="vertical" className={Styles.patientResource}>
          <Row gutter={16}>
            <Col sm={24} md={12}>
              <FormItem>
                {getFieldDecorator(PATIENT_FORM.avatar, {
                  rules: [
                    {
                      required: true,
                      message: formatMessage(messages.avatarRequired),
                    },
                  ],
                })(
                  <UploadAvatar
                    ref={(avatarUpload) => { this.avatarUpload = avatarUpload }}
                    shouldClear={shouldClearAvatar}
                    image={avatarSrc}
                    handleUploadFile={this.handleUploadAvatar}
                    defaultIcon="person"
                    fallbackImage={DEFAULT_AVATAR}
                  />
                )}
              </FormItem>
            </Col>
            <Col sm={24} md={12}>
              {isEdit && (
                <FormItem>
                  <PatientQRCode code={code} size={60} />
                </FormItem>
              )}
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm={24} md={12}>
              <FormItem label={formatMessage(messages.firstName)} colon={false}>
                {getFieldDecorator(PATIENT_FORM.firstName, {
                  rules: [
                    {
                      required: true,
                      message: formatMessage(messages.firstNameError),
                      whitespace: true,
                    },
                  ],
                })(
                  <Input
                    className={PP_FORM_INPUT}
                    placeholder={formatMessage(messages.firstNamePlaceholder)}
                  />
                )}
              </FormItem>
            </Col>
            <Col sm={24} md={12}>
              <FormItem label={formatMessage(messages.lastName)} colon={false}>
                {getFieldDecorator(PATIENT_FORM.lastName, {
                  rules: [
                    {
                      required: true,
                      message: formatMessage(messages.lastNameError),
                      whitespace: true,
                    },
                  ],
                })(
                  <Input
                    className={PP_FORM_INPUT}
                    placeholder={formatMessage(messages.lastNamePlaceholder)}
                  />
                )}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col sm={24} md={12}>
              <FormItem label={formatMessage(messages.dob)} colon={false}>
                {getFieldDecorator(PATIENT_FORM.dob, {
                  rules: [
                    {
                      required: true,
                      message: formatMessage(messages.dobError),
                      whitespace: true,
                      type: 'object',
                    },
                  ],
                })(
                  <DatePicker
                    className={PP_FORM_INPUT}
                    format={SERVER_DATE_FORMAT}
                    placeholder={formatMessage(messages.dobPlaceholder)}
                    disabledDate={this.disabledDob}
                  />
                )}
              </FormItem>
            </Col>
            <Col sm={24} md={12}>
              <FormItem label={formatMessage(messages.gender)} colon={false}>
                {getFieldDecorator(PATIENT_FORM.gender, {
                  rules: [{ required: true, message: formatMessage(messages.genderRequired) }],
                })(
                  <Select
                    className={PP_FORM_SELECT}
                    placeholder={formatMessage(messages.selectGenderPlaceholder)}
                  >
                    {genders.map(gender => (
                      <Option key={gender.value} value={gender.value}>
                        {gender.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm={24} md={12}>
              <FormItem label={formatMessage(messages.phone)} colon={false}>
                {getFieldDecorator(PATIENT_FORM.phoneNum, {
                  rules: [
                    {
                      validator: this.validatePhoneInput,
                    },
                    {
                      required: true,
                      message: formatMessage(messages.phoneRequiredError),
                    },
                  ],
                })(
                  <PhoneInput
                    displayInitialValueAsLocalNumber
                    className={Styles.phoneInputComponent}
                    placeholder={formatMessage(messages.phone)}
                  />
                )}
              </FormItem>
            </Col>
            <Col sm={24} md={12}>
              <FormItem label={formatMessage(messages.email)} colon={false}>
                {getFieldDecorator(PATIENT_FORM.email, {
                  rules: [
                    {
                      type: 'email',
                      message: formatMessage(messages.emailInvalidError),
                    },
                    {
                      required: true,
                      message: formatMessage(messages.emailEmptyError),
                    },
                  ],
                })(
                  <Input
                    disabled={isEdit}
                    className={PP_FORM_INPUT}
                    placeholder={formatMessage(messages.emailPlaceholder)}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col sm={24} md={24}>
              <FormItem label={formatMessage(messages.address)} colon={false}>
                {getFieldDecorator(PATIENT_FORM.address)(
                  <TextArea
                    size="small"
                    rows={2}
                    className={PP_FORM_INPUT}
                    placeholder={formatMessage(messages.addressPlaceholder)}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm={24} md={24}>
              <FormItem label={formatMessage(messages.defaultDentist)} colon={false}>
                {getFieldDecorator(PATIENT_FORM.defaultDentist)(
                  <DentistFilter
                    className={PP_FORM_INPUT}
                    placeholder={formatMessage(messages.defaultDentistPlaceholder)}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default PatientModalView;
