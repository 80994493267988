import { curry } from 'ramda';
import moment from 'moment-timezone';

export default curry(function formatDateTime(format, field, target) {
  const fieldValue = target[field];
  return {
    ...target,
    [field]: fieldValue ? moment(fieldValue, format) : fieldValue,
  };
});
