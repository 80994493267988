import request from '@/utils/request';
import { get } from 'lodash';
import { SCHEDULER_TYPE_FORM } from './constants';
import { trimString } from '@/utils/utils';
import { PAGE_BIG_SIZE, ENTITY_TYPE, SORT_ORDER } from '@/constants';

export function mapToPostData(object) {
  const data = {};
  Object.keys(object).forEach(key => {
    const value = trimString(object[key]);
    data[key] = value;
  });
  return data;
}

export async function getMajors() {
  return {
    results: {
      majors: [],
    },
  };
}

export async function createSchedulerType(schedulerType) {
  const postData = mapToPostData(schedulerType);
  return request('/metadata/peopleResources', {
    method: 'POST',
    body: postData,
  });
}

export async function updateSchedulerType(id, schedulerType) {
  const putData = mapToPostData(schedulerType);
  return request(`/metadata/peopleResources/${id}`, {
    method: 'PUT',
    body: putData,
  });
}

export function mapToFormData(schedulerType = {}) {
  const currentSchedulerType = {};
  Object.keys(SCHEDULER_TYPE_FORM).forEach(key => {
    const property = SCHEDULER_TYPE_FORM[key];
    currentSchedulerType[property] = get(schedulerType, property);
  });
  return currentSchedulerType;
}
