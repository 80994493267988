import { get } from 'lodash';

import { buildWorkingTimes, transformAppointments } from '@/componentsX/_share/AppointmentCalendar';
import {
  getAppointments,
  createAppointment,
  getDentistWorkingTimes,
  sendSMS,
  updateAppointment,
} from './service';
import { NAMESPACE } from './constants';

const defaultState = {
  appointments: [],
  appointment: {},
  dentistWorkingTimes: {},
  isSuccess: false,
};

const model = {
  namespace: NAMESPACE,

  state: {
    ...defaultState,
  },

  effects: {
    *fetchAppointments({ payload }, { call, put }) {
      const { startDate, endDate, dentistId, userTimezone } = payload;
      const response = yield call(getAppointments, startDate, endDate, dentistId);

      if (response) {
        const appointments = get(response, 'data.items', []);
        yield put({
          type: 'saveAppointments',
          payload: { appointments: transformAppointments(appointments, userTimezone) },
        });
      }
    },
    *fetchDentistWorkingTimes({ dentistId }, { call, put }) {
      const response = yield call(getDentistWorkingTimes, dentistId);
      if (response) {
        const workingTimes = get(response, 'data', []);
        yield put({
          type: 'saveDentistWorkingTime',
          payload: { workingTimes },
        });
      }
    },
    *createOrUpdate({ payload }, { call, put }) {
      const { appointment, callback } = payload;

      let response;
      if (appointment.id) {
        response = yield call(updateAppointment, appointment.id, appointment);
      } else {
        response = yield call(createAppointment, appointment);
      }
      if (response) {
        const appointmentCreated = get(response, 'data', {});
        yield put({ type: 'save', payload: { id: appointmentCreated.id } });
        callback();
      }
    },
    *sendSMS({ payload }, { call, put }) {
      const { appointmentId } = payload;

      const response = yield call(sendSMS, appointmentId);
      if (response) {
        yield put({ type: 'save' });
      }
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        appointment: {
          ...state.appointment,
          ...payload,
        },
        isSuccess: true,
      };
    },
    saveAppointments(
      state,
      {
        payload: { appointments },
      }
    ) {
      return {
        ...state,
        appointments,
      };
    },
    saveStepFormData(state, { payload }) {
      return {
        ...state,
        appointment: {
          ...state.appointment,
          ...payload,
        },
      };
    },
    saveDentistWorkingTime(
      state,
      {
        payload: { workingTimes },
      }
    ) {
      return {
        ...state,
        dentistWorkingTimes: buildWorkingTimes(workingTimes),
      };
    },
    clear(state) {
      return {
        ...state,
        ...defaultState,
      };
    },
  },
};

export default model;
