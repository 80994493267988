import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import { formatMessage } from 'umi/locale';
import moment from 'moment';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { isNil, isEmpty } from 'lodash';

import { getAvatarURL } from '@/utils/image';
import { PP_FORM_INPUT, SUBMIT_BUTTON, DEFAULT_AVATAR } from '@/constants';
import {
  Button,
  Form,
  Row,
  Col,
  Input,
  Radio,
} from '@/componentsX/UIElements';
import UploadFile from '@/componentsX/UploadFile';
import SignatureDrawer from '@/componentsX/SignatureDrawer';
import { mapToFormData } from '../../service';
import messages from './messages';
import { FORM, TYPE } from '../../constants';
import Styles from './styles.less';

const { Item: FormItem, createFormField } = Form;
const plainOptions = [TYPE.permanent, TYPE.partTime];

@Form.create({
  mapPropsToFields(props) {
    const user = mapToFormData(props.user);
    return Object.keys(user).reduce((acc, fieldName) => {
      acc[fieldName] = createFormField({ value: user[fieldName] });
      return acc;
    }, {});
  },
})
class AdditionalPropertiesView extends PureComponent {
  socialNumberValidatePattern = /^\d{3}-\d{2}-\d{4}$/;

  static propTypes = {
    user: propTypes.object.isRequired,
    isEdit: propTypes.bool.isRequired,
    loading: propTypes.bool.isRequired,
    initDataLoading: propTypes.bool.isRequired,
    submitButton: propTypes.string.isRequired,
    handleSave: propTypes.func.isRequired,
    handleCancel: propTypes.func.isRequired,
    handleSaveAndNew: propTypes.func.isRequired,
    handleUpdate: propTypes.func.isRequired,
  };

  state = {
    permanentStatus: TYPE.permanent,
    fileName: false,
    isOpenSignatureDrawer: false,
  };

  componentDidMount() {
    const { user } = this.props;
    const { type } = user;
    let status = type;
    if (type === TYPE.partTimeSnakeCase) {
      status = TYPE.partTime;
    }
    this.setState({ permanentStatus: status });
  }

  handleCloseSingatureDrawer = () => {
    this.setState({ isOpenSignatureDrawer: false });
  };

  handleOpenSingatureDrawer = () => {
    this.setState({ isOpenSignatureDrawer: true });
  };

  handleSignatureDrawer = file => {
    this.setState({ fileName: file });
  };

  handleCallback = (callBack, values) => {
    const { form } = this.props;
    callBack(form, values);
  };

  renderFooter = () => {
    const {
      isEdit,
      handleSave,
      handleSaveAndNew,
      handleUpdate,
      loading: loadingData,
      initDataLoading,
      submitButton,
      handleCancel,
    } = this.props;
    const loading = initDataLoading || loadingData;
    const handleSubmit = values => this.handleCallback(handleSave, values);
    const handleSubmitAndNew = values => this.handleCallback(handleSaveAndNew, values);
    const handleSubmitUpdate = values => this.handleCallback(handleUpdate, values);
    const submitButtons = isEdit
      ? [
        <Button
          key="submit"
          type="primary"
          loading={loading && submitButton === SUBMIT_BUTTON.update}
          onClick={handleSubmitUpdate}
        >
          {formatMessage(messages.update)}
        </Button>,
      ]
    : [
      <Button
        key="submitAndNew"
        loading={loading && submitButton === SUBMIT_BUTTON.saveAndNew}
        onClick={handleSubmitAndNew}
      >
        {formatMessage(messages.saveAndNew)}
      </Button>,
      <Button
        key="submit"
        type="primary"
        loading={loading && submitButton === SUBMIT_BUTTON.save}
        onClick={handleSubmit}
      >
        {formatMessage(messages.save)}
      </Button>,
      ];
    return [
      <Button key="cancel" onClick={handleCancel}>
        {formatMessage(messages.cancel)}
      </Button>,
      ...submitButtons,
    ];
  };

  disabledDob = date => {
    return moment(date).isAfter(moment());
  };

  validatePhoneInput = (rule, value, callback) => {
    if (value && !isValidPhoneNumber(value)) {
      callback(formatMessage(messages.invalidPhoneNumber));
    }
    callback();
  };

  handleUploadAvatar = file => {
    const { form } = this.props;
    const { name } = file;
    this.setState({ fileName: name });
    form.setFieldsValue({ [FORM.signatureAgreement]: file });
  };

  onPermanentChange = event => {
    const { value } = event.target;
    this.setState({
      permanentStatus: value,
    });
  };

  normalizeSocialNumber = (value) => {
    if (isNil(value) || isEmpty(value)) {
      return value;
    }
    const res = this.socialNumberValidatePattern.test(value);
    if (!res) {
      return value
        .match(/\d*/g)
        .join('')
        .match(/(\d{0,3})(\d{0,2})(\d{0,4})/)
        .slice(1)
        .join('-')
        .replace(/-*$/g, '');
    }
    return value;
  };

  render() {
    const { user, form } = this.props;
    const { permanentStatus, fileName, isOpenSignatureDrawer } = this.state;
    const { getFieldDecorator } = form;
    const { signatureAgreement } = user;
    const signatureSource =
      user && !isEmpty(signatureAgreement) ? getAvatarURL(signatureAgreement) : '';
    const shouldClearAvatar = isEmpty(user);
    return (
      <Form layout="vertical" className={Styles.userResource}>
        <Row gutter={16}>
          <Col>
            <FormItem label={formatMessage(messages.socialNumber)} colon={false}>
              {getFieldDecorator(FORM.socialNo, {
                validateFirst: true,
                rules: [
                  {
                    required: true,
                    message: formatMessage(messages.socialNumberError),
                  },
                  {
                    pattern: this.socialNumberValidatePattern, message: 'Social number is invalid',
                  },
                ],
                normalize: this.normalizeSocialNumber,
              })(
                <Input
                  className={PP_FORM_INPUT}
                  placeholder={formatMessage(messages.socialNumberPlaceholder)}
                />
              )}
            </FormItem>
          </Col>
          <Col>
            <FormItem label={formatMessage(messages.signature)} colon={false}>
              {getFieldDecorator(FORM.signatureAgreement, {
                rules: [
                  {
                    required: true,
                    message: formatMessage(messages.signatureError),
                  },
                ],
              })(
                <div>
                  <div className={Styles.signature}>
                    <UploadFile
                      ref={avatarUpload => {
                        this.avatarUpload = avatarUpload;
                      }}
                      shouldClear={shouldClearAvatar}
                      image={signatureSource}
                      fileName={fileName}
                      handleUploadFile={this.handleUploadAvatar}
                      defaultIcon="upload"
                      fallbackImage={DEFAULT_AVATAR}
                      text={formatMessage(messages.uploadFile)}
                    />
                    <p className={Styles.or}>-------------- Or --------------</p>
                    <Button className={Styles.sign} onClick={this.handleOpenSingatureDrawer}>
                      Sign signature
                    </Button>
                    <SignatureDrawer
                      isOpen={isOpenSignatureDrawer}
                      onClose={this.handleCloseSingatureDrawer}
                      onSave={this.handleUploadAvatar}
                    />
                  </div>
                  <div className={Styles.signature}>
                    {fileName ? (
                      <div className="file">Attach file: {fileName}</div>
                    ) : (
                      <img
                        className={Styles.img}
                        src={signatureSource}
                        alt=""
                      />
                    )}
                  </div>
                </div>
              )}
            </FormItem>
          </Col>
          <Col>
            <FormItem label={formatMessage(messages.permanent)} colon={false}>
              {getFieldDecorator(FORM.type, {
                rules: [
                  {
                    required: true,
                    message: formatMessage(messages.permanentError),
                    whitespace: true,
                  },
                ],
              })(
                <Radio.Group
                  options={plainOptions}
                  onChange={this.onPermanentChange}
                  value={permanentStatus}
                />
              )}
            </FormItem>
          </Col>
          <Col>
            <FormItem label={formatMessage(messages.ratePerHours)} colon={false}>
              {getFieldDecorator(FORM.ratePerHour, {
                rules: [
                  {
                    required: true,
                    message: formatMessage(messages.ratePerHoursError),
                  },
                ],
              })(
                <Input
                  className={PP_FORM_INPUT}
                  placeholder={formatMessage(messages.ratePerHoursPlaceholder)}
                />
              )}
            </FormItem>
          </Col>
          <Col>
            <FormItem label={formatMessage(messages.overtime)} colon={false}>
              {getFieldDecorator(FORM.overtimeRatePerHour, {
                rules: [
                  {
                    required: true,
                    message: formatMessage(messages.overtimeError),
                  },
                ],
              })(
                <Input
                  className={PP_FORM_INPUT}
                  placeholder={formatMessage(messages.overtimePlaceholder)}
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col className={Styles.footer}>{this.renderFooter()}</Col>
        </Row>
      </Form>
    );
  }
}

export default AdditionalPropertiesView;
