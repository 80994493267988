import { defineMessages } from 'umi/locale';
import globalMessages from '@/messages';
import formMessages from '@/messages/form';
import userMessages from '@/messages/user';
import { NAMESPACE } from '../../constants';

const messages = defineMessages({
  socialNumber: {
    id: `${NAMESPACE}.socialNumber`,
    defaultMessage: 'Social Number',
  },
  socialNumberError: {
    id: `${NAMESPACE}.socialNumberError`,
    defaultMessage: 'Social Number is required',
  },
  socialNumberPlaceholder: {
    id: `${NAMESPACE}.socialNumberPlaceholder`,
    defaultMessage: 'Social Number',
  },
  signature: {
    id: `${NAMESPACE}.signature`,
    defaultMessage: 'Signature',
  },
  signatureError: {
    id: `${NAMESPACE}.signatureError`,
    defaultMessage: 'Signature is required',
  },
  signaturePlaceholder: {
    id: `${NAMESPACE}.signaturePlaceholder`,
    defaultMessage: 'Signature',
  },
  permanent: {
    id: `${NAMESPACE}.permanent`,
    defaultMessage: 'Permanent',
  },
  permanentError: {
    id: `${NAMESPACE}.permanentError`,
    defaultMessage: 'Permanent is required',
  },
  permanentPlaceholder: {
    id: `${NAMESPACE}.permanentPlaceholder`,
    defaultMessage: 'Permanent',
  },
  ratePerHours: {
    id: `${NAMESPACE}.ratePerHours`,
    defaultMessage: 'Rate Per Hours',
  },
  ratePerHoursError: {
    id: `${NAMESPACE}.ratePerHoursError`,
    defaultMessage: 'Rate Per Hours is required',
  },
  ratePerHoursPlaceholder: {
    id: `${NAMESPACE}.ratePerHoursPlaceholder`,
    defaultMessage: 'Rate Per Hours',
  },
  overtime: {
    id: `${NAMESPACE}.overtime`,
    defaultMessage: 'Overtime',
  },
  overtimeError: {
    id: `${NAMESPACE}.overtimeError`,
    defaultMessage: 'Overtime is required',
  },
  overtimePlaceholder: {
    id: `${NAMESPACE}.overtimePlaceholder`,
    defaultMessage: 'Overtime',
  },
  uploadFile: {
    id: `${NAMESPACE}.uploadFile`,
    defaultMessage: 'Click or drag file to this area to upload',
  },
});

export default {
  ...formMessages,
  ...userMessages,
  ...messages,
  update: globalMessages.common.update,
  saveAndNew: globalMessages.common.saveAndNew,
  save: globalMessages.common.save,
  cancel: globalMessages.common.cancel,
};
