export function getAccessToken() {
  return localStorage.getItem('jwt');
}

export function setAccessToken(jwt) {
  return localStorage.setItem('jwt', jwt);
}

export function getRefreshToken() {
  return localStorage.getItem('refresh_jwt');
}

export function setRefreshToken(refreshJwt) {
  return localStorage.setItem('refresh_jwt', refreshJwt);
}

function updateTokensToStorage(token, refreshToken) {
  setAccessToken(token);
  setRefreshToken(refreshToken);
}

class AuthService {
  /* eslint class-methods-use-this: 0 */
  processAuthenticateFailed() {
    updateTokensToStorage('', '');
    window.location.replace(`/user/login?redirect=${window.location.href}`);
  }

  updateToken(callback) {
    return callback();
  }
}

export default AuthService;
