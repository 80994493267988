import React, { PureComponent } from 'react';
import { MINUTES_AUTO_LOGOUT } from '@/constants';

const withSessionTimeout = WrappedComponent => {
  class SessionTimeoutComponent extends PureComponent {
    constructor(props) {
      super(props);
      this.lastAction = Date.now();
    }

    componentDidMount() {
      this.initListener();
    }

    setLayoutElement = e => {
      this.layoutEle = e;
    };

    initListener = () => {
      if (this.layoutEle) {
        this.layoutEle.addEventListener('click', () => {
          this.checkTimeLogout();
        });
      }
    };

    checkTimeLogout = () => {
      const now = Date.now();
      const timeleft = this.lastAction + MINUTES_AUTO_LOGOUT * 60 * 1000;
      const diff = timeleft - now;
      if (diff < 0) {
        // TODO: logout
      } else {
        this.lastAction = Date.now();
      }
    };

    render() {
      const style = {
        height: '100%',
      };
      return (
        <div style={style} ref={this.setLayoutElement}>
          <WrappedComponent {...this.props} />
        </div>
      );
    }
  }

  return SessionTimeoutComponent;
};

export default withSessionTimeout;
