import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { formatMessage } from 'umi/locale';
import { Select, Spin } from '@/componentsX/UIElements';
import messages from './messages';

const { Option, LabeledValue } = Select;

class ServiceFilterView extends PureComponent {
  static propTypes = {
    services: PropTypes.array.isRequired,
    selectedService: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.shape(LabeledValue),
      PropTypes.arrayOf(PropTypes.shape(LabeledValue)),
    ]),
    mode: PropTypes.string.isRequired,
    size: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    onSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    size: 'small',
    className: undefined,
    placeholder: undefined,
    selectedService: undefined,
  };

  render() {
    const {
      services,
      loading,
      selectedService,
      mode,
      size,
      className,
      placeholder,
      onSearch,
      onChange,
      ...restProps
    } = this.props;
    const placeholderComputed = placeholder || formatMessage(messages.placeholder);

    return (
      <Select
        {...restProps}
        size={size}
        style={{ width: '100%' }}
        className={className}
        mode={mode}
        showSearch
        allowClear
        labelInValue
        value={selectedService}
        placeholder={placeholderComputed}
        loading={loading}
        notFoundContent={loading ? <Spin size="small" /> : null}
        filterOption={false}
        onSearch={onSearch}
        onChange={onChange}
        getPopupContainer={triggerNode => triggerNode.parentNode}
      >
        {services.map(({ id, name }) => (
          <Option key={id}>{name}</Option>
        ))}
      </Select>
    );
  }
}

export default ServiceFilterView;
