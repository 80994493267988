import React, { PureComponent, div } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { formatMessage } from 'umi/locale';
import { DatePicker, Button, Tooltip, Radio } from '@/componentsX/UIElements';
import IconButton from '@/componentsX/IconButton';
import { CALENDAR_VIEW, FULL_DATE_FORMAT } from '@/constants';

import messages from '../messages';
import Styles from './CalendarNavigator.less';
import { getPrevTimeByMode, getNextTimeByMode } from '@/utils/utils';
import { withLocaleContext, toTimezoneAsMoment } from '@/utils/locale';

class CalendarNavigator extends PureComponent {
  static propTypes = {
    currentDate: PropTypes.object.isRequired,
    min: PropTypes.object,
    viewMode: PropTypes.string.isRequired,
    onDateChange: PropTypes.func.isRequired,
    onViewModeChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    min: undefined,
  };

  constructor(props) {
    super(props);

    const { viewMode } = props;
    this.state = {
      calendarView: viewMode || CALENDAR_VIEW.month,
    };
  }

  changeDate = date => {
    if (!date) return;

    const { onDateChange } = this.props;
    onDateChange(date);
  };

  changeToday = () => {
    const { userTimezone } = this.props;
    const currentDate = toTimezoneAsMoment(moment(), userTimezone);
    this.changeDate(currentDate);
  };

  next = () => {
    const { calendarView } = this.state;
    const { currentDate } = this.props;
    const date = getNextTimeByMode(calendarView, currentDate);

    this.changeDate(date);
  };

  prev = () => {
    const { calendarView } = this.state;
    const { currentDate } = this.props;

    const date = getPrevTimeByMode(calendarView, currentDate);

    this.changeDate(date);
  };

  handleViewModeChange = e => {
    const viewMode = e.target.value;
    const { onViewModeChange } = this.props;

    this.setState({
      calendarView: viewMode,
    });

    onViewModeChange(viewMode);
  };

  disabledDate = date => {
    const { min } = this.props;
    if (min && min.isAfter(date)) {
      return true;
    }
    return false;
  };

  render() {
    const { calendarView } = this.state;
    const { currentDate } = this.props;
    return (
      <div className={Styles.leftAction}>
        <Radio.Group
          size="small"
          value={calendarView}
          buttonStyle="solid"
          onChange={this.handleViewModeChange}
        >
          <Radio.Button value={CALENDAR_VIEW.month}>{formatMessage(messages.month)}</Radio.Button>
          <Radio.Button value={CALENDAR_VIEW.week}>{formatMessage(messages.week)}</Radio.Button>
          <Radio.Button value={CALENDAR_VIEW.day}>{formatMessage(messages.day)}</Radio.Button>
          {/* <Radio.Button value={CALENDAR_VIEW.agenda}>Agenda</Radio.Button> */}
        </Radio.Group>

        <Tooltip title={formatMessage(messages.today)}>
          <Button className={Styles.leftElement} size="small" onClick={this.changeToday}>
            {formatMessage(messages.today)}
          </Button>
        </Tooltip>

        <IconButton
          icon="navigate_before"
          onClick={this.prev}
          tooltip={{ title: `${formatMessage(messages.prev)} ${calendarView}` }}
        />
        <IconButton
          icon="navigate_next"
          onClick={this.next}
          tooltip={{ title: `${formatMessage(messages.next)} ${calendarView}` }}
        />

        <DatePicker
          allowClear={false}
          size="small"
          style={{ width: 250 }}
          value={currentDate}
          format={FULL_DATE_FORMAT}
          onChange={this.changeDate}
          className={Styles.leftElement}
          disabledDate={this.disabledDate}
        />
      </div>
    );
  }
}

export default withLocaleContext(CalendarNavigator);
