import request from '@/utils/request';
import { FORM } from './constants';

export async function updateProperty(id, property) {
  return request(`/managements/application-configs/${id}`, {
    method: 'PUT',
    body: {
      value: property.value
    },
  });
}

export function mapToFormData(policy = {}) {
  const currentService = {};
  Object.keys(FORM).forEach(key => {
    const property = FORM[key];
    const value = policy[key] || policy[property];
    currentService[property] = value;
  });
  return currentService;
}
