import React, { PureComponent } from 'react';
import moment from 'moment';
import { formatMessage } from 'umi/locale';
import { Button, TimePicker } from '@/componentsX/UIElements';
import { WORKING_TIME_PICKER_FORMAT } from '@/constants';
import { convertTo12HoursFormat } from '@/utils/utils';
import messages from './messages';
import Styles from './styles.less';

class PeriodPicker extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      period: props.period,
      editMode: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { period } = this.props;
    if (prevProps.period !== period) {
      this.setState({ period });
    }
  }

  timePickerFromOnChange = time => {
    const { period } = this.state;
    this.setState({
      period: { ...period, start: time },
    });
  };

  timePickerToOnChange = time => {
    const { period } = this.state;
    this.setState({
      period: { ...period, end: time },
    });
  };

  handlePeriodChanged = () => {
    const { period } = this.state;
    const { period: prevPeriod, onPeriodChanged } = this.props;
    if (period !== prevPeriod) {
      period.start = convertTo12HoursFormat(period.start);
      period.end = convertTo12HoursFormat(period.end);
      onPeriodChanged(period);
    }
    this.setState({ editMode: false });
  };

  enterEditMode = () => {
    this.setState({ editMode: true });
  };

  render() {
    const { period, editMode } = this.state;
    const { onPeriodRemoved } = this.props;
    const { start, end } = period;
    const fromStr = convertTo12HoursFormat(start);
    const toStr = convertTo12HoursFormat(end);
    const timePickerFrom = moment(start, WORKING_TIME_PICKER_FORMAT);
    const timePickerTo = moment(end, WORKING_TIME_PICKER_FORMAT);

    return (
      <div className={Styles.container}>
        {editMode ? (
          <div>
            <span> {formatMessage(messages.from)} </span>
            <TimePicker
              use12Hours
              format={WORKING_TIME_PICKER_FORMAT}
              defaultValue={timePickerFrom}
              suffixIcon={<div />}
              allowClear={false}
              onChange={this.timePickerFromOnChange}
            />
            <span> {formatMessage(messages.to)} </span>
            <TimePicker
              use12Hours
              format={WORKING_TIME_PICKER_FORMAT}
              defaultValue={timePickerTo}
              suffixIcon={<div />}
              allowClear={false}
              onChange={this.timePickerToOnChange}
            />
            <Button type="link" icon="save" onClick={this.handlePeriodChanged} />
            <Button type="link" icon="delete" onClick={onPeriodRemoved} />
          </div>
        ) : (
          <div>
            {`  ${formatMessage(messages.from)} ${fromStr} ${formatMessage(messages.to)} ${toStr}`}
            <Button type="link" icon="edit" onClick={this.enterEditMode} />
          </div>
        )}
      </div>
    );
  }
}
export default PeriodPicker;
