export const NAMESPACE = 'share.userModal';
export const FORM = {
  id: 'id',
  avatar: 'avatar',
  firstName: 'first_name',
  lastName: 'last_name',
  active: 'status',
  phoneNum: 'phone_num',
  email: 'email',
  password: 'password',
  dob: 'dob',
  address: 'address',
  socialNo: 'social_no',
  signatureAgreement: 'signature_agreement',
  type: 'type',
  ratePerHour: 'rate_per_hour',
  overtimeRatePerHour: 'overtime_rate_per_hour',
  workingHours: 'working_hours',
};

export const TYPE = {
  permanent: 'PERMANENT',
  partTime: 'PART TIME',
  partTimeSnakeCase: 'PART_TIME',
};

export const TABS = {
  userInfo: 'userInfo',
  additionalProperties: 'additionalProperties',
  workingHours: 'workingHours',
  w4Form: 'w4Form',
};
