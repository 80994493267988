import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'dva';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { formatMessage } from 'umi/locale';

import { Descriptions, Tag, Button } from '@/componentsX/UIElements';
import { buildTimeString } from '@/componentsX/_share/AppointmentCalendar';

import { NAMESPACE } from '../constants';
import messages from '../messages';
import Styles from './Step3.less';

@connect(state => ({
  appointment: state[NAMESPACE].appointment,
  loading: state.loading.effects[`${NAMESPACE}createOrUpdate`],
}))
class Step3 extends PureComponent {
  static propTypes = {
    next: PropTypes.func.isRequired,
    prev: PropTypes.func.isRequired,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    loading: false,
  };

  handleSubmit = () => {
    const { next, appointment, dispatch } = this.props;
    dispatch({
      type: `${NAMESPACE}/createOrUpdate`,
      payload: {
        appointment: { ...appointment },
        callback: next,
      },
    });
  };

  handleBack = () => {
    const { prev } = this.props;
    prev();
  };

  render() {
    const { appointment, loading } = this.props;

    if (isEmpty(appointment)) return null;

    const {
      title,
      start,
      end,
      patient,
      dentist,
      description,
      service,
      schedulerType,
    } = appointment;

    const timeString = buildTimeString(start, end);
    const dentistName = `${dentist.firstName} ${dentist.lastName}`;
    const patientName = `${patient.firstName} ${patient.lastName}`;
    const serviceName = get(service, 'name');
    const schedulerTypeName = get(schedulerType, 'name');

    return (
      <div className={Styles.wrapper}>
        <Descriptions column={1} title="Appointment Summary" bordered>
          <Descriptions.Item label="Title">{title}</Descriptions.Item>
          <Descriptions.Item label="Time">{timeString}</Descriptions.Item>
          <Descriptions.Item label="Patient">
            <Tag title={patientName}>{patientName}</Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Dentist">
            <Tag title={dentistName}>{dentistName}</Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Service">
            <Tag title={serviceName}>{serviceName}</Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Scheduler Type">
            <Tag title={schedulerTypeName}>{schedulerTypeName}</Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Description">{description}</Descriptions.Item>
        </Descriptions>

        <div className={Styles.actions}>
          <Button onClick={this.handleBack}>{formatMessage(messages.previous)}</Button>
          <Button
            type="primary"
            onClick={this.handleSubmit}
            className={Styles.leftElement}
            loading={loading}
          >
            {formatMessage(messages.confirm)}
          </Button>
        </div>
      </div>
    );
  }
}

export default Step3;
