import { get } from 'lodash';
import { resetPassword } from './service';
import { NAMESPACE } from './constants';

const defaultState = {
  name: '',
  description: '',
};

const model = {
  namespace: NAMESPACE,
  state: {
    initData: defaultState,
    // isSuccess: false,
  },
  effects: {
    *resetPassword({ payload }, { call, put }) {
      const { id, user, callback } = payload;
      const { new_password: newPassword } = user;
      const response = yield call(resetPassword, id, newPassword);
      if (response) {
        yield put({ type: 'save' });
        callback();
      }
    },
  },

  reducers: {
    saveInitData(state, { payload }) {
      return {
        ...state,
        initData: {
          ...state.initData,
          ...payload,
        },
      };
    },
    save(state) {
      return {
        ...state,
        isSuccess: true,
      };
    },
    clear(state) {
      return {
        ...state,
        isSuccess: false,
      };
    },
  },
};

export default model;
