export default {
  'global.messages.user.newUser': 'New User',
  'global.messages.user.updateUser': 'Update User',
  'global.messages.user.deleteUserTitle': 'Delete User',
  'global.messages.user.deleteUserContent': 'Are you sure you want to delete this User ?',
  'global.messages.user.archiveUserTitle': 'Archive User',
  'global.messages.user.archiveUserContent': 'Are you sure you want to archive this User ?',
  'global.messages.user.blockUserTitle': 'Block User',
  'global.messages.user.blockUserContent': 'Are you sure you want to block this User ?',
  'global.messages.user.userInfo': 'Account Settings',
  'global.messages.user.logout': 'Logout',
  'pages.admin.user.filterPlaceholder': 'Select status',
  'pages.admin.user.searchPlaceholder': 'Search by name or email',
  'pages.admin.user.list.allUsers': 'All Users',
  'pages.admin.user.list.firstName': 'FIRST NAME',
  'pages.admin.user.list.lastName': 'LAST NAME',
  'pages.admin.user.list.email': 'EMAIL',
  'pages.admin.user.list.status': 'STATUS',
  'pages.admin.user.list.id': 'ID',
  'pages.admin.user.list.actions': 'ACTIONS',
  'share.userModal.userInfo': 'User Info',
  'share.userModal.checkboxPassword': 'Generate Password Automatically',
  'share.userModal.additionalProperties': 'Additional Properties',
  'share.userModal.workingHours': 'Working Hours',
  'share.userModal.userCreated': 'User created successfully!',
  'share.userModal.userUpdated': 'User updated successfully!',
  'share.userModal.socialNumber': 'Social Number',
  'share.userModal.socialNumberError': 'Social Number is required',
  'share.userModal.socialNumberPlaceholder': 'Social Number',
  'share.userModal.signature': 'Signature',
  'share.userModal.signatureError': 'Signature is required',
  'share.userModal.signaturePlaceholder': 'Signature',
  'share.userModal.permanent': 'Permanent',
  'share.userModal.permanentError': 'Permanent is required',
  'share.userModal.permanentPlaceholder': 'Permanent',
  'share.userModal.ratePerHours': 'Rate Per Hours',
  'share.userModal.ratePerHoursError': 'Rate Per Hours is required',
  'share.userModal.ratePerHoursPlaceholder': 'Rate Per Hours',
  'share.userModal.overtime': 'Overtime',
  'share.userModal.overtimeError': 'Overtime is required',
  'share.userModal.overtimePlaceholder': 'Overtime',
  'share.userModal.addNewHours': 'Add New Hours',
  'share.userModal.addHours': 'Add Hours',
  'share.userModal.monday': 'Monday',
  'share.userModal.tuesday': 'Tuesday',
  'share.userModal.wednesday': 'Wednesday',
  'share.userModal.thursday': 'Thursday',
  'share.userModal.friday': 'Friday',
  'share.userModal.saturday': 'Saturday',
  'share.userModal.sunday': 'Sunday',
  'share.userModal.from': 'From',
  'share.userModal.to': 'to',
};