export default {
  'global.messages.dentist.newDentist': 'New Dentist',
  'global.messages.dentist.updateDentist': 'Update Dentist',
  'global.messages.dentist.deleteDentistTitle': 'Delete Dentist',
  'global.messages.dentist.deleteDentistContent': 'Are you sure you want to delete this Dentist ?',
  'global.messages.dentist.archiveDentistTitle': 'Archive Dentist',
  'global.messages.dentist.archiveDentistContent':
    'Are you sure you want to archive this Dentist ?',
  'global.messages.dentist.dentistInfo': 'Dentist Info',
  'global.messages.dentist.workingHours': 'Working Hours',
  'share.dentistFilter.placeholder': 'Select dentist',
  'pages.dentist.list.allDentists': 'All Dentists',
  'pages.dentist.list.firstName': 'FIRST NAME',
  'pages.dentist.list.lastName': 'LAST NAME',
  'pages.dentist.list.email': 'EMAIL',
  'pages.dentist.list.major': 'MAJORS',
  'pages.dentist.list.phone': 'PHONE',
  'pages.dentist.list.actions': 'ACTIONS',
  'pages.dentist.list.averageTreatmentTime': 'AVG. TREATMENT TIME',
  'pages.dentist.majorFilter': 'Major Filter',
  'pages.dentist.archive': 'Archive',
  'pages.dentist.searchPlaceholder': 'Search by name or email',
  'share.dentistModal.averageTime': 'Average Time',
  'share.dentistModal.averageTimePlaceholder':
    'Average time to treat a patient (e.g 20 min, 30 min).',
  'share.dentistModal.averageTimeError': 'Average time is required',
  'share.dentistModal.addNewHours': 'Add New Hours',
  'share.dentistModal.addHours': 'Add Hours',
  'share.dentistModal.monday': 'Monday',
  'share.dentistModal.tuesday': 'Tuesday',
  'share.dentistModal.wednesday': 'Wednesday',
  'share.dentistModal.thursday': 'Thursday',
  'share.dentistModal.friday': 'Friday',
  'share.dentistModal.saturday': 'Saturday',
  'share.dentistModal.sunday': 'Sunday',
  'share.dentistModal.from': 'From',
  'share.dentistModal.to': 'to',
};
