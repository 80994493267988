import { defineMessages } from 'umi/locale';
import globalMessages from '@/messages';
import formMessages from '@/messages/form';
import appointmentMessages from '@/messages/appointment';
import { NAMESPACE } from './constants';

const messages = defineMessages({
  date: {
    id: `${NAMESPACE}.date`,
    defaultMessage: 'Time',
  },
  datePlaceholder: {
    id: `${NAMESPACE}.datePlaceholder`,
    defaultMessage: 'Date',
  },
  dateError: {
    id: `${NAMESPACE}.dateError`,
    defaultMessage: 'Date is required',
  },
  startTime: {
    id: `${NAMESPACE}.startTime`,
    defaultMessage: 'Start Time',
  },
  startTimePlaceholder: {
    id: `${NAMESPACE}.startTimePlaceholder`,
    defaultMessage: 'Start Time',
  },
  startTimeError: {
    id: `${NAMESPACE}.startTimeError`,
    defaultMessage: 'Start time is required',
  },
  endTime: {
    id: `${NAMESPACE}.endTime`,
    defaultMessage: 'End Time',
  },
  endTimePlaceholder: {
    id: `${NAMESPACE}.endTimePlaceholder`,
    defaultMessage: 'End Time',
  },
  endTimeError: {
    id: `${NAMESPACE}.endTimeError`,
    defaultMessage: 'End time is required',
  },
  patient: {
    id: `${NAMESPACE}.patient`,
    defaultMessage: 'Patient',
  },
  patientError: {
    id: `${NAMESPACE}.patientError`,
    defaultMessage: 'Patient is required',
  },
  dentist: {
    id: `${NAMESPACE}.dentist`,
    defaultMessage: 'Dentist',
  },
  dentistError: {
    id: `${NAMESPACE}.dentistError`,
    defaultMessage: 'Dentist is required',
  },
  title: {
    id: `${NAMESPACE}.title`,
    defaultMessage: 'Title',
  },
  titlePlaceholder: {
    id: `${NAMESPACE}.titlePlaceholder`,
    defaultMessage: 'Title',
  },
  titleError: {
    id: `${NAMESPACE}.titleError`,
    defaultMessage: 'Title is required',
  },
  titleMaxLengthError: {
    id: `${NAMESPACE}.titleMaxLengthError`,
    defaultMessage: 'Max length is 255.',
  },
  services: {
    id: `${NAMESPACE}.services`,
    defaultMessage: 'Services',
  },
  servicesPlaceholder: {
    id: `${NAMESPACE}.servicesPlaceholder`,
    defaultMessage: 'Select service',
  },
  servicesRequired: {
    id: `${NAMESPACE}.servicesRequired`,
    defaultMessage: 'Services is required',
  },
  steps: {
    step1Title: {
      id: `${NAMESPACE}.steps.step1Title`,
      defaultMessage: 'Patient and Dentist',
    },
    step1Description: {
      id: `${NAMESPACE}.steps.step1Description`,
      defaultMessage: 'Select patient, dentist',
    },
    step2Title: {
      id: `${NAMESPACE}.steps.step2Title`,
      defaultMessage: 'Time Slot',
    },
    step2Description: {
      id: `${NAMESPACE}.steps.step2Description`,
      defaultMessage: 'Select available time slot',
    },
    step3Title: {
      id: `${NAMESPACE}.steps.step3Title`,
      defaultMessage: 'Confirm',
    },
    step3Description: {
      id: `${NAMESPACE}.steps.step3Description`,
      defaultMessage: 'Booking confirmation',
    },
    step3SMSHeader: {
      id: `${NAMESPACE}.steps.step3SMSHeader`,
      defaultMessage: 'A confirmation message has been sent to this phone number:',
    },
    step3SMSWarning: {
      id: `${NAMESPACE}.steps.step3SMSWarning`,
      defaultMessage: 'Please check your phone number in case you have not received any message.',
    },
    resendSMS: {
      id: `${NAMESPACE}.steps.resendSMS`,
      defaultMessage: 'Resend SMS',
    },
    step4Title: {
      id: `${NAMESPACE}.steps.step4Title`,
      defaultMessage: 'Result',
    },
    step4Description: {
      id: `${NAMESPACE}.steps.step4Description`,
      defaultMessage: 'Booking result',
    },
    availableSlotSelected: {
      id: `${NAMESPACE}.steps.availableSlotSelected`,
      defaultMessage: 'Appointment slot {start} - {end} selected!',
    },
    notAvailableSlotSelected: {
      id: `${NAMESPACE}.steps.notAvailableSlotSelected`,
      defaultMessage:
        'Selected appointment slot is not available or slot is out of working times. Please select another!',
    },
  },
  appointmentCreated: {
    id: `${NAMESPACE}.appointmentCreated`,
    defaultMessage: 'Appointment created successfully!',
  },
  appointmentUpdated: {
    id: `${NAMESPACE}.appointmentUpdated`,
    defaultMessage: 'Appointment updated successfully!',
  },
});

export default {
  ...formMessages,
  ...appointmentMessages,
  ...messages,
  update: globalMessages.common.update,
  saveAndNew: globalMessages.common.saveAndNew,
  save: globalMessages.common.save,
  cancel: globalMessages.common.cancel,
  done: globalMessages.common.done,
  confirm: globalMessages.common.confirm,
  previous: globalMessages.common.previous,
};
