import React, { PureComponent } from 'react';
import propTypes from 'prop-types';

import { PP_FORM_INPUT } from '@/constants';
import {
  Modal,
  Button,
  Form,
  Row,
  Col,
  Input,
} from '@/componentsX/UIElements';

import { FORM } from './constants';
import { mapToFormData } from './service';
import messages from './messages';
import Styles from './styles.less';

const { Item: FormItem, createFormField } = Form;

@Form.create({
  mapPropsToFields(props) {
    const property = mapToFormData(props.property);
    return Object.keys(property).reduce((acc, fieldName) => {
      acc[fieldName] = createFormField({ value: property[fieldName] });
      return acc;
    }, {});
  },
})
class PropertyModalView extends PureComponent {
  static propTypes = {
    isOpen: propTypes.bool.isRequired,
    loading: propTypes.bool.isRequired,
    handleCancel: propTypes.func.isRequired,
    handleUpdate: propTypes.func.isRequired,
  };

  handleCallback = (callBack, values) => {
    const { form } = this.props;
    callBack(form, values);
  };

  renderFooter = () => {
    const {
      handleUpdate,
      loading,
      handleCancel,
    } = this.props;

    const handleSubmitUpdate = values => this.handleCallback(handleUpdate, values);
    return [
      <Button key="cancel" onClick={handleCancel}>
        Cancel
      </Button>,
      <Button
        key="submit"
        type="primary"
        loading={loading}
        onClick={handleSubmitUpdate}
      >
        Update
      </Button>
    ];
  };

  render() {
    const { form, isOpen, handleCancel } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Modal
        title='Update Property'
        centered
        visible={isOpen}
        onCancel={handleCancel}
        destroyOnClose
        maskClosable={false}
        footer={this.renderFooter()}
        width="600px"
        className={Styles.modalBody}
      >
        <Form layout="vertical" className={Styles.propertyResource}>
          <Row gutter={16}>
            <Col sm={24} md={12}>
              <FormItem label="Property Name" colon={false}>
                {getFieldDecorator(FORM.property, {
                  rules: [{ required: true, message: 'Property Name is required' }],
                })(
                  <Input
                    className={PP_FORM_INPUT}
                    placeholder="Property Name"
                  />
                )}
              </FormItem>
            </Col>
            <Col sm={24} md={12}>
              <FormItem label="Property Value" colon={false}>
                {getFieldDecorator(FORM.value, {
                  rules: [{ required: true, message: 'Property Value is required' }],
                })(
                  <Input
                    className={PP_FORM_INPUT}
                    placeholder="Property Value"
                  />
                )}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default PropertyModalView;
