import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import { formatMessage } from 'umi/locale';
import { PP_FORM_INPUT, SUBMIT_BUTTON } from '@/constants';
import { Modal, Button, Form, Row, Col, Input } from '@/componentsX/UIElements';
import { FORM } from './constants';
import { mapToFormData } from './service';
import messages from './messages';
import Styles from './styles.less';

const { Item: FormItem, createFormField } = Form;

@Form.create({
  mapPropsToFields(props) {
    const user = mapToFormData(props.user);
    return Object.keys(user).reduce((acc, fieldName) => {
      acc[fieldName] = createFormField({ value: user[fieldName] });
      return acc;
    }, {});
  },
  onValuesChange(props, values) {
    props.handleValuesChange(values);
  },
})
class ResetPasswordModalView extends PureComponent {
  static propTypes = {
    disableButtonUpdate: propTypes.bool.isRequired,
    isOpen: propTypes.bool.isRequired,
    isEdit: propTypes.bool.isRequired,
    loading: propTypes.bool.isRequired,
    initDataLoading: propTypes.bool.isRequired,
    submitButton: propTypes.string.isRequired,
    handleSave: propTypes.func.isRequired,
    handleCancel: propTypes.func.isRequired,
    handleUpdate: propTypes.func.isRequired,
  };

  handleCallback = (callBack, values) => {
    const { form } = this.props;
    callBack(form, values);
  };

  renderFooter = () => {
    const {
      isEdit,
      disableButtonUpdate,
      handleSave,
      handleUpdate,
      loading: loadingData,
      initDataLoading,
      submitButton,
      handleCancel,
    } = this.props;
    const loading = initDataLoading || loadingData;
    const handleSubmit = values => this.handleCallback(handleSave, values);
    const handleSubmitUpdate = values => this.handleCallback(handleUpdate, values);
    const submitButtons = isEdit
      ? [
        <Button
          key="submit"
          type="primary"
          loading={loading && submitButton === SUBMIT_BUTTON.update}
          disabled={disableButtonUpdate}
          onClick={handleSubmitUpdate}
        >
          {formatMessage(messages.update)}
        </Button>,
        ]
      : [
        <Button
          key="submit"
          type="primary"
          loading={loading && submitButton === SUBMIT_BUTTON.save}
          onClick={handleSubmit}
        >
          {formatMessage(messages.save)}
        </Button>,
        ];
    return [
      <Button key="cancel" onClick={handleCancel}>
        {formatMessage(messages.cancel)}
      </Button>,
      ...submitButtons,
    ];
  };

  render() {
    const { form, isOpen, isEdit, handleCancel } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Modal
        title={formatMessage(messages.resetPassword)}
        centered
        visible={isOpen}
        onCancel={handleCancel}
        destroyOnClose
        maskClosable={false}
        footer={this.renderFooter()}
        width="600px"
        className={Styles.modalBody}
      >
        <Form layout="vertical" className={Styles.userResource}>
          <Row>
            <Col>
              <FormItem label={formatMessage(messages.newPassword)} colon={false}>
                {getFieldDecorator(FORM.newPassword, {
                  rules: [{ required: true, message: formatMessage(messages.newPasswordRequired) }],
                })(
                  <Input
                    className={PP_FORM_INPUT}
                    placeholder={formatMessage(messages.newPassword)}
                    type="password"
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormItem label={formatMessage(messages.repeatNewPassword)} colon={false}>
                {getFieldDecorator(FORM.repeatNewPassword, {
                  rules: [
                    { required: true, message: formatMessage(messages.repeatNewPasswordRequired) },
                  ],
                })(
                  <Input
                    className={PP_FORM_INPUT}
                    placeholder={formatMessage(messages.repeatNewPassword)}
                    type="password"
                  />
                )}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default ResetPasswordModalView;
