export default {
  'global.messages.appointment.newAppointment': 'New Appointment',
  'global.messages.appointment.updateAppointment': 'Update Appointment',
  'global.messages.appointment.deleteAppointmentTitle': 'Delete appointment',
  'global.messages.appointment.deleteAppointmentContent': 'Are you sure you want to delete ',
  'global.messages.appointment.deleteAppointmentSuccess': 'Appointment deleted successfully!',
  'global.messages.appointment.completeAppointmentTitle': 'Mark as complete',
  'global.messages.appointment.completeAppointmentContent':
    'Are you sure you want to mark this appointment as complete?',
  'global.messages.appointment.completeAppointmentSuccess': 'Appointment update successfully!',
  'share.appointment.calendar.prev': 'Previous ',
  'share.appointment.calendar.next': 'Next ',
  'share.appointment.calendar.markAsComplete': 'Mark as complete',
  'share.appointment.calendar.cancelAppointment': 'Cancel appointment',
  'share.appointment.calendar.appointmentCompleted': 'Appointment is completed!',
  'share.appointment.calendar.notAvailableSlotSelected':
    'Selected appointment slot is not available or slot is out of working times. Please select another!',
  'pages.appointment.clearAllFilter': 'Clear all filter',
  'share.appointmentModal.date': 'Time',
  'share.appointmentModal.datePlaceholder': 'Date',
  'share.appointmentModal.dateError': 'Date is required',
  'share.appointmentModal.startTime': 'Start Time',
  'share.appointmentModal.startTimePlaceholder': 'Start Time',
  'share.appointmentModal.startTimeError': 'Start time is required',
  'share.appointmentModal.endTime': 'End Time',
  'share.appointmentModal.endTimePlaceholder': 'End Time',
  'share.appointmentModal.endTimeError': 'End time is required',
  'share.appointmentModal.patient': 'Patient',
  'share.appointmentModal.patientError': 'Patient is required',
  'share.appointmentModal.dentist': 'Dentist',
  'share.appointmentModal.dentistError': 'Dentist is required',
  'share.appointmentModal.title': 'Title',
  'share.appointmentModal.titlePlaceholder': 'Title',
  'share.appointmentModal.titleError': 'Title is required',
  'share.appointmentModal.titleMaxLengthError': 'Max length is 255.',
  'share.appointmentModal.services': 'Services',
  'share.appointmentModal.servicesPlaceholder': 'Select service',
  'share.appointmentModal.servicesRequired': 'Services is required',
  'share.appointmentModal.steps.step1Title': 'Patient and Dentist',
  'share.appointmentModal.steps.step1Description': 'Select patient, dentist',
  'share.appointmentModal.steps.step2Title': 'Time Slot',
  'share.appointmentModal.steps.step2Description': 'Select available time slot',
  'share.appointmentModal.steps.step3Title': 'Confirm',
  'share.appointmentModal.steps.step3Description': 'Booking confirmation',
  'share.appointmentModal.steps.step3SMSHeader':
    'A confirmation message has been sent to this phone number:',
  'share.appointmentModal.steps.step3SMSWarning':
    'Please check your phone number in case you have not received any message.',
  'share.appointmentModal.steps.resendSMS': 'Resend SMS',
  'share.appointmentModal.steps.step4Title': 'Result',
  'share.appointmentModal.steps.step4Description': 'Booking result',
  'share.appointmentModal.steps.availableSlotSelected':
    'Appointment slot {start} - {end} selected!',
  'share.schedulerTypeFilter.placeholder': 'Select scheduler types',
  'share.appointmentStatusFilter.placeholder': 'Select a status',
};
