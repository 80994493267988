import { createService, updateService } from './service';
import { NAMESPACE } from './constants';

const defaultState = {};

const model = {
  namespace: NAMESPACE,

  state: {
    initData: defaultState,
    isSuccess: false,
  },

  effects: {
    *create({ payload }, { call, put }) {
      const { service, callback } = payload;
      const response = yield call(createService, service);
      if (response) {
        yield put({ type: 'save' });
        callback();
      }
    },
    *update({ payload }, { call, put }) {
      const { id, service, callback } = payload;
      const response = yield call(updateService, id, service);
      if (response) {
        yield put({ type: 'save' });
        callback();
      }
    },
  },

  reducers: {
    save(state) {
      return {
        ...state,
        isSuccess: true,
      };
    },
    clear(state) {
      return {
        ...state,
        isSuccess: false,
      };
    },
  },
};

export default model;
