import moment from "moment";

export default function momentToDate(momentDate) {
  const computedDate = moment.isMoment(momentDate) ? momentDate : moment(momentDate);
  const date = new Date(
    computedDate.year(),
    computedDate.month(),
    computedDate.date(),
    computedDate.hour(),
    computedDate.minute(),
    computedDate.second(),
  );
  return date;
}
