import { defineMessages } from 'umi/locale';
import PATH from './constants';

export default defineMessages({
  newAppointment: {
    id: `${PATH}.appointment.newAppointment`,
    defaultMessage: 'New Appointment',
  },
  updateAppointment: {
    id: `${PATH}.appointment.updateAppointment`,
    defaultMessage: 'Update Appointment',
  },
  deleteAppointmentTitle: {
    id: `${PATH}.appointment.deleteAppointmentTitle`,
    defaultMessage: 'Delete appointment',
  },
  deleteAppointmentContent: {
    id: `${PATH}.appointment.deleteAppointmentContent`,
    defaultMessage: 'Are you sure you want to delete ',
  },
  deleteAppointmentSuccess: {
    id: `${PATH}.appointment.deleteAppointmentSuccess`,
    defaultMessage: 'Appointment deleted successfully!',
  },
  completeAppointmentTitle: {
    id: `${PATH}.appointment.completeAppointmentTitle`,
    defaultMessage: 'Mark as complete',
  },
  completeAppointmentContent: {
    id: `${PATH}.appointment.completeAppointmentContent`,
    defaultMessage: 'Are you sure you want to mark this appointment as complete?',
  },
  completeAppointmentSuccess: {
    id: `${PATH}.appointment.completeAppointmentSuccess`,
    defaultMessage: 'Appointment update successfully!',
  },
  cancelAppointmentTitle: {
    id: `${PATH}.appointment.cancelAppointmentTitle`,
    defaultMessage: 'Cancel appointment',
  },
  cancelAppointmentContent: {
    id: `${PATH}.appointment.cancelAppointmentContent`,
    defaultMessage: 'Are you sure you want to cancel this appointment?',
  },
  cancelAppointmentSuccess: {
    id: `${PATH}.appointment.cancelAppointmentSuccess`,
    defaultMessage: 'Appointment update successfully!',
  },
});
