export { default } from './W4Form';
export const W4_FIELDS = {
  firstName: 'first_name',
  lastName: 'last_name',
  socialSecurityNumber: 'social_security_number',
  homeAddress: 'home_address',
  single: 'single',
  married: 'married',
  marriedHigherSingleRate: 'married_higher_single_rate',
  cityTownStateZipCode: 'city_town_state_zip_code',
  lastNameDifferSsc: 'last_name_differ_ssc',
  totalAllowances: 'total_allowances',
  additionalAmount: 'additional_amount',
  exempt: 'exempt',
  employerName: 'employer_name',
  firstDateEmployment: 'first_date_employment',
  employerIdentificationNumber: 'employer_identification_number',
  page31: 'page_3_1',
  page32: 'page_3_2',
  page33: 'page_3_3',
  page34: 'page_3_4',
  page35: 'page_3_5',
  page36: 'page_3_6',
  page37: 'page_3_7',
  page38: 'page_3_8',
  page39: 'page_3_9',
  page310: 'page_3_10',
  page3a: 'page_3_a',
  page3b: 'page_3_b',
  page3c: 'page_3_c',
  page3d: 'page_3_d',
  page3e: 'page_3_e',
  page3f: 'page_3_f',
  page3g: 'page_3_g',
  page3h: 'page_3_h',
  page41: 'page_4_1',
  page42: 'page_4_2',
  page43: 'page_4_3',
  page44: 'page_4_4',
  page45: 'page_4_5',
  page46: 'page_4_6',
  page47: 'page_4_7',
  page48: 'page_4_8',
  page49: 'page_4_9',
};
