import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import { formatMessage } from 'umi/locale';
import { PP_FORM_INPUT, SUBMIT_BUTTON } from '@/constants';
import { SCHEDULER_TYPE_FORM } from './constants';
import {
  Modal,
  Button,
  Form,
  Row,
  Col,
  Input,
  Select,
  InputNumber,
} from '@/componentsX/UIElements';
import { mapToFormData } from './service';
import messages from './messages';
import Styles from './styles.less';

const { Item: FormItem, createFormField } = Form;
const { Option } = Select;

@Form.create({
  mapPropsToFields(props) {
    const schedulerType = mapToFormData(props.schedulerType);
    return Object.keys(schedulerType).reduce((acc, fieldName) => {
      acc[fieldName] = createFormField({ value: schedulerType[fieldName] });
      return acc;
    }, {});
  },
})
class SchedulerTypeModalView extends PureComponent {
  static propTypes = {
    initData: propTypes.object.isRequired,
    schedulerType: propTypes.object.isRequired,
    disableButtonUpdate: propTypes.bool.isRequired,
    isOpen: propTypes.bool.isRequired,
    isEdit: propTypes.bool.isRequired,
    loading: propTypes.bool.isRequired,
    initDataLoading: propTypes.bool.isRequired,
    submitButton: propTypes.string.isRequired,
    handleSave: propTypes.func.isRequired,
    handleCancel: propTypes.func.isRequired,
    handleSaveAndNew: propTypes.func.isRequired,
    handleUpdate: propTypes.func.isRequired,
  };

  handleCallback = (callBack, values) => {
    const { form } = this.props;
    callBack(form, values);
  };

  renderFooter = () => {
    const {
      isEdit,
      disableButtonUpdate,
      handleSave,
      handleSaveAndNew,
      handleUpdate,
      loading: loadingData,
      initDataLoading,
      submitButton,
      handleCancel,
    } = this.props;

    const loading = initDataLoading || loadingData;
    const handleSubmit = values => this.handleCallback(handleSave, values);
    const handleSubmitAndNew = values => this.handleCallback(handleSaveAndNew, values);
    const handleSubmitUpdate = values => this.handleCallback(handleUpdate, values);
    const submitButtons = isEdit
      ? [
          <Button
            key="submit"
            type="primary"
            loading={loading && submitButton === SUBMIT_BUTTON.update}
            disabled={disableButtonUpdate}
            onClick={handleSubmitUpdate}
          >
            {formatMessage(messages.update)}
          </Button>,
        ]
      : [
          <Button
            key="submitAndNew"
            loading={loading && submitButton === SUBMIT_BUTTON.saveAndNew}
            onClick={handleSubmitAndNew}
          >
            {formatMessage(messages.saveAndNew)}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading && submitButton === SUBMIT_BUTTON.save}
            onClick={handleSubmit}
          >
            {formatMessage(messages.save)}
          </Button>,
        ];
    return [
      <Button key="cancel" onClick={handleCancel}>
        {formatMessage(messages.cancel)}
      </Button>,
      ...submitButtons,
    ];
  };

  renderEmptyOption = () => {
    return (
      <Option key={undefined} value={undefined}>
        --
      </Option>
    );
  };

  render() {
    const { form, isOpen, isEdit, handleCancel, initData } = this.props;
    const { getFieldDecorator } = form;
    const { majors } = initData;
    return (
      <Modal
        title={formatMessage(isEdit ? messages.updateSchedulerType : messages.newSchedulerType)}
        centered
        visible={isOpen}
        onCancel={handleCancel}
        destroyOnClose
        maskClosable={false}
        footer={this.renderFooter()}
        width="600px"
        className={Styles.modalBody}
      >
        <Form layout="vertical" className={Styles.schedulerTypeResource}>
          <Row>
            <Col sm={24} md={24}>
              <FormItem label={formatMessage(messages.name)} colon={false}>
                {getFieldDecorator(SCHEDULER_TYPE_FORM.name, {
                  rules: [
                    {
                      required: true,
                      message: formatMessage(messages.nameRequired),
                      whitespace: true,
                    },
                  ],
                })(
                  <Input
                    className={PP_FORM_INPUT}
                    placeholder={formatMessage(messages.namePlaceholder)}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm={24} md={12}>
              <FormItem label={formatMessage(messages.major)} colon={false}>
                {getFieldDecorator(SCHEDULER_TYPE_FORM.major, {
                  rules: [
                    {
                      required: true,
                      message: formatMessage(messages.majorError),
                      whitespace: true,
                    },
                  ],
                })(
                  <Select
                    className={Styles.select}
                    placeholder={formatMessage(messages.majorPlaceholder)}
                  >
                    {this.renderEmptyOption()}
                    {majors.map(item => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col sm={24} md={12}>
              <FormItem label={formatMessage(messages.time)} colon={false}>
                {getFieldDecorator(SCHEDULER_TYPE_FORM.time, {
                  rules: [
                    {
                      required: true,
                      message: formatMessage(messages.timeError),
                      whitespace: true,
                    },
                  ],
                })(
                  <InputNumber
                    className={Styles.input}
                    min={1}
                    placeholder={formatMessage(messages.timePlaceholder)}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default SchedulerTypeModalView;
