import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'dva';
import { debounce, isPlainObject } from 'lodash';

import { Select } from '@/componentsX/UIElements';
import PatientModal from '@/componentsX/_share/PatientModal';

import PatientFilterView from './view';
import { NAMESPACE, DEBOUNCE_TIME } from './constants';

const { LabeledValue } = Select;
@connect(state => ({
  patients: state[NAMESPACE].patients,
  loading: state.loading.effects[`${NAMESPACE}/fetch`],
}))
class PatientFilterContainer extends PureComponent {
  static propTypes = {
    patients: PropTypes.array.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.shape(LabeledValue),
      PropTypes.arrayOf(PropTypes.shape(LabeledValue)),
    ]),
    loading: PropTypes.bool,
    allowCreateInline: PropTypes.bool,
    size: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    allowCreateInline: false,
    loading: false,
    value: undefined,
    size: 'default',
  };

  // memoization here
  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.value) {
      return {
        selectedPatient: props.value,
        value: props.value,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.fetchPatientsDebounce = debounce(this.fetchPatients, DEBOUNCE_TIME);
    this.state = {
      selectedPatient: props.value,
      value: props.value,
      isModalOpen: false,
    };
  }

  componentDidMount() {
    this.fetchPatients();
  }

  fetchPatients = () => {
    const { dispatch } = this.props;
    const { search } = this;
    dispatch({
      type: `${NAMESPACE}/fetch`,
      search,
    });
  };

  handleValueChange = value => {
    const { onChange, patients } = this.props;
    this.setState({
      selectedPatient: value,
    });

    let conductedValue;

    if (Array.isArray(value)) {
      conductedValue = value.map(v => {
        const patient = patients.find(p => p.id === Number(v.key));
        return {
          ...v,
          ...patient,
        };
      });
    } else if (isPlainObject(value)) {
      const patient = patients.find(p => p.id === Number(value.key));

      conductedValue = {
        ...value,
        ...patient,
      };
    }

    onChange(conductedValue);
  };

  handleSearch = search => {
    this.search = search;
    this.fetchPatientsDebounce();
  };

  handleCreate = () => {
    this.setState({
      isModalOpen: true,
    });
  };

  handleCloseModal = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  handlePatientCreated = patient => {
    if (patient) {
      const { id, firstName, lastName } = patient;

      this.handleValueChange({
        ...patient,
        key: id,
        label: `${firstName} ${lastName}`,
      });
    }
  };

  render() {
    const { patients, loading, className, size, allowCreateInline, ...restProps } = this.props;
    const { selectedPatient, isModalOpen } = this.state;
    return (
      <Fragment>
        <PatientFilterView
          {...restProps}
          allowCreateInline={allowCreateInline}
          patients={patients}
          selectedPatient={selectedPatient}
          className={className}
          size={size}
          loading={loading}
          onSearch={this.handleSearch}
          onChange={this.handleValueChange}
          onCreate={this.handleCreate}
        />
        {allowCreateInline && (
          <PatientModal
            isOpen={isModalOpen}
            isEdit={false}
            handleCancel={this.handleCloseModal}
            callback={this.handlePatientCreated}
          />
        )}
      </Fragment>
    );
  }
}

export default PatientFilterContainer;

export { default as model } from './model';
export { NAMESPACE } from './constants';
