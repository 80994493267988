import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'dva';

import { notification } from '@/componentsX/UIElements';
import { NAMESPACE } from './constants';
import PropertyModalView from './view';

@connect(state => ({
  isSuccess: state[NAMESPACE].isSuccess,
  loading:
    state.loading.effects[`${NAMESPACE}/create`] || state.loading.effects[`${NAMESPACE}/update`],
}))
class PropertyModalContainer extends PureComponent {
  static propTypes = {
    property: PropTypes.object,
    isOpen: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    loading: false,
    property: {},
  };

  handleCancel = () => {
    const { handleCancel } = this.props;
    handleCancel();
  };

  handleCallback = () => {
    const { callback, handleCancel } = this.props;
    handleCancel();
    callback();
  };

  handleUpdate = form => {
    const { dispatch } = this.props;

    form.validateFieldsAndScroll((err, values) => {
      if (err) return;
      const {
        property: { id }
      } = this.props;

      dispatch({
        type: `${NAMESPACE}/update`,
        payload: {
          id, property: { id, ...values }, callback: () => {
            notification.success({ message: 'Property has been updated successfully' });
            this.handleCallback();
          }
        },
      });
    });
  };

  handleCancel = () => {
    const { handleCancel } = this.props;
    handleCancel();
  };

  render() {
    const { isOpen, isEdit, loading, property } = this.props;
    return (
      <PropertyModalView
        property={property}
        isOpen={isOpen}
        isEdit={isEdit}
        loading={loading}
        handleUpdate={this.handleUpdate}
        handleCancel={this.handleCancel}
      />
    );
  }
}

export default PropertyModalContainer;

export { default as model } from './model';
export { NAMESPACE } from './constants';
