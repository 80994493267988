import { defineMessages } from 'umi/locale';
import globalMessages from '@/messages';
import formMessages from '@/messages/form';
import { NAMESPACE } from './constants';

const messages = defineMessages({
  newTemplate: {
    id: `${NAMESPACE}.emailTemplate.newTemplate`,
    defaultMessage: 'New Template',
  },
  updateTemplate: {
    id: `${NAMESPACE}.emailTemplate.updateTemplate`,
    defaultMessage: 'Update Template',
  },
  type: {
    id: `${NAMESPACE}.emailTemplate.type`,
    defaultMessage: 'Type',
  },
  typeErr: {
    id: `${NAMESPACE}.emailTemplate.typeErr`,
    defaultMessage: 'Type is required!',
  },
  event: {
    id: `${NAMESPACE}.emailTemplate.event`,
    defaultMessage: 'Event',
  },
  eventErr: {
    id: `${NAMESPACE}.emailTemplate.eventErr`,
    defaultMessage: 'Event is required!',
  },
  models: {
    id: `${NAMESPACE}.emailTemplate.models`,
    defaultMessage: 'Models',
  },
  modelsErr: {
    id: `${NAMESPACE}.emailTemplate.modelsErr`,
    defaultMessage: 'Model is required!',
  },
  html: {
    id: `${NAMESPACE}.emailTemplate.html`,
    defaultMessage: 'HTML',
  },
  htmlErr: {
    id: `${NAMESPACE}.emailTemplate.htmlErr`,
    defaultMessage: 'HTML is required!',
  },
});

export default {
  ...formMessages,
  ...messages,
  update: globalMessages.common.update,
  saveAndNew: globalMessages.common.saveAndNew,
  save: globalMessages.common.save,
  cancel: globalMessages.common.cancel,
};
