import { defineMessages } from 'umi/locale';
import globalMessages from '@/messages';
import formMessages from '@/messages/form';
import schedulerTypeMessages from '@/messages/schedulerType';
import { NAMESPACE } from './constants';

const messages = defineMessages({
  time: {
    id: `${NAMESPACE}.time`,
    defaultMessage: 'Time',
  },
  timePlaceholder: {
    id: `${NAMESPACE}.timePlaceholder`,
    defaultMessage: 'Time in months',
  },
  timeError: {
    id: `${NAMESPACE}.timeError`,
    defaultMessage: 'Time is required',
  },
});

export default {
  ...formMessages,
  ...schedulerTypeMessages,
  ...messages,
  update: globalMessages.common.update,
  saveAndNew: globalMessages.common.saveAndNew,
  save: globalMessages.common.save,
  cancel: globalMessages.common.cancel,
};
