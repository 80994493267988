import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import moment from 'moment';
import { formatMessage } from 'umi/locale';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { isEmpty } from 'lodash';

import {
  PP_FORM_INPUT,
  PP_FORM_SELECT,
  SUBMIT_BUTTON,
  DEFAULT_AVATAR,
  SERVER_DATE_FORMAT,
} from '@/constants';
import { getAvatarURL } from '@/utils/image';
import {
  Button,
  Form,
  Row,
  Col,
  Input,
  DatePicker,
  Select,
  InputNumber,
} from '@/componentsX/UIElements';
import UploadAvatar from '@/componentsX/UploadAvatar';
import PhoneInput from '@/componentsX/PhoneInput';
import MajorFilter from '@/componentsX/_share/MajorFilter';

import { DENTIST_FORM } from '../../constants';
import { mapToFormData } from '../../service';
import messages from '../../messages';
import Styles from './styles.less';

const { Item: FormItem, createFormField } = Form;
const { Option } = Select;

@Form.create({
  mapPropsToFields(props) {
    const dentist = mapToFormData(props.dentist);
    return Object.keys(dentist).reduce((acc, fieldName) => {
      acc[fieldName] = createFormField({ value: dentist[fieldName] });
      return acc;
    }, {});
  },
})
class DentistInfoModalView extends PureComponent {
  static propTypes = {
    dentist: propTypes.object.isRequired,
    genders: propTypes.array.isRequired,
    isEdit: propTypes.bool.isRequired,
    loading: propTypes.bool.isRequired,
    submitButton: propTypes.string.isRequired,
    handleSave: propTypes.func.isRequired,
    handleCancel: propTypes.func.isRequired,
    handleSaveAndNew: propTypes.func.isRequired,
    handleUpdate: propTypes.func.isRequired,
  };

  componentDidUpdate() {
    const { form } = this.props;
    if (isEmpty(form.getFieldValue(DENTIST_FORM.avatar)) && this.avatarUpload) {
      this.avatarUpload.clearImage();
    }
  }

  handleCallback = (callBack, values) => {
    const { form } = this.props;
    callBack(form, values);
  };

  handleUploadAvatar = file => {
    const { form } = this.props;
    form.setFieldsValue({ [DENTIST_FORM.avatar]: file });
  };

  renderFooter = () => {
    const {
      isEdit,
      handleSave,
      handleSaveAndNew,
      handleUpdate,
      loading,
      submitButton,
      handleCancel,
    } = this.props;
    const handleSubmit = values => this.handleCallback(handleSave, values);
    const handleSubmitAndNew = values => this.handleCallback(handleSaveAndNew, values);
    const handleSubmitUpdate = values => this.handleCallback(handleUpdate, values);
    const submitButtons = isEdit
      ? [
        <Button
          key="submit"
          type="primary"
          loading={loading && submitButton === SUBMIT_BUTTON.update}
          onClick={handleSubmitUpdate}
        >
          {formatMessage(messages.update)}
        </Button>,
      ]
      : [
        <Button
          key="submitAndNew"
          loading={loading && submitButton === SUBMIT_BUTTON.saveAndNew}
          onClick={handleSubmitAndNew}
        >
          {formatMessage(messages.saveAndNew)}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading && submitButton === SUBMIT_BUTTON.save}
          // eslint-disable-next-line react/jsx-indent-props
          onClick={handleSubmit}
        >
          {formatMessage(messages.save)}
        </Button>,
      ];
    return [
      <Button key="cancel" onClick={handleCancel}>
        {formatMessage(messages.cancel)}
      </Button>,
      ...submitButtons,
    ];
  };

  validatePhoneInput = (rule, value, callback) => {
    if (value && !isValidPhoneNumber(value)) {
      callback(formatMessage(messages.invalidPhoneNumber));
    }
    callback();
  };

  disabledDob = date => {
    return moment(date).isAfter(moment());
  };

  render() {
    const { dentist, form, genders, isEdit } = this.props;
    const { getFieldDecorator } = form;
    const avatarSrc = isEdit ? getAvatarURL(dentist.avatar) : '';
    const shouldClearAvatar = isEmpty(dentist);
    return (
      <div>
        <Form layout="vertical" className={Styles.dentistResource}>
          <Row gutter={16}>
            <Col sm={24} md={4}>
              <FormItem>
                {getFieldDecorator(DENTIST_FORM.avatar, {
                  rules: [
                    {
                      required: true,
                      message: formatMessage(messages.avatarRequired),
                    },
                  ],
                })(
                  <UploadAvatar
                    ref={(avatarUpload) => { this.avatarUpload = avatarUpload }}
                    shouldClear={shouldClearAvatar}
                    image={avatarSrc}
                    handleUploadFile={this.handleUploadAvatar}
                    defaultIcon="person"
                    fallbackImage={DEFAULT_AVATAR}
                  />
                )}
              </FormItem>
            </Col>
            <Col sm={24} md={10}>
              <FormItem label={formatMessage(messages.firstName)} colon={false}>
                {getFieldDecorator(DENTIST_FORM.firstName, {
                  rules: [
                    {
                      required: true,
                      message: formatMessage(messages.firstNameError),
                      whitespace: true,
                    },
                  ],
                })(
                  <Input
                    className={PP_FORM_INPUT}
                    placeholder={formatMessage(messages.firstNamePlaceholder)}
                  />
                )}
              </FormItem>
            </Col>
            <Col sm={24} md={10}>
              <FormItem label={formatMessage(messages.lastName)} colon={false}>
                {getFieldDecorator(DENTIST_FORM.lastName, {
                  rules: [
                    {
                      required: true,
                      message: formatMessage(messages.lastNameError),
                      whitespace: true,
                    },
                  ],
                })(
                  <Input
                    className={PP_FORM_INPUT}
                    placeholder={formatMessage(messages.lastNamePlaceholder)}
                  />
                )}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col sm={24} md={12}>
              <FormItem label={formatMessage(messages.dob)} colon={false}>
                {getFieldDecorator(DENTIST_FORM.dob, {
                  rules: [
                    {
                      required: true,
                      message: formatMessage(messages.dobError),
                      type: 'object',
                    },
                  ],
                })(
                  <DatePicker
                    className={PP_FORM_INPUT}
                    format={SERVER_DATE_FORMAT}
                    placeholder={formatMessage(messages.dobPlaceholder)}
                    disabledDate={this.disabledDob}
                  />
                )}
              </FormItem>
            </Col>
            <Col sm={24} md={12}>
              <FormItem label={formatMessage(messages.major)} colon={false}>
                {getFieldDecorator(DENTIST_FORM.majors, {
                  rules: [
                    {
                      required: true,
                      message: formatMessage(messages.majorError),
                      whitespace: true,
                      type: 'array',
                    },
                  ],
                })(
                  <MajorFilter
                    className={PP_FORM_INPUT}
                    mode="multiple"
                    placeholder={formatMessage(messages.majorPlaceholder)}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm={24} md={12}>
              <FormItem label={formatMessage(messages.gender)} colon={false}>
                {getFieldDecorator(DENTIST_FORM.gender, {
                  rules: [{ required: true, message: formatMessage(messages.genderRequired) }],
                })(
                  <Select
                    className={PP_FORM_SELECT}
                    placeholder={formatMessage(messages.selectGenderPlaceholder)}
                  >
                    {genders.map(gender => (
                      <Option key={gender.value} value={gender.value}>
                        {gender.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col sm={24} md={12}>
              <FormItem label={formatMessage(messages.averageTime)} colon={false}>
                {getFieldDecorator(DENTIST_FORM.averageTreatmentTime, {
                  rules: [
                    {
                      required: true,
                      message: formatMessage(messages.averageTimeError),
                      whitespace: true,
                      type: 'number',
                    },
                  ],
                })(
                  <InputNumber
                    className={PP_FORM_INPUT}
                    placeholder={formatMessage(messages.averageTimePlaceholder)}
                    min={0}
                    max={1440}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm={24} md={12}>
              <FormItem label={formatMessage(messages.phone)} colon={false}>
                {getFieldDecorator(DENTIST_FORM.phoneNum, {
                  rules: [
                    {
                      validator: this.validatePhoneInput,
                    },
                  ],
                })(
                  <PhoneInput
                    displayInitialValueAsLocalNumber
                    className={PP_FORM_INPUT}
                    placeholder={formatMessage(messages.phone)}
                  />
                )}
              </FormItem>
            </Col>
            <Col sm={24} md={12}>
              <FormItem label={formatMessage(messages.email)} colon={false}>
                {getFieldDecorator(DENTIST_FORM.email, {
                  rules: [
                    {
                      type: 'email',
                      message: formatMessage(messages.emailInvalidError),
                    },
                    {
                      required: true,
                      message: formatMessage(messages.emailEmptyError),
                    },
                  ],
                })(
                  <Input
                    disabled={isEdit}
                    className={PP_FORM_INPUT}
                    placeholder={formatMessage(messages.emailPlaceholder)}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={Styles.address}>{formatMessage(messages.address)}</div>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormItem colon={false}>
                {getFieldDecorator(DENTIST_FORM.address)(
                  <Input className={PP_FORM_INPUT} placeholder={formatMessage(messages.address)} />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col className={Styles.footer}>
              {this.renderFooter()}
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default DentistInfoModalView;
