/* eslint-disable no-param-reassign */
import React, { PureComponent } from 'react';
import SignaturePad from 'react-signature-canvas';
import { Modal, Button } from '@/componentsX/UIElements';
import Styles from './styles.less';

class SignatureDrawer extends PureComponent {
  state = { trimmedDataURL: null };

  sigPad = {};

  clear = () => {
    this.sigPad.clear();
  };

  dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  trim = () => {
    const trimmedDataURL = this.sigPad.getTrimmedCanvas().toDataURL('image/png');
    this.setState({ trimmedDataURL });
    const { onSave, onClose } = this.props;
    const file = this.dataURLtoFile(trimmedDataURL, 'signature.png');
    onSave(file);
    onClose();
  };

  render() {
    const { trimmedDataURL } = this.state;
    const { isOpen, onClose } = this.props;
    return (
      <Modal
        centered
        visible={isOpen}
        onCancel={onClose}
        destroyOnClose
        maskClosable={false}
        footer=""
        width="600px"
      >
        <div className={Styles.container}>
          <div className={Styles.sigContainer}>
            <SignaturePad
              canvasProps={{ className: Styles.sigPad }}
              ref={ref => {
                this.sigPad = ref;
              }}
            />
          </div>
          <div>
            <Button className={Styles.buttons} onClick={this.clear}>
              CLEAR
            </Button>
            <Button className={Styles.buttons} onClick={this.trim}>
              SAVE
            </Button>
          </div>
          {trimmedDataURL ? <img className={Styles.sigImage} src={trimmedDataURL} /> : null}
        </div>
      </Modal>
    );
  }
}

export default SignatureDrawer;
