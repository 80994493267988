export default {
  'pages.admin.admin': 'Admin',
  'pages.admin.generalSettings': 'General Settings',
  'pages.admin.alertRulesSettings': 'Alert Rules Settings',
  'pages.admin.accountSettings': 'Account Settings',
  'pages.admin.searchSettings': 'Search settings',
  'pages.admin.regions': 'Regions',
  'pages.admin.users': 'Users',
  'pages.admin.roles': 'Roles',
  'pages.admin.account': 'Account',
  'pages.admin.schedulerTypes': 'Scheduler Types',
  'pages.admin.major': 'Majors',
  'pages.admin.services': 'Services',
  'pages.admin.workingTime': 'Working Times',
  'pages.admin.properties': 'Properties',
  'share.resetPasswordModal.repeatNewPassword': 'Repeat New Password',
  'share.resetPasswordModal.currentPasswordRequired': 'Current Password is required',
  'share.resetPasswordModal.newPassword': 'New Password',
  'share.resetPasswordModal.resetPassword': 'Reset Password',
};
