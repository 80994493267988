import { get } from 'lodash';
import { createW4Form, fetchW4Form, generateW4FormPDF } from '@/services/w4Form';
import { createUser, updateUser, updateAdditionalProps } from './service';
import {
  getWorkingTimes,
  addHours,
  updateHours,
  deleteHours,
} from './components/WorkingTimes/service';
import { uploadAvatar } from '@/utils/image';
import { NAMESPACE } from './constants';
import normalizeW4Form from './components/W4Form/utils/normalizeW4Form';

const defaultState = {
  users: [],
};

const model = {
  namespace: NAMESPACE,

  state: {
    initData: defaultState,
    isSuccess: false,
    w4Form: {},
  },

  effects: {
    *create({ payload }, { call, put }) {
      const { user, callback } = payload;
      const avatarResponse = yield call(uploadAvatar, user.avatar);

      if (avatarResponse) {
        const response = yield call(createUser, user, get(avatarResponse, 'uri'));
        if (response) {
          yield put({ type: 'save' });
          callback();
        }
      }
    },
    *update({ payload }, { call, put }) {
      const { id, user, callback } = payload;
      const { avatar } = user;

      if (avatar instanceof File) {
        const avatarResponse = yield call(uploadAvatar, avatar);
        if (avatarResponse) {
          const response = yield call(updateUser, id, user, get(avatarResponse, 'uri'));
          if (response) {
            yield put({ type: 'save' });
            callback();
          }
        }
      } else {
        const response = yield call(updateUser, id, user, avatar);
        if (response) {
          yield put({ type: 'save' });
          callback();
        }
      }
    },
    *updateAdditionalProperties({ payload }, { call, put }) {
      const { id, user, callback } = payload;
      const { signature_agreement: signatureAgreement } = user;

      if (signatureAgreement instanceof File) {
        const signatureResponse = yield call(uploadAvatar, signatureAgreement);
        if (signatureResponse) {
          const response = yield call(
            updateAdditionalProps,
            id,
            user,
            get(signatureResponse, 'uri')
          );
          if (response) {
            yield put({ type: 'save' });
            callback();
          }
        }
      } else {
        const response = yield call(updateAdditionalProps, id, user, signatureAgreement);
        if (response) {
          yield put({ type: 'save' });
          callback();
        }
      }
    },
    // workingTime
    *fetchWorkingTimes({ payload }, { call, put }) {
      const { user } = payload;
      const response = yield call(getWorkingTimes, user);
      if (response) {
        yield put({
          type: 'saveTime',
          payload: {
            workingTimes: get(response, 'data', []),
          },
        });
      }
    },
    *createWorkingTime({ payload }, { call, put }) {
      const { workingTime, user, callBack } = payload;
      yield call(addHours, workingTime, user);
      yield put({ type: 'save' });
      callBack();
    },
    *updateWorkingTime({ payload }, { call, put }) {
      const { workingTime, user, callBack } = payload;
      yield call(updateHours, workingTime, user);
      yield put({ type: 'save' });
      callBack();
    },
    *deleteWorkingTime({ payload }, { call, put }) {
      const { id, user, callBack } = payload;
      yield call(deleteHours, id, user);
      yield put({ type: 'save' });
      callBack();
    },
    *submitW4Form(
      {
        payload: { staffID, w4Form, onSuccess, onError },
      },
      { call }
    ) {
      const normalizedW4Form = normalizeW4Form(w4Form);
      const response = yield call(createW4Form, staffID, normalizedW4Form);
      if (!response && !onError) return;
      if (!response) {
        onError();
        return;
      }
      if (response && !onSuccess) return;
      onSuccess();
    },
    *fetchW4Form(
      {
        payload: { staffID },
      },
      { call, put }
    ) {
      const response = yield call(fetchW4Form, staffID);
      if (!response) {
        return;
      }
      const w4Form = get(response, 'data.properties', {});
      yield put({
        type: 'saveW4Form',
        payload: { w4Form },
      });
    },
    *previewW4Form(
      {
        payload: { staffID, w4Form },
      },
      { call }
    ) {
      const normalizedW4Form = normalizeW4Form(w4Form);
      yield call(generateW4FormPDF, staffID, normalizedW4Form);
    },
  },

  reducers: {
    saveInitData(state, { payload }) {
      return {
        ...state,
        initData: {
          ...state.initData,
          ...payload,
        },
      };
    },
    save(state) {
      return {
        ...state,
        isSuccess: true,
      };
    },
    clear(state) {
      return {
        ...state,
        isSuccess: false,
      };
    },
    saveTime(
      state,
      {
        payload: { workingTimes },
      }
    ) {
      return {
        ...state,
        list: {
          workingTimes,
        },
      };
    },
    saveW4Form(
      state,
      {
        payload: { w4Form },
      }
    ) {
      return {
        ...state,
        w4Form,
      };
    },
    clearW4Form(state) {
      return {
        ...state,
        w4Form: {},
      };
    },
  },
};

export default model;
