import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'dva';
import { formatMessage } from 'umi/locale';

import { notification } from '@/componentsX/UIElements';
import { SUBMIT_BUTTON } from '@/constants';
import { NAMESPACE } from './constants';
import ServiceModalView from './view';
import messages from './messages';

@connect(state => ({
  isSuccess: state[NAMESPACE].isSuccess,
  loading:
    state.loading.effects[`${NAMESPACE}/create`] || state.loading.effects[`${NAMESPACE}/update`],
}))
class ServiceModalContainer extends PureComponent {
  static propTypes = {
    service: PropTypes.object,
    isOpen: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    isSuccess: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    loading: false,
    service: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      submitButton: '',
      serviceInfo: props.service,
    };
  }

  componentDidUpdate(prevProps) {
    const { isOpen, isSuccess, dispatch } = this.props;
    const { submitButton } = this.state;

    if (isOpen && !prevProps.isOpen) {
      dispatch({ type: `${NAMESPACE}/clear` });
    }

    if (isOpen && isSuccess) {
      if (submitButton === SUBMIT_BUTTON.save || submitButton === SUBMIT_BUTTON.update) {
        this.handleCancel();
      } else if (submitButton === SUBMIT_BUTTON.saveAndNew) {
        this.handleClearData();
      }
    }
  }

  handleClearData = () => {
    const { dispatch } = this.props;
    this.setState(
      {
        serviceInfo: {},
      },
    );
    dispatch({ type: `${NAMESPACE}/clear` });
  };

  handleCancel = () => {
    const { handleCancel } = this.props;
    handleCancel();
    this.handleClearData();
  };

  handleCallback = () => {
    const { callback } = this.props;
    this.handleClearData();
    callback();
  };

  handleSave = form => {
    const { dispatch } = this.props;

    form.validateFieldsAndScroll((err, values) => {
      if (err) return;
      this.setState({ submitButton: SUBMIT_BUTTON.save, serviceInfo: { ...values } }, () => {
        dispatch({
          type: `${NAMESPACE}/create`,
          payload: {
            service: { ...values }, callback: () => {
              notification.success({ message: formatMessage(messages.serviceCreated) });
              this.handleCallback();
            }
          },
        });
      });
    });
  };

  handleSaveAndNew = form => {
    const { dispatch } = this.props;

    form.validateFieldsAndScroll((err, values) => {
      if (err) return;
      this.setState({ submitButton: SUBMIT_BUTTON.saveAndNew, serviceInfo: { ...values } }, () => {
        dispatch({
          type: `${NAMESPACE}/create`,
          payload: {
            service: { ...values }, callback: () => {
              notification.success({ message: formatMessage(messages.serviceCreated) });
              this.handleCallback();
            }
          },
        });
      });
    });
  };

  handleUpdate = form => {
    const { dispatch } = this.props;

    form.validateFieldsAndScroll((err, values) => {
      if (err) return;
      const {
        service: { id, price },
      } = this.props;
      this.setState({ submitButton: SUBMIT_BUTTON.update, serviceInfo: { ...values } }, () => {
        dispatch({
          type: `${NAMESPACE}/update`,
          payload: {
            id, service: { id, ...values, price }, callback: () => {
              notification.success({ message: formatMessage(messages.serviceUpdated) });
              this.handleCallback();
            }
          },
        });
      });
    });
  };

  render() {
    const { isOpen, isEdit, loading } = this.props;
    const { submitButton, serviceInfo } = this.state;
    return (
      <ServiceModalView
        service={serviceInfo}
        isOpen={isOpen}
        isEdit={isEdit}
        loading={loading}
        submitButton={submitButton}
        handleSave={this.handleSave}
        handleSaveAndNew={this.handleSaveAndNew}
        handleUpdate={this.handleUpdate}
        handleCancel={this.handleCancel}
      />
    );
  }
}

export default ServiceModalContainer;

export { default as model } from './model';
export { NAMESPACE } from './constants';
