import { defineMessages } from 'umi/locale';
import PATH from './constants';

export default defineMessages({
  newUser: {
    id: `${PATH}.user.newUser`,
    defaultMessage: 'New User',
  },
  updateUser: {
    id: `${PATH}.user.updateUser`,
    defaultMessage: 'Update User',
  },
  deleteUserTitle: {
    id: `${PATH}.user.deleteUserTitle`,
    defaultMessage: 'Delete User',
  },
  deleteUserContent: {
    id: `${PATH}.user.deleteUserContent`,
    defaultMessage: 'Are you sure you want to delete this User ?',
  },
  archiveUserTitle: {
    id: `${PATH}.user.archiveUserTitle`,
    defaultMessage: 'Archive User',
  },
  archiveUserContent: {
    id: `${PATH}.user.archiveUserContent`,
    defaultMessage: 'Are you sure you want to archive this User ?',
  },
  blockUserTitle: {
    id: `${PATH}.user.blockUserTitle`,
    defaultMessage: 'Block User',
  },
  blockUserContent: {
    id: `${PATH}.user.blockUserContent`,
    defaultMessage: 'Are you sure you want to block this User ?',
  },
  // Menu User profile
  userInfo: {
    id: `${PATH}.user.userInfo`,
    defaultMessage: 'Account Settings',
  },
  logout: {
    id: `${PATH}.user.logout`,
    defaultMessage: 'Logout',
  },
  attendanceReports: {
    id: `${PATH}.user.attendance`,
    defaultMessage: 'Attendance',
  },
});
