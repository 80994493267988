import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isEqual, unionBy } from 'lodash';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import classNames from 'classnames';

import { CALENDAR_VIEW, CALENDAR_STEP, CALENDAR_TIME_SLOT, APPOINTMENT_STATUS } from '@/constants';
import { toTimezone } from '@/utils/locale';
import { Spin } from '@/componentsX/UIElements';

import AppointmentEvent from './components/AppointmentEvent';
import AppointmentEventWrapper from './components/AppointmentEventWrapper';
import ResourceHeader from './components/ResourceHeader';
import Footer from './components/Footer';

import { mapAppointmentsToEvents, getDentistColorMapping } from './service';
import Styles from './styles.less';

const localizer = momentLocalizer(moment);

class CalendarView extends PureComponent {
  static propTypes = {
    allowEdit: PropTypes.bool.isRequired,
    appointments: PropTypes.array.isRequired,
    busyAppointments: PropTypes.array.isRequired,
    viewMode: PropTypes.string.isRequired,
    currentDate: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    highlightedAppointment: PropTypes.object,
    workingDentists: PropTypes.array.isRequired,
    onSelectTimeSlot: PropTypes.func.isRequired,
    onSelectAppointment: PropTypes.func.isRequired,
    onEditAppointment: PropTypes.func.isRequired,
    onDeleteAppointment: PropTypes.func.isRequired,
    onAppointmentPopoverClose: PropTypes.func.isRequired,
    dayPropGetter: PropTypes.func.isRequired,
    slotPropGetter: PropTypes.func.isRequired,
  };

  static defaultProps = {
    highlightedAppointment: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      events: this.getEvents(),
      dentistMapping: getDentistColorMapping(props.appointments),
    };
  }

  componentDidUpdate(prevProps) {
    const { appointments, busyAppointments } = this.props;
    if (!isEqual(appointments, prevProps.appointments)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        events: this.getEvents(),
        dentistMapping: getDentistColorMapping(appointments),
      });
    }

    if (!isEqual(busyAppointments, prevProps.busyAppointments)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        events: this.getEvents(),
      });
    }
  }

  getEvents = () => {
    const { appointments, busyAppointments } = this.props;
    return mapAppointmentsToEvents(unionBy(appointments, busyAppointments, 'id'));
  };

  getEventStyle = event => {
    const { status } = event;

    const styles = {
      border: 'none',
    };

    if (APPOINTMENT_STATUS.COMPLETED === status) {
      styles.opacity = 1;
    }
    return { style: styles };
  };

  render() {
    const {
      allowEdit,
      viewMode,
      currentDate,
      userTimezone,
      loading,
      highlightedAppointment,
      workingDentists,
      onSelectTimeSlot,
      onSelectAppointment,
      onEditAppointment,
      onDeleteAppointment,
      onCreateAppointment,
      onAppointmentPopoverClose,
      dayPropGetter,
      slotPropGetter,
      onCompleteAppointment,
      onCancelAppointment,
    } = this.props;
    const { events, dentistMapping } = this.state;
    const showResources = CALENDAR_VIEW.day === viewMode; // || CALENDAR_VIEW.week === viewMode;

    const components = {
      event: props => <AppointmentEvent {...props} view={viewMode} />,
      eventWrapper: props => {
        const {
          event: { appointmentInfo },
        } = props;
        const { id } = appointmentInfo;
        const highlighted = highlightedAppointment ? highlightedAppointment.id === id : false;
        return (
          <AppointmentEventWrapper
            {...props}
            highlighted={highlighted}
            allowEdit={allowEdit}
            onEditAppointment={onEditAppointment}
            onDeleteAppointment={onDeleteAppointment}
            onCreateAppointment={onCreateAppointment}
            onAppointmentPopoverClose={onAppointmentPopoverClose}
            onCompleteAppointment={onCompleteAppointment}
            onCancelAppointment={onCancelAppointment}
          />
        );
      },
      resourceHeader: props => (
        <ResourceHeader {...props} resourceInfo={dentistMapping[props.resource.id]} />
      ),
    };

    const calendarProps = {
      selectable: true,
      localizer,
      view: viewMode,
      timeslots: CALENDAR_TIME_SLOT,
      step: CALENDAR_STEP,
      onView: () => null,
      events,
      onSelectEvent: onSelectAppointment,
      onSelectSlot: onSelectTimeSlot,
      date: currentDate.toDate(),
      onNavigate: () => null,
      resizable: true,
      popup: true,
      toolbar: false,
      components,
      eventPropGetter: this.getEventStyle,
      dayPropGetter,
      slotPropGetter,
      getNow: () => toTimezone(new Date(), userTimezone),
      scrollToTime: currentDate.toDate(),
    };

    if (showResources) {
      // const resourceMap =  Object.keys(dentistMapping).map(key => dentistMapping[key]);
      const resourceMap = workingDentists;
      calendarProps.resources = resourceMap;
      calendarProps.resourceAccessor = event => event.resourceId;
      calendarProps.resourceIdAccessor = resource => resource.id;
    }

    const calendarCls = classNames(Styles.calendar, {
      [Styles.noFooter]: showResources,
    });

    return (
      <Spin spinning={loading} wrapperClassName={Styles.wrapper}>
        <div className={calendarCls}>
          <BigCalendar {...calendarProps} />
        </div>
        {!showResources && <Footer className={Styles.footer} dentistMapping={dentistMapping} />}
      </Spin>
    );
  }
}

export default CalendarView;
