import React, { PureComponent } from 'react';
import { connect } from 'dva';
import { isEqual } from 'lodash';
import { Spin } from '@/componentsX/UIElements';
import AuthService, { getAccessToken } from './authService';

const withAuthorized = WrapperComponent => {
  @connect(state => ({
    user: state.global.currentUser,
  }))
  class AuthorizedComponent extends PureComponent {
    constructor(props) {
      super(props);
      this.authService = new AuthService();
      this.state = {
        isRender: false,
      };
    }

    componentDidMount() {
      const { dispatch } = this.props;

      const accessToken = getAccessToken();
      if (accessToken) {
        this.setState({ isRender: true });
        dispatch({ type: 'global/fetchCurrentUser' });
      } else {
        this.authService.processAuthenticateFailed();
      }
    }

    render() {
      const { isRender } = this.state;
      const SpinStyle = {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      };

      return isRender ? (
        <WrapperComponent {...this.props} />
      ) : (
        <div style={SpinStyle}>
          <Spin tip="Loading..." />
        </div>
      );
    }
  }
  return AuthorizedComponent;
};

export default withAuthorized;
