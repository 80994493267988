import { defineMessages } from 'umi/locale';
import PATH from './constants';

export default defineMessages({
  newService: {
    id: `${PATH}.service.newService`,
    defaultMessage: 'New Service',
  },
  updateService: {
    id: `${PATH}.service.updateService`,
    defaultMessage: 'Update Service',
  },
  deleteServiceTitle: {
    id: `${PATH}.service.deleteServiceTitle`,
    defaultMessage: 'Delete Service',
  },
  deleteServiceContent: {
    id: `${PATH}.service.deleteServiceContent`,
    defaultMessage: 'Are you sure you want to delete this service?',
  },
});
