export const NAMESPACE = 'share.dentistModal';

export const DENTIST_FORM = {
  id: 'id',
  avatar: 'avatar',
  firstName: 'first_name',
  lastName: 'last_name',
  gender: 'gender',
  active: 'status',
  phoneNum: 'phone_num',
  email: 'email',
  dob: 'dob',
  majors: 'majors',
  majorIds: 'major_ids',
  averageTreatmentTime: 'average_treatment_time',
  address: 'address',
  workingHours: 'working_hours',
};
