import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import { formatMessage } from 'umi/locale';
import classNames from 'classnames';

import { Select, Spin, Input, Button } from '@/componentsX/UIElements';

import messages from './messages';
import Styles from './styles.less';

const { Option, LabeledValue } = Select;
const InputGroup = Input.Group;

class PatientFilterView extends PureComponent {
  static propTypes = {
    patients: propTypes.array.isRequired,
    selectedPatient: propTypes.oneOfType([
      propTypes.string,
      propTypes.arrayOf(propTypes.string),
      propTypes.shape(LabeledValue),
      propTypes.arrayOf(propTypes.shape(LabeledValue)),
    ]),
    allowCreateInline: propTypes.bool.isRequired,
    loading: propTypes.bool.isRequired,
    size: propTypes.string.isRequired,
    onSearch: propTypes.func.isRequired,
    onChange: propTypes.func.isRequired,
    onCreate: propTypes.func.isRequired,
  };

  static defaultProps = {
    selectedPatient: undefined,
  };

  handleCreate = () => {
    const { onCreate } = this.props;
    onCreate();
  };

  render() {
    const {
      allowCreateInline,
      patients,
      loading,
      selectedPatient,
      onSearch,
      onChange,
      className,
      size,
      ...restProps
    } = this.props;
    const selectCls = classNames(Styles.select, className, {
      [Styles.inlineCreate]: allowCreateInline === true,
    });

    return (
      <InputGroup compact={allowCreateInline} style={{ width: '100%' }}>
        <Select
          {...restProps}
          size={size}
          className={selectCls}
          allowClear
          showSearch
          labelInValue
          value={selectedPatient}
          placeholder={formatMessage(messages.placeholder)}
          notFoundContent={loading ? <Spin size="small" /> : null}
          filterOption={false}
          onSearch={onSearch}
          onChange={onChange}
        >
          {patients.map(d => (
            <Option key={d.id}>{`${d.firstName} ${d.lastName}`}</Option>
          ))}
        </Select>
        {allowCreateInline && <Button icon="plus" onClick={this.handleCreate} />}
      </InputGroup>
    );
  }
}

export default PatientFilterView;
