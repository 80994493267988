import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'dva';
import { formatMessage } from 'umi/locale';

import { Progress, Card, Divider, Button } from '@/componentsX/UIElements';

import { NAMESPACE } from '../constants';
import messages from '../messages';
import Styles from './Step4.less';

@connect(state => ({
  appointment: state[NAMESPACE].appointment,
  loading: state.loading.effects[`${NAMESPACE}/sendSMS`],
}))
class Step4 extends PureComponent {
  static propTypes = {
    ok: PropTypes.func.isRequired,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    loading: false,
  }

  handleSubmit = () => {
    const { ok } = this.props;
    ok();
  };

  handleResend = () => {
    const { dispatch, appointment } = this.props;
    dispatch({
      type: `${NAMESPACE}/sendSMS`,
      payload: {
        appointmentId: appointment.id,
      }
    })
  };

  render() {
    const {
      appointment: { patient },
      loading,
    } = this.props;
    const phoneNum = patient ? patient.phoneNum : undefined;

    return (
      <div className={Styles.wrapper}>
        <Progress type="circle" percent={100} width={40} />
        <Card bordered={false}>
          <p>{formatMessage(messages.steps.step3SMSHeader)}</p>
          <p className={Styles.phoneNumber}>{phoneNum}</p>
          <Divider />
          <p>{formatMessage(messages.steps.step3SMSWarning)}</p>
        </Card>

        <div>
          <Button type="primary" onClick={this.handleSubmit}>
            {formatMessage(messages.done)}
          </Button>
          <Button onClick={this.handleResend} className={Styles.leftElement} loading={loading}>
            {formatMessage(messages.steps.resendSMS)}
          </Button>
        </div>
      </div>
    );
  }
}

export default Step4;
