export default {
  'global.messages.freeservice.newService': 'New Free Service',
  'global.messages.freeservice.updateService': 'Update Free Service',
  'global.messages.freeservice.deleteServiceTitle': 'Delete Free Service',
  'global.messages.freeservice.deleteServiceContent': 'Are you sure you want to delete this free service?',
  'share.serviceModal.serviceCreated': 'Service created successfully!',
  'share.serviceModal.serviceUpdated': 'Service updated successfully!',
  'pages.admin.free-services.allServices': 'All Free Services',
  'pages.admin.free-services.actions': 'ACTIONS',
};
