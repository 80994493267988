import { defineMessages } from 'umi/locale';
import PATH from './constants';

export default defineMessages({
  newPatient: {
    id: `${PATH}.patient.newPatient`,
    defaultMessage: 'New Patient',
  },
  updatePatient: {
    id: `${PATH}.patient.updatePatient`,
    defaultMessage: 'Update Patient',
  },
  deletePatientTitle: {
    id: `${PATH}.patient.deletePatientTitle`,
    defaultMessage: 'Delete patient',
  },
  deletePatientContent: {
    id: `${PATH}.patient.deletePatientContent`,
    defaultMessage: 'Are you sure you want to delete ',
  },
});
