export const NAMESPACE = 'share.serviceModal';

export const SERVICE_FORM = {
  id: 'id',
  name: 'name',
  description: 'description',
  requiredMajor: 'required_major',
  duration: 'duration',
  price: 'price',
  schedulerTypes: 'scheduler_types',
  schedulerTypeIds: 'scheduler_type_ids',
  requiredMajorId: 'required_major_id',
};
