import moment from 'moment-timezone';
import { curry } from 'ramda';

const vanillaDateToMomentWithTimezone = curry((timezone, vanillaDate) => {
  const momentDate = moment().tz(timezone);
  momentDate.year(vanillaDate.getFullYear());
  momentDate.month(vanillaDate.getMonth());
  momentDate.date(vanillaDate.getDate());
  momentDate.hour(vanillaDate.getHours());
  momentDate.minute(vanillaDate.getMinutes());
  momentDate.second(vanillaDate.getSeconds());
  return momentDate;
});

export default vanillaDateToMomentWithTimezone;
