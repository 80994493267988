import { get } from 'lodash';
import { createWorkload, updateWorkload, deleteWorkload } from './service';
import { NAMESPACE } from './constants';

const defaultState = {
  name: '',
  description: '',
};

const model = {
  namespace: NAMESPACE,
  state: {
    initData: defaultState,
    isSuccess: false,
  },
  effects: {
    *create({ payload }, { call, put }) {
      const { workload, callback, userTimezone } = payload;
      const response = yield call(createWorkload, workload, userTimezone);
      if (response) {
        yield put({ type: 'save' });
        callback();
      }
    },
    *update({ payload }, { call, put }) {
      const { id, workload, callback, userTimezone } = payload;
      const response = yield call(updateWorkload, id, workload, userTimezone);
      if (response) {
        yield put({ type: 'save' });
        callback();
      }
    },
    *delete({ payload }, { call, put }) {
      const { id, callback } = payload;
      const response = yield call(deleteWorkload, id);
      if (response) {
        yield put({ type: 'save' });
        callback();
      }
    },
  },

  reducers: {
    save(state) {
      return {
        ...state,
        isSuccess: true,
      };
    },
    clear(state) {
      return {
        ...state,
        isSuccess: false,
      };
    },
  },
};

export default model;
