import { curry } from 'ramda';

export default curry(function getTimeParamsFromBasicFilter(format, unit, convertToUTC, { time }) {
  const start = time[0]
    .clone()
    .startOf(unit)
  const end = time[1]
    .clone()
    .endOf(unit)
  if (convertToUTC) {
    start.utc();
    end.utc();
  }
  return { start: start.format(format), end: end.format(format) };
});
