import { defineMessages } from 'umi/locale';
import globalMessages from '@/messages';
import serviceMessages from '@/messages/service';
import formMessages from '@/messages/form';
import { NAMESPACE } from './constants';

const messages = defineMessages({
  duration: {
    id: `${NAMESPACE}.duration`,
    defaultMessage: 'Duration (minutes)',
  },
  durationPlaceholder: {
    id: `${NAMESPACE}.durationPlaceholder`,
    defaultMessage: 'Duration in minutes',
  },
  durationError: {
    id: `${NAMESPACE}.durationError`,
    defaultMessage: 'Duration is required',
  },
  schedulerType: {
    id: `${NAMESPACE}.schedulerType`,
    defaultMessage: 'Scheduler Types',
  },
  schedulerTypePlaceholder: {
    id: `${NAMESPACE}.schedulerTypePlaceholder`,
    defaultMessage: 'Select scheduler types',
  },
  schedulerTypeError: {
    id: `${NAMESPACE}.schedulerTypeError`,
    defaultMessage: 'Scheduler types is required',
  },
  serviceCreated: {
    id: `${NAMESPACE}.serviceCreated`,
    defaultMessage: 'Service created successfully!',
  },
  serviceUpdated: {
    id: `${NAMESPACE}.serviceUpdated`,
    defaultMessage: 'Service updated successfully!',
  },
});

export default {
  ...formMessages,
  ...serviceMessages,
  ...messages,
  update: globalMessages.common.update,
  saveAndNew: globalMessages.common.saveAndNew,
  save: globalMessages.common.save,
  cancel: globalMessages.common.cancel,
};
