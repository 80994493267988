import { curry } from 'ramda';

const renderMapBased = curry(function renderMapBased(fallback, map, value) {
  if (!Object.prototype.hasOwnProperty.call(map, value)) {
    return fallback;
  }
  return map[value];
});

export default renderMapBased;
