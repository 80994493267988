import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import { isEqual } from 'lodash';

import { Upload, Icon, message } from '../UIElements';
import Styles from './index.less';

const { Dragger } = Upload;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJPG = file.type === 'image/jpeg';
  if (!isJPG) {
    message.error('You can only upload JPG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJPG && isLt2M;
}

export default class UploadFile extends PureComponent {
  static propTypes = {
    image: propTypes.string,
    defaultIcon: propTypes.string,
    iconType: propTypes.string,
    iconSize: propTypes.number,
    shouldClear: propTypes.bool,
    handleUploadFile: propTypes.func,
  };

  static defaultProps = {
    image: '',
    defaultIcon: 'add',
    iconType: 'material',
    iconSize: 20,
    shouldClear: false,
    handleUploadFile: () => {},
  };

  constructor(props) {
    super(props);
    const { shouldClear } = this.props;
    this.state = {
      loading: false,
      props: {
        shouldClear,
      },
    };
  }

  static getDerivedStateFromProps(nextProps, nextState) {
    const { shouldClear } = nextProps;
    if (shouldClear && !isEqual(shouldClear, nextState.props.shouldClear)) {
      return { imageUrl: '', props: { shouldClear } };
    }
    return null;
  }

  handleChange = file => {
    const { handleUploadFile } = this.props;
    handleUploadFile(file);
    if (file) {
      getBase64(file, imageUrl =>
        this.setState({
          imageUrl,
          loading: false,
        })
      );
    }
  };

  upload = file => new Promise(() => this.handleChange(file), () => {});

  clearImage = () => this.setState({ imageUrl: '' });

  handleImageError = () => {
    const { fallbackImage } = this.props;
    this.setState({
      imageUrl: fallbackImage,
    });
  };

  render() {
    const { defaultIcon, iconType, iconSize, image, fileName, text } = this.props;
    const { imageUrl, loading } = this.state;
    let uploadButton = loading ? (
      <Icon type="loading" />
    ) : (
      <i className={`material-icons ${Styles.icon}`}>{defaultIcon}</i>
    );
    if (iconType === 'ant')
      uploadButton = <Icon type={defaultIcon} style={{ fontSize: `${iconSize}px` }} />;
    return (
      <div className={Styles.signature}>
        <Dragger
          name="avatar"
          listType="picture-card"
          accept="image/jpeg"
          showUploadList={false}
          beforeUpload={beforeUpload}
          action={this.upload}
        >
          <p className="ant-upload-drag-icon">
            <Icon type="inbox" />
          </p>
          <p className="ant-upload-text">{text}</p>
        </Dragger>
        {/* {fileName ? (
          <div className="file">Attach file: {fileName}</div>
        ) : (
          <img className={Styles.img} src={image} alt="" onError={this.handleImageError} />
        )} */}
      </div>
    );
  }
}
