import moment from 'moment';
import request from '@/utils/request';
import { FORM, TYPE } from './constants';
import { trimString } from '@/utils/utils';
import { SERVER_DATE_FORMAT } from '@/constants';

export function mapToPostData(object) {
  const data = {};
  Object.keys(object).forEach(key => {
    const value = trimString(object[key]);
    if (key === FORM.dob) {
      data[key] = moment(value).format(SERVER_DATE_FORMAT);
    } else {
      data[key] = value;
    }
  });

  return data;
}

export async function createUser(user, avatar) {
  const postData = mapToPostData({ ...user, avatar });
  return request('/staffs', {
    method: 'POST',
    body: postData,
  });
}
// BE does not support update now but we build it fisrt, because of it will be the same with others.
export async function updateUser(id, user, newAvatar) {
  const putData = mapToPostData({ ...user, avatar: newAvatar });
  return request(`/staffs/${id}`, {
    method: 'PUT',
    body: putData,
  });
}

export function mapToPutData(object) {
  const data = {};
  Object.keys(object).forEach(key => {
    const value = trimString(object[key]);
    if (key === FORM.socialNo) {
      data[key] = value;
    }
    if (key === 'signature') {
      data[FORM.signatureAgreement] = value;
    }
    if (key === FORM.ratePerHour) {
      data[key] = value;
    }
    if (key === FORM.overtimeRatePerHour) {
      data[key] = value;
    }
    if (key === FORM.type) {
      let vl = value;
      if (value === TYPE.partTime) {
        vl = TYPE.partTimeSnakeCase;
      }
      data[key] = vl;
    }
  });

  return data;
}

export async function updateAdditionalProps(id, user, newSignature) {
  const putData = mapToPutData({ ...user, signature: newSignature });
  return request(`/staffs/${id}/additional-properties`, {
    method: 'PUT',
    body: putData,
  });
}

export function mapToFormData(object = {}) {
  const data = {};

  Object.keys(FORM).forEach(key => {
    const formKey = FORM[key];
    const value = trimString(object[key] || object[formKey]);
    if (key === FORM.dob) {
      data[formKey] = value ? moment(value, SERVER_DATE_FORMAT) : undefined;
    } else if (key === FORM.type) {
      let vl = value;
      if (value === TYPE.partTimeSnakeCase) {
        vl = TYPE.partTime;
      }
      data[formKey] = vl;
    } else {
      data[formKey] = value;
    }
  });
  return data;
}
