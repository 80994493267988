import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'dva';
import { updateObject } from '@/utils/utils';
import { SUBMIT_BUTTON } from '@/constants';
import { get, isEqual } from 'lodash';
import SchedulerTypeModalView from './view';
import { SCHEDULER_TYPE_FORM, NAMESPACE } from './constants';

@connect(state => ({
  initData: state[NAMESPACE].initData,
  isSuccess: state[NAMESPACE].isSuccess,
  loading:
    state.loading.effects[`${NAMESPACE}/create`] || state.loading.effects[`${NAMESPACE}/update`],
  initDataLoading: state.loading.effects[`${NAMESPACE}/initData`],
}))
class SchedulerTypeModalContainer extends PureComponent {
  static propTypes = {
    initData: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    isSuccess: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
    initDataLoading: PropTypes.bool,
  };

  static defaultProps = {
    loading: false,
    initDataLoading: false,
  };

  state = {
    submitButton: '',
    disableButtonUpdate: true,
    schedulerType: {},
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { isEdit, schedulerType } = nextProps;
    if (isEdit && get(schedulerType, 'id') !== get(prevState, 'schedulerType.id')) {
      return {
        schedulerType,
        disableButtonUpdate: true,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    const { isOpen, isSuccess, schedulerType } = this.props;
    const { submitButton } = this.state;

    if (isOpen && !prevProps.isOpen) {
      this.fetchInputData(schedulerType);
    }

    if (isOpen && isSuccess && isSuccess !== prevProps.isSuccess) {
      if (submitButton === SUBMIT_BUTTON.saveAndNew) {
        this.handleClearData();
        this.fetchInputData(schedulerType);
      } else {
        this.handleCancel();
      }
    }
  }

  fetchInputData = schedulerType => {
    const { dispatch } = this.props;
    dispatch({
      type: `${NAMESPACE}/initData`,
      schedulerType,
    });
  };

  handleClearData = () => {
    const { dispatch } = this.props;
    this.setState(
      {
        schedulerType: {},
        disableButtonUpdate: true,
      },
      () => dispatch({ type: `${NAMESPACE}/clear` })
    );
  };

  handleCancel = () => {
    const { handleCancel } = this.props;
    handleCancel();
    this.handleClearData();
  };

  handleSave = form => {
    const { dispatch, callback } = this.props;

    form.validateFieldsAndScroll((err, values) => {
      if (err) return;
      this.setState({ submitButton: SUBMIT_BUTTON.save }, () => {
        dispatch({
          type: `${NAMESPACE}/create`,
          payload: { schedulerType: values, callback },
        });
      });
    });
  };

  handleSaveAndNew = form => {
    const { dispatch, callback } = this.props;

    form.validateFieldsAndScroll((err, values) => {
      if (err) return;
      this.setState({ submitButton: SUBMIT_BUTTON.saveAndNew }, () => {
        dispatch({
          type: `${NAMESPACE}/create`,
          payload: { schedulerType: values, callback },
        });
      });
    });
  };

  handleUpdate = form => {
    const { dispatch, callback } = this.props;

    form.validateFieldsAndScroll((err, values) => {
      if (err) return;
      const {
        schedulerType: { id },
      } = this.props;
      this.setState({ submitButton: SUBMIT_BUTTON.update }, () => {
        dispatch({
          type: `${NAMESPACE}/update`,
          payload: { id, schedulerType: { id, ...values }, callback },
        });
      });
    });
  };

  handleValuesChange = values => {
    const {
      initData: {
        initAttributes: { phone, email },
      },
    } = this.props;
    const { schedulerType } = this.state;
    const initialValues = {
      [SCHEDULER_TYPE_FORM.phones]: [{ type: phone[0] }],
      [SCHEDULER_TYPE_FORM.emails]: [{ type: email[0] }],
    };
    const updateSchedulerTypeInfo = updateObject(schedulerType, values);
    const schedulerTypeInfo = Object.assign({}, initialValues, updateSchedulerTypeInfo);
    const phones = get(values, [SCHEDULER_TYPE_FORM.phones]);
    const emails = get(values, [SCHEDULER_TYPE_FORM.emails]);
    if (
      (phones && isEqual(phones, initialValues[SCHEDULER_TYPE_FORM.phones])) ||
      (emails && isEqual(emails, initialValues[SCHEDULER_TYPE_FORM.emails]))
    ) {
      this.setState({ schedulerType: schedulerTypeInfo });
    } else {
      this.setState({ schedulerType: schedulerTypeInfo, disableButtonUpdate: false });
    }
  };

  handleAvatarChange = file => {
    const values = { avatar: file };
    this.handleValuesChange(values);
  };

  render() {
    const { initData, isOpen, isEdit, loading, initDataLoading } = this.props;
    const { schedulerType, disableButtonUpdate } = this.state;
    const { submitButton } = this.state;
    return (
      <SchedulerTypeModalView
        schedulerType={schedulerType}
        disableButtonUpdate={disableButtonUpdate}
        initData={initData}
        isOpen={isOpen}
        isEdit={isEdit}
        loading={loading}
        initDataLoading={initDataLoading}
        handleValuesChange={this.handleValuesChange}
        submitButton={submitButton}
        handleSave={this.handleSave}
        handleSaveAndNew={this.handleSaveAndNew}
        handleUpdate={this.handleUpdate}
        handleCancel={this.handleCancel}
        handleUploadAvatar={this.handleAvatarChange}
      />
    );
  }
}

export default SchedulerTypeModalContainer;

export { default as model } from './model';
export { NAMESPACE } from './constants';
