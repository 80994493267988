import { get } from 'lodash';
import request from '@/utils/request';
import { trimString } from '@/utils/utils';
import { MAJOR_FORM } from './constants';

export function mapToPostData(object) {
  const data = {};
  Object.keys(object).forEach(key => {
    const value = trimString(object[key]);
    data[key] = value;
  });
  return data;
}

export async function createMajor(major) {
  const postData = mapToPostData(major);
  return request('/majors', {
    method: 'POST',
    body: postData,
  });
}

export async function updateMajor(id, major) {
  const putData = mapToPostData(major);
  return request(`/majors/${id}`, {
    method: 'PUT',
    body: putData,
  });
}

export function mapToFormData(major = {}) {
  const currentMajor = {};
  Object.keys(MAJOR_FORM).forEach(key => {
    const property = MAJOR_FORM[key];
    if (key === MAJOR_FORM.phones && major[key]) {
      major[key].forEach((phone, index) => {
        currentMajor[`${key}[${index}].type`] = get(phone, 'type');
        currentMajor[`${key}[${index}].value`] = get(phone, 'value');
      });
    } else if (key === MAJOR_FORM.emails && major[key]) {
      major[key].forEach((email, index) => {
        currentMajor[`${key}[${index}].type`] = get(email, 'type');
        currentMajor[`${key}[${index}].value`] = get(email, 'value');
      });
    } else {
      currentMajor[property] = get(major, property);
    }
  });
  return currentMajor;
}
