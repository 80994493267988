import { compose } from 'ramda';
import removeEmptyFields from './removeEmptyFields';
import removeNullFields from './removeNullFields';

export const removeNullAndEmptyFields = compose(
  removeEmptyFields,
  removeNullFields
);
export { removeEmptyFields, removeNullFields };
export { default as isNullOrEmpty } from './isNullOrEmpty';
export { default as buildQueryString } from './buildQueryString';
export { default as snakeCaseToCamelCase } from './snakeCaseToCamelCase';
export { default as isPlainObject } from './isPlainObject';
export { default as toCamelCaseObject } from './toCamelCaseObject';
export { default as fromMap } from './fromMap';
export { default as renderTextColumn } from './renderTextColumn';
export { default as getSort } from './getSort';
export { default as createReducer } from './createReducer';
export { default as camelCaseToSnakeCase } from './camelCaseToSnakeCase';
export { default as toSnakeCaseObject } from './toSnakeCaseObject';
export { default as includes } from './includes';
export { default as noop } from './noop';
export { default as normalizeDateTime } from './normalizeDateTime';
export { default as formatDateTime } from './formatDateTime';
export { default as getCurrentTime } from './getCurrentTime';
export { default as momentToDate } from './momentToDate';
export { default as vanillaDateToMomentWithTimezone } from './vanillaDateToMomentWithTimezone';
export { default as actions } from './actions';
export { default as statuses } from './statuses';
export { default as extractAxiosError } from './extractAxiosError';
export { default as dummyRequest } from './dummyRequest';
export { default as getBase64 } from './getBase64';
export { default as getParams } from './getParams';
export { default as renderDateColumn } from './renderDateColumn';
export { default as minutesAsString } from './minutesAsString';
export { default as getBasicFilterValues } from './getBasicFilterValues';
export { default as getTimeParamsFromBasicFilter } from './getTimeParamsFromBasicFilter';
export { default as confirm } from './confirm';
export { default as renderMapBased } from './renderMapBased';
