import { getAppointmentStatuses } from './service';
import { NAMESPACE } from './constants';

const defaultState = {
  statuses: [],
};

const model = {
  namespace: NAMESPACE,
  state: { ...defaultState },
  effects: {
    *fetch({ search, service }, { call, put }) {
      const response = yield call(getAppointmentStatuses, search, service);
      if (response) {
        yield put({
          type: 'save',
          payload: {
            statuses: response,
          },
        });
      }
    },
  },
  reducers: {
    save(
      state,
      {
        payload: { statuses },
      }
    ) {
      return {
        ...state,
        statuses,
      };
    },
  },
};

export default model;
