import { routerRedux } from 'dva/router';
import { get } from 'lodash';
import { reloadAuthorized, setAuthority, setCurrentUser, setAccessToken, flattenRole } from '@/utils/auth';
import { sortTimezones } from '@/utils/utils';
import { queryNotices, getCurrentUser, getTimezones, getClinicTimezone } from '@/services/global';
import { setClinicTimezone } from '@/utils/locale';

const model = {
  namespace: 'global',

  state: {
    currentUser: {},
    timezones: [],
    clinicTimezone: 'UTC',
    permission: {},
    collapsed: true,
    notices: [],
    notifyCount: 0,
  },

  effects: {
    *fetchCurrentUser({ payload }, { call, put }) {

      const response = yield call(getCurrentUser);
      if (response) {
        const currentUser = response;
        const roles = get(currentUser, 'roles', []);

        const authority = roles.map(flattenRole);
        setAuthority(authority);
        setCurrentUser(currentUser);
        reloadAuthorized();
        if (payload) {
          const { callback } = payload;
          callback();
        }
        yield put({
          type: 'saveCurrentUser',
          payload: {
            currentUser,
          },
        });
      } else {
        put(routerRedux.push('/user/login'));
      }
    },
    *fetchClinicTimezone(_, { call, put }) {
      const response = yield call(getClinicTimezone);
      if (response) {
        const zone = get(response, 'data.items.0', 'UTC');
        setClinicTimezone(zone);
        yield put({
          type: 'saveClinicTimezone',
          zone,
        });
      }
    },
    *fetchTimezones(_, { call, put }) {
      const response = yield call(getTimezones);
      if (response) {
        const timezones = get(response, 'data.items', []);
        yield put({
          type: 'saveTimezones',
          payload: {
            timezones: sortTimezones(timezones),
          },
        });
      } else {
        put(routerRedux.push('/user/login'));
      }
    },
    *logout({ redirect }, { put, select }) {
      try {
        // get location pathname
        const urlParams = new URL(window.location.href);
        const pathname = yield select(state => state.routing.location.pathname);
        // add the parameters in the url
        urlParams.searchParams.set('redirect', pathname);
        window.history.replaceState(null, 'login', urlParams.href);
      } finally {
        setAuthority('');
        reloadAuthorized();
        setCurrentUser('');
        setAccessToken('');
        yield put({
          type: 'clearCurrentUser',
        });
        if (redirect) {
          yield put(routerRedux.push(`/user/login?redirect=${redirect}`));
        } else {
          yield put(routerRedux.push('/user/login'));
        }
      }
    },
    *fetchNotices(_, { call, put }) {
      const data = yield call(queryNotices);
      yield put({
        type: 'saveNotices',
        payload: data,
      });
      yield put({
        type: 'changeNotifyCount',
        payload: data.length,
      });
    },
    *clearNotices({ payload }, { put, select }) {
      yield put({
        type: 'saveClearedNotices',
        payload,
      });
      const count = yield select(state => state.global.notices.length);
      yield put({
        type: 'changeNotifyCount',
        payload: count,
      });
    },
  },

  reducers: {
    clearCurrentUser: (state) => ({
      ...state,
      currentUser: {},
    }),
    saveCurrentUser(
      state,
      {
        payload: { currentUser, permission },
      }
    ) {
      return {
        ...state,
        currentUser,
        permission,
      };
    },
    saveTimezones(
      state,
      {
        payload: { timezones },
      }
    ) {
      return {
        ...state,
        timezones,
      };
    },
    saveClinicTimezone(state, { zone }) {
      return {
        ...state,
        clinicTimezone: zone,
      };
    },
    changeNotifyCount(state, action) {
      return {
        ...state,
        notifyCount: action.payload,
      };
    },
    changeLayoutCollapsed(state, { payload }) {
      return {
        ...state,
        collapsed: payload,
      };
    },
    saveNotices(state, { payload }) {
      return {
        ...state,
        notices: payload,
      };
    },
    saveClearedNotices(state, { payload }) {
      return {
        ...state,
        notices: state.notices.filter(item => item.type !== payload),
      };
    },
  },

  subscriptions: {
    setup({ history }) {
      // Subscribe history(url) change, trigger `load` action if pathname is `/`
      return history.listen(({ pathname, search }) => {
        if (typeof window.ga !== 'undefined') {
          window.ga('send', 'pageview', pathname + search);
        }
      });
    },
  },
};

export default model;
