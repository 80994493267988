import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'dva';
import { isEmpty } from 'lodash';

import { SORT_ORDER, DEFAULT_PAGE_NUM, PAGE_SIZE } from '@/constants';

import NotificationView from './view';
import { NAMESPACE } from './constants';
import { getTotalUnreadNotification } from './service';

@connect(state => ({
  notifications: state[NAMESPACE].notifications,
  totalItem: state[NAMESPACE].totalItem,
  loading: state.loading.effects[`${NAMESPACE}/fetch`],
}))
class NotificationContainer extends PureComponent {
  static propTypes = {
    notifications: PropTypes.array.isRequired,
    // totalItem: PropTypes.int.isRequired,
    dispatch: PropTypes.func.isRequired,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    loading: false,
  };

  pagination = {
    current: DEFAULT_PAGE_NUM,
    pageSize: PAGE_SIZE,
  };

  sort = {
    field: 'id',
    order: SORT_ORDER.descend,
  };

  componentDidMount() {
    this.handleReloadNotification();
  }

  handleReloadNotification = (refreshing = false) => {
    const {
      props: { dispatch },
      pagination,
      sort,
    } = this;
    dispatch({
      type: `${NAMESPACE}/fetch`,
      payload: {
        pagination,
        sort,
        refreshing
      },
    });
  };

  handleItemClick = (item) => {
    const { id } = item;
    const { dispatch } = this.props;
    const callback = () => this.handleReloadNotification(true);
    dispatch({
      type: `${NAMESPACE}/markViewed`,
      payload: { id, callback }
    });
  };

  handleNoticeVisibleChange = visible => {
    const { notifications } = this.props;
    if (visible && isEmpty(notifications)) {
      this.handleReloadNotification();
    }
  };

  clearNewNotifications = () => {
    const { dispatch } = this.props;
    dispatch({
      type: `${NAMESPACE}/clearNewNotifications`,
    });
  };

  handleLoadMore = () => {
    const clonePagination = this.pagination;
    clonePagination.current += 1;
    this.pagination = clonePagination;
    this.handleReloadNotification();
  }

  render() {
    const { notifications, loading, totalItem } = this.props;
    const unreadNotifications = getTotalUnreadNotification(notifications);

    return (
      <NotificationView
        notifications={notifications}
        totalItem={totalItem}
        unreadNotifications={unreadNotifications}
        onNoticeVisibleChange={this.handleNoticeVisibleChange}
        clearNewNotifications={this.clearNewNotifications}
        loading={loading}
        onItemClick={this.handleItemClick}
        onLoadMore={this.handleLoadMore}
      />
    );
  }
}

export default NotificationContainer;

export { default as model } from './model';
export { NAMESPACE } from './constants';
