// eslint-disable-next-line import/prefer-default-export
export async function getAppointmentStatuses() {
  return [
    {
      id: 0,
      name: 'All',
      value: 'ALL',
    },
    {
      id: 1,
      name: 'New',
      value: 'NEW',
    },
    {
      id: 2,
      name: 'Accepted',
      value: 'ACCEPTED',
    },
    {
      id: 3,
      name: 'Completed',
      value: 'COMPLETED',
    },
    {
      id: 4,
      name: 'Cancelled',
      value: 'CANCELED',
    },
  ];
}
