import request from '@/utils/request';
import { SERVICE_FORM } from './constants';
import { trimString } from '@/utils/utils';

export function mapToPostData(object) {
  const data = {};
  Object.keys(object).forEach(key => {
    const value = trimString(object[key]);

    if (key === SERVICE_FORM.schedulerTypes) {
      data[SERVICE_FORM.schedulerTypeIds] = value.map(v => v.key);
    } else if (key === SERVICE_FORM.requiredMajor) {
      data[SERVICE_FORM.requiredMajorId] = value.key;
    } else {
      data[key] = value;
    }
  });
  return data;
}

export async function createService(service) {
  const postData = mapToPostData(service);
  // TODO: hard code
  postData.price = 150;
  return request('/treatments', {
    method: 'POST',
    body: postData,
  });
}

export async function updateService(id, service) {
  const putData = mapToPostData(service);
  return request(`/treatments/${id}`, {
    method: 'PUT',
    body: putData,
  });
}

export function mapToFormData(service = {}) {
  const currentService = {};
  Object.keys(SERVICE_FORM).forEach(key => {
    const property = SERVICE_FORM[key];
    const value = service[key] || service[property];

    if (property === SERVICE_FORM.schedulerTypes) {
      let schedulerType;
      if (value && value.length > 0) {
        schedulerType = value.map(v => ({ key: v.id, label: v.name, ...v }));
      }
      currentService[property] = schedulerType;
    } else if (property === SERVICE_FORM.requiredMajor) {
      currentService[property] = value ? { key: value.id, label: value.name, ...value } : undefined;
    } else {
      currentService[property] = value;
    }
  });
  return currentService;
}
