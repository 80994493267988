import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import { formatMessage } from 'umi/locale';
import classNames from 'classnames';

import { Select, Spin, Input, Button } from '@/componentsX/UIElements';

import messages from './messages';
import Styles from './styles.less';

const { Option, LabeledValue } = Select;
const InputGroup = Input.Group;

class StaffFilterView extends PureComponent {
  static propTypes = {
    staffs: propTypes.array.isRequired,
    selectedStaff: propTypes.oneOfType([
      propTypes.string,
      propTypes.arrayOf(propTypes.string),
      propTypes.shape(LabeledValue),
      propTypes.arrayOf(propTypes.shape(LabeledValue)),
    ]),
    allowCreateInline: propTypes.bool.isRequired,
    loading: propTypes.bool.isRequired,
    size: propTypes.string.isRequired,
    onSearch: propTypes.func.isRequired,
    onChange: propTypes.func.isRequired,
    onCreate: propTypes.func.isRequired,
  };

  static defaultProps = {
    selectedStaff: undefined,
  };

  handleCreate = () => {
    const { onCreate } = this.props;
    onCreate();
  };

  render() {
    const {
      allowCreateInline,
      staffs,
      loading,
      selectedStaff,
      onSearch,
      onChange,
      className,
      size,
      mode,
      title,
      ...restProps
    } = this.props;

    return (
      <InputGroup style={{ width: '100%' }}>
        <Select
          {...restProps}
          size={size}
          className={className || Styles.staffFilter}
          mode={mode || 'default'}
          allowClear
          showSearch
          labelInValue
          value={selectedStaff}
          placeholder={title || formatMessage(messages.placeholder)}
          notFoundContent={loading ? <Spin size="small" /> : null}
          filterOption={false}
          onSearch={onSearch}
          onChange={onChange}
          style={{ width: '100%' }}
        >
          {staffs.map(d => (
            <Option key={d.id}>{`${d.firstName} ${d.lastName}`}</Option>
          ))}
        </Select>
      </InputGroup>
    );
  }
}

export default StaffFilterView;
