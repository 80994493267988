import { defineMessages } from 'umi/locale';
import PATH from './constants';

export default defineMessages({
  newSchedulerType: {
    id: `${PATH}.schedulerType.newSchedulerType`,
    defaultMessage: 'New SchedulerType',
  },
  updateSchedulerType: {
    id: `${PATH}.schedulerType.updateSchedulerType`,
    defaultMessage: 'Update Scheduler Type',
  },
  deleteSchedulerTypeTitle: {
    id: `${PATH}.schedulerType.deleteSchedulerTypeTitle`,
    defaultMessage: 'Delete Scheduler Type',
  },
  deleteSchedulerTypeContent: {
    id: `${PATH}.schedulerType.deleteSchedulerTypeContent`,
    defaultMessage: 'Are you sure you want to delete this scheduler type ?',
  },
});
