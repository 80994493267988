export const NAMESPACE = 'share.patientModal';

export const PATIENT_FORM = {
  id: 'id',
  code: 'code',
  avatar: 'avatar',
  firstName: 'first_name',
  lastName: 'last_name',
  gender: 'gender',
  dob: 'dob',
  phoneNum: 'phone_num',
  email: 'email',
  address: 'address',
  defaultDentist: 'default_dentist',
  defaultDentistId: 'default_dentist_id',
};
