export default {
  'global.messages.form.avatarRequired': 'Avatar is required',
  'global.messages.form.name': 'Name',
  'global.messages.form.namePlaceholder': 'Add name',
  'global.messages.form.nameRequired': 'Name is required',
  'global.messages.form.nameWhitespace': 'This field must not contain whitespace',
  'global.messages.form.firstName': 'First Name',
  'global.messages.form.firstNamePlaceholder': 'First Name',
  'global.messages.form.firstNameError': 'Please input First Name',
  'global.messages.form.lastName': 'Last Name',
  'global.messages.form.lastNamePlaceholder': 'Last Name',
  'global.messages.form.lastNameError': 'Please input Last Name',
  'global.messages.form.email': 'E-mail',
  'global.messages.form.emailPlaceholder': 'Email',
  'global.messages.form.emailEmptyError': 'Please input your E-mail',
  'global.messages.form.emailInvalidError': 'The input is not valid E-mail',
  'global.messages.form.password': 'Password',
  'global.messages.form.passwordPlaceholder': 'Password',
  'global.messages.form.passwordError': 'Password is required',
  'global.messages.form.phone': 'Phone',
  'global.messages.form.phoneRequiredError': 'Phone number is required',
  'global.messages.form.invalidPhoneNumber': 'Invalid phone number',
  'global.messages.form.address': 'Address',
  'global.messages.form.addressPlaceholder': 'Address',
  'global.messages.form.street': 'street',
  'global.messages.form.unitNumber': 'unitNumber',
  'global.messages.form.city': 'city',
  'global.messages.form.state': 'state',
  'global.messages.form.zipCode': 'zipCode',
  'global.messages.form.country': 'country',
  'global.messages.form.gender': 'Gender',
  'global.messages.form.selectGenderPlaceholder': 'Select gender',
  'global.messages.form.genderRequired': 'Gender is required',
  'global.messages.form.dob': 'D.O.B',
  'global.messages.form.dobPlaceholder': 'D.O.B',
  'global.messages.form.dobError': 'D.O.B is required',
  'global.messages.form.description': 'Description',
  'global.messages.form.descriptionPlaceholder': 'Description',
  'global.messages.form.descriptionError': 'Description is required',
  'global.messages.form.major': 'Major',
  'global.messages.form.majorPlaceholder': 'Major',
  'global.messages.form.majorError': 'Major is required',
};
