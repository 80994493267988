import React, { PureComponent } from 'react';
import { connect } from 'dva';
import PropTypes from 'prop-types';
import moment from 'moment';

import { CALENDAR_VIEW } from '@/constants';
import { toTimezone } from '@/utils/locale';
import PageHeaderLayout from '@/layouts/PageHeaderLayout';
import { Button } from '@/componentsX/UIElements';
import AppointmentCalendar, {
  CalendarNavigator,
  colorizedAppointment,
} from '@/componentsX/_share/AppointmentCalendar';

import { NAMESPACE } from '../constants';
import Styles from './Step2.less';

@connect(state => ({
  appointment: state[NAMESPACE].appointment,
  appointments: state[NAMESPACE].appointments,
  dentistWorkingTimes: state[NAMESPACE].dentistWorkingTimes,
  loading:
    state.loading.effects[`${NAMESPACE}/fetchAppointments`] ||
    state.loading.effects[`${NAMESPACE}/fetchDentistWorkingTimes`],
}))
class Step2 extends PureComponent {
  static propTypes = {
    next: PropTypes.func.isRequired,
    prev: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      viewMode: CALENDAR_VIEW.week,
      currentDate: moment(props.appointment.start),
      start: moment(props.appointment.start),
      end: moment(props.appointment.end),
      draftAppointment: undefined,
    };
  }

  changeDate = date => {
    this.setState(
      {
        currentDate: date,
      },
      this.fetchAppointments
    );
  };

  changeViewMode = mode => {
    this.setState({
      viewMode: mode,
    });
  };

  handleSubmit = () => {
    const { next, dispatch } = this.props;
    const { start, end } = this.state;
    dispatch({
      type: `${NAMESPACE}/saveStepFormData`,
      payload: { start, end },
    });
    next();
  };

  handleBack = () => {
    const { prev } = this.props;
    prev();
  };

  handleSelectTimeSlot = (start, end) => {
    const { userTimezone, appointment } = this.props;
    const draftAppointment = {
      ...appointment,
      draft: false,
      start: toTimezone(start, userTimezone),
      end: toTimezone(end, userTimezone),
    };

    this.setState({
      draftAppointment: colorizedAppointment(draftAppointment),
      start,
      end,
      currentDate: start,
    });
  };

  renderRightAction = () => {
    return (
      <div>
        <Button onClick={this.handleBack}>Previous</Button>
        <Button type="primary" onClick={this.handleSubmit} className={Styles.leftElement}>
          Next
        </Button>
      </div>
    );
  };

  renderLeftAction = () => {
    const { viewMode, currentDate, maxScheduleDate } = this.state;
    return (
      <div className={Styles.leftAction}>
        <CalendarNavigator
          currentDate={currentDate}
          viewMode={viewMode}
          onDateChange={this.changeDate}
          onViewModeChange={this.changeViewMode}
          min={maxScheduleDate}
        />
      </div>
    );
  };

  render() {
    const { currentDate, viewMode, draftAppointment } = this.state;
    const { appointment } = this.props;
    const { dentist, patient, service, schedulerType } = appointment;
    const filter = {
      service,
      schedulerType,
      dentist,
      patient,
    };

    return (
      <PageHeaderLayout
        hideBreadcrumb
        action={this.renderRightAction()}
        leftAction={this.renderLeftAction()}
        contentClassName={Styles.content}
        iconContainerClassName={Styles.iconWrapper}
      >
        <div className={Styles.wrapper}>
          <div className={Styles.calendar}>
            <AppointmentCalendar
              isEdit
              allowEdit={false}
              currentDate={currentDate}
              viewMode={viewMode}
              filter={filter}
              draftAppointment={draftAppointment}
              onSelectTimeSlot={this.handleSelectTimeSlot}
            />
          </div>
        </div>
      </PageHeaderLayout>
    );
  }
}

export default Step2;
