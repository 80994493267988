import { get } from 'lodash';
import { getServices } from './service';
import { NAMESPACE } from './constants';

const defaultState = {
  services: [],
};

const model = {
  namespace: NAMESPACE,
  state: { ...defaultState },
  effects: {
    *fetch({ payload }, { call, put }) {
      const { search, majors } = payload;
      const response = yield call(getServices, search, majors);
      if (response) {
        yield put({
          type: 'save',
          payload: {
            services: get(response, 'data.items', []),
          },
        });
      }
    },
  },
  reducers: {
    save(
      state,
      {
        payload: { services },
      }
    ) {
      return {
        ...state,
        services,
      };
    },
  },
};

export default model;
