import { get } from 'lodash';
import { NAMESPACE } from './constants';
import {
  getRoleDetail,
  getUsers,
  createRole,
  updateRole,
  getFeatures,
  mapSelectedRolesToRole,
} from './service';

const defaultState = {
  role: {
    features: [],
  },
  inputData: {
    users: [],
    features: [],
  },
  selectedRoles: [],
  roleChangedIndex: 0,
  changedIndex: 0,
};

const model = {
  namespace: NAMESPACE,

  state: defaultState,

  effects: {
    *getRoleDetail({ roleId }, { call, put }) {
      const response = yield call(getRoleDetail, roleId);
      if (response) {
        yield put({
          type: 'saveRoleDetail',
          payload: {
            role: get(response, 'results.role', {}),
          },
        });
      }
    },
    *fetchInputData(_, { all, call, put }) {
      const [ usersResponse, featuresResponse ] = yield all([
        call(getUsers),
        call(getFeatures),
      ]);
      if (usersResponse || featuresResponse) {
        yield put({
          type: 'saveInitData',
          payload: {
            users: get(usersResponse, 'results.users', []),
            features: get(featuresResponse, 'results.features', []),
          },
        });
      }
    },
    *create({ payload: { role, selectedRoles } }, { call, put }) {
      const response = yield call(createRole, () => {
        let postData = { ...role };

        if (selectedRoles.length > 0) {
          postData = mapSelectedRolesToRole(postData, selectedRoles);
        }
        return postData
      });
      if (response) {
        yield put({ type: 'save' });
      }
    },
    *update({ payload: { role, selectedRoles } }, { call, put }) {
      const response = yield call(updateRole, () => {
        let postData = { ...role };

        if (selectedRoles.length > 0) {
          postData = mapSelectedRolesToRole(postData, selectedRoles);
        }
        return postData
      });
      if (response) {
        yield put({ type: 'save' });
      }
    },
  },

  reducers: {
    saveRoleDetail(state, { payload: { role } }) {
      return {
        ...state,
        role,
      }
    },
    saveInitData(state, { payload: { users, features } }) {
      const repairPayload = {
        users: users.map(user => ({ id: user.id, display: `${user.firstName} ${user.lastName}` })),
        features,
      };

      return {
        ...state,
        inputData: {
          ...state.inputData,
          ...repairPayload,
        },
      }
    },
    save(state) {
      return {
        ...state,
        changedIndex: state.changedIndex + 1,
      };
    },
    features(state, { selectedRoles }) {
      return {
        ...state,
        selectedRoles,
        roleChangedIndex: state.roleChangedIndex + 1,
      }
    },
    clear(state) {
      return {
        ...state,
        ...defaultState,
        roleChangedIndex: state.roleChangedIndex,
        changedIndex: state.changedIndex,
      }
    },
  },
};

export default model;
