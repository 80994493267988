import React from 'react';
import { curry } from 'ramda';
import Ellipsis from '@/componentsX/Ellipsis';
import isNullOrEmpty from './isNullOrEmpty';

export default curry((defaultValue, value) => {
  if (isNullOrEmpty(value)) {
    return defaultValue;
  }
  return (
    <Ellipsis lines={1} tooltip={value}>
      {value}
    </Ellipsis>
  );
});
