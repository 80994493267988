export default function minutesAsString(minutes) {
  const hourValue = Math.floor(minutes/ 60);
  const minuteValue = Math.round(minutes % 60);
  const parts = [];
  if (hourValue !== 0) {
    parts.push(`${hourValue} ${hourValue > 1 ? 'hours' : 'hour' }`);
  }
  if (minuteValue !== 0) {
    parts.push(`${minuteValue} ${minuteValue > 1 ? 'minutes' : 'minute' }`);
  }
  return parts.join(' ');
}
