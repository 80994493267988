import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Avatar as AntAvatar, Popover } from '@/componentsX/UIElements';
import { DEFAULT_AVATAR } from '@/constants';

export default class Avatar extends PureComponent {
  static propTypes = {
    src: PropTypes.string.isRequired,
    fallbackSrc: PropTypes.any,
    triggerType: PropTypes.string,
    shouldPopupImage: PropTypes.bool,
    popovercontent: PropTypes.any,
  };

  static defaultProps = {
    fallbackSrc: DEFAULT_AVATAR,
    triggerType: 'hover',
    shouldPopupImage: false,
    popovercontent: undefined,
  };

  static getDerivedStateFromProps(nextProps, nextState) {
    const { src } = nextProps;
    if (src !== nextState.src) {
      return {
        src,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    const { src } = props;
    this.state = {
      src,
    };
  }

  get popoverContent() {
    const { popovercontent } = this.props;
    const { src } = this.state;
    if (!popovercontent) {
      return <img alt="fullImage" src={src} style={{ maxWidth: '200px' }} />;
    }
    return popovercontent;
  }

  handleImageError = () => {
    const { fallbackSrc } = this.props;
    this.setState({
      src: fallbackSrc,
    });
    return false;
  };

  render() {
    const { src, fallbackSrc, shouldPopupImage, triggerType, ...rest } = this.props;
    const { src: srcState } = this.state;
    const trigger = shouldPopupImage ? triggerType : 'none';

    return (
      <Popover content={this.popoverContent} placement="bottom" trigger={trigger}>
        <AntAvatar {...rest} src={srcState} onError={this.handleImageError} />
      </Popover>
    );
  }
}
