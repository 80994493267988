import { defineMessages } from 'umi/locale';
import PATH from './constants';

export default defineMessages({
  createRole: {
    id: `${PATH}.createRole`,
    defaultMessage: 'New Role',
  },
  updateRole: {
    id: `${PATH}.updateRole`,
    defaultMessage: 'Update Role',
  },
});
