import isPlainObject from './isPlainObject';
import camelCaseToSnakeCase from './camelCaseToSnakeCase';

export default function toSnakeCaseObject(obj) {
  if (obj === null) {
    return obj;
  }
  if (!isPlainObject(obj)) {
    return obj;
  }
  return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];
    const snakeCased = camelCaseToSnakeCase(key);
    if (isPlainObject(value)) {
      return {
        ...acc,
        [snakeCased]: toSnakeCaseObject(value),
      };
    }
    if (Array.isArray(value)) {
      return {
        ...acc,
        [snakeCased]: value.map(toSnakeCaseObject),
      };
    }
    return {
      ...acc,
      [snakeCased]: value,
    };
  }, {});
}
