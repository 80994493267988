import { defineMessages } from 'umi/locale';

const path = 'share.notification';

export default defineMessages({
  emptyNotification: {
    id: `${path}.emptyNotification`,
    defaultMessage: 'You have viewed all notifications',
  },
  title: {
    id: `${path}.title`,
    defaultMessage: 'Notifications',
  },
  moreTitle: {
    id: `${path}.moreTitle`,
    defaultMessage: 'View more',
  },
});
