import get from 'lodash/get';
import request from '@/utils/request';
import { FREE_SERVICE_FORM } from './constants';

export function mapToPostData(object) {
  const { id, age, treatment, [FREE_SERVICE_FORM.schedulerType]: schedulerType } = object;
  return {
    id,
    name: 'name',
    min_age: age[0] || 0,
    max_age: age[1] || 0,
    condition_treatments: [
      {
        scheduler_type_id: schedulerType.id,
        treatment_id: treatment.id,
      },
    ],
  };
}

export async function createService(service) {
  const postData = mapToPostData(service);
  return request('/treatments/free-conditions', {
    method: 'POST',
    body: postData,
  });
}

export async function updateService(id, service) {
  const putData = mapToPostData(service);
  return request(`/treatments/free-conditions/${id}`, {
    method: 'PUT',
    body: putData,
  });
}

export function mapToFormData(service = {}) {
  const { conditionTreatments, ...rest } = service;
  const treatment = get(conditionTreatments, '[0]', {});
  const transformedService = {
    ...rest,
    ...treatment,
  };
  const currentService = {};

  Object.keys(FREE_SERVICE_FORM).forEach(key => {
    const property = FREE_SERVICE_FORM[key];
    const value = transformedService[key] || transformedService[property];

    if (property === FREE_SERVICE_FORM.schedulerType) {
      currentService[property] = value ? { key: value.id, label: value.name, ...value } : undefined;
    } else if (property === FREE_SERVICE_FORM.service) {
      currentService[property] = value ? { key: value.id, label: value.name, ...value } : undefined;
    } else {
      currentService[property] = value;
    }
  });

  currentService[FREE_SERVICE_FORM.age] = [service.minAge, service.maxAge];
  return currentService;
}
