import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { debounce, isPlainObject } from 'lodash';
import { connect } from 'dva';

import { Select } from '@/componentsX/UIElements';

import AppointmentStatusFilterView from './view';
import { NAMESPACE, DEBOUNCE_TIME } from './constants';

const { LabeledValue } = Select;

@connect(state => ({
  statuses: state[NAMESPACE].statuses,
  loading: state.loading.effects[`${NAMESPACE}/fetch`],
}))
class AppointmentStatusFilterContainer extends PureComponent {
  static propTypes = {
    statuses: PropTypes.array.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.shape(LabeledValue),
      PropTypes.arrayOf(PropTypes.shape(LabeledValue)),
    ]),
    mode: PropTypes.string,
    size: PropTypes.string,
    service: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    className: PropTypes.string,
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    loading: false,
    value: undefined,
    service: undefined,
    className: undefined,
    placeholder: undefined,
    size: 'default',
    mode: 'default',
  };

  // memoization here
  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.value) {
      return {
        selectedStatus: props.value,
        value: props.value,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.fetchAppointmentStatusDebounce = debounce(this.fetchAppointmentStatus, DEBOUNCE_TIME);
    this.state = {
      selectedStatus: props.value,
      value: props.value,
    };
  }

  componentDidMount() {
    this.fetchAppointmentStatus();
  }

  componentDidUpdate(prevProps) {
    const { service } = this.props;
    if (service !== prevProps.service) {
      this.fetchAppointmentStatus();
    }
  }

  fetchAppointmentStatus = search => {
    const { service, dispatch } = this.props;
    dispatch({
      type: `${NAMESPACE}/fetch`,
      search,
      service,
    });
  };

  handleValueChange = value => {
    const { onChange, statuses } = this.props;

    this.setState({
      selectedStatus: value,
    });

    let conductedValue;

    if (Array.isArray(value)) {
      conductedValue = value.map(v => {
        const values = statuses.find(p => p.id === Number(v.key));
        return {
          ...v,
          ...values,
        };
      });
    } else if (isPlainObject(value)) {
      const values = statuses.find(p => p.id === Number(value.key));

      conductedValue = {
        ...value,
        ...values,
      };
    }

    onChange(conductedValue);
  };

  render() {
    const { statuses, loading, className, placeholder, size, mode } = this.props;
    const { selectedStatus } = this.state;
    return (
      <AppointmentStatusFilterView
        mode={mode}
        size={size}
        statuses={statuses}
        selectedStatus={selectedStatus}
        loading={loading}
        onSearch={this.fetchAppointmentStatusDebounce}
        onChange={this.handleValueChange}
        className={className}
        placeholder={placeholder}
      />
    );
  }
}

export default AppointmentStatusFilterContainer;

export { default as model } from './model';
export { NAMESPACE } from './constants';
