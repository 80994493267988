import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'dva';
import { get } from 'lodash';
import { updateObject } from '@/utils/utils';
import { SUBMIT_BUTTON } from '@/constants';
import TemplateModalView from './view';
import { NAMESPACE, SMS_CHARS_LIMIT, TYPE } from './constants';
import { getEditorStateFromHTML, convertEditorStateToString, convertEditorStateToRaw } from './service';

@connect(state => ({
  isSuccess: state[NAMESPACE].isSuccess,
  loading: state.loading.effects[`${NAMESPACE}/create`] ||
    state.loading.effects[`${NAMESPACE}/update`],
  initDataLoading: state.loading.effects[`${NAMESPACE}/initData`],
  editor: state[NAMESPACE].editor,
}))
class ModalContainer extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    isSuccess: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
    initDataLoading: PropTypes.bool,
  };

  static defaultProps = {
    loading: false,
    initDataLoading: false,
  };

  state = {
    submitButton: '',
    disableButtonUpdate: true,
    template: {},
    editorState: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { isEdit, template } = nextProps;
    if (isEdit && get(template, 'event') !== get(prevState, 'template.event')) {
      const { html } = template;
      if (html) {
        const editorState = getEditorStateFromHTML(html);
        return {
          editorState,
          template,
          disableButtonUpdate: false,
        }
      }
      return {
        template,
        disableButtonUpdate: true,
      }
    }
    return null
  }

  componentDidMount() {
    const { template: { html } } = this.state;
    if (html) {
      const editorState = getEditorStateFromHTML(html);
      this.setState({ editorState });
    }
  }

  componentDidUpdate(prevProps) {
    const { isOpen, isSuccess, dispatch } = this.props;
    const { submitButton } = this.state;

    if (isOpen && !prevProps.isOpen) {
      dispatch({ type: `${NAMESPACE}/clear` });
    }

    if (isOpen && isSuccess) {
      if (submitButton === SUBMIT_BUTTON.saveAndNew) {
        this.handleClearData();
      } else {
        this.handleCancel();
      }
    }
  }

  handleClearData = () => {
    const { dispatch } = this.props;
    this.setState({
      template: {},
      disableButtonUpdate: true,
    });
    dispatch({ type: `${NAMESPACE}/clear` });
  };

  handleCancel = () => {
    const { handleCancel } = this.props;
    handleCancel();
    this.handleClearData()
  };

  handleSave = form => {
    const { dispatch, callback } = this.props;

    form.validateFieldsAndScroll((err, values) => {
      if (err) return;
      this.setState({ submitButton: SUBMIT_BUTTON.save }, () => {
        dispatch({
          type: `${NAMESPACE}/create`,
          payload: { template: values, callback },
        });
      });
    });
  };

  handleSaveAndNew = form => {
    const { dispatch, callback } = this.props;

    form.validateFieldsAndScroll((err, values) => {
      if (err) return;
      this.setState({ submitButton: SUBMIT_BUTTON.saveAndNew }, () => {
        dispatch({
          type: `${NAMESPACE}/create`,
          payload: { template: values, callback },
        });
      });
    });
  };

  handleUpdate = () => {
    const { dispatch, callback } = this.props;
    const { editorState, template } = this.state;
    const { id, type } = template;

    const body = type === TYPE.sms ? convertEditorStateToRaw(editorState) : convertEditorStateToString(editorState);
    dispatch({
      type: `${NAMESPACE}/update`,
      payload: { id, body, callback },
    });
  };

  handleEditorStateChange = (editorState) => {
    this.setState({ editorState });
  }

  // handleBeforeInput = () => {
  //   const { editorState } = this.state;
  //   const currentContent = editorState.getCurrentContent();
  //   const raw = convertToRaw(currentContent);
  //   const inputLength = draftToHtml(raw).length;
  //   if (inputLength >= SMS_CHARS_LIMIT) {
  //     return 'handled';
  //   }
  //   return '';
  // };

  handleValuesChange = (values) => {
    const { template } = this.state;
    const initialValues = {};
    const updateTemplateInfo = updateObject(template, values);
    const templateInfo = Object.assign({}, initialValues, updateTemplateInfo);
    this.setState({ template: templateInfo, disableButtonUpdate: false })
  };

  render() {
    const { initData, isOpen, isEdit, loading, initDataLoading } = this.props;
    const {
      template,
      disableButtonUpdate,
      editorState,
      submitButton
    } = this.state;

    return (
      <TemplateModalView
        template={template}
        disableButtonUpdate={disableButtonUpdate}
        initData={initData}
        isOpen={isOpen}
        isEdit={isEdit}
        loading={loading}
        initDataLoading={initDataLoading}
        handleValuesChange={this.handleValuesChange}
        submitButton={submitButton}
        handleSave={this.handleSave}
        handleSaveAndNew={this.handleSaveAndNew}
        handleUpdate={this.handleUpdate}
        handleCancel={this.handleCancel}
        editorState={editorState}
        onSave={this.handleSave}
        onChangeEditor={this.handleEditorStateChange}
      // onBeforeInput={this.handleBeforeInput}
      />
    );
  }
}

export default ModalContainer;

export { default as model } from './model';
export { NAMESPACE } from './constants';
