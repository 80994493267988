import { DEFAULT_DATE_FORMAT } from '@/constants';

export default function normalizeFirstDateEmployment(w4Form) {
  const { first_date_employment: firstDateEmployment, ...rest } = w4Form;
  return {
    ...rest,
    first_date_employment: firstDateEmployment
      ? firstDateEmployment.format(DEFAULT_DATE_FORMAT)
      : null,
  };
}
