import { defineMessages } from 'umi/locale';
import globalMessages from '@/messages';
import formMessages from '@/messages/form';
import userMessages from '@/messages/user';
import { NAMESPACE } from './constants';

const messages = defineMessages({
  userCreated: {
    id: `${NAMESPACE}.userCreated`,
    defaultMessage: 'User created successfully!',
  },
  userUpdated: {
    id: `${NAMESPACE}.userUpdated`,
    defaultMessage: 'User updated successfully!',
  },
  userInfo: {
    id: `${NAMESPACE}.userInfo`,
    defaultMessage: 'User Info',
  },
  workingHours: {
    id: `${NAMESPACE}.workingHours`,
    defaultMessage: 'Working Hours',
  },
  additionalProperties: {
    id: `${NAMESPACE}.additionalProperties`,
    defaultMessage: 'Additional Properties',
  },
  checkboxPassword: {
    id: `${NAMESPACE}.checkboxPassword`,
    defaultMessage: 'Generate Password Automatically',
  },
});

export default {
  ...formMessages,
  ...userMessages,
  ...messages,
  update: globalMessages.common.update,
  saveAndNew: globalMessages.common.saveAndNew,
  save: globalMessages.common.save,
  cancel: globalMessages.common.cancel,
};
