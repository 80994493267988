import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'dva';
import { getAccessToken } from '@/utils/auth';

const withCookies = WrappedComponent => {
  @connect()
  class CookieComponent extends PureComponent {
    static propTypes = {
      dispatch: PropTypes.func.isRequired,
    };

    componentDidMount() {
      document.cookie = `tk=${getAccessToken()}; path=/avatar;`;
      document.cookie = `tk=${getAccessToken()}; path=/upload;`;
      this.setWebsocketCookie();
    }

    setWebsocketCookie = () => {
      const { dispatch } = this.props;
      dispatch({ type: 'global/setWebsocketCookie' });
    };

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  CookieComponent.displayName = `withCookies(${WrappedComponent.displayName ||
    WrappedComponent.name})`;

  return CookieComponent;
};

export default withCookies;
