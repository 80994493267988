import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import { formatMessage } from 'umi/locale';
import { Select, Spin } from '@/componentsX/UIElements';
import messages from './messages';

const { Option, LabeledValue } = Select;

class SchedulerTypeFilterView extends PureComponent {
  static propTypes = {
    schedulerTypes: propTypes.array.isRequired,
    selectedSchedulerType: propTypes.oneOfType([
      propTypes.string,
      propTypes.arrayOf(propTypes.string),
      propTypes.shape(LabeledValue),
      propTypes.arrayOf(propTypes.shape(LabeledValue)),
    ]),
    loading: propTypes.bool.isRequired,
    className: propTypes.string,
    placeholder: propTypes.string,
    size: propTypes.string,
    allowHidden: propTypes.bool.isRequired,
    mode: propTypes.string,
    onSearch: propTypes.func.isRequired,
    onChange: propTypes.func.isRequired,
  };

  static defaultProps = {
    className: undefined,
    placeholder: undefined,
    size: 'default',
    mode: 'default',
    selectedSchedulerType: undefined,
  };

  renderOptions = () => {
    const { schedulerTypes, allowHidden } = this.props;

    const filteredSchedulerTypes = schedulerTypes.filter(({ hidden }) => !hidden || allowHidden);
    return filteredSchedulerTypes.map(({ id, name }) => <Option key={id}>{name}</Option>);
  };

  render() {
    const {
      loading,
      selectedSchedulerType,
      onSearch,
      onChange,
      className,
      placeholder,
      size,
      mode,
      ...restProps
    } = this.props;
    const placeholderComputed = placeholder || formatMessage(messages.placeholder);

    return (
      <Select
        {...restProps}
        mode={mode}
        size={size}
        style={{ width: '100%' }}
        className={className}
        labelInValue
        allowClear
        value={selectedSchedulerType}
        placeholder={placeholderComputed}
        notFoundContent={loading ? <Spin size="small" /> : null}
        filterOption={false}
        onSearch={onSearch}
        onChange={onChange}
      >
        {this.renderOptions()}
      </Select>
    );
  }
}

export default SchedulerTypeFilterView;
