import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { debounce, isPlainObject } from 'lodash';
import { connect } from 'dva';
import { Select } from '@/componentsX/UIElements';
import ServiceFilterView from './view';
import { NAMESPACE, DEBOUNCE_TIME } from './constants';

const { LabeledValue } = Select;

@connect(state => ({
  services: state[NAMESPACE].services,
  loading: state.loading.effects[`${NAMESPACE}/fetch`],
}))
class ServiceFilterContainer extends PureComponent {
  static propTypes = {
    services: PropTypes.array.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.shape(LabeledValue),
      PropTypes.arrayOf(PropTypes.shape(LabeledValue)),
    ]),
    majors: PropTypes.array,
    loading: PropTypes.bool,
    size: PropTypes.string,
    mode: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    loading: false,
    size: 'default',
    mode: 'default',
    value: undefined,
    majors: [],
    className: undefined,
    placeholder: undefined,
  };

  // memoization here
  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.value) {
      return {
        selectedService: props.value,
        value: props.value,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.fetchServiceDebounce = debounce(this.fetchService, DEBOUNCE_TIME);
    this.state = {
      selectedService: props.value,
      value: props.value,
    };
  }

  componentDidMount() {
    this.fetchService();
  }

  componentDidUpdate(prevProps) {
    const { majors } = this.props;
    if (majors !== prevProps.majors) {
      this.fetchService();
    }
  }

  fetchService = search => {
    const { dispatch, majors } = this.props;
    dispatch({
      type: `${NAMESPACE}/fetch`,
      payload: {
        search,
        majors,
      },
    });
  };

  // Temporary disable multiple mode
  handleValueChange = value => {
    const { onChange, services } = this.props;
    this.setState({
      selectedService: value,
    });

    let conductedValue;

    if (Array.isArray(value)) {
      conductedValue = value.map(v => {
        const service = services.find(p => p.id === Number(v.key));
        return {
          ...v,
          ...service,
        };
      });
    } else if (isPlainObject(value)) {
      const service = services.find(p => p.id === Number(value.key));

      conductedValue = {
        ...value,
        ...service,
      };
    }

    onChange(conductedValue);
    this.fetchService('');
  };

  render() {
    const { services, loading, className, size, mode, placeholder, ...restProps } = this.props;
    const { selectedService } = this.state;
    return (
      <ServiceFilterView
        {...restProps}
        size={size}
        mode={mode}
        services={services}
        selectedService={selectedService}
        loading={loading}
        onSearch={this.fetchServiceDebounce}
        onChange={this.handleValueChange}
        className={className}
        placeholder={placeholder}
      />
    );
  }
}

export default ServiceFilterContainer;

export { default as model } from './model';
export { NAMESPACE } from './constants';
