import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import { formatMessage } from 'umi/locale';
import { Slider } from 'antd';

import { isValidAge } from '@/utils/utils'
import { PP_FORM_SELECT, SUBMIT_BUTTON } from '@/constants';
import { Modal, Button, Form, Row, Col } from '@/componentsX/UIElements';
import ServiceFilter from '@/componentsX/_share/ServiceFilter';
import SchedulerTypeFilter from '@/componentsX/_share/SchedulerTypeFilter';

import { FREE_SERVICE_FORM } from './constants';
import { mapToFormData } from './service';
import messages from './messages';
import Styles from './styles.less';

const { Item: FormItem, createFormField } = Form;

@Form.create({
  mapPropsToFields(props) {
    const service = mapToFormData(props.service);
    return Object.keys(service).reduce((acc, fieldName) => {
      acc[fieldName] = createFormField({ value: service[fieldName] });
      return acc;
    }, {});
  },
})
class ServiceModalView extends PureComponent {
  static propTypes = {
    isOpen: propTypes.bool.isRequired,
    isEdit: propTypes.bool.isRequired,
    loading: propTypes.bool.isRequired,
    submitButton: propTypes.string.isRequired,
    handleSave: propTypes.func.isRequired,
    handleCancel: propTypes.func.isRequired,
    handleUpdate: propTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      service: undefined,
      ageSliderMarks: this.calculateAgeSliderMarks(props.service),
    };
  }

  calculateAgeSliderMarks = service => {
    const { minAge, maxAge } = service;
    return isValidAge(minAge) && isValidAge(maxAge)
      ? {
        [minAge]: minAge,
        [maxAge]: maxAge,
      }
      : {};
  };

  handleCallback = (callBack, values) => {
    const { form } = this.props;
    callBack(form, values);
  };

  handleServiceChange = service => {
    this.setState({
      service,
    });

    // Clean scheduler type
    const { form } = this.props;
    form.setFieldsValue({
      [FREE_SERVICE_FORM.schedulerType]: undefined,
    });
  };

  handleAgeSliderChange = ages => {
    this.setState({
      ageSliderMarks: {
        [ages[0]]: ages[0],
        [ages[1]]: ages[1],
      },
    });
  };

  renderFooter = () => {
    const {
      isEdit,
      disableButtonUpdate,
      handleSave,
      handleUpdate,
      loading,
      submitButton,
      handleCancel,
    } = this.props;

    const handleSubmit = values => this.handleCallback(handleSave, values);
    const handleSubmitUpdate = values => this.handleCallback(handleUpdate, values);
    const submitButtons = isEdit
      ? [
        <Button
          key="submit"
          type="primary"
          loading={loading && submitButton === SUBMIT_BUTTON.update}
          disabled={disableButtonUpdate}
          onClick={handleSubmitUpdate}
        >
          {formatMessage(messages.update)}
        </Button>,
      ]
      : [
        <Button
          key="submit"
          type="primary"
          loading={loading && submitButton === SUBMIT_BUTTON.save}
          onClick={handleSubmit}
        >
          {formatMessage(messages.save)}
        </Button>,
      ];
    return [
      <Button key="cancel" onClick={handleCancel}>
        {formatMessage(messages.cancel)}
      </Button>,
      ...submitButtons,
    ];
  };

  render() {
    const { service, ageSliderMarks } = this.state;
    const { form, isOpen, isEdit, handleCancel } = this.props;
    const { getFieldDecorator } = form;
    const sliderMarks = {
      0: '0',
      100: '100',
      ...ageSliderMarks,
    };

    return (
      <Modal
        title={formatMessage(isEdit ? messages.updateService : messages.newService)}
        centered
        visible={isOpen}
        onCancel={handleCancel}
        destroyOnClose
        maskClosable={false}
        footer={this.renderFooter()}
        width="600px"
        className={Styles.modalBody}
      >
        <Form layout="vertical" className={Styles.serviceResource}>
          <Row gutter={16}>
            <Col sm={24} md={12}>
              <FormItem label="Service" colon={false}>
                {getFieldDecorator(FREE_SERVICE_FORM.service, {
                  rules: [
                    {
                      required: true,
                      message: 'Service is required',
                      type: 'object',
                    },
                  ],
                })(
                  <ServiceFilter
                    size="default"
                    className={PP_FORM_SELECT}
                    placeholder="Select a service"
                    onChange={this.handleServiceChange}
                  />
                )}
              </FormItem>
            </Col>
            <Col sm={24} md={12}>
              <FormItem label="Scheduler Type" colon={false}>
                {getFieldDecorator(FREE_SERVICE_FORM.schedulerType, {
                  rules: [
                    {
                      required: true,
                      message: 'Scheduler type is required',
                      type: 'object',
                    },
                  ],
                })(
                  <SchedulerTypeFilter
                    size="default"
                    allowHidden={false}
                    className={PP_FORM_SELECT}
                    placeholder="Select a scheduler type"
                    service={service}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormItem label="Scheduler Type" colon={false}>
                {getFieldDecorator(FREE_SERVICE_FORM.age)(
                  <Slider
                    range
                    min={0}
                    max={100}
                    marks={sliderMarks}
                    onChange={this.handleAgeSliderChange}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default ServiceModalView;
