import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'dva';
import { updateObject } from '@/utils/utils';
import { SUBMIT_BUTTON } from '@/constants';
import { get, isEqual } from 'lodash';
import MajorModalView from './view';
import { NAMESPACE } from './constants';

@connect(state => ({
  isSuccess: state[NAMESPACE].isSuccess,
  loading: state.loading.effects[`${NAMESPACE}/create`] ||
    state.loading.effects[`${NAMESPACE}/update`],
  initDataLoading: state.loading.effects[`${NAMESPACE}/initData`],
}))
class MajorModalContainer extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    isSuccess: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
    initDataLoading: PropTypes.bool,
  };

  static defaultProps = {
    loading: false,
    initDataLoading: false,
  };

  state = {
    submitButton: '',
    disableButtonUpdate: true,
    major: {},
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { isEdit, major } = nextProps;
    if (isEdit && get(major, 'id') !== get(prevState, 'major.id')) {
      return {
        major,
        disableButtonUpdate: true,
      }
    }
    return null
  }

  componentDidUpdate(prevProps) {
    const { isOpen, isSuccess, dispatch} = this.props;
    const { submitButton } = this.state;

    if (isOpen && !prevProps.isOpen) {
      dispatch({ type: `${NAMESPACE}/clear` });
    }

    if (isOpen && isSuccess) {
      if (submitButton === SUBMIT_BUTTON.saveAndNew) {
        this.handleClearData();
      } else {
        this.handleCancel();
      }
    }
  }

  handleClearData = () => {
    const { dispatch } = this.props;
    this.setState({
      major: {},
      disableButtonUpdate: true,
    });
    dispatch({ type: `${NAMESPACE}/clear` });
  };

  handleCancel = () => {
    const { handleCancel } = this.props;
    handleCancel();
    this.handleClearData()
  };

  handleSave = form => {
    const { dispatch, callback } = this.props;

    form.validateFieldsAndScroll((err, values) => {
      if (err) return;
      this.setState({ submitButton: SUBMIT_BUTTON.save }, () => {
        dispatch({
          type: `${NAMESPACE}/create`,
          payload: { major: values, callback },
        });
      });
    });
  };

  handleSaveAndNew = form => {
    const { dispatch, callback } = this.props;

    form.validateFieldsAndScroll((err, values) => {
      if (err) return;
      this.setState({ submitButton: SUBMIT_BUTTON.saveAndNew }, () => {
        dispatch({
          type: `${NAMESPACE}/create`,
          payload: { major: values, callback },
        });
      });
    });
  };

  handleUpdate = form => {
    const { dispatch, callback } = this.props;

    form.validateFieldsAndScroll((err, values) => {
      if (err) return;
      const { major: { id } } = this.props;
      this.setState({ submitButton: SUBMIT_BUTTON.update }, () => {
        dispatch({
          type: `${NAMESPACE}/update`,
          payload: { id, major: { id, ...values }, callback },
        });
      });
    });
  };

  handleValuesChange = (values) => {
    const { major } = this.state;
    const initialValues = {};
    const updateMajorInfo = updateObject(major, values);
    const majorInfo = Object.assign({}, initialValues, updateMajorInfo);
    this.setState({ major: majorInfo, disableButtonUpdate: false })
  };

  handleAvatarChange = (file) => {
    const values = { avatar: file };
    this.handleValuesChange(values);
  };

  render() {
    const { initData, isOpen, isEdit, loading, initDataLoading } = this.props;
    const { major, disableButtonUpdate } = this.state;
    const { submitButton } = this.state;
    return (
      <MajorModalView
        major={major}
        disableButtonUpdate={disableButtonUpdate}
        initData={initData}
        isOpen={isOpen}
        isEdit={isEdit}
        loading={loading}
        initDataLoading={initDataLoading}
        handleValuesChange={this.handleValuesChange}
        submitButton={submitButton}
        handleSave={this.handleSave}
        handleSaveAndNew={this.handleSaveAndNew}
        handleUpdate={this.handleUpdate}
        handleCancel={this.handleCancel}
        handleUploadAvatar={this.handleAvatarChange}
      />
    );
  }
}

export default MajorModalContainer;

export { default as model } from './model';
export { NAMESPACE } from './constants';
