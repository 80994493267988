import { defineMessages } from 'umi/locale';
import globalMessages from '@/messages';
import formMessages from '@/messages/form';
import { NAMESPACE } from './constants';

const messages = defineMessages({
  resetPassword: {
    id: `${NAMESPACE}.resetPassword`,
    defaultMessage: 'Reset Password',
  },
  currentPassword: {
    id: `${NAMESPACE}.currentPassword`,
    defaultMessage: 'Current Password',
  },
  currentPasswordRequired: {
    id: `${NAMESPACE}.currentPasswordRequired`,
    defaultMessage: 'Current Password is required',
  },
  newPassword: {
    id: `${NAMESPACE}.newPassword`,
    defaultMessage: 'New Password',
  },
  newPasswordRequired: {
    id: `${NAMESPACE}.currentPasswordRequired`,
    defaultMessage: 'New Password is required',
  },
  repeatNewPassword: {
    id: `${NAMESPACE}.repeatNewPassword`,
    defaultMessage: 'Repeat New Password',
  },
  repeatNewPasswordRequired: {
    id: `${NAMESPACE}.currentPasswordRequired`,
    defaultMessage: 'Repeat New Password is required',
  },
  updatePasswordSuccess: {
    id: `${NAMESPACE}.updatePasswordSuccess`,
    defaultMessage: 'Password is updated!',
  },
  passwordNotMatch: {
    id: `${NAMESPACE}.passwordNotMatch`,
    defaultMessage: 'Password does not match!',
  }
});

export default {
  ...formMessages,
  ...messages,
  update: globalMessages.common.update,
  saveAndNew: globalMessages.common.saveAndNew,
  save: globalMessages.common.save,
  cancel: globalMessages.common.cancel,
};
