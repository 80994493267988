import { defineMessages } from 'umi/locale';
import globalMessages from '@/messages';
import formMessages from '@/messages/form';
import majorMessages from '@/messages/major';
import { NAMESPACE } from './constants';

const messages = defineMessages({
  updateWorkload: {
    id: `${NAMESPACE}.updateWorkload`,
    defaultMessage: 'Update Workload',
  },
  sendOut: {
    id: `${NAMESPACE}.sendOut`,
    defaultMessage: 'Send out workload',
  },
  start: {
    id: `${NAMESPACE}.start`,
    defaultMessage: 'Start time',
  },
  startError: {
    id: `${NAMESPACE}.startError`,
    defaultMessage: 'Start is required',
  },
  end: {
    id: `${NAMESPACE}.end`,
    defaultMessage: 'End time',
  },
  endError: {
    id: `${NAMESPACE}.endError`,
    defaultMessage: 'End is required',
  },
});

export default {
  ...formMessages,
  ...majorMessages,
  ...messages,
  update: globalMessages.common.update,
  delete: globalMessages.common.delete,
  save: globalMessages.common.save,
  cancel: globalMessages.common.cancel,
};
