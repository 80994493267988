import request from '@/utils/request';
import { SORT_ORDER } from '@/constants';
import { mapSortToBEPost, mapPageToBE } from '@/utils/utils';

// eslint-disable-next-line import/prefer-default-export
export async function getPatients(
  search = '',
  currentPage = 1,
  sort = { order: SORT_ORDER.ascend, field: 'id' },
  dentistId = null
) {
  return request('/patients/search', {
    method: 'POST',
    body: {
      page_num: mapPageToBE(currentPage),
      page_size: 20,
      ...mapSortToBEPost(sort),
      text: search,
      default_dentist_id: dentistId,
    },
  });
}
