export default {
  'global.messages.patient.newPatient': 'New Patient',
  'global.messages.patient.updatePatient': 'Update Patient',
  'global.messages.patient.deletePatientTitle': 'Delete patient',
  'global.messages.patient.deletePatientContent': 'Are you sure you want to delete ',
  'pages.patient.list.allPatients': 'All Patients',
  'pages.patient.list.code': 'PATIENT CODE',
  'pages.patient.list.firstName': 'FIRST NAME',
  'pages.patient.list.lastName': 'LAST NAME',
  'pages.patient.list.gender': 'GENDER',
  'pages.patient.list.dob': 'DOB',
  'pages.patient.list.dentist': 'DENTIST',
  'pages.patient.list.actions': 'ACTIONS',
  'pages.patient.searchPlaceholder': 'Search by code or name',
  'share.patientFilter.placeholder': 'Select patient',
  'share.patientModal.defaultDentist': 'Default Dentist',
  'share.patientModal.defaultDentistPlaceholder': 'Default dentist',
  'share.patientModal.defaultDentistError': 'Default dentist is required',
};
