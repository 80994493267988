import {
  createPolicy,
  updatePolicy,
} from './service';
import { NAMESPACE } from './constants';

const defaultState = {
};

const model = {
  namespace: NAMESPACE,

  state: {
    initData: defaultState,
    isSuccess: false,
  },

  effects: {
    *create({ payload }, { call, put }) {
      const { policy, callback } = payload;
      const response = yield call(createPolicy, policy);
      if (response) {
        yield put({ type: 'save' });
        callback();
      }
    },
    *update({ payload }, { call, put }) {
      const { id, policy, callback } = payload;
      const response = yield call(updatePolicy, id, policy);
      if (response) {
        yield put({ type: 'save' });
        callback();
      }
    },
  },

  reducers: {
    save(state) {
      return {
        ...state,
        isSuccess: true,
      };
    },
    clear(state) {
      return {
        ...state,
        isSuccess: false,
      };
    },
  },
};

export default model;
