import { defineMessages } from 'umi/locale';
import globalMessages from '@/messages';
import patientMessages from '@/messages/patient';
import formMessages from '@/messages/form';
import { NAMESPACE } from './constants';

const messages = defineMessages({
  defaultDentist: {
    id: `${NAMESPACE}.defaultDentist`,
    defaultMessage: 'Default Dentist',
  },
  defaultDentistPlaceholder: {
    id: `${NAMESPACE}.defaultDentistPlaceholder`,
    defaultMessage: 'Default dentist',
  },
  defaultDentistError: {
    id: `${NAMESPACE}.defaultDentistError`,
    defaultMessage: 'Default dentist is required',
  },
  patientCreated: {
    id: `${NAMESPACE}.patientCreated`,
    defaultMessage: 'Patient created successfully!',
  },
  patientUpdated: {
    id: `${NAMESPACE}.patientUpdated`,
    defaultMessage: 'Patient updated successfully!',
  },
});

export default {
  ...messages,
  ...patientMessages,
  ...formMessages,
  update: globalMessages.common.update,
  saveAndNew: globalMessages.common.saveAndNew,
  save: globalMessages.common.save,
  cancel: globalMessages.common.cancel,
};
