import moment from 'moment-timezone';

export default function getBasicFilterValues(userTimezone, unit) {
  if (!unit) {
    return {
      time: [moment.tz(userTimezone), moment.tz(userTimezone)],
    };
  }
  return {
    time: [moment.tz(userTimezone).startOf(unit), moment.tz(userTimezone).endOf(unit)],
  };
}
