import { defineMessages } from 'umi/locale';
import globalMessages from '@/messages';
import policyMessages from '@/messages/policy';
import formMessages from '@/messages/form';
import { NAMESPACE } from './constants';

const messages = defineMessages({
  duration: {
    id: `${NAMESPACE}.duration`,
    defaultMessage: 'Duration (minutes)',
  },
  durationError: {
    id: `${NAMESPACE}.durationError`,
    defaultMessage: 'Duration is required',
  },
  numberOfHours: {
    id: `${NAMESPACE}.numberOfHours`,
    defaultMessage: 'Number Of Hours',
  },
  numberOfHoursError: {
    id: `${NAMESPACE}.numberOfHoursError`,
    defaultMessage: 'Number Of Hours is required',
  },
  typeError: {
    id: `${NAMESPACE}.typeError`,
    defaultMessage: 'Type is required',
  },
  type: {
    id: `${NAMESPACE}.type`,
    defaultMessage: 'Type',
  },
  shouldPayError: {
    id: `${NAMESPACE}.shouldPayError`,
    defaultMessage: 'Should Pay is required',
  },
  shouldPay: {
    id: `${NAMESPACE}.shouldPay`,
    defaultMessage: 'Should Pay',
  },
  policyCreated: {
    id: `${NAMESPACE}.policyCreated`,
    defaultMessage: 'Policy created successfully!',
  },
  policyUpdated: {
    id: `${NAMESPACE}.policyUpdated`,
    defaultMessage: 'Policy updated successfully!',
  },
  typeFilterPlaceholder: {
    id: `${NAMESPACE}.typeFilterPlaceholder`,
    defaultMessage: 'Select type',
  },
});

export default {
  ...formMessages,
  ...policyMessages,
  ...messages,
  update: globalMessages.common.update,
  saveAndNew: globalMessages.common.saveAndNew,
  save: globalMessages.common.save,
  cancel: globalMessages.common.cancel,
};
