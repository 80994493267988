import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'dva';
import { isEqual, isEmpty } from 'lodash';
import { SUBMIT_BUTTON } from '@/constants';
import { NAMESPACE, ROLE_FORM } from './constants';
import { getFirstField } from './service';
import RoleModalView from './view';

class RoleModalContainer extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
    inputDataLoading: PropTypes.bool,
    selectedRoles: PropTypes.array.isRequired,
    getRoleDetailLoading: PropTypes.bool,
    changedIndex: PropTypes.number.isRequired,
    roleChangedIndex: PropTypes.number.isRequired,
    inputData: PropTypes.object.isRequired,
    roleId: PropTypes.string,
    role: PropTypes.object.isRequired,
  };

  static defaultProps = {
    loading: false,
    inputDataLoading: false,
    getRoleDetailLoading: false,
    roleId: '',
  };

  static computeState(nextProps, nextState) {
    const { roleId, role, inputData: { features } } = nextProps;

    const reUpdateRoleId = !isEqual(roleId, nextState.props.roleId);
    const reUpdateRoleInfo = !isEqual(role, nextState.props.role)
                             || !isEqual(features, nextState.props.features);

    if (reUpdateRoleInfo || reUpdateRoleId) {
      return {
        roleInfo: reUpdateRoleInfo ? role : nextState.roleInfo,
        disableButtonUpdate: true,
        props: {
          roleId,
          role,
          features,
        },
        isEditMode: !!role.id,
      }
    }
    return null;
  };

  static getDerivedStateFromProps(nextProps, nextState) {
    const result = RoleModalContainer.computeState(nextProps, nextState);
    if (result) {
      return result;
    }
    return null;
  }

  constructor(props) {
    super(props);
    const { roleId, role, inputData: { features } } = this.props;
    this.state = {
      props: {
        roleId,
        role,
        features,
      },
      isEditMode: !!role.id,
      roleInfo: role,
      submitButton: '',
      disableButtonUpdate: true,
    };
  }

  componentDidUpdate(prevProps) {
    const { isOpen, inputDataLoading, roleId } = this.props;
    if (isOpen && !prevProps.isOpen) {
      this.fetchInputData()
    }

    // fetch roleDetail when input data loaded
    if (!inputDataLoading && !isEmpty(roleId) && !isEqual(inputDataLoading, prevProps.inputDataLoading)) {
      this.fetchRoleDetail(roleId);
    }
    this.onRoleChanged(prevProps);
    this.processAfterSubmit(prevProps);
  }

  onRoleChanged = (prevProps) => {
    const { roleChangedIndex } = this.props;
    if (prevProps.roleChangedIndex !== roleChangedIndex) {
      this.setState({ disableButtonUpdate: false })
    }
  };

  fetchRoleDetail = (roleId) => {
    const { dispatch } = this.props;
    dispatch({
      type: `${NAMESPACE}/getRoleDetail`,
      roleId,
    })
  };

  fetchInputData = () => {
    const { dispatch } = this.props;
    dispatch({ type: `${NAMESPACE}/fetchInputData` })
  };

  processAfterSubmit = (prevProps) => {
    const { changedIndex, role } = this.props;
    const { submitButton } = this.state;
    const isSubmitted = changedIndex !== prevProps.changedIndex;

    if (isSubmitted) {
      if (submitButton === SUBMIT_BUTTON.save || submitButton === SUBMIT_BUTTON.update) {
        return this.handleCancel();
      }
      this.setState({ roleInfo: role }, this.handleClearData);
    }
  };

  handleValuesChange = (values) => {
    const changedField = getFirstField(values);

    if (ROLE_FORM.features !== changedField) {
      return this.handleNormalFieldChange(values);
    }
  };

  handleNormalFieldChange = (values) => {
    const { roleInfo } = this.state;
    this.setState({
      roleInfo: {
        ...roleInfo,
        ...values,
      },
      disableButtonUpdate: false,
    });
  };

  save = (form, isSaveAndNew) => {
    const { dispatch, selectedRoles } = this.props;
    const { roleInfo } = this.state;

    form.validateFieldsAndScroll((err) => {
      if (err) return;
      const { saveAndNew, save } = SUBMIT_BUTTON;
      const submitButton = isSaveAndNew ? saveAndNew : save;

      this.setState({ submitButton }, () => {
        dispatch({
          type: `${NAMESPACE}/create`,
          payload: {
            role: roleInfo,
            selectedRoles,
          },
        });
      });
    });
  };

  handleSave = form => {
    this.save(form, false);
  };

  handleSaveAndNew = form => {
    this.save(form, true);
  };

  handleUpdate = form => {
    const { dispatch, selectedRoles, role: { id } } = this.props;
    const { roleInfo } = this.state;

    form.validateFieldsAndScroll((err) => {
      if (err) return;
      this.setState({ submitButton: SUBMIT_BUTTON.update }, () => {
        dispatch({
          type: `${NAMESPACE}/update`,
          payload: {
            role: { id, ...roleInfo },
            selectedRoles,
          },
        });
      });
    });
  };

  handleCancel = () => {
    const { handleCancel, role } = this.props;
    handleCancel();
    this.setState({ roleInfo: role, disableButtonUpdate: true }, this.handleClearData);
  };

  handleClearData = () => {
    const { dispatch } = this.props;
    setTimeout(() => {
      dispatch({ type: `${NAMESPACE}/clear` });
    });
  };

  render() {
    const {
      isOpen,
      loading,
      inputDataLoading,
      selectedRoles,
      getRoleDetailLoading,
      inputData: { users, features },
    } = this.props;
    const {
      roleInfo,
      disableButtonUpdate,
      submitButton,
      isEditMode,
    } = this.state;

    return (
      <RoleModalView
        isOpen={isOpen}
        loading={loading}
        inputDataLoading={inputDataLoading}
        getRoleDetailLoading={getRoleDetailLoading}
        role={roleInfo}
        selectedRoles={selectedRoles}
        disableButtonUpdate={disableButtonUpdate}
        features={features}
        users={users}
        isEditMode={isEditMode}
        submitButton={submitButton}
        handleValuesChange={this.handleValuesChange}
        handleSave={this.handleSave}
        handleSaveAndNew={this.handleSaveAndNew}
        handleUpdate={this.handleUpdate}
        handleCancel={this.handleCancel}
      />
    );
  }
}

const mapStateToProps = state => {
  const {
    inputData,
    changedIndex,
    role,
    selectedRoles,
    roleChangedIndex,
  } = state[NAMESPACE];
  const { global, loading: { effects } } = state;
  return {
    role,
    inputData,
    selectedRoles,
    roleChangedIndex,
    changedIndex,
    currentUser: global.currentUser,
    loading: effects[`${NAMESPACE}/create`] || effects[`${NAMESPACE}/update`],
    getRoleDetailLoading: state.loading.effects[`${NAMESPACE}/getRoleDetail`],
    inputDataLoading: effects[`${NAMESPACE}/fetchInputData`],
  }
};

export default connect(mapStateToProps)(RoleModalContainer)
export { default as model } from './model';
export { NAMESPACE } from './constants';
