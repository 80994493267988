export default {
  'menu.home': 'Home',
  'menu.login': 'Login',
  'menu.dashboard': 'Dashboard',
  'menu.patient': 'Patients',
  'menu.dentist': 'Dentists',
  'menu.admin': 'Admin',
  'menu.appointment': 'Appointments',
  'menu.leaveRequest': 'Leave Request',
  'menu.timesheet': 'Timesheet Report',
  'menu.workload': 'Staff Workload',
  'menu.workload.staffsWorkloadManagement': 'Staffs Workload Management',
  'menu.workload.myWorkload': 'My Workload',
  'menu.staffs': 'Staffs',
  'menu.not-permission': '403',
  'menu.not-find': '404',
  'menu.server-error': '500',
  'menu.exception': 'Exception',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': 'Trigger',
  'menu.account': 'Account',
  'menu.account.center': 'Account Center',
  'menu.account.settings': 'Account Settings',
  'menu.account.trigger': 'Trigger Error',
  'menu.account.logout': 'Logout',
  'menu.myWorkload': 'My Workload',
  'menu.staffsWorkloadManagement': 'Staffs Workload Management',
  'menu.myOvertimeRequests': 'My Overtime Requests',
  'menu.profile': 'Profile',
  'menu.attendanceReport': 'Attendance Report',
  'menu.report': 'Report',
  'menu.report.timesheet': 'Timesheet Report',
  'menu.report.attendance': 'Attendance Report',
  'menu.myReport': 'My Report',
  'menu.leaveRequest.myRequestsSummary': 'My Requests Summary',
  'menu.leaveRequest.management': 'Management',
  'menu.leaveRequest.myRequests': 'My Requests',
  'menu.overtimeRequest': 'Overtime Request',
  'menu.overtimeRequest.myRequests': 'My Requests',
  'menu.overtimeRequest.management': 'Management',
  'menu.clinics': 'Clinics',
  'menu.clinicDetail': 'Clinic Details',
  'menu.clinicDetail.workingHours': 'Working Hours',
  'menu.clinicDetail.staffs': 'Staffs',
  'menu.profile.contract': 'Contract',
  'menu.profile.license': 'License',
  'menu.profile.workingHours': 'Working Hours',
  'menu.profile.w4Form': 'W4 Form',
};
