export default {
  'pages.admin.workingTime.addNewHours': 'Add New Hours',
  'pages.admin.workingTime.addHours': 'Add Hours',
  'pages.admin.workingTime.monday': 'Monday',
  'pages.admin.workingTime.tuesday': 'Tuesday',
  'pages.admin.workingTime.wednesday': 'Wednesday',
  'pages.admin.workingTime.thursday': 'Thursday',
  'pages.admin.workingTime.friday': 'Friday',
  'pages.admin.workingTime.saturday': 'Saturday',
  'pages.admin.workingTime.sunday': 'Sunday',
  'pages.admin.workingTime.from': 'From',
  'pages.admin.workingTime.to': 'to',
};
