import PATH from './constants';

export default {
  dispatchJob: {
    id: `${PATH}.route.dispatchJob`,
    defaultMessage: 'Dispatch Job',
  },
  dispatchRouteTitle: {
    id: `${PATH}.route.dispatchRouteTitle`,
    defaultMessage: 'Only Pending Dispatched jobs can be dispatched',
  },
  dispatchRouteContent: {
    id: `${PATH}.route.dispatchRouteContent`,
    defaultMessage:
      'There are jobs in route have status is not Pending Dispatched. ' +
      'Only Pending Dispatched jobs will be dispatched. Do you want to continue?',
  },
};
