export default {
  'global.messages.policy.newPolicy': 'New Policy',
  'global.messages.policy.updatePolicy': 'Update Policy',
  'pages.admin.policy.list.allPolicies': 'All Policies',
  'pages.admin.policy.list.description': 'DESCRIPTION',
  'pages.admin.policy.list.numberOfHours': 'NUMBER OF HOURS',
  'pages.admin.policy.list.duration': 'DURATION',
  'pages.admin.policy.list.type': 'TYPE',
  'pages.admin.policy.list.id': 'ID',
  'pages.admin.policy.list.shouldPay': 'SHOULD PAY',
  'pages.admin.policy.list.actions': 'ACTIONS',
  'pages.admin.policy.filterPlaceholder': 'Select type',
  'share.policyModal.duration': 'Duration (minutes)',
  'share.policyModal.durationError': 'Duration is required',
  'share.policyModal.numberOfHours': 'Number Of Hours',
  'share.policyModal.numberOfHoursError': 'Number Of Hours is required',
  'share.policyModal.type': 'Type',
  'share.policyModal.typeError': 'Type is required',
  'share.policyModal.shouldPay': 'Should Pay',
  'share.policyModal.shouldPayError': 'Should Pay is required',
  'share.policyModal.policyUpdated': 'Policy updated successfully!',
  'share.policyModal.policyCreated': 'Policy created successfully!',
  'pages.admin.policy.archiveTitle': 'Archive Policy',
  'pages.admin.policy.archiveContent': 'Are you sure you want to archive this policy?',
};

