import React, { PureComponent } from 'react';
import { connect } from 'dva';

import { Spin } from '@/componentsX/UIElements';

const withLocale = WrappedComponent => {
  @connect(state => ({
    user: state.global.currentUser,
    clinicTimezone: state.global.clinicTimezone,
  }))
  class LocaleComponent extends PureComponent {
    componentDidMount() {
      const { dispatch } = this.props;
      dispatch({ type: 'global/fetchTimezones' });
      dispatch({ type: 'global/fetchClinicTimezone' });
    }

    render() {
      const {
        clinicTimezone,
        user: { zoneId },
      } = this.props;

      // Don't render component if missing zoneId
      if (!zoneId) return <Spin spinning />;

      const localeData = {
        userTimezone: zoneId || 'UTC',
        clinicTimezone,
      };

      return <WrappedComponent {...this.props} localeData={localeData} />;
    }
  }

  LocaleComponent.displayName = `withLocale(${WrappedComponent.displayName ||
    WrappedComponent.name})`;

  return LocaleComponent;
};

export default withLocale;
