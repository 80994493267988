import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';

import { CALENDAR_VIEW, TIME_FORMAT_MIN, APPOINTMENT_STATUS } from '@/constants';
import { getAvatarURL } from '@/utils/image';
import { Avatar, Icon, Typography } from '@/componentsX/UIElements';

import Styles from './AppointmentEvent.less';

class AppointmentEvent extends PureComponent {
  static propTypes = {
    event: propTypes.object.isRequired,
    view: propTypes.string.isRequired,
  };

  renderStatusIcon = () => {
    const {
      event: { appointmentInfo },
    } = this.props;
    const { status } = appointmentInfo;

    let icon;
    if (APPOINTMENT_STATUS.COMPLETED === status) {
      icon = 'check-square';
    }

    return icon ? (
      <Icon className={classNames(Styles.statusIcon, Styles[status.toLowerCase()])} type={icon} />
    ) : null;
  };

  renderPatient = () => {
    const {
      event: {
        appointmentInfo: { patient },
      },
    } = this.props;
    const { avatar, firstName, lastName } = patient;
    const patientName = `${firstName} ${lastName}`;

    return (
      <div className={Styles.row}>
        <Avatar size={16} src={getAvatarURL(avatar)} />
        <Typography.Text ellipsis title={patientName}>
          {patientName}
        </Typography.Text>
      </div>
    );
  };

  render() {
    const {
      view,
      event: { title, start, color },
    } = this.props;

    const needPatient = CALENDAR_VIEW.day === view || CALENDAR_VIEW.week === view;

    const eventTime = moment(start).format(TIME_FORMAT_MIN);

    return (
      <div className={Styles.wrapper} style={{ background: color }}>
        <div className={Styles.leftColumn}>
          <div className={Styles.row}>
            <span>{eventTime}</span>
            <Typography.Text ellipsis>{title}</Typography.Text>
          </div>
          {needPatient && this.renderPatient()}
        </div>
        <div className={Styles.rightColumn}>{this.renderStatusIcon()}</div>
      </div>
    );
  }
}

export default AppointmentEvent;
