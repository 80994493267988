import React, { PureComponent, Fragment } from 'react';
import propTypes from 'prop-types';
import { formatMessage } from 'umi/locale';
import get from 'lodash/get';
import { EMPTY_VALUE } from '@/constants';
import { Card, Typography, Row, Col, Tag, Button } from '@/componentsX/UIElements';
import IconButton from '@/componentsX/IconButton';
import { withLocaleContext } from '@/utils/locale';

import messages from '../messages';
import {
  buildTimeString,
  isAppointmentComplete,
  isAppointmentNew,
  isAfterCurrentDate,
} from '../service';
import Styles from './AppointmentCard.less';

const { Title } = Typography;

class AppointmentCard extends PureComponent {
  static propTypes = {
    event: propTypes.object.isRequired,
    onClose: propTypes.func.isRequired,
    onEditAppointment: propTypes.func.isRequired,
    onDeleteAppointment: propTypes.func.isRequired,
    onMarkAsComplete: propTypes.func.isRequired,
    onCancelAppointment: propTypes.func.isRequired,
  };

  renderTitle() {
    const {
      allowEdit,
      event: { title, appointmentInfo },
      onClose,
      onEditAppointment,
      onDeleteAppointment,
    } = this.props;

    return (
      <Fragment>
        <div className={Styles.toolbar}>
          {isAppointmentComplete(appointmentInfo) && (
            <IconButton
              className={Styles.leftButton}
              isCircle
              size="small"
              icon="check"
              tooltip={{ title: formatMessage(messages.appointmentCompleted) }}
            />
          )}
          <div className={Styles.rightButton}>
            {allowEdit && !isAppointmentComplete(appointmentInfo) && (
              <IconButton
                isCircle
                size="small"
                icon="edit"
                tooltip={{ title: formatMessage(messages.edit) }}
                onClick={onEditAppointment}
              />
            )}
            <IconButton
              isCircle
              size="small"
              icon="delete"
              tooltip={{ title: formatMessage(messages.delete) }}
              onClick={onDeleteAppointment}
            />
            <IconButton
              isCircle
              size="small"
              icon="close"
              tooltip={{ title: formatMessage(messages.close) }}
              onClick={onClose}
            />
          </div>
        </div>
        <Title level={3}>{title}</Title>
      </Fragment>
    );
  }

  renderFooterActions = () => {
    const {
      onMarkAsComplete,
      onCancelAppointment,
      event: { appointmentInfo, start },
      userTimezone,
    } = this.props;

    const btns = [];

    if (
      !isAfterCurrentDate(start, userTimezone) &&
      !isAppointmentNew(appointmentInfo) &&
      !isAppointmentComplete(appointmentInfo)
    ) {
      btns.push(
        <div className={Styles.groupButton}>
          <Button
            key="complete"
            type="primary"
            block
            className={Styles.complete}
            onClick={onMarkAsComplete}
          >
            {formatMessage(messages.markAsComplete)}
          </Button>
          <Button
            key="cancel"
            type="danger"
            block
            className={Styles.cancel}
            onClick={onCancelAppointment}
          >
            {formatMessage(messages.cancelAppointment)}
          </Button>
        </div>
      );
    }
    return btns;
  };

  render() {
    const {
      event: { start, end, appointmentInfo },
    } = this.props;
    const { patient, dentist, description, appointmentTreatments, color } = appointmentInfo;
    const timeString = buildTimeString(start, end);
    const dentistName = `${dentist.firstName} ${dentist.lastName}`;
    const patientName = `${patient.firstName} ${patient.lastName}`;
    const serviceName = get(appointmentTreatments, '[0].treatment.name', EMPTY_VALUE);
    const schedulerTypeName = get(appointmentTreatments, '[0].schedulerType.name', EMPTY_VALUE);

    return (
      <Card title={this.renderTitle()} bordered={false} className={Styles.wrapper}>
        <Row gutter={16} className={Styles.section}>
          <Col>{timeString}</Col>
        </Row>
        <Row gutter={16} className={Styles.section}>
          <Col>
            <span>Patient:</span> <Tag title={patientName}>{patientName}</Tag>
          </Col>
        </Row>
        <Row gutter={16} className={Styles.section}>
          <Col>
            <span>Dentist:</span>{' '}
            <Tag color={color} title={dentistName}>
              {dentistName}
            </Tag>
          </Col>
        </Row>
        <Row gutter={16} className={Styles.section}>
          <Col>
            <span>Services:</span> <Tag title={serviceName}>{serviceName}</Tag>
          </Col>
        </Row>
        <Row gutter={16} className={Styles.section}>
          <Col>
            <span>Scheduler Type:</span> <Tag title={schedulerTypeName}>{schedulerTypeName}</Tag>
          </Col>
        </Row>
        <Row gutter={16} className={Styles.section}>
          <Col>Description: {description}</Col>
        </Row>
        <Row gutter={16} className={Styles.section}>
          {this.renderFooterActions()}
        </Row>
      </Card>
    );
  }
}

export default withLocaleContext(AppointmentCard);
