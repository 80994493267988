import React from 'react';
import { List, Button } from 'antd';
import classNames from 'classnames';
import Avatar from '@/componentsX/Avatar';
import { DEFAULT_AVATAR } from '@/constants';
import { convertNotificationTime } from '@/utils/utils';
import styles from './NoticeList.less';

export default function NoticeList({
  data = [],
  onClick,
  locale,
  emptyText,
  emptyImage,
  loadMore,
}) {
  if (data.length === 0) {
    return (
      <div className={styles.notFound}>
        {emptyImage ? <img src={emptyImage} alt="not found" /> : null}
        <div>{emptyText || locale.emptyText}</div>
      </div>
    );
  }
  return (
    <div>
      <List className={styles.list} loadMore={loadMore}>
        {data.map((item, i) => {
          const itemCls = classNames(styles.item, {
            [styles.viewed]: item.viewed,
          });
          // eslint-disable-next-line no-nested-ternary
          const leftIcon = item.avatar ? (
            typeof item.avatar === 'string' ? (
              <Avatar className={styles.avatar} src={item.avatar} fallbackSrc={DEFAULT_AVATAR} />
            ) : (
                item.avatar
              )
          ) : null;

          return (
            <List.Item className={itemCls} key={item.id || i} onClick={() => onClick(item)}>
              <List.Item.Meta
                className={styles.meta}
                avatar={<span className={styles.iconElement}>{leftIcon}</span>}
                title={
                  <div className={styles.title}>
                    {item.header}
                  </div>
                }
                description={
                  <div>
                    <div className={styles.description} title={item.details}>
                      {item.details}
                    </div>
                    <div className={styles.datetime}>{item.lastModifiedDate ? convertNotificationTime(item.lastModifiedDate) : ''}</div>
                  </div>
                }
              />
            </List.Item>
          );
        })}
      </List>
    </div>
  );
}
