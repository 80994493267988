import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'dva';
import { formatMessage } from 'umi/locale';
import { SUBMIT_BUTTON } from '@/constants';
import { notification } from '@/componentsX/UIElements';
import PatientModalView from './view';
import messages from './messages';
import { NAMESPACE } from './constants';

@connect(state => ({
  genders: state[NAMESPACE].genders,
  isSuccess: state[NAMESPACE].isSuccess,
  loading:
    state.loading.effects[`${NAMESPACE}/create`] || state.loading.effects[`${NAMESPACE}/update`],
}))
class PatientModalContainer extends PureComponent {
  static propTypes = {
    patient: PropTypes.object,
    isOpen: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    isSuccess: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
    genders: PropTypes.array,
  };

  static defaultProps = {
    patient: {},
    loading: false,
    genders: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      submitButton: '',
      patientInfo: props.patient,
    };
  }

  componentDidMount() {
    this.fetchInputData();
  }

  componentDidUpdate(prevProps) {
    const { isOpen, isSuccess, dispatch } = this.props;
    const { submitButton } = this.state;

    if (isOpen && !prevProps.isOpen) {
      dispatch({ type: `${NAMESPACE}/clear` });
      this.fetchInputData();
    }

    if (isOpen && isSuccess) {
      if (submitButton === SUBMIT_BUTTON.save || submitButton === SUBMIT_BUTTON.update) {
        this.handleCancel();
      } else if (submitButton === SUBMIT_BUTTON.saveAndNew) {
        this.handleClearData();
        this.fetchInputData();
      }
    }
  }

  fetchInputData = () => {
    const { dispatch } = this.props;
    dispatch({
      type: `${NAMESPACE}/initData`,
    });
  };

  handleClearData = () => {
    const { dispatch } = this.props;
    this.setState({
      patientInfo: {},
      submitButton: '',
    });
    dispatch({ type: `${NAMESPACE}/clear` });
  };

  handleCancel = () => {
    const { handleCancel } = this.props;
    handleCancel();
    this.handleClearData();
  };

  handleCallback = patient => {
    const { callback } = this.props;
    this.handleClearData();
    callback(patient);
  };

  handleSave = form => {
    const { dispatch } = this.props;

    form.validateFieldsAndScroll((err, values) => {
      if (err) return;
      this.setState({ submitButton: SUBMIT_BUTTON.save, patientInfo: { ...values } }, () => {
        dispatch({
          type: `${NAMESPACE}/create`,
          payload: {
            patient: { ...values },
            callback: patient => {
              notification.success({ message: formatMessage(messages.patientCreated) });
              this.handleCallback(patient);
            },
          },
        });
      });
    });
  };

  handleSaveAndNew = form => {
    const { dispatch } = this.props;

    form.validateFieldsAndScroll((err, values) => {
      if (err) return;
      this.setState({ submitButton: SUBMIT_BUTTON.saveAndNew, patientInfo: { ...values } }, () => {
        dispatch({
          type: `${NAMESPACE}/create`,
          payload: {
            patient: { ...values },
            callback: patient => {
              notification.success({ message: formatMessage(messages.patientCreated) });
              this.handleCallback(patient);
            },
          },
        });
      });
    });
  };

  handleUpdate = form => {
    const { dispatch } = this.props;

    form.validateFieldsAndScroll((err, values) => {
      if (err) return;
      const {
        patient: { id },
      } = this.props;
      this.setState({ submitButton: SUBMIT_BUTTON.update, patientInfo: { ...values } }, () => {
        dispatch({
          type: `${NAMESPACE}/update`,
          payload: {
            id,
            patient: { id, ...values },
            callback: patient => {
              notification.success({ message: formatMessage(messages.patientUpdated) });
              this.handleCallback(patient);
            },
          },
        });
      });
    });
  };

  render() {
    const { isOpen, isEdit, loading, genders } = this.props;
    const { submitButton, patientInfo } = this.state;
    return (
      <PatientModalView
        patient={patientInfo}
        genders={genders}
        isOpen={isOpen}
        isEdit={isEdit}
        loading={loading}
        submitButton={submitButton}
        handleSave={this.handleSave}
        handleSaveAndNew={this.handleSaveAndNew}
        handleUpdate={this.handleUpdate}
        handleCancel={this.handleCancel}
      />
    );
  }
}

export default PatientModalContainer;

export { default as model } from './model';
export { NAMESPACE, PATIENT_FORM } from './constants';
