import request from '@/utils/request';

export async function getRoleDetail(roleId) {
  return request(`/role/${roleId}`);
}

export async function getUsers() {
  return request('/user');
}

export async function getFeatures() {
  return request(`/platform/tenants/realm/features`);
}

export async function createRole(role) {
  const requestParams = role();
  return request('/role', {
    method: 'POST',
    body: requestParams,
  });
}

export async function updateRole(role) {
  const requestParams = role();
  return request('/role', {
    method: 'PUT',
    body: requestParams,
  });
}

export function mapSelectedRolesToRole(role, selectedRoles) {
  return Object.assign(role, { features: selectedRoles })
}

export function getFirstField(values) {
  return Object.keys(values)[0];
}
