export const NAMESPACE = 'share.emailTemplateModal';

export const FORM = {
  type: 'type',
  event: 'event',
  models: 'models',
  html: 'html',
};

export const TYPE = {
  sms: 'SMS',
  email: 'EMAIL',
};

export const SMS_CHARS_LIMIT = 168;
