import { get } from 'lodash';
import {
  createMajor,
  updateMajor,
} from './service';
import { NAMESPACE } from './constants';

const defaultState = {
  name: '',
  description: '',
};

const model = {
  namespace: NAMESPACE,
  state: {
    initData: defaultState,
    isSuccess: false,
  },
  effects: {
    *create({ payload }, { call, put }) {
      const { major, callback } = payload;
      const response = yield call(createMajor, major);
      if (response) {
        yield put({ type: 'save' });
        callback()
      }
    },
    *update({ payload }, { call, put }) {
      const { id, major, callback } = payload;
      const response = yield call(updateMajor, id, major);
      if (response) {
        yield put({ type: 'save' });
        callback()
      }
    },
  },

  reducers: {
    saveInitData(state, { payload }) {
      return {
        ...state,
        initData: {
          ...state.initData,
          ...payload,
        },
      };
    },
    save(state) {
      return {
        ...state,
        isSuccess: true,
      };
    },
    clear(state) {
      return {
        ...state,
        isSuccess: false,
      };
    },
  },
};

export default model;
