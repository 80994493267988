import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'dva';
import { get } from 'lodash';

import { updateObject } from '@/utils/utils';
import { SUBMIT_BUTTON } from '@/constants';
import { withLocaleContext } from '@/utils/locale';

import SendOutModalView from './view';
import { NAMESPACE } from './constants';

@connect(state => ({
  isSuccess: state[NAMESPACE].isSuccess,
  loading:
    state.loading.effects[`${NAMESPACE}/create`] || state.loading.effects[`${NAMESPACE}/update`],
  initDataLoading: state.loading.effects[`${NAMESPACE}/initData`],
}))
class SendOutModalContainer extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    isSuccess: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
    initDataLoading: PropTypes.bool,
  };

  static defaultProps = {
    loading: false,
    initDataLoading: false,
  };

  state = {
    submitButton: '',
    disableButtonUpdate: true,
    workload: {},
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { isEdit, workload } = nextProps;
    if (isEdit && get(workload, 'id') !== get(prevState, 'workload.id')) {
      return {
        workload,
        disableButtonUpdate: true,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    const { isOpen, isSuccess, dispatch } = this.props;
    const { submitButton } = this.state;

    if (isOpen && !prevProps.isOpen) {
      dispatch({ type: `${NAMESPACE}/clear` });
    }

    if (isOpen && isSuccess) {
      if (submitButton === SUBMIT_BUTTON.saveAndNew) {
        this.handleClearData();
      } else {
        this.handleCancel();
      }
    }
  }

  handleClearData = () => {
    const { dispatch } = this.props;
    this.setState({
      workload: {},
      disableButtonUpdate: true,
    });
    dispatch({ type: `${NAMESPACE}/clear` });
  };

  handleCancel = () => {
    const { handleCancel } = this.props;
    handleCancel();
    this.handleClearData();
  };

  handleSave = form => {
    const { dispatch, callback, selectedAvailableSlot, userTimezone } = this.props;

    form.validateFieldsAndScroll((err, values) => {
      if (err) return;
      this.setState({ submitButton: SUBMIT_BUTTON.save }, () => {
        dispatch({
          type: `${NAMESPACE}/create`,
          payload: { workload: values, callback, userTimezone },
        });
      });
    });
  };

  handleUpdate = form => {
    const { dispatch, callback } = this.props;

    form.validateFieldsAndScroll((err, values) => {
      if (err) return;
      const {
        workload: { id },
        userTimezone,
      } = this.props;
      this.setState({ submitButton: SUBMIT_BUTTON.update }, () => {
        dispatch({
          type: `${NAMESPACE}/update`,
          payload: { id, workload: { id, ...values }, callback, userTimezone },
        });
      });
    });
  };

  handleDelete = () => {
    const { dispatch, callback } = this.props;
    const {
      workload: { id },
    } = this.props;
    this.setState({ submitButton: SUBMIT_BUTTON.delete }, () => {
      dispatch({
        type: `${NAMESPACE}/delete`,
        payload: { id, callback },
      });
    });
  };

  handleValuesChange = values => {
    const { workload } = this.state;
    const initialValues = {};
    const updateWorkloadInfo = updateObject(workload, values);
    const workloadInfo = Object.assign({}, initialValues, updateWorkloadInfo);
    this.setState({ workload: workloadInfo, disableButtonUpdate: false });
  };

  render() {
    const { initData, isOpen, isEdit, loading, initDataLoading } = this.props;
    const { workload, disableButtonUpdate } = this.state;
    const { submitButton } = this.state;
    return (
      <SendOutModalView
        workload={workload}
        disableButtonUpdate={disableButtonUpdate}
        initData={initData}
        isOpen={isOpen}
        isEdit={false}
        loading={loading}
        initDataLoading={initDataLoading}
        handleValuesChange={this.handleValuesChange}
        submitButton={submitButton}
        handleSave={this.handleSave}
        handleUpdate={this.handleUpdate}
        handleCancel={this.handleCancel}
        handleDelete={this.handleDelete}
      />
    );
  }
}

export default withLocaleContext(SendOutModalContainer);

export { default as model } from './model';
export { NAMESPACE } from './constants';
