import { defineMessages } from 'umi/locale';
import globalMessages from '@/messages';
import serviceMessages from '@/messages/freeService';
import formMessages from '@/messages/form';
import { NAMESPACE } from './constants';

const messages = defineMessages({
  serviceCreated: {
    id: `${NAMESPACE}.serviceCreated`,
    defaultMessage: 'Free Service created successfully!',
  },
  serviceUpdated: {
    id: `${NAMESPACE}.serviceUpdated`,
    defaultMessage: 'Free Service updated successfully!',
  },
});

export default {
  ...formMessages,
  ...serviceMessages,
  ...messages,
  update: globalMessages.common.update,
  saveAndNew: globalMessages.common.saveAndNew,
  save: globalMessages.common.save,
  cancel: globalMessages.common.cancel,
};
