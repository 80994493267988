import { defineMessages } from 'umi/locale';
import PATH from './constants';

export default defineMessages({
  avatarRequired: {
    id: `${PATH}.form.avatarRequired`,
    defaultMessage: 'Avatar is required',
  },
  name: {
    id: `${PATH}.form.name`,
    defaultMessage: 'Name',
  },
  namePlaceholder: {
    id: `${PATH}.form.namePlaceholder`,
    defaultMessage: 'Add name',
  },
  nameRequired: {
    id: `${PATH}.form.nameRequired`,
    defaultMessage: 'Name is required',
  },
  nameWhitespace: {
    id: `${PATH}.form.nameWhitespace`,
    defaultMessage: 'This field must not contain whitespace',
  },
  firstName: {
    id: `${PATH}.form.firstName`,
    defaultMessage: 'First Name',
  },
  firstNamePlaceholder: {
    id: `${PATH}.form.firstNamePlaceholder`,
    defaultMessage: 'First Name',
  },
  firstNameError: {
    id: `${PATH}.form.firstNameError`,
    defaultMessage: 'Please input First Name',
  },
  lastName: {
    id: `${PATH}.form.lastName`,
    defaultMessage: 'Last Name',
  },
  lastNamePlaceholder: {
    id: `${PATH}.form.lastNamePlaceholder`,
    defaultMessage: 'Last Name',
  },
  lastNameError: {
    id: `${PATH}.form.lastNameError`,
    defaultMessage: 'Please input Last Name',
  },
  email: {
    id: `${PATH}.form.email`,
    defaultMessage: 'E-mail',
  },
  emailPlaceholder: {
    id: `${PATH}.form.emailPlaceholder`,
    defaultMessage: 'Email',
  },
  emailEmptyError: {
    id: `${PATH}.form.emailEmptyError`,
    defaultMessage: 'Please input your E-mail',
  },
  emailInvalidError: {
    id: `${PATH}.form.emailInvalidError`,
    defaultMessage: 'The input is not valid E-mail',
  },
  password: {
    id: `${PATH}.form.password`,
    defaultMessage: 'Password',
  },
  passwordPlaceholder: {
    id: `${PATH}.form.passwordPlaceholder`,
    defaultMessage: 'Password',
  },
  passwordError: {
    id: `${PATH}.form.passwordError`,
    defaultMessage: 'Password is required',
  },
  phone: {
    id: `${PATH}.form.phone`,
    defaultMessage: 'Phone',
  },
  phoneRequiredError: {
    id: `${PATH}.form.phoneRequiredError`,
    defaultMessage: 'Phone number is required',
  },
  invalidPhoneNumber: {
    id: `${PATH}.form.invalidPhoneNumber`,
    defaultMessage: 'Invalid phone number',
  },
  address: {
    id: `${PATH}.form.address`,
    defaultMessage: 'Address',
  },
  addressPlaceholder: {
    id: `${PATH}.form.addressPlaceholder`,
    defaultMessage: 'Address',
  },
  street: {
    id: `${PATH}.form.street`,
    defaultMessage: 'street',
  },
  unitNumber: {
    id: `${PATH}.form.unitNumber`,
    defaultMessage: 'unitNumber',
  },
  city: {
    id: `${PATH}.form.city`,
    defaultMessage: 'city',
  },
  state: {
    id: `${PATH}.form.state`,
    defaultMessage: 'state',
  },
  zipCode: {
    id: `${PATH}.form.zipCode`,
    defaultMessage: 'zipCode',
  },
  country: {
    id: `${PATH}.form.country`,
    defaultMessage: 'country',
  },
  gender: {
    id: `${PATH}.form.gender`,
    defaultMessage: 'Gender',
  },
  selectGenderPlaceholder: {
    id: `${PATH}.form.selectGenderPlaceholder`,
    defaultMessage: 'Select gender',
  },
  genderRequired: {
    id: `${PATH}.form.genderRequired`,
    defaultMessage: 'Gender is required',
  },
  dob: {
    id: `${PATH}.form.dob`,
    defaultMessage: 'D.O.B',
  },
  dobPlaceholder: {
    id: `${PATH}.form.dobPlaceholder`,
    defaultMessage: 'D.O.B',
  },
  dobError: {
    id: `${PATH}.form.dobError`,
    defaultMessage: 'D.O.B is required',
  },
  description: {
    id: `${PATH}.form.description`,
    defaultMessage: 'Description',
  },
  descriptionPlaceholder: {
    id: `${PATH}.form.descriptionPlaceholder`,
    defaultMessage: 'Description',
  },
  descriptionError: {
    id: `${PATH}.form.descriptionError`,
    defaultMessage: 'Description is required',
  },
  major: {
    id: `${PATH}.form.major`,
    defaultMessage: 'Major',
  },
  majorPlaceholder: {
    id: `${PATH}.form.majorPlaceholder`,
    defaultMessage: 'Major',
  },
  majorError: {
    id: `${PATH}.form.majorError`,
    defaultMessage: 'Major is required',
  },
});
