export const NAMESPACE = 'share.policyModal';

export const TYPE = [
  {
    name: 'BREAK'
  },
  {
    name: 'LUNCH'
  },
]

export const SHOULD_PAY = [
  {
    name: 'YES'
  },
  {
    name: 'NO'
  },
]

export const FORM = {
  id: 'id',
  numberOfHours: 'number_of_hours',
  description: 'description',
  shouldPay: 'should_pay',
  duration: 'duration',
  type: 'type',
};
