import { get } from 'lodash';
import { ACCESS_TYPES } from '../constants';

export const initDefaultValue = (roleFeatures, features) => {
  const roleFeatureIds = roleFeatures.map(item => item.id);
  const featureNotRegistered = features.filter(item => !roleFeatureIds.includes(item.id));
  const items = featureNotRegistered.map(item => ({ id: item.id, subFeatures: [] }));

  return roleFeatures.concat(items);
};

export const mapRoleFeaturesToDataView = (roleFeatures, features) => {
  const dataView = {};
  for (let i = 0; i <= roleFeatures.length - 1; i += 1) {
    const { id } = roleFeatures[i];
    dataView[id] = setValue(roleFeatures[i], features);
  }
  return dataView;
};

const setValue = (roleFeature, features) => {
  const { FULL, NONE, SOME } = ACCESS_TYPES;
  const subFeatures = get(roleFeature, 'subFeatures', []);
  const value = {};

  for (let j = 0; j <= subFeatures.length - 1; j += 1) {
    value[subFeatures[j].id] = 1;
  }
  value.type = isSelectSomeSub(roleFeature, features) ? SOME : (isUnSelectAllSub(roleFeature) ? NONE : FULL);

  return value;
};

const isSelectSomeSub = (roleFeature, features) => {
  const currentFeature = features.filter(item => item.id === roleFeature.id)[0];
  const subFeatures = get(currentFeature, 'subFeatures', []);
  const rolSubFeatures = get(roleFeature, 'subFeatures', []);
  const subIds = subFeatures.map(item => item.id);

  const isExist = rolSubFeatures.filter(item => subIds.includes(item.id));
  if (ACCESS_TYPES.SOME === roleFeature.registerd) {
    return true;
  }
  return rolSubFeatures.length < subIds.length && isExist.length > 0;
};

const isUnSelectAllSub = (roleFeature) => {
  const subFeatures = get(roleFeature, 'subFeatures', []);
  return subFeatures.length <= 0;
};

export const setAll = (features, featureId) => {
  const currentFeature = features.filter(item => item.id === featureId);
  const subFeatures = get(currentFeature, '0.subFeatures', []);

  return subFeatures.map(item => ({ id: item.id }));
};
