import { defineMessages } from 'umi/locale';
import PATH from './constants';

export default defineMessages({
  newDentist: {
    id: `${PATH}.dentist.newDentist`,
    defaultMessage: 'New Dentist',
  },
  updateDentist: {
    id: `${PATH}.dentist.updateDentist`,
    defaultMessage: 'Update Dentist',
  },
  deleteDentistTitle: {
    id: `${PATH}.dentist.deleteDentistTitle`,
    defaultMessage: 'Delete Dentist',
  },
  deleteDentistContent: {
    id: `${PATH}.dentist.deleteDentistContent`,
    defaultMessage: 'Are you sure you want to delete this Dentist ?',
  },
  archiveDentistTitle: {
    id: `${PATH}.dentist.archiveDentistTitle`,
    defaultMessage: 'Archive Dentist',
  },
  archiveDentistContent: {
    id: `${PATH}.dentist.archiveDentistContent`,
    defaultMessage: 'Are you sure you want to archive this Dentist ?',
  },
  dentistInfo: {
    id: `${PATH}.dentist.dentistInfo`,
    defaultMessage: 'Dentist Info',
  },
  workingHours: {
    id: `${PATH}.dentist.workingHours`,
    defaultMessage: 'Working Hours',
  },
});
