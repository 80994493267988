import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import { formatMessage } from 'umi/locale';
import { Select, Spin } from '@/componentsX/UIElements';
import messages from './messages';

const { Option, LabeledValue } = Select;

class AppointmentStatusFilterView extends PureComponent {
  static propTypes = {
    statuses: propTypes.array.isRequired,
    selectedStatus: propTypes.oneOfType([
      propTypes.string,
      propTypes.arrayOf(propTypes.string),
      propTypes.shape(LabeledValue),
      propTypes.arrayOf(propTypes.shape(LabeledValue)),
    ]),
    loading: propTypes.bool.isRequired,
    className: propTypes.string,
    placeholder: propTypes.string,
    size: propTypes.string,
    mode: propTypes.string,
    onSearch: propTypes.func.isRequired,
    onChange: propTypes.func.isRequired,
  };

  static defaultProps = {
    className: undefined,
    placeholder: undefined,
    size: 'default',
    mode: 'default',
    selectedStatus: undefined,
  };

  render() {
    const {
      statuses,
      loading,
      selectedStatus,
      onSearch,
      onChange,
      className,
      placeholder,
      size,
      mode,
    } = this.props;
    const placeholderComputed = placeholder || formatMessage(messages.placeholder);

    return (
      <Select
        mode={mode}
        size={size}
        style={{ width: '100%' }}
        className={className}
        labelInValue
        value={selectedStatus}
        placeholder={placeholderComputed}
        notFoundContent={loading ? <Spin size="small" /> : null}
        filterOption={false}
        onSearch={onSearch}
        onChange={onChange}
      >
        {statuses.map(({ value, name }) => (
          <Option key={value}>{name}</Option>
        ))}
      </Select>
    );
  }
}

export default AppointmentStatusFilterView;
