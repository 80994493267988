import moment from 'moment';
import React from 'react';
import { parse, stringify } from 'qs';
import {
  isPlainObject,
  set,
  get,
  cloneDeep,
  isEmpty,
  isString,
  isNil,
  trim,
} from 'lodash';
import { includes, filter } from 'ramda';
import {
  DATE_TIME_FORMAT,
  DATE_TIME_MODIFY_FORMAT,
  SORT_ORDER,
  CALENDAR_VIEW,
  WORKING_TIME_FORMAT,
  TIME_FORMAT,
  WORKING_TIME_PICKER_FORMAT,
} from '@/constants';

export function fixedZero(val) {
  return val * 1 < 10 ? `0${val}` : val;
}

export function getTimeDistance(type) {
  const now = new Date();
  const oneDay = 1000 * 60 * 60 * 24;

  if (type === 'today') {
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    return [moment(now), moment(now.getTime() + (oneDay - 1000))];
  }

  if (type === 'week') {
    let day = now.getDay();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);

    if (day === 0) {
      day = 6;
    } else {
      day -= 1;
    }

    const beginTime = now.getTime() - day * oneDay;

    return [moment(beginTime), moment(beginTime + (7 * oneDay - 1000))];
  }

  if (type === 'month') {
    const year = now.getFullYear();
    const month = now.getMonth();
    const nextDate = moment(now).add(1, 'months');
    const nextYear = nextDate.year();
    const nextMonth = nextDate.month();

    return [
      moment(`${year}-${fixedZero(month + 1)}-01 00:00:00`),
      moment(moment(`${nextYear}-${fixedZero(nextMonth + 1)}-01 00:00:00`).valueOf() - 1000),
    ];
  }

  const year = now.getFullYear();
  return [moment(`${year}-01-01 00:00:00`), moment(`${year}-12-31 23:59:59`)];
}

export function getCalendarDateRange(type, currentDate) {
  if (type === CALENDAR_VIEW.year) {
    return {
      startDate: currentDate.clone().startOf('year'),
      endDate: currentDate.clone().endOf('year'),
    };
  }

  if (type === CALENDAR_VIEW.week) {
    return {
      startDate: currentDate.clone().startOf('week'),
      endDate: currentDate.clone().endOf('week'),
    };
  }

  if (type === CALENDAR_VIEW.day) {
    return {
      startDate: currentDate.clone().startOf('day'),
      endDate: currentDate.clone().endOf('day'),
    };
  }
  // Default is month
  return {
    startDate: currentDate.clone().startOf('month'),
    endDate: currentDate.clone().endOf('month'),
  };
}

export function getPlainNode(nodeList, parentPath = '') {
  const arr = [];
  nodeList.forEach(node => {
    const item = node;
    item.path = `${parentPath}/${item.path || ''}`.replace(/\/+/g, '/');
    item.exact = true;
    if (item.children && !item.component) {
      arr.push(...getPlainNode(item.children, item.path));
    } else {
      if (item.children && item.component) {
        item.exact = false;
      }
      arr.push(item);
    }
  });
  return arr;
}

function getRelation(str1, str2) {
  if (str1 === str2) {
    console.warn('Two path are equal!'); // eslint-disable-line
  }
  const arr1 = str1.split('/');
  const arr2 = str2.split('/');
  if (arr2.every((item, index) => item === arr1[index])) {
    return 1;
  }
  if (arr1.every((item, index) => item === arr2[index])) {
    return 2;
  }
  return 3;
}

function getRenderArr(routes) {
  let renderArr = [];
  renderArr.push(routes[0]);
  for (let i = 1; i < routes.length; i += 1) {
    // 去重
    renderArr = renderArr.filter(item => getRelation(item, routes[i]) !== 1);
    // 是否包含
    const isAdd = renderArr.every(item => getRelation(item, routes[i]) === 3);
    if (isAdd) {
      renderArr.push(routes[i]);
    }
  }
  return renderArr;
}

/**
 * Get router routing configuration
 * { path:{name,...param}}=>Array<{name,path ...param}>
 * @param {string} path
 * @param {routerData} routerData
 */
export function getRoutes(path, routerData) {
  let routes = Object.keys(routerData).filter(
    routePath => routePath.indexOf(path) === 0 && routePath !== path
  );
  // Replace path to '' eg. path='user' /user/name => name
  routes = routes.map(item => item.replace(path, ''));
  // Get the route to be rendered to remove the deep rendering
  const renderArr = getRenderArr(routes);
  // Conversion and stitching parameters
  const renderRoutes = renderArr.map(item => {
    const exact = !routes.some(route => route !== item && getRelation(route, item) === 1);
    return {
      exact,
      ...routerData[`${path}${item}`],
      key: `${path}${item}`,
      path: `${path}${item}`,
    };
  });
  return renderRoutes;
}

export function getPageQuery() {
  return parse(window.location.href.split('?')[1]);
}

export function getQueryPath(path = '', query = {}) {
  const search = stringify(query);
  if (search.length) {
    return `${path}?${search}`;
  }
  return path;
}

/* eslint no-useless-escape:0 */
const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;

export function isUrl(path) {
  return reg.test(path);
}

export function formatWan(val) {
  const v = val * 1;
  if (!v) return '';

  let result = val;
  if (val > 10000) {
    result = Math.floor(val / 10000);
    result = (
      <span>
        {result}
        <span
          style={{
            position: 'relative',
            top: -2,
            fontSize: 14,
            fontStyle: 'normal',
            marginLeft: 2,
          }}
        >
          万
        </span>
      </span>
    );
  }
  return result;
}

// 给官方演示站点用，用于关闭真实开发环境不需要使用的特性
export function isAntdPro() {
  return window.location.hostname === 'preview.pro.ant.design';
}

export const importCDN = (url, name) =>
  new Promise(resolve => {
    const dom = document.createElement('script');
    dom.src = url;
    dom.type = 'text/javascript';
    dom.onload = () => {
      resolve(window[name]);
    };
    document.head.appendChild(dom);
  });

export function mapSortToBE(sort) {
  if (isEmpty(sort)) {
    return '';
  }

  const { order, columnKey } = sort;
  if (columnKey) {
    return `&orderBy=${columnKey}&direction=${order === SORT_ORDER.ascend ? 'asc' : 'desc'}`;
  }

  return `&orderBy=${sort.field}&direction=${sort.order === SORT_ORDER.ascend ? 'asc' : 'desc'}`;
}

export function mapSortToBEPost(sort) {
  if (isEmpty(sort)) {
    return {};
  }

  const { order, columnKey, field } = sort;
  return {
    order_by: columnKey || field,
    direction: order === SORT_ORDER.ascend ? 'ASC' : 'DESC',
  };
}

export function mapPageToBE(pageNum) {
  return pageNum ? Number(pageNum) - 1 : 0;
}

export function getTotalPage(response) {
  return Math.ceil(get(response, 'data.total') / get(response, 'data.pageSize'));
}

export function trimString(value) {
  return isString(value) ? trim(value) : value;
}

export function flatternObject(object) {
  const keys = Object.keys(object);
  if (keys && keys.length > 0) {
    const [key] = keys;
    const value = object[key];
    if (Array.isArray(value)) {
      const isArrayObject = value.some(data => isPlainObject(data));
      if (isArrayObject) {
        const validIndex = value.findIndex(data => data);
        return `${key}.${validIndex}.${flatternObject(value[validIndex])}`;
      }
      return key;
    }
    if (isPlainObject(value)) {
      return `${key}.${flatternObject(value)}`;
    }
    return key;
  }
}

export function updateObject(source, newValue) {
  const newSource = cloneDeep(source);
  const path = flatternObject(newValue);
  const value = get(newValue, path);
  return set(newSource, path, value);
}

export function convertToCamelCase(underScoreString) {
  return underScoreString.replace(/_([a-z])/g, g => g[1].toUpperCase());
}

export function range(start, end) {
  const arr = [];
  for (let i = +start; i < +end; i += 1) {
    arr.push(i);
  }
  return arr;
}

export function disabledHours(period) {
  const bias =
    moment(period.start, WORKING_TIME_FORMAT).minutes() <
    moment(period.end, WORKING_TIME_FORMAT).minutes()
      ? 1
      : 0;
  return {
    start: range(moment(period.end, WORKING_TIME_FORMAT).hours() + bias, 24),
    end: range(0, moment(period.start, WORKING_TIME_FORMAT).hours()),
  };
}

export function disabledMinutes(period) {
  if (
    moment(period.start, WORKING_TIME_FORMAT).hours() <
    moment(period.end, WORKING_TIME_FORMAT).hours()
  )
    return [];
  return {
    start: range(moment(period.end, WORKING_TIME_FORMAT).minutes(), 60),
    end: range(0, moment(period.start, WORKING_TIME_FORMAT).minutes()),
  };
}

export function convertLongWeekDay(day) {
  const lowerCaseDay = day.toLowerCase();
  switch (lowerCaseDay) {
    case 'mon':
      return 'monday';
    case 'tue':
      return 'tuesday';
    case 'wed':
      return 'wednesday';
    case 'thu':
      return 'thursday';
    case 'fri':
      return 'friday';
    case 'sat':
      return 'saturday';
    case 'sun':
      return 'sunday';
    default:
      return '';
  }
}

export function convertTimeToUTC(time) {
  const modTime = moment(time, WORKING_TIME_PICKER_FORMAT);
  const utcTime = modTime.utc().format(TIME_FORMAT);
  return utcTime;
}

export function convertToLocalTime(utcTime) {
  const modTime = moment.utc(utcTime, WORKING_TIME_PICKER_FORMAT);
  const localTime = modTime.local().format(WORKING_TIME_PICKER_FORMAT);
  return localTime;
}

export function convertTo12HoursFormat(time) {
  const isGoodFormat = moment(time, WORKING_TIME_FORMAT, true).isValid();
  if (isGoodFormat) {
    return moment(time, WORKING_TIME_FORMAT).format(WORKING_TIME_PICKER_FORMAT);
  }
  return null;
}

export function convertTo24HoursFormat(time) {
  const isGoodFormat = moment(time, WORKING_TIME_PICKER_FORMAT, true).isValid();
  if (isGoodFormat) {
    return moment(time, WORKING_TIME_PICKER_FORMAT).format(WORKING_TIME_FORMAT);
  }
  return moment(time, WORKING_TIME_FORMAT).format(WORKING_TIME_FORMAT);
}

export function getSorterWithKey(a, b, key) {
  const aValue = a[key];
  const bValue = b[key];
  // sorting for date time
  if (moment(aValue, moment.ISO_8601).isValid() && moment(bValue, moment.ISO_8601).isValid()) {
    return moment(aValue).diff(moment(bValue));
  }
  // sorting for string.
  return aValue.localeCompare(bValue);
}

export function sortTimezones(timezones) {
  return timezones.sort();
}

export function convertNotificationTime(time) {
  return moment(time).format(DATE_TIME_FORMAT);
}

export function humanizeDuration(durationInMinutes) {
  const duration = moment.duration(durationInMinutes, 'minutes');
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const durationArr = [];
  if (days > 0) {
    durationArr.push(`${days}d`);
  }
  if (hours > 0) {
    durationArr.push(`${hours}h`);
  }
  if (minutes > 0) {
    durationArr.push(`${minutes}m`);
  }
  return durationArr.join(' ');
}

export function getNextTimeByMode(mode, time = moment()) {
  switch (mode) {
    case CALENDAR_VIEW.year:
      return moment(time).add(1, 'years');
    case CALENDAR_VIEW.month:
      return moment(time).add(1, 'months');
    case CALENDAR_VIEW.week:
      return moment(time).add(1, 'weeks');
    case CALENDAR_VIEW.day:
      return moment(time).add(1, 'days');
    default:
      return moment(time);
  }
}

export function getPrevTimeByMode(mode, time = moment()) {
  switch (mode) {
    case CALENDAR_VIEW.year:
      return moment(time).subtract(1, 'years');
    case CALENDAR_VIEW.month:
      return moment(time).subtract(1, 'months');
    case CALENDAR_VIEW.week:
      return moment(time).subtract(1, 'weeks');
    case CALENDAR_VIEW.day:
      return moment(time).subtract(1, 'days');
    default:
      return moment(time);
  }
}

export function isValidAge(age) {
  return Number(age) >= 0 && Number.isInteger(age);
}

export function searchParamsFromCurrentUrl(paramName) {
  const currentUrl = new URL(window.location.href);
  const paramValue = currentUrl.searchParams.get(paramName);
  return paramValue || '';
}
export function generateRandomPassword() {
  return Math.random()
    .toString(36)
    .slice(-8);
}

export function isAdmin(currentUser) {
  const { roles } = currentUser;
  let currentRole = '';
  if (Array.isArray(roles) > 0) {
    currentRole = roles[0].name;
  }
  if (currentRole && currentRole === 'ROLE_ADMIN') {
    return true;
  }
  return false;
}

export function hasOrRoles(currentUser, roleNames) {
  const { roles = [] } = currentUser;
  const staffRoleNames = roles && roles.map(({ name }) => name);
  return filter(r => includes(r, staffRoleNames), roleNames).length > 0;
}
