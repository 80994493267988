import get from 'lodash/get';

import { NAMESPACE } from './constants';
import {
  buildWorkingTimes,
  createAppointment,
  getAppointments,
  getBusyAppointments,
  getLastAppointment,
  getDentistWorkingTimes,
  getWorkingDentists,
  transformAppointment,
  transformAppointments,
  transformBusyAppointments,
  transformWorkingDentists,
} from './service';

const defaultState = {
  isSuccess: false,
  workingDentists: [],
  appointments: [],
  busyAppointments: [],
  dentistWorkingTimes: {},
  lastAppointment: undefined,
};

const model = {
  namespace: NAMESPACE,

  state: {
    ...defaultState,
  },

  effects: {
    *create({ payload, callback }, { call, put }) {
      const { appointment } = payload;

      const response = yield call(createAppointment, appointment);
      if (response) {
        yield put({ type: 'save' });
        callback();
      }
    },
    *fetch({ payload }, { call, put }) {
      const {
        startDate,
        endDate,
        serviceId,
        schedulerTypeId,
        dentistId,
        patientId,
        userTimezone,
      } = payload;
      const response = yield call(
        getAppointments,
        startDate,
        endDate,
        serviceId,
        schedulerTypeId,
        dentistId,
        patientId
      );

      if (response) {
        const appointments = get(response, 'data.items', []);
        yield put({
          type: 'saveAppointments',
          payload: { appointments: transformAppointments(appointments, userTimezone) },
        });
      }
    },
    *fetchWorkingDentists(_, { call, put }) {
      const response = yield call(getWorkingDentists);

      if (response) {
        const workingDentists = get(response, 'data', []);
        yield put({
          type: 'saveWorkingDentists',
          payload: { workingDentists: transformWorkingDentists(workingDentists) },
        });
      }
    },
    *fetchBusyAppointments({ payload }, { call, put }) {
      const { startDate, endDate, dentistId, patientId, userTimezone } = payload;
      const response = yield call(getBusyAppointments, startDate, endDate, dentistId, patientId);

      if (response) {
        const appointments = get(response, 'data', []);
        yield put({
          type: 'saveBusyAppointments',
          payload: { appointments: transformBusyAppointments(appointments, userTimezone) },
        });
      }
    },
    *fetchLastAppointment({ payload }, { call, put }) {
      const { serviceId, schedulerTypeId, patientId, userTimezone, callback } = payload;

      if (serviceId && patientId && schedulerTypeId) {
        const response = yield call(getLastAppointment, serviceId, schedulerTypeId, patientId);
        const appointment = get(response, 'data', undefined);
        yield put({
          type: 'saveLastAppointment',
          payload: transformAppointment(appointment, userTimezone),
        });
      } else {
        yield put({
          type: 'saveLastAppointment',
          payload: undefined,
        });
      }

      if (callback) {
        callback();
      }
    },
    *fetchDentistWorkingTimes({ dentistId }, { call, put }) {
      if (dentistId) {
        const response = yield call(getDentistWorkingTimes, dentistId);
        if (response) {
          const workingTimes = get(response, 'data', []);
          yield put({
            type: 'saveDentistWorkingTime',
            payload: { workingTimes: buildWorkingTimes(workingTimes) },
          });
        }
      } else {
        yield put({
          type: 'saveDentistWorkingTime',
          payload: { workingTimes: {} },
        });
      }
    },
  },

  reducers: {
    save(state) {
      return {
        ...state,
        isSuccess: true,
      };
    },
    saveWorkingDentists(
      state,
      {
        payload: { workingDentists },
      }
    ) {
      return {
        ...state,
        workingDentists,
      };
    },
    saveAppointments(
      state,
      {
        payload: { appointments },
      }
    ) {
      return {
        ...state,
        appointments,
      };
    },
    saveBusyAppointments(
      state,
      {
        payload: { appointments },
      }
    ) {
      return {
        ...state,
        busyAppointments: appointments,
      };
    },
    saveDentistWorkingTime(
      state,
      {
        payload: { workingTimes },
      }
    ) {
      return {
        ...state,
        dentistWorkingTimes: workingTimes,
      };
    },
    saveLastAppointment(state, { payload }) {
      return {
        ...state,
        lastAppointment: payload,
      };
    },
    clear(state) {
      return {
        ...state,
        ...defaultState,
      };
    },
  },
};

export default model;
