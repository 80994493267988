function extractResponseError(e) {
  const {
    response: {
      data: { detail, title, message },
    },
  } = e;
  const error = detail || title || message || 'Internal Server Error. Please try again later.';
  return { error };
}

function extractRequestError() {
  return { error: 'The network connection is lost.' };
}

export default function extractAxiosError(error) {
  console.error(error);
  if (error.response) {
    return extractResponseError(error);
  }
  if (error.request) {
    return extractRequestError(error);
  }
  return { error: 'Cannot make request to the server. Please try again later.' };
}
