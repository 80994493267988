import exception from './en-US/exception';
import menu from './en-US/menu';
import form from './en-US/form';
import login from './en-US/login';
import settings from './en-US/settings';
import pwa from './en-US/pwa';
import notification from './en-US/notification';
import common from './en-US/common';
import user from './en-US/user';
import patient from './en-US/patient';
import dentist from './en-US/dentist';
import appointment from './en-US/appointment';
import major from './en-US/major';
import admin from './en-US/admin';
import service from './en-US/service';
import freeService from './en-US/freeService';
import workingTime from './en-US/working-time';
import emailTemplates from './en-US/emailTemplates';
import policy from './en-US/policy';

export default {
  menu: ' ',
  'navBar.lang': 'Languages',
  'layout.user.link.help': 'Help',
  'layout.user.link.privacy': 'Privacy',
  'layout.user.link.terms': 'Terms',
  'app.home.introduce': 'introduce',
  'app.forms.basic.title': 'Basic form',
  'app.forms.basic.description':
    'Form pages are used to collect or verify information to users, and basic forms are common in scenarios where there are fewer data items.',
  ...exception,
  ...menu,
  ...form,
  ...login,
  ...settings,
  ...pwa,
  ...common,
  ...user,
  ...notification,
  ...patient,
  ...dentist,
  ...appointment,
  ...major,
  ...admin,
  ...service,
  ...freeService,
  ...workingTime,
  ...emailTemplates,
  ...policy,
};
