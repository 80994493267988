export default {
  'pages.user.login.login': 'Login',
  'pages.user.login.failed': 'Username or password is incorrect. Please try again.',
  'pages.user.login.errorUserName': 'Please input your username!',
  'pages.user.login.errorPassword': 'Please input your password!',
  'pages.user.login.placeHolderUserName': 'Username',
  'pages.user.login.placeHolderPassword': 'Password',
  'pages.user.login.forgotPassword': 'Forgot Password',
  'pages.user.forgotpassword.forgotPassword': 'Forgot Password',
  'pages.user.forgotpassword.errorUserName': 'Please input your username!',
  'pages.user.forgotpassword.placeHolderUserName': 'Username or email',
  'pages.user.forgotpassword.submit': 'Submit',
  'pages.user.resetpassword.resetPassword': 'Reset password',
  'pages.user.resetpassword.errorPassword': 'Please input your password!',
  'pages.user.resetpassword.placeHolderNewPassword': 'New Password',
  'pages.user.resetpassword.placeHolderRepeatPassword': 'Repeat New Password',
  'pages.user.resetpassword.submit': 'Submit',
};
