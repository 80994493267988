import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';

import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Styles from './index.less';

export default class PhoneInputPP extends PureComponent {
  static propType = {
    children: propTypes.element.isRequired,
    className: propTypes.bool.isRequired,
  };

  static defaultProps = {
    className: '',
  };

  render() {
    const { children, className, ...rest } = this.props;

    return (
      <PhoneInput defaultCountry="US" className={classNames(Styles.wrapper, className)} {...rest}>
        {children}
      </PhoneInput>
    );
  }
}
