import request from '@/utils/request';
import { SORT_ORDER } from '@/constants';
import { mapSortToBEPost, mapPageToBE } from '@/utils/utils';

export async function getNotifications(
  pagination,
  sort = { order: SORT_ORDER.ascend, field: 'id' },
) {
  return request('/notifications/list', {
    method: 'POST',
    body: {
      page_num: mapPageToBE(pagination.current),
      page_size: pagination.pageSize,
      ...mapSortToBEPost(sort)
    }
  },
  );
}

export async function markNotificationViewed(id) {
  return request(`/notifications/view/${id}`, {
    method: 'PUT',
  });
}

export function getTotalUnreadNotification(notifications) {
  return notifications ? notifications.filter(item => !item.viewed).length : 0
}
