import { get } from 'lodash';
import { uploadAvatar } from '@/utils/image';
import { createPatient, getGenders, updatePatient } from './service';
import { NAMESPACE } from './constants';

const defaultState = {
  initData: {
    genders: [],
  },
  isSuccess: false,
};

const model = {
  namespace: NAMESPACE,

  state: { ...defaultState },

  effects: {
    *initData(_, { all, call, put }) {
      const [genderResponse] = yield all([call(getGenders)]);
      if (genderResponse) {
        yield put({
          type: 'saveInitData',
          payload: {
            genders: genderResponse,
          },
        });
      }
    },
    *create({ payload }, { call, put }) {
      const { patient, callback } = payload;
      const avatarResponse = yield call(uploadAvatar, patient.avatar);

      if (avatarResponse) {
        const response = yield call(createPatient, patient, get(avatarResponse, 'uri'));
        if (response) {
          yield put({ type: 'save' });
          callback(get(response, 'data'));
        }
      }
    },
    *update({ payload }, { call, put }) {
      const { id, patient, callback } = payload;
      const { avatar } = patient;

      if (avatar instanceof File) {
        const avatarResponse = yield call(uploadAvatar, avatar);
        if (avatarResponse) {
          const response = yield call(updatePatient, id, patient, get(avatarResponse, 'uri'));
          if (response) {
            yield put({ type: 'save' });
            callback(get(response, 'data'));
          }
        }
      } else {
        const response = yield call(updatePatient, id, patient, avatar);
        if (response) {
          yield put({ type: 'save' });
          callback();
        }
      }
    },
  },

  reducers: {
    saveInitData(
      state,
      {
        payload: { genders },
      }
    ) {
      return {
        ...state,
        genders,
      };
    },
    save(state) {
      return {
        ...state,
        isSuccess: true,
      };
    },
    clear() {
      return {
        ...defaultState,
      };
    },
  },
};

export default model;
