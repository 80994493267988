import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  Row,
  Col,
  Form,
  Input,
  Checkbox,
  DatePicker,
  Divider,
  InputNumber,
} from '@/componentsX/UIElements';
import { PP_FORM_INPUT, DEFAULT_DATE_FORMAT } from '@/constants';
import Styles from './W4Form.less';

const { Item: FormItem } = Form;

export default function W4Form({ form, fields, initialValues }) {
  const { getFieldDecorator } = form;
  const {
    firstName,
    lastName,
    socialSecurityNumber,
    homeAddress,
    single,
    married,
    marriedHigherSingleRate,
    cityTownStateZipCode,
    lastNameDifferSsc,
    totalAllowances,
    additionalAmount,
    exempt,
    employerName,
    firstDateEmployment,
    employerIdentificationNumber,
    page3a,
    page3b,
    page3c,
    page3d,
    page3e,
    page3f,
    page3g,
    page3h,
    page31,
    page32,
    page33,
    page34,
    page35,
    page36,
    page37,
    page38,
    page39,
    page310,
    page41,
    page42,
    page43,
    page44,
    page45,
    page46,
    page47,
    page48,
    page49,
  } = fields;
  return (
    <Form layout="vertical">
      <Row gutter={16}>
        <Col md={12}>
          <FormItem label="Your first name and middle initial">
            {getFieldDecorator(firstName, {
              initialValue: initialValues[firstName],
            })(<Input className={PP_FORM_INPUT} disabled />)}
          </FormItem>
        </Col>
        <Col md={12}>
          <FormItem label="Last name">
            {getFieldDecorator(lastName, {
              initialValue: initialValues[lastName],
            })(<Input className={PP_FORM_INPUT} disabled />)}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <FormItem label="Your social security number">
            {getFieldDecorator(socialSecurityNumber, {
              initialValue: initialValues[socialSecurityNumber],
            })(<Input className={PP_FORM_INPUT} disabled />)}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <FormItem label="Home address (number and street or rural route)">
            {getFieldDecorator(homeAddress, {
              initialValue: initialValues[homeAddress],
            })(<Input className={PP_FORM_INPUT} />)}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={8}>
          <FormItem>
            {getFieldDecorator(fields.single, {
              valuePropName: 'checked',
              initialValue: initialValues[single],
            })(
              <Checkbox>
                <span className={Styles.textDefault}>Single</span>
              </Checkbox>
            )}
          </FormItem>
        </Col>
        <Col md={8}>
          <FormItem>
            {getFieldDecorator(fields.married, {
              valuePropName: 'checked',
              initialValue: initialValues[married],
            })(
              <Checkbox>
                <span className={Styles.label}>Married</span>
              </Checkbox>
            )}
          </FormItem>
        </Col>
        <Col md={8}>
          <FormItem>
            {getFieldDecorator(fields.marriedHigherSingleRate, {
              valuePropName: 'checked',
              initialValue: initialValues[marriedHigherSingleRate],
            })(
              <Checkbox>
                <span className={Styles.label}>Married, but withhold at higher Single rate</span>
              </Checkbox>
            )}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <FormItem label="City or town, state, and ZIP code">
            {getFieldDecorator(cityTownStateZipCode, {
              initialValue: initialValues[cityTownStateZipCode],
            })(<Input className={PP_FORM_INPUT} />)}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <FormItem>
            {getFieldDecorator(lastNameDifferSsc, {
              valuePropName: 'checked',
              initialValue: initialValues[lastNameDifferSsc],
            })(
              <Checkbox>
                <span className={classnames(Styles.strong, Styles.label)}>
                  If your last name differs from that shown on your social security card, check
                  here. You must call 800-772-1213 for a replacement card.
                </span>
              </Checkbox>
            )}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <FormItem label="Total number of allowances you’re claiming (from the applicable worksheet on the following pages)">
            {getFieldDecorator(totalAllowances, {
              initialValue: initialValues[totalAllowances],
            })(<InputNumber className={PP_FORM_INPUT} min={0} />)}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <FormItem label="Additional amount, if any, you want withheld from each paycheck">
            {getFieldDecorator(additionalAmount, {
              initialValue: initialValues[additionalAmount],
            })(<InputNumber className={PP_FORM_INPUT} min={0} />)}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <FormItem
            label={
              <span>
                I claim exemption from withholding for 2019, and I certify that I meet{' '}
                <span className={Styles.strong}>both</span> of the following conditions for
                exemption.
                <ul className={Styles.list}>
                  <li>
                    Last year I had a right to a refund of
                    <span className={Styles.strong}>&nbsp;all&nbsp;</span>
                    federal income tax withheld because I had
                    <span className={Styles.strong}>no</span> tax liability,{' '}
                    <span className={Styles.strong}>and</span>
                  </li>
                  <li>
                    This year I expect a refund of
                    <span className={Styles.strong}>&nbsp;all</span> federal income tax withheld
                    because I expect to have
                    <span className={Styles.strong}>&nbsp;no</span> tax liability.
                  </li>
                </ul>
                If you meet both conditions, write “Exempt” here
              </span>
            }
          >
            {getFieldDecorator(exempt, {
              initialValue: initialValues[exempt],
            })(<Input className={PP_FORM_INPUT} />)}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <FormItem
            label={
              <span>
                Employer’s name and address (<span className={Styles.strong}>Employer:&nbsp;</span>
                Complete boxes 8 and 10 if sending to IRS and complete boxes 8, 9, and 10 if sending
                to State Directory of New Hires.)
              </span>
            }
          >
            {getFieldDecorator(employerName, {
              initialValue: initialValues[employerName],
            })(<Input className={PP_FORM_INPUT} />)}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <FormItem label="First date of employment">
            {getFieldDecorator(firstDateEmployment, {
              initialValue: initialValues[firstDateEmployment],
            })(<DatePicker format={DEFAULT_DATE_FORMAT} />)}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <FormItem label="Employer identification number (EIN)">
            {getFieldDecorator(employerIdentificationNumber, {
              initialValue: initialValues[employerIdentificationNumber],
            })(<Input className={PP_FORM_INPUT} />)}
          </FormItem>
        </Col>
      </Row>
      <Divider orientation="left">
        <span className={Styles.strong}>Personal Allowances Worksheet</span>
      </Divider>
      <Row gutter={16}>
        <Col md={24}>
          <FormItem
            label={
              <span>
                <span className={Styles.strong}>A)&nbsp;</span>
                Enter &quot;1&quot; for yourself
              </span>
            }
          >
            {getFieldDecorator(page3a, {
              initialValue: initialValues[page3a],
            })(<Input className={PP_FORM_INPUT} />)}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <FormItem
            label={
              <span>
                <span className={Styles.strong}>B)&nbsp;</span>
                Enter &quot;1&quot; if you will file as married filing jointly
              </span>
            }
          >
            {getFieldDecorator(page3b, {
              initialValue: initialValues[page3b],
            })(<Input className={PP_FORM_INPUT} />)}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <FormItem
            label={
              <span>
                <span className={Styles.strong}>C)&nbsp;</span>
                Enter &quot;1&quot; if you will file with household
              </span>
            }
          >
            {getFieldDecorator(page3c, {
              initialValue: initialValues[page3c],
            })(<Input className={PP_FORM_INPUT} />)}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <FormItem
            label={
              <span>
                <span className={Styles.strong}>D)&nbsp;</span>
                Enter &quot;1&quot; if:
                <ul className={Styles.list}>
                  <li>You’re single, or married filing separately, and have only one job; or</li>
                  <li>
                    You’re married filing jointly, have only one job, and your spouse doesn’t work;
                    or
                  </li>
                  <li>
                    Your wages from a second job or your spouse’s wages (or the total of both) are
                    $1,500 or less.
                  </li>
                </ul>
              </span>
            }
          >
            {getFieldDecorator(page3d, {
              initialValue: initialValues[page3d],
            })(<Input className={PP_FORM_INPUT} />)}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <FormItem
            label={
              <span>
                <span className={Styles.strong}>E)&nbsp;</span>
                <span className={Styles.strong}>Child tax credit.&nbsp;</span>
                See Pub. 972, Child Tax Credit, for more information.
                <ul className={Styles.list}>
                  <li>
                    If your total income will be less than $71,201 ($103,351 if married filing
                    jointly), enter &quot;4&quot; for each eligible child.
                  </li>
                  <li>
                    If your total income will be from $71,201 to $179,050 ($103,351 to $345,850 if
                    married filing jointly), enter &quot;2&quot; for each eligible child.
                  </li>
                  <li>
                    If your total income will be from $179,051 to $200,000 ($345,851 to $400,000 if
                    married filing jointly), enter &quot;1&quot; for each eligible child.
                  </li>
                  <li>
                    If your total income will be higher than $200,000 ($400,000 if married filing
                    jointly), enter &quot;-0-&quot;
                  </li>
                </ul>
              </span>
            }
          >
            {getFieldDecorator(page3e, {
              initialValue: initialValues[page3e],
            })(<Input className={PP_FORM_INPUT} />)}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <FormItem
            label={
              <span>
                <span className={Styles.strong}>F)&nbsp;</span>
                <span className={Styles.strong}>Credit for other dependents.&nbsp;</span>
                See Pub. 972, Child Tax Credit, for more information.
                <ul className={Styles.list}>
                  <li>
                    If your total income will be less than $71,201 ($103,351 if married filing
                    jointly), enter &quot;1&quot; for each eligible dependent.
                  </li>
                  <li>
                    If your total income will be from $71,201 to $179,050 ($103,351 to $345,850 if
                    married filing jointly), enter &quot;1&quot; for every two dependents (for
                    example, &quot;-0-&quot; for one dependent, &quot;1&quot; if you have two or
                    three dependents, and &quot;2&quot; if you have four dependents).
                  </li>
                  <li>
                    If your total income will be higher than $179,050 ($345,850 if married filing
                    jointly), enter &quot;-0-&quot;
                  </li>
                </ul>
              </span>
            }
          >
            {getFieldDecorator(page3f, {
              initialValue: initialValues[page3f],
            })(<Input className={PP_FORM_INPUT} />)}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <FormItem
            label={
              <span>
                <span className={Styles.strong}>G)&nbsp;</span>
                <span className={Styles.strong}>Other credits.&nbsp;</span>
                If you have other credits, see Worksheet 1-6 of Pub. 505 and enter the amount from
                that worksheet here. If you use Worksheet 1-6, enter &quot;-0-&quot; on lines E and
                F
              </span>
            }
          >
            {getFieldDecorator(page3g, {
              initialValue: initialValues[page3g],
            })(<Input className={PP_FORM_INPUT} />)}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <FormItem
            label={
              <span>
                <span className={Styles.strong}>H)&nbsp;</span>
                Add lines A through G and enter the total here
              </span>
            }
          >
            {getFieldDecorator(page3h, {
              initialValue: initialValues[page3h],
            })(<Input className={PP_FORM_INPUT} />)}
          </FormItem>
        </Col>
      </Row>
      <Divider orientation="left">
        <span className={Styles.strong}>
          Deductions, Adjustments, and Additional Income Worksheet
        </span>
      </Divider>
      <Row gutter={16}>
        <Col md={24}>
          <FormItem
            label={
              <span>
                <span className={Styles.strong}>1&nbsp;</span>
                Enter an estimate of your 2019 itemized deductions. These include qualifying home
                mortgage interest, charitable contributions, state and local taxes (up to $10,000),
                and medical expenses in excess of 10% of your income. See Pub. 505 for details
              </span>
            }
          >
            {getFieldDecorator(page31, {
              initialValue: initialValues[page31],
            })(<Input className={PP_FORM_INPUT} />)}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <FormItem
            label={
              <span>
                <span className={Styles.strong}>1&nbsp;</span>
                Enter:
                <ul className={Styles.list}>
                  <li>$24,400 if you’re married filing jointly or qualifying widow(er)</li>
                  <li>$18,350 if you’re head of household</li>
                  <li>$12,200 if you’re single or married filing separately</li>
                </ul>
              </span>
            }
          >
            {getFieldDecorator(page32, {
              initialValue: initialValues[page32],
            })(<Input className={PP_FORM_INPUT} />)}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <FormItem
            label={
              <span>
                <span className={Styles.strong}>3&nbsp;</span>
                <span className={Styles.strong}>Subtract&nbsp;</span>
                line 2 from line 1. If zero or less, enter &quot;-0-&quot;
              </span>
            }
          >
            {getFieldDecorator(page33, {
              initialValue: initialValues[page33],
            })(<Input className={PP_FORM_INPUT} />)}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <FormItem
            label={
              <span>
                <span className={Styles.strong}>4&nbsp;</span>
                Enter an estimate of your 2019 adjustments to income, qualified business income
                deduction, and any additional standard deduction for age or blindness (see Pub. 505
                for information about these items)
              </span>
            }
          >
            {getFieldDecorator(page34, {
              initialValue: initialValues[page34],
            })(<Input className={PP_FORM_INPUT} />)}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <FormItem
            label={
              <span>
                <span className={Styles.strong}>5&nbsp;</span>
                <span className={Styles.strong}>Add&nbsp;</span>
                lines 3 and 4 and enter the total
              </span>
            }
          >
            {getFieldDecorator(page35, {
              initialValue: initialValues[page35],
            })(<Input className={PP_FORM_INPUT} />)}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <FormItem
            label={
              <span>
                <span className={Styles.strong}>6&nbsp;</span>
                Enter an estimate of your 2019 nonwage income not subject to withholding (such as
                dividends or interest)
              </span>
            }
          >
            {getFieldDecorator(page36, {
              initialValue: initialValues[page36],
            })(<Input className={PP_FORM_INPUT} />)}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <FormItem
            label={
              <span>
                <span className={Styles.strong}>7&nbsp;</span>
                <span className={Styles.strong}>Subtract&nbsp;</span>
                line 5 from line 6. If zero, enter &quot;-0-&quot;. If less than zero, enter the
                amount in parentheses
              </span>
            }
          >
            {getFieldDecorator(page37, {
              initialValue: initialValues[page37],
            })(<Input className={PP_FORM_INPUT} />)}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <FormItem
            label={
              <span>
                <span className={Styles.strong}>8&nbsp;</span>
                <span className={Styles.strong}>Divide&nbsp;</span>
                the amount on line 7 by $4,200 and enter the result here. If a negative amount,
                enter in parentheses. Drop any fraction
              </span>
            }
          >
            {getFieldDecorator(page38, {
              initialValue: initialValues[page38],
            })(<Input className={PP_FORM_INPUT} />)}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <FormItem
            label={
              <span>
                <span className={Styles.strong}>9&nbsp;</span>
                Enter the number from the &nbsp;
                <span className={Styles.stronf}>Personal Allowances Worksheet</span>, line H, above
              </span>
            }
          >
            {getFieldDecorator(page39, {
              initialValue: initialValues[page39],
            })(<Input className={PP_FORM_INPUT} />)}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <FormItem
            label={
              <span>
                <span className={Styles.strong}>10&nbsp;</span>
                <span className={Styles.strong}>Add&nbsp;</span>
                lines 8 and 9 and enter the total here. If zero or less, enter &quot;-0-&quot;. If
                you plan to use the
                <span className={Styles.strong}>&nbsp;Two-Earners/Multiple Jobs Worksheet</span>,
                also enter this total on line 1 of that worksheet on page 4. Otherwise,
                <span className={Styles.strong}>&nbsp;stop here&nbsp;</span>
                and enter this total on Form W-4, line 5, page 1
              </span>
            }
          >
            {getFieldDecorator(page310, {
              initialValue: initialValues[page310],
            })(<Input className={PP_FORM_INPUT} />)}
          </FormItem>
        </Col>
      </Row>
      <Divider orientation="left">
        <span className={Styles.strong}>Two-Earners/Multiple Jobs Worksheet</span>
      </Divider>
      <Row gutter={16}>
        <Col md={24}>
          <FormItem
            label={
              <span>
                <span className={Styles.strong}>1&nbsp;</span>
                Enter the number from the
                <span className={Styles.strong}>&nbsp;Personal Allowances Worksheet</span>, line H,
                page 3 (or, if you used the
                <span className={Styles.strong}>
                  &nbsp;Deductions, Adjustments, and Additional Income Worksheet&nbsp;
                </span>
                on page 3, the number from line 10 of that worksheet)
              </span>
            }
          >
            {getFieldDecorator(page41, {
              initialValue: initialValues[page41],
            })(<Input className={PP_FORM_INPUT} />)}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <FormItem
            label={
              <span>
                <span className={Styles.strong}>2&nbsp;</span>
                Find the number in
                <span className={Styles.strong}>&nbsp;Table 1&nbsp;</span>
                below that applies to the
                <span className={Styles.strong}>&nbsp;LOWEST&nbsp;</span>
                paying job and enter it here.
                <span className={Styles.strong}>&nbsp;However</span>, if you’re married filing
                jointly and wages from the highest paying job are $75,000 or less and the combined
                wages for you and your spouse are $107,000 or less, don’t enter more than
                &quot;3&quot;
              </span>
            }
          >
            {getFieldDecorator(page42, {
              initialValue: initialValues[page42],
            })(<Input className={PP_FORM_INPUT} />)}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <FormItem
            label={
              <span>
                <span className={Styles.strong}>3&nbsp;</span>
                If line 1 is
                <span className={Styles.strong}>&nbsp;more than or equal&nbsp;</span>
                to line 2, subtract line 2 from line 1. Enter the result here (if zero, enter
                &quot;-0-&quot;) and on Form W-4, line 5, page 1.
                <span className={Styles.strong}>&nbsp;Do not</span> use the rest of this worksheet
              </span>
            }
          >
            {getFieldDecorator(page43, {
              initialValue: initialValues[page43],
            })(<Input className={PP_FORM_INPUT} />)}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <FormItem
            label={
              <span>
                <span className={Styles.strong}>4&nbsp;</span>
                Enter the number from line 2 of this worksheet
              </span>
            }
          >
            {getFieldDecorator(page44, {
              initialValue: initialValues[page44],
            })(<Input className={PP_FORM_INPUT} />)}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <FormItem
            label={
              <span>
                <span className={Styles.strong}>5&nbsp;</span>
                Enter the number from line 1 of this worksheet
              </span>
            }
          >
            {getFieldDecorator(page45, {
              initialValue: initialValues[page45],
            })(<Input className={PP_FORM_INPUT} />)}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <FormItem
            label={
              <span>
                <span className={Styles.strong}>6&nbsp;</span>
                <span className={Styles.strong}>Subtract&nbsp;</span>
                line 5 from line 4
              </span>
            }
          >
            {getFieldDecorator(page46, {
              initialValue: initialValues[page46],
            })(<Input className={PP_FORM_INPUT} />)}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <FormItem
            label={
              <span>
                <span className={Styles.strong}>7&nbsp;</span>
                Find the amount in
                <span className={Styles.strong}>&nbsp;Table 2&nbsp;</span>
                below that applies to the
                <span className={Styles.strong}>&nbsp;HIGHEST&nbsp;</span>
                paying job and enter it here
              </span>
            }
          >
            {getFieldDecorator(page47, {
              initialValue: initialValues[page47],
            })(<Input className={PP_FORM_INPUT} />)}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <FormItem
            label={
              <span>
                <span className={Styles.strong}>8&nbsp;</span>
                <span className={Styles.strong}>Multiply&nbsp;</span>
                line 7 by line 6 and enter the result here. This is the additional annual
                withholding needed
              </span>
            }
          >
            {getFieldDecorator(page48, {
              initialValue: initialValues[page48],
            })(<Input className={PP_FORM_INPUT} />)}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <FormItem
            label={
              <span>
                <span className={Styles.strong}>9&nbsp;</span>
                <span className={Styles.strong}>Devide&nbsp;</span>
                line 8 by the number of pay periods remaining in 2019. For example, divide by 18 if
                you’re paid every 2 weeks and you complete this form on a date in late April when
                there are 18 pay periods remaining in 2019. Enter the result here and on Form W-4,
                line 6, page 1. This is the additional amount to be withheld from each paycheck
              </span>
            }
          >
            {getFieldDecorator(page49, {
              initialValue: initialValues[page49],
            })(<Input className={PP_FORM_INPUT} />)}
          </FormItem>
        </Col>
      </Row>
    </Form>
  );
}

W4Form.propTypes = {
  form: PropTypes.object.isRequired,
  fields: PropTypes.object,
  initialValues: PropTypes.object,
};

W4Form.defaultProps = {
  fields: {},
  initialValues: {},
};
