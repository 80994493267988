import React, { PureComponent } from "react";
import { Table, Button, TimePicker } from '@/componentsX/UIElements';
import moment from "moment";
import { formatMessage } from 'umi/locale';

import { convertLongWeekDay, disabledHours, disabledMinutes } from '@/utils/utils';
import { WORKING_TIME_PICKER_FORMAT } from '@/constants';

import PeriodPicker from './PeriodPicker';
import messages from './messages';
import Styles from './styles.less';
import { groupByWeekDay } from './service';

class WorkingHoursView extends PureComponent {

  getColumnConfig = () => {
    const {
      newPeriod,
      selectedWeekday,
      handleChangePeriod,
      handleRemovePeriod,
      handleAddNewPeriod,
      handleAddNewHours,
      handleAddNewPeriodFrom,
      handleAddNewPeriodTo
    } = this.props;
    return [
      {
        title: "Weekday",
        dataIndex: "weekDay",
        width: '10%',
        render: (shortDay) => {
          return convertLongWeekDay(shortDay).toUpperCase();
        }
      },
      {
        title: "Working Hours",
        dataIndex: "",
        width: '70%',
        render: (weekData) => {
          return (
            <div className={Styles.container}>
              {weekData.workingHrs.map((period, index) => (
                <PeriodPicker
                  key={period.id}
                  index={index}
                  period={period}
                  onPeriodChanged={editPeriod => handleChangePeriod(weekData.weekDay, index, editPeriod)}
                  onPeriodRemoved={() => handleRemovePeriod(weekData.workingHrs, index)}
                  disabledHours={disabledHours}
                  disabledMinutes={disabledMinutes}
                />
              ))}
            </div>
          );
        }
      },
      {
        title: "Action",
        dataIndex: "",
        width: '20%',
        render: (weekData) => {
          if (weekData.weekDay === selectedWeekday) {
            return (
              <div className={Styles.timePickerContainer}>
                <div className={Styles.children}>
                  <span> {formatMessage(messages.from)} </span>
                  <TimePicker
                    use12Hours
                    format={WORKING_TIME_PICKER_FORMAT}
                    defaultValue={moment(newPeriod.start, WORKING_TIME_PICKER_FORMAT)}
                    suffixIcon={<div />}
                    allowClear={false}
                    onChange={time => handleAddNewPeriodFrom(time)}
                  />
                  <span> {formatMessage(messages.to)} </span>
                  <TimePicker
                    use12Hours
                    format={WORKING_TIME_PICKER_FORMAT}
                    defaultValue={moment(newPeriod.end, WORKING_TIME_PICKER_FORMAT)}
                    suffixIcon={<div />}
                    allowClear={false}
                    onChange={time => handleAddNewPeriodTo(time)}
                  />
                </div>
                <Button type="primary" onClick={handleAddNewPeriod}>
                  {formatMessage(messages.addHours)}
                </Button>
              </div>
            );
          }
          return (
            <Button icon="plus" onClick={() => handleAddNewHours(weekData)}>
              {formatMessage(messages.addNewHours)}
            </Button>
          );
        }
      }
    ];
  }

  render() {
    const { workingTimes } = this.props;
    const dataSource = groupByWeekDay(workingTimes);
    return (
      <Table
        className={Styles.table}
        showHeader={false}
        rowKey="weekDay"
        dataSource={Object.values(dataSource)}
        columns={this.getColumnConfig()}
        pagination={false}
      />
    );
  }
}

export default WorkingHoursView;
