export default {
  'global.messages.common.upload': 'Upload',
  'global.messages.common.checkAll': 'Check All',
  'global.messages.common.assigned': 'Assigned',
  'global.messages.common.notFound': 'Not Found',
  'global.messages.common.yes': 'Yes',
  'global.messages.common.no': 'No',
  'global.messages.common.warning': 'Warning',
  'global.messages.common.cancel': 'Cancel',
  'global.messages.common.update': 'Update',
  'global.messages.common.ok': 'Ok',
  'global.messages.common.save': 'Save',
  'global.messages.common.saveAs': 'Save As',
  'global.messages.common.saveAndNew': 'Save & New',
  'global.messages.common.delete': 'Delete',
  'global.messages.common.edit': 'Edit',
  'global.messages.common.close': 'Close',
  'global.messages.common.select': 'Select',
  'global.messages.common.selected': 'selected',
  'global.messages.common.addMore': 'Add more',
  'global.messages.common.more': 'More',
  'global.messages.common.refresh': 'Refresh',
  'global.messages.common.filter': 'Filter',
  'global.messages.common.customizeColumns': 'Customize Columns',
  'global.messages.common.new': 'New',
  'global.messages.common.on': 'On',
  'global.messages.common.off': 'Off',
  'global.messages.common.todayBtn': 'Today',
  'global.messages.common.year': 'Year',
  'global.messages.common.month': 'Month',
  'global.messages.common.week': 'Week',
  'global.messages.common.day': 'Day',
  'global.messages.common.success': 'Success',
  'global.messages.common.error': 'Error',
  'global.messages.common.done': 'Done',
  'global.messages.common.confirm': 'Confirm',
  'global.messages.common.previous': 'Previous',
  'global.messages.common.next': 'Next',
  'global.messages.common.block': 'Block',
  'global.messages.common.archive': 'Archive',
  'global.messages.common.start': 'Start',
  'global.messages.common.end': 'End',
  'global.messages.common.resetPassword': 'Reset Password',
  'global.messages.user.attendance': 'Attendance',
};
