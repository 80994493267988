import React, { PureComponent } from "react";
import propTypes from 'prop-types';
import { connect } from 'dva';
import { WORKING_TIME_PICKER_FORMAT } from '@/constants';
import WorkingTimeView from './view';
import { NAMESPACE } from '../../constants';

@connect(state => ({
  list: state[NAMESPACE].list,
  loading: state.loading.effects[`${NAMESPACE}/fetch`],
}))
class WorkingTimeContainer extends PureComponent {
  static propTypes = {
    list: propTypes.object.isRequired,
    dispatch: propTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedWeekday: "",
      newPeriod: { start: "08:00 am", end: "12:00 pm" }
    };
  }

  componentDidMount() {
    this.handleReloadPage();
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch({ type: `${NAMESPACE}/clear` });
  }

  handleReloadPage = () => {
    const { dispatch, user } = this.props;
    dispatch({
      type: `${NAMESPACE}/fetchWorkingTimes`,
      payload: { user },
    });
  };

  // edit
  handleChangePeriod = (weekday, index, editPeriod) => {
    const callBack = () => { this.handleReloadPage(); };
    const { dispatch, user } = this.props;
    dispatch({
      type: `${NAMESPACE}/updateWorkingTime`,
      payload: { workingTime: { ...editPeriod, weekday }, user, callBack },
    });
  }

  // delete
  handleRemovePeriod = (workingHrs, index) => {
    const willRemoveHour = workingHrs[index];
    const { id } = willRemoveHour;
    const callBack = () => { this.handleReloadPage(); };
    const { dispatch, user } = this.props;
    dispatch({
      type: `${NAMESPACE}/deleteWorkingTime`,
      payload: { id, user, callBack },
    });
  }

  // create
  handleAddNewPeriod = () => {
    const { selectedWeekday, newPeriod } = this.state;
    const callBack = () => { this.handleReloadPage(); };
    const { dispatch, user } = this.props;
    dispatch({
      type: `${NAMESPACE}/createWorkingTime`,
      payload: { workingTime: { ...newPeriod, selectedWeekday }, user, callBack },
    });
    this.setState({
      selectedWeekday: "",
      newPeriod: { start: "08:00 am", end: "12:00 pm" }
    });
  }

  handleAddNewHours = (weekData) => {
    this.setState({ selectedWeekday: weekData.weekDay })
  };

  handleAddNewPeriodFrom = (time) => {
    const { newPeriod } = this.state;
    this.setState({
      newPeriod: { ...newPeriod, start: time }
    })
  }

  handleAddNewPeriodTo = (time) => {
    const { newPeriod } = this.state;
    this.setState({
      newPeriod: { ...newPeriod, end: time }
    })
  }

  render() {
    const { selectedWeekday, newPeriod } = this.state;
    const { list } = this.props;
    const { workingTimes } = list || {};
    return (
      <WorkingTimeView
        workingTimes={workingTimes}
        selectedWeekday={selectedWeekday}
        newPeriod={newPeriod}
        handleChangePeriod={this.handleChangePeriod}
        handleRemovePeriod={this.handleRemovePeriod}
        handleAddNewPeriod={this.handleAddNewPeriod}
        handleAddNewHours={this.handleAddNewHours}
        handleAddNewPeriodFrom={this.handleAddNewPeriodFrom}
        handleAddNewPeriodTo={this.handleAddNewPeriodTo}
      />
    );
  }
}

export default (WorkingTimeContainer);
