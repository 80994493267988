import { get, groupBy, max, maxBy, isObject } from 'lodash';

import moment from 'moment';

import request from '@/utils/request';
import {
  SORT_ORDER,
  TIME_FORMAT,
  SCHEDULER_TYPES,
  DATE_FORMAT,
  TIME_FORMAT_12H,
  APPOINTMENT_STATUS,
} from '@/constants';
import { trimString, mapSortToBEPost } from '@/utils/utils';
import { toTimezone, fromTimezone, toTimezoneAsMoment } from '@/utils/locale';

import {
  APPOINTMENT_FORM,
  WEEK_DAY_NUMERIC_MAPPING,
  DEFAULT_SERVICE_DURATION,
  DENTIST_COLORS,
} from './constants';

function mapToPostData(object) {
  const data = {};
  Object.keys(object).forEach(key => {
    const value = trimString(object[key]);
    if (key === APPOINTMENT_FORM.service) {
      const services = Array.isArray(value) ? value : [value];
      data[APPOINTMENT_FORM.servicesIds] = services.map(s => s.key);
    } else if (key === APPOINTMENT_FORM.dentist) {
      data[APPOINTMENT_FORM.dentistId] = value.key;
    } else if (key === APPOINTMENT_FORM.patient) {
      data[APPOINTMENT_FORM.patientId] = value.key;
    } else if (key === APPOINTMENT_FORM.schedulerType) {
      data[APPOINTMENT_FORM.schedulerTypeId] = value.key;
    } else {
      data[key] = value;
    }
  });
  return data;
}

export async function createAppointment(appointment) {
  const postData = mapToPostData(appointment);
  return request('/appointments', {
    method: 'POST',
    body: postData,
  });
}

export async function getAppointments(
  start = moment()
    .startOf('day')
    .valueOf(),
  end = moment()
    .endOf('day')
    .valueOf(),
  serviceId = null,
  schedulerTypeId = null,
  dentistId = null,
  patientId = null,
  sort = { order: SORT_ORDER.ascend, field: 'id' }
) {
  return request('/appointments/search', {
    method: 'POST',
    body: {
      start,
      end,
      page_number: 0,
      page_size: 1000, // Assume load 1 page with 1000 appointments, calendar view doesn't have pagination
      treatment_ids: serviceId ? [serviceId] : null,
      scheduler_type_id: schedulerTypeId,
      dentist_id: dentistId,
      patient_id: patientId,
      ...mapSortToBEPost(sort),
    },
  });
}

export async function getBusyAppointments(
  start = moment()
    .startOf('day')
    .valueOf(),
  end = moment()
    .endOf('day')
    .valueOf(),
  dentistId = null,
  patientId = null,
  sort = { order: SORT_ORDER.ascend, field: 'id' }
) {
  if (!patientId || !dentistId) {
    return { data: [] };
  }
  return request(`/appointments/busy/${dentistId}/${patientId}`, {
    method: 'POST',
    body: {
      start,
      end,
      page_number: 0,
      page_size: 1000, // Assume load 1 page with 1000 appointments, calendar view doesn't have pagination
      ...mapSortToBEPost(sort),
    },
  });
}

export async function getLastAppointment(serviceId, schedulerTypeId, patientId) {
  return request(
    `/appointments/last?serviceId=${serviceId}&patientId=${patientId}&schedulerTypeId=${schedulerTypeId}`
  );
}

export async function getDentistWorkingTimes(dentistId) {
  return request(`/dentists/${dentistId}/working-hours`, {
    method: 'GET',
  });
}

export async function getWorkingDentists() {
  return request('/dentists/available', {
    method: 'POST',
    body: {
      working_date: moment(),
    },
  });
}

export function mapToFormData(appointment = {}) {
  const currentAppointment = {};
  Object.keys(APPOINTMENT_FORM).forEach(key => {
    const property = APPOINTMENT_FORM[key];
    const value = get(appointment, property);

    if (key === APPOINTMENT_FORM.start || key === APPOINTMENT_FORM.end) {
      currentAppointment[property] = moment(value);
    } else if (property === APPOINTMENT_FORM.dentist) {
      currentAppointment[property] =
        value && value.id
          ? { key: value.id, label: `${value.firstName} ${value.lastName}`, ...value }
          : value;
    } else if (property === APPOINTMENT_FORM.patient) {
      currentAppointment[property] =
        value && value.id
          ? { key: value.id, label: `${value.firstName} ${value.lastName}`, ...value }
          : value;
    } else if (property === APPOINTMENT_FORM.service) {
      currentAppointment[property] =
        value && value.id ? { key: value.id, label: `${value.name}`, ...value } : value;
    } else if (property === APPOINTMENT_FORM.schedulerType) {
      currentAppointment[property] =
        value && value.id ? { key: value.id, label: `${value.name}`, ...value } : value;
    } else {
      currentAppointment[property] = value;
    }
  });
  return currentAppointment;
}

export function mapAppointmentsToEvents(appointments) {
  const scheduledAppointments = appointments.filter(appointment => {
    const { start, end } = appointment;
    return start && end;
  });

  return scheduledAppointments.map(appointment => {
    const { id, title, start, end, draft, busy, color, status, dentist } = appointment;
    return {
      draft,
      id,
      title,
      start,
      end,
      color,
      status,
      busy,
      resourceId: dentist.id,
      appointmentInfo: appointment,
    };
  });
}

export function calculateDentistColor(dentist) {
  const id = isObject(dentist) ? dentist.id : dentist;
  const dentistIdAsNumber = Number(id);

  return DENTIST_COLORS[dentistIdAsNumber % DENTIST_COLORS.length];
}

export function getDentistColorMapping(appointments) {
  const dentistOccurrences = groupBy(appointments, 'dentist.id');

  return Object.keys(dentistOccurrences).reduce((obj, dentistId) => {
    const { dentist } = dentistOccurrences[dentistId][0];
    // eslint-disable-next-line no-param-reassign
    obj[dentistId] = {
      id: dentistId,
      dentistInfo: {
        ...dentist,
        name: `${dentist.firstName} ${dentist.lastName}`,
      },
      count: dentistOccurrences[dentistId].length,
      color: calculateDentistColor(dentistId),
    };
    return obj;
  }, {});
}

export function buildWorkingTimes(workingTimes = []) {
  return workingTimes.reduce((result, workingTime) => {
    const { dayOfWeek, start, end } = workingTime;
    const dayOfWeekNumeric = WEEK_DAY_NUMERIC_MAPPING[dayOfWeek];

    // eslint-disable-next-line no-param-reassign
    result[dayOfWeekNumeric] = [...(result[dayOfWeekNumeric] || []), { start, end }];
    return result;
  }, {});
}

function convertWorkingTime(date, timeAsString, userTimezone, clinicTimezone) {
  const time = moment(timeAsString, TIME_FORMAT);

  const clinicDate = moment.tz(
    {
      year: date.year(),
      month: date.month(),
      date: date.date(),
      hour: time.hour(),
      minute: time.minute(),
      second: date.second(),
    },
    clinicTimezone
  );

  const userDate = clinicDate.tz(userTimezone);
  return userDate;
}

export function isDateAvailable(date, workingTimes) {
  const dayOfWeek = date.day();
  return !!workingTimes[dayOfWeek];
}

export function isSlotAvailable(slotStart, workingTimes, userTimezone, clinicTimezone) {
  const slotStartAsUserTimezone = fromTimezone(slotStart.toDate(), userTimezone);
  const clinicDate = moment.tz(slotStartAsUserTimezone, clinicTimezone);
  const dayOfWeek = clinicDate.day();

  return workingTimes[dayOfWeek]
    ? workingTimes[dayOfWeek].some(({ start, end }) => {
        const startTime = convertWorkingTime(clinicDate, start, userTimezone, clinicTimezone);
        const endTime = convertWorkingTime(clinicDate, end, userTimezone, clinicTimezone);

        return slotStartAsUserTimezone.isBetween(startTime, endTime, null, '[]');
      })
    : false;
}

export function isSlotAvailableStrictly(
  slotStart,
  slotEnd,
  workingTimes,
  userTimezone,
  clinicTimezone
) {
  return (
    isSlotAvailable(slotStart, workingTimes, userTimezone, clinicTimezone) &&
    isSlotAvailable(slotEnd, workingTimes, userTimezone, clinicTimezone)
  );
}

export function getMaxDateByServices(services) {
  if (!services) return undefined;

  const serviceArr = Array.isArray(services) ? services : [services];
  const schedulerTypes = serviceArr.map(service => service.schedulerType);

  if (schedulerTypes.indexOf('Custom') !== -1) return undefined;

  const maxDurationInMonths = max(
    schedulerTypes.map(schedulerType => SCHEDULER_TYPES[schedulerType])
  );
  return moment()
    .add(maxDurationInMonths, 'months')
    .endOf('day');
}

export function getMaxDurationOfServices(services) {
  if (!services) return DEFAULT_SERVICE_DURATION;

  const serviceArr = Array.isArray(services) ? services : [services];

  return maxBy(serviceArr, 'duration').duration;
}

export function buildTimeString(start, end, dateFormat = DATE_FORMAT, dateInclude = true) {
  const startTime = moment(start);
  const endTime = moment(end);

  const startDate = startTime.date();
  const endDate = endTime.date();

  const arr = [];
  if (dateInclude || startDate !== endDate) {
    arr.push(`${startTime.format(dateFormat)}, `);
  }

  arr.push(startTime.format(TIME_FORMAT_12H));

  if (startDate === endDate) {
    arr.push(` - ${endTime.format(TIME_FORMAT_12H)}`);
  } else {
    arr.push(`${endTime.format(dateFormat)}, ${endTime.format(TIME_FORMAT_12H)}`);
  }
  return arr.join(' ');
}

export function isAppointmentComplete(appointment) {
  const status = get(appointment, 'status');

  return APPOINTMENT_STATUS.COMPLETED === status || APPOINTMENT_STATUS.CANCELED === status;
}

export function isAppointmentNew(appointment) {
  const status = get(appointment, 'status');

  return APPOINTMENT_STATUS.NEW === status;
}

export function isAppointmentSlotValid(start, schedulerType, lastAppointment) {
  let isValid = true;

  // If there is no last appointment
  // OR there is no scheduler type
  // OR scheduler type is Custom
  // then the slot is valid absolutely.
  if (lastAppointment && schedulerType && schedulerType.name !== 'Custom') {
    const { start: lastAppointmentStart } = lastAppointment;
    const duration = moment.duration(start.diff(lastAppointmentStart));
    const asYears = Math.abs(duration.asYears());

    // If last appointment already passed over 1 year
    // then the slot is still valid.
    if (asYears < 1) {
      // Check for duration
      const { period } = schedulerType;
      // Checkpoint is beginning of the next date
      const checkpoint = moment(lastAppointmentStart)
        .add(period, 'months')
        .add(1, 'days')
        .startOf('day');

      // If the slot cannot passed over the checkpoint
      // then it's NOT VALID
      if (start.isBefore(checkpoint)) {
        isValid = false;
      }
    }
  }
  return isValid;
}

export function colorizedAppointment(appointment) {
  return {
    ...appointment,
    color: calculateDentistColor(appointment.dentist),
  };
}

export function colorizedDentist(dentist) {
  return {
    ...dentist,
    color: calculateDentistColor(dentist),
  };
}

function setDefaultBusyTitle(appointment) {
  return {
    ...appointment,
    title: 'Busy',
    busy: true,
  };
}

function localizedAppointment(appointment, userTimezone) {
  const { start, end } = appointment;
  const startTime = toTimezone(start, userTimezone);
  const endTime = toTimezone(end, userTimezone);
  return {
    ...appointment,
    start: startTime,
    end: endTime,
  };
}

export function transformBusyAppointments(appointments, userTimezone) {
  return appointments
    .map(busyAppt => busyAppt.appointment)
    .map(setDefaultBusyTitle)
    .map(appt => localizedAppointment(appt, userTimezone));
}

export function transformAppointments(appointments, userTimezone) {
  return appointments
    .map(colorizedAppointment)
    .map(appt => localizedAppointment(appt, userTimezone));
}

export function transformAppointment(appointment, userTimezone) {
  return appointment ? transformAppointments([appointment], userTimezone)[0] : undefined;
}

export function transformWorkingDentists(dentists) {
  return dentists.map(colorizedDentist);
}

export function isAfterCurrentDate(date, userTimezone) {
  const now = toTimezoneAsMoment(moment(), userTimezone);
  return now.isBefore(date);
}
