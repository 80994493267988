import { isEmpty } from 'lodash';

// use localStorage to store the authority info, which might be sent from server in actual project.
export function getAuthority() {
  const authority = localStorage.getItem('authority');
  if (isEmpty(authority)) {
    return [];
  }

  return authority.split(',');
}

export function setAuthority(authority) {
  return localStorage.setItem('authority', authority);
}

export function getCurrentUser() {
  const name = localStorage.getItem('userName' || '');
  const avatar = localStorage.getItem('userImage' || '');
  const timeZone = localStorage.getItem('timeZone' || '');
  return { name, avatar, timeZone };
}

export function setCurrentUser(currentUser) {
  const defaultAvatar = 'https://gw.alipayobjects.com/zos/rmsportal/BiazfanxmamNRoxxVxka.png';
  localStorage.setItem('userName', currentUser.firstName);
  localStorage.setItem('userImage', currentUser.avatar || defaultAvatar);
  localStorage.setItem('timeZone', currentUser.zoneId || 'UTC');
}
