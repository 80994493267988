import { privateClient } from '@/utils/httpClient';

export default function generateW4FormPDF(staffID, w4Form) {
  return privateClient.post(`/staffs/${staffID}/w4forms/generate/pdf`, w4Form, {
    headers: {
      Accept: 'application/pdf',
    },
    responseType: 'blob',
  });
}
