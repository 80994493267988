import { defineMessages } from 'umi/locale';
import PATH from './constants';

export default defineMessages({
  newService: {
    id: `${PATH}.freeservice.newService`,
    defaultMessage: 'New Free Service',
  },
  updateService: {
    id: `${PATH}.freeservice.updateService`,
    defaultMessage: 'Update Free Service',
  },
  deleteServiceTitle: {
    id: `${PATH}.freeservice.deleteServiceTitle`,
    defaultMessage: 'Delete Free Service',
  },
  deleteServiceContent: {
    id: `${PATH}.freeservice.deleteServiceContent`,
    defaultMessage: 'Are you sure you want to delete this free service?',
  },
});
