import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import { connect } from 'dva';

import { Card, Button, notification } from '@/componentsX/UIElements';
import { withLocaleContext, fromTimezone } from '@/utils/locale';

import { NAMESPACE } from '../../constants';
import Styles from './styles.less';
import AppointmentCreationForm from './AppointmentCreationForm';

@connect(state => ({
  loading: state.loading.effects[`${NAMESPACE}/create`],
}))
class AppointmentCreationCard extends PureComponent {
  static propTypes = {
    event: propTypes.object.isRequired,
    onClose: propTypes.func.isRequired,
    onCreate: propTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const {
      userTimezone,
      event: { appointmentInfo },
    } = props;
    this.state = {
      appointmentInfo: {
        ...appointmentInfo,
        start: fromTimezone(appointmentInfo.start, userTimezone),
        end: fromTimezone(appointmentInfo.end, userTimezone),
      },
    };
  }

  handleCreateAppointment = () => {
    const { dispatch, userTimezone } = this.props;
    const { formRef } = this;

    formRef.getForm().validateFieldsAndScroll((err, values) => {
      if (err) return;

      const { start, end } = values;
      dispatch({
        type: `${NAMESPACE}/create`,
        payload: {
          appointment: {
            ...values,
            start: fromTimezone(start, userTimezone),
            end: fromTimezone(end, userTimezone),
          },
        },
        callback: this.onAppointmentCreated,
      });
    });
  };

  onAppointmentCreated = () => {
    const { onCreate } = this.props;
    notification.success({ message: 'Appointment created successfully!' });
    onCreate();
  };

  handleFormChange = values => {
    this.setState(({ appointmentInfo }) => ({
      appointmentInfo: { ...appointmentInfo, ...values },
    }));
  };

  keepRefForm = form => {
    this.formRef = form;
  };

  render() {
    const { loading, onClose } = this.props;
    const { appointmentInfo } = this.state;

    return (
      <Card bordered={false} className={Styles.wrapper}>
        <AppointmentCreationForm
          wrappedComponentRef={this.keepRefForm}
          appointment={appointmentInfo}
          onChange={this.handleFormChange}
        />
        <div className={Styles.toolbar}>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            type="primary"
            loading={loading}
            className={Styles.rightButton}
            onClick={this.handleCreateAppointment}
          >
            Create
          </Button>
        </div>
      </Card>
    );
  }
}

export default withLocaleContext(AppointmentCreationCard);
