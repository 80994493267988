import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Badge } from '@/componentsX/UIElements';
import Styles from './Footer.less';

class Footer extends PureComponent {
  static propTypes = {
    dentistMapping: PropTypes.object.isRequired,
  };

  renderDentistBadge = dentist => {
    const { color, count, dentistInfo } = dentist;
    const { firstName, lastName, id } = dentistInfo;
    return (
      <div key={id}>
        <Badge className={Styles.badge} count={count} showZero style={{ backgroundColor: color }} />
        <span>{`${firstName} ${lastName}`}</span>
      </div>
    );
  };

  render() {
    const { dentistMapping } = this.props;
    return (
      <div className={Styles.wrapper}>
        {Object.keys(dentistMapping).map(dentistId =>
          this.renderDentistBadge(dentistMapping[dentistId])
        )}
      </div>
    );
  }
}

export default Footer;
