import { defineMessages } from 'umi/locale';
import { NAMESPACE } from './constants';

const messages = defineMessages({
  placeholder: {
    id: `${NAMESPACE}.placeholder`,
    defaultMessage: 'Select dentist',
  },
});

export default {
  ...messages,
};
