import { defineMessages } from 'umi/locale';
import globalMessages from '@/messages';
import formMessages from '@/messages/form';
import dentistMessages from '@/messages/dentist';
import { NAMESPACE } from './constants';

const messages = defineMessages({
  averageTime: {
    id: `${NAMESPACE}.averageTime`,
    defaultMessage: 'Average Time',
  },
  averageTimePlaceholder: {
    id: `${NAMESPACE}.averageTimePlaceholder`,
    defaultMessage: 'Average time to treat a patient (e.g 20 min, 30 min).',
  },
  averageTimeError: {
    id: `${NAMESPACE}.averageTimeError`,
    defaultMessage: 'Average time is required',
  },
  dentistCreated: {
    id: `${NAMESPACE}.dentistCreated`,
    defaultMessage: 'Dentist created successfully!',
  },
  dentistUpdated: {
    id: `${NAMESPACE}.dentistUpdated`,
    defaultMessage: 'Dentist updated successfully!',
  },
});

export default {
  ...messages,
  ...formMessages,
  ...dentistMessages,
  update: globalMessages.common.update,
  saveAndNew: globalMessages.common.saveAndNew,
  save: globalMessages.common.save,
  cancel: globalMessages.common.cancel,
};
