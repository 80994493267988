import { get } from 'lodash';
import request from '@/utils/request';
import { trimString } from '@/utils/utils';
import { FORM } from './constants';


export async function resetPassword(id, password) {
  return request(`/staffs/${id}/reset-password`, {
    method: 'PUT',
    body: {
      new_password: password
    },
  });
}

export function mapToFormData(major = {}) {
  const currentMajor = {};
  Object.keys(FORM).forEach(key => {
    const property = FORM[key];
    currentMajor[property] = get(major, property);
  });
  return currentMajor;
}
