import request from '@/utils/request';

export async function getCurrentUser() {
  return request('/accounts/profile');
}

export async function queryNotices() {
  return request('/api/notices');
}

export async function getTimezones() {
  return request('/timezone');
}

export async function getClinicTimezone() {
  return request('/timezone/clinic');
}
