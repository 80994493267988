import React from 'react';
import {
  Router as DefaultRouter,
  Route,
  Switch,
  StaticRouter,
} from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/lib/renderRoutes';
import history from '@@/history';
import RendererWrapper0 from '/app/src/pages/.umi-production/LocaleWrapper.jsx';
import _dvaDynamic from 'dva/dynamic';

const Router = require('dva/router').routerRedux.ConnectedRouter;

const routes = [
  {
    path: '/user',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__UserLayout" */ '../../layouts/UserLayout'),
          LoadingComponent: require('/app/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/UserLayout').default,
    routes: [
      {
        path: '/user',
        redirect: '/user/login',
        exact: true,
      },
      {
        path: '/user/redirect',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__user__Redirect" */ '../user/Redirect'),
              LoadingComponent: require('/app/src/components/PageLoading/index')
                .default,
            })
          : require('../user/Redirect').default,
        exact: true,
      },
      {
        path: '/user/login',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__user__components__login" */ '../user/components/login'),
              LoadingComponent: require('/app/src/components/PageLoading/index')
                .default,
            })
          : require('../user/components/login').default,
        exact: true,
      },
      {
        path: '/user/forgot-password',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__user__components__forgotPassword" */ '../user/components/forgotPassword'),
              LoadingComponent: require('/app/src/components/PageLoading/index')
                .default,
            })
          : require('../user/components/forgotPassword').default,
        exact: true,
      },
      {
        path: '/user/reset-password',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__user__components__resetPassword" */ '../user/components/resetPassword'),
              LoadingComponent: require('/app/src/components/PageLoading/index')
                .default,
            })
          : require('../user/components/resetPassword').default,
        exact: true,
      },
    ],
  },
  {
    path: '/',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__BasicLayout" */ '../../layouts/BasicLayout'),
          LoadingComponent: require('/app/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/BasicLayout').default,
    Routes: [require('../Authorized').default],
    routes: [
      {
        path: '/',
        redirect: '/workload',
        exact: true,
      },
      {
        path: '/setting/account',
        routes: [
          {
            path: '/setting/account',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__setting__components__account__model.js' */ '/app/src/pages/setting/components/account/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__setting__components__account" */ '../setting/components/account'),
                  LoadingComponent: require('/app/src/components/PageLoading/index')
                    .default,
                })
              : require('../setting/components/account').default,
            exact: true,
          },
        ],
      },
      {
        path: '/dashboard',
        icon: 'dashboard',
        name: 'dashboard',
        routes: [
          {
            path: '/dashboard',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__dashboard__models__appointment-chart.js' */ '/app/src/pages/dashboard/models/appointment-chart.js').then(
                      m => {
                        return { namespace: 'appointment-chart', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__dashboard__models__model.js' */ '/app/src/pages/dashboard/models/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__dashboard__models__statistics.js' */ '/app/src/pages/dashboard/models/statistics.js').then(
                      m => {
                        return { namespace: 'statistics', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__dashboard__models__working-dentists.js' */ '/app/src/pages/dashboard/models/working-dentists.js').then(
                      m => {
                        return { namespace: 'working-dentists', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__dashboard" */ '../dashboard'),
                  LoadingComponent: require('/app/src/components/PageLoading/index')
                    .default,
                })
              : require('../dashboard').default,
            exact: true,
          },
        ],
        authority: ['ALWAYS_HIDE'],
      },
      {
        path: '/appointment',
        icon: 'event',
        name: 'appointment',
        routes: [
          {
            path: '/appointment',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__appointment__models__appointment-list.js' */ '/app/src/pages/appointment/models/appointment-list.js').then(
                      m => {
                        return { namespace: 'appointment-list', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__appointment__models__model.js' */ '/app/src/pages/appointment/models/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__appointment" */ '../appointment'),
                  LoadingComponent: require('/app/src/components/PageLoading/index')
                    .default,
                })
              : require('../appointment').default,
            exact: true,
          },
        ],
        authority: ['ALWAYS_HIDE'],
      },
      {
        path: '/workload',
        exact: true,
        hideInMenu: true,
        icon: 'schedule',
        name: 'workload',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__workload" */ '../workload'),
              LoadingComponent: require('/app/src/components/PageLoading/index')
                .default,
            })
          : require('../workload').default,
      },
      {
        path: '/workload/my-workload',
        name: 'myWorkload',
        icon: 'schedule',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__workload__components__myWorkloads" */ '../workload/components/myWorkloads'),
              LoadingComponent: require('/app/src/components/PageLoading/index')
                .default,
            })
          : require('../workload/components/myWorkloads').default,
        authority: ['ROLE_USER'],
      },
      {
        path: '/workload/staffs-workload-management',
        name: 'staffsWorkloadManagement',
        exact: true,
        icon: 'schedule',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__workload__components__staffWorkloadManagement" */ '../workload/components/staffWorkloadManagement'),
              LoadingComponent: require('/app/src/components/PageLoading/index')
                .default,
            })
          : require('../workload/components/staffWorkloadManagement').default,
        authority: ['ROLE_MANAGER', 'ROLE_ADMIN'],
      },
      {
        path: '/my-report',
        name: 'myReport',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__myReport" */ '../myReport'),
              LoadingComponent: require('/app/src/components/PageLoading/index')
                .default,
            })
          : require('../myReport').default,
        hideInMenu: true,
      },
      {
        path: '/overtime-request',
        redirect: '/overtime-request/my-requests',
        hideInMenu: true,
        exact: true,
      },
      {
        path: '/overtime-request',
        icon: 'clock-circle',
        name: 'overtimeRequest',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__overtimeRequest" */ '../overtimeRequest'),
              LoadingComponent: require('/app/src/components/PageLoading/index')
                .default,
            })
          : require('../overtimeRequest').default,
        routes: [
          {
            path: '/overtime-request/my-requests',
            name: 'myRequests',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__overtimeRequest__components__myRequests" */ '../overtimeRequest/components/myRequests'),
                  LoadingComponent: require('/app/src/components/PageLoading/index')
                    .default,
                })
              : require('../overtimeRequest/components/myRequests').default,
            exact: true,
          },
          {
            path: '/overtime-request/management',
            name: 'management',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__overtimeRequest__components__management" */ '../overtimeRequest/components/management'),
                  LoadingComponent: require('/app/src/components/PageLoading/index')
                    .default,
                })
              : require('../overtimeRequest/components/management').default,
            authority: ['ROLE_MANAGER', 'ROLE_ADMIN'],
            exact: true,
          },
          {
            redirect: '/exception/404',
            exact: true,
          },
        ],
      },
      {
        path: '/leave-request',
        redirect: '/leave-request/my-requests',
        hideInMenu: true,
        exact: true,
      },
      {
        path: '/leave-request',
        icon: 'reconciliation',
        name: 'leaveRequest',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__leaveRequest" */ '../leaveRequest'),
              LoadingComponent: require('/app/src/components/PageLoading/index')
                .default,
            })
          : require('../leaveRequest').default,
        routes: [
          {
            path: '/leave-request/my-requests-summary',
            name: 'myRequestsSummary',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__leaveRequest__components__myRequestsSummary" */ '../leaveRequest/components/myRequestsSummary'),
                  LoadingComponent: require('/app/src/components/PageLoading/index')
                    .default,
                })
              : require('../leaveRequest/components/myRequestsSummary').default,
            exact: true,
          },
          {
            path: '/leave-request/my-requests',
            name: 'myRequests',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__leaveRequest__components__myRequests" */ '../leaveRequest/components/myRequests'),
                  LoadingComponent: require('/app/src/components/PageLoading/index')
                    .default,
                })
              : require('../leaveRequest/components/myRequests').default,
            exact: true,
          },
          {
            path: '/leave-request/management',
            name: 'management',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__leaveRequest__components__management" */ '../leaveRequest/components/management'),
                  LoadingComponent: require('/app/src/components/PageLoading/index')
                    .default,
                })
              : require('../leaveRequest/components/management').default,
            authority: ['ROLE_MANAGER', 'ROLE_ADMIN'],
            exact: true,
          },
          {
            redirect: '/exception/404',
            exact: true,
          },
        ],
      },
      {
        path: '/report',
        redirect: '/report/attendance',
        hideInMenu: true,
        exact: true,
      },
      {
        path: '/report',
        icon: 'bar-chart',
        name: 'report',
        authority: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_PAYROLL'],
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__report" */ '../report'),
              LoadingComponent: require('/app/src/components/PageLoading/index')
                .default,
            })
          : require('../report').default,
        routes: [
          {
            path: '/report/attendance',
            name: 'attendance',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__report__components__attendance" */ '../report/components/attendance'),
                  LoadingComponent: require('/app/src/components/PageLoading/index')
                    .default,
                })
              : require('../report/components/attendance').default,
            authority: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_PAYROLL'],
            exact: true,
          },
          {
            path: '/report/timesheet',
            name: 'timesheet',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__report__components__timesheet" */ '../report/components/timesheet'),
                  LoadingComponent: require('/app/src/components/PageLoading/index')
                    .default,
                })
              : require('../report/components/timesheet').default,
            authority: ['ROLE_ADMIN', 'ROLE_PAYROLL'],
            exact: true,
          },
          {
            redirect: '/exception/404',
            exact: true,
          },
        ],
      },
      {
        path: '/patient-checkin',
        name: 'patientCheckin',
        icon: 'team',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__report__components__patientCheckin" */ '../report/components/patientCheckin'),
              LoadingComponent: require('/app/src/components/PageLoading/index')
                .default,
            })
          : require('../report/components/patientCheckin').default,
        authority: ['ROLE_ADMIN', 'ROLE_PATIENT_CHECKIN'],
        exact: true,
      },
      {
        path: '/patient',
        icon: 'people',
        name: 'patient',
        routes: [
          {
            path: '/patient',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__patient__model.js' */ '/app/src/pages/patient/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__patient" */ '../patient'),
                  LoadingComponent: require('/app/src/components/PageLoading/index')
                    .default,
                })
              : require('../patient').default,
            exact: true,
          },
        ],
        hideInMenu: true,
        authority: ['ALWAYS_HIDDEN'],
      },
      {
        path: '/staffs',
        icon: 'team',
        name: 'staffs',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__staffs" */ '../staffs'),
              LoadingComponent: require('/app/src/components/PageLoading/index')
                .default,
            })
          : require('../staffs').default,
        authority: ['ROLE_MANAGER', 'ROLE_ADMIN', 'ROLE_PAYROLL'],
        exact: true,
      },
      {
        path: '/staffs/:id',
        redirect: '/staffs/:id/license',
        exact: true,
      },
      {
        path: '/staffs/:id',
        name: 'profile',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__staffs__components__details" */ '../staffs/components/details'),
              LoadingComponent: require('/app/src/components/PageLoading/index')
                .default,
            })
          : require('../staffs/components/details').default,
        authority: ['ROLE_MANAGER', 'ROLE_ADMIN', 'ROLE_PAYROLL'],
        hideInMenu: true,
        routes: [
          {
            path: '/staffs/:id/contract',
            name: 'contract',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__staffs__components__details__components__contract" */ '../staffs/components/details/components/contract'),
                  LoadingComponent: require('/app/src/components/PageLoading/index')
                    .default,
                })
              : require('../staffs/components/details/components/contract')
                  .default,
            authority: ['ROLE_ADMIN', 'ROLE_PAYROLL'],
            exact: true,
          },
          {
            path: '/staffs/:id/license',
            name: 'license',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__staffs__components__details__components__license" */ '../staffs/components/details/components/license'),
                  LoadingComponent: require('/app/src/components/PageLoading/index')
                    .default,
                })
              : require('../staffs/components/details/components/license')
                  .default,
            authority: ['ROLE_MANAGER', 'ROLE_ADMIN', 'ROLE_PAYROLL'],
            exact: true,
          },
          {
            path: '/staffs/:id/w4form',
            name: 'w4Form',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__staffs__components__details__components__w4Form" */ '../staffs/components/details/components/w4Form'),
                  LoadingComponent: require('/app/src/components/PageLoading/index')
                    .default,
                })
              : require('../staffs/components/details/components/w4Form')
                  .default,
            authority: ['ROLE_MANAGER', 'ROLE_ADMIN', 'ROLE_PAYROLL'],
            exact: true,
          },
          {
            path: '/staffs/:id/working-hours',
            name: 'workingHours',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__staffs__components__details__components__workingHours" */ '../staffs/components/details/components/workingHours'),
                  LoadingComponent: require('/app/src/components/PageLoading/index')
                    .default,
                })
              : require('../staffs/components/details/components/workingHours')
                  .default,
            authority: ['ROLE_MANAGER', 'ROLE_ADMIN', 'ROLE_PAYROLL'],
            exact: true,
          },
          {
            redirect: '/exception/404',
            exact: true,
          },
        ],
      },
      {
        path: '/clinics',
        icon: 'home',
        name: 'clinics',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__clinics" */ '../clinics'),
              LoadingComponent: require('/app/src/components/PageLoading/index')
                .default,
            })
          : require('../clinics').default,
        authority: ['ROLE_MANAGER', 'ROLE_ADMIN'],
        exact: true,
      },
      {
        path: '/clinics/:id',
        redirect: '/clinics/:id/staffs',
        exact: true,
      },
      {
        path: '/clinics/:id',
        name: 'clinicDetail',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__clinics__components__details" */ '../clinics/components/details'),
              LoadingComponent: require('/app/src/components/PageLoading/index')
                .default,
            })
          : require('../clinics/components/details').default,
        hideInMenu: true,
        authority: ['ROLE_MANAGER', 'ROLE_ADMIN'],
        routes: [
          {
            path: '/clinics/:id/staffs',
            name: 'staffs',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__clinics__components__details__components__staffs" */ '../clinics/components/details/components/staffs'),
                  LoadingComponent: require('/app/src/components/PageLoading/index')
                    .default,
                })
              : require('../clinics/components/details/components/staffs')
                  .default,
            authority: ['ROLE_MANAGER', 'ROLE_ADMIN'],
            exact: true,
          },
          {
            path: '/clinics/:id/working-hours',
            name: 'workingHours',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__clinics__components__details__components__workingHours" */ '../clinics/components/details/components/workingHours'),
                  LoadingComponent: require('/app/src/components/PageLoading/index')
                    .default,
                })
              : require('../clinics/components/details/components/workingHours')
                  .default,
            authority: ['ROLE_MANAGER', 'ROLE_ADMIN'],
            exact: true,
          },
          {
            redirect: '/exception/404',
            exact: true,
          },
        ],
      },
      {
        path: '/admin',
        icon: 'setting',
        name: 'admin',
        hideChildrenInMenu: true,
        routes: [
          {
            path: '/admin',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__admin" */ '../admin'),
                  LoadingComponent: require('/app/src/components/PageLoading/index')
                    .default,
                })
              : require('../admin').default,
            exact: true,
          },
          {
            path: '/admin/roles',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__admin__components__Roles__model.js' */ '/app/src/pages/admin/components/Roles/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__admin__components__Roles" */ '../admin/components/Roles'),
                  LoadingComponent: require('/app/src/components/PageLoading/index')
                    .default,
                })
              : require('../admin/components/Roles').default,
            exact: true,
          },
          {
            path: '/admin/scheduler-types',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__admin__components__SchedulerTypes__model.js' */ '/app/src/pages/admin/components/SchedulerTypes/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__admin__components__SchedulerTypes" */ '../admin/components/SchedulerTypes'),
                  LoadingComponent: require('/app/src/components/PageLoading/index')
                    .default,
                })
              : require('../admin/components/SchedulerTypes').default,
            exact: true,
          },
          {
            path: '/admin/major',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__admin__components__Majors__model.js' */ '/app/src/pages/admin/components/Majors/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__admin__components__Majors" */ '../admin/components/Majors'),
                  LoadingComponent: require('/app/src/components/PageLoading/index')
                    .default,
                })
              : require('../admin/components/Majors').default,
            exact: true,
          },
          {
            path: '/admin/services',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__admin__components__Services__model.js' */ '/app/src/pages/admin/components/Services/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__admin__components__Services" */ '../admin/components/Services'),
                  LoadingComponent: require('/app/src/components/PageLoading/index')
                    .default,
                })
              : require('../admin/components/Services').default,
            exact: true,
          },
          {
            path: '/admin/working-times',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__admin__components__WorkingTimes__model.js' */ '/app/src/pages/admin/components/WorkingTimes/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__admin__components__WorkingTimes" */ '../admin/components/WorkingTimes'),
                  LoadingComponent: require('/app/src/components/PageLoading/index')
                    .default,
                })
              : require('../admin/components/WorkingTimes').default,
            exact: true,
          },
          {
            path: '/admin/free-services',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__admin__components__FreeServices__model.js' */ '/app/src/pages/admin/components/FreeServices/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__admin__components__FreeServices" */ '../admin/components/FreeServices'),
                  LoadingComponent: require('/app/src/components/PageLoading/index')
                    .default,
                })
              : require('../admin/components/FreeServices').default,
            exact: true,
          },
          {
            path: '/admin/setting-template',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__admin__components__EmailTemplate__model.js' */ '/app/src/pages/admin/components/EmailTemplate/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__admin__components__EmailTemplate" */ '../admin/components/EmailTemplate'),
                  LoadingComponent: require('/app/src/components/PageLoading/index')
                    .default,
                })
              : require('../admin/components/EmailTemplate').default,
            exact: true,
          },
          {
            path: '/admin/policy',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__admin__components__Policy__model.js' */ '/app/src/pages/admin/components/Policy/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__admin__components__Policy" */ '../admin/components/Policy'),
                  LoadingComponent: require('/app/src/components/PageLoading/index')
                    .default,
                })
              : require('../admin/components/Policy').default,
            exact: true,
          },
          {
            path: '/admin/properties',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__admin__components__Properties__model.js' */ '/app/src/pages/admin/components/Properties/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__admin__components__Properties" */ '../admin/components/Properties'),
                  LoadingComponent: require('/app/src/components/PageLoading/index')
                    .default,
                })
              : require('../admin/components/Properties').default,
            exact: true,
          },
          {
            path: '/admin/patient-checkin-form',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__admin__components__PatientCheckinForm__model.js' */ '/app/src/pages/admin/components/PatientCheckinForm/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__admin__components__PatientCheckinForm" */ '../admin/components/PatientCheckinForm'),
                  LoadingComponent: require('/app/src/components/PageLoading/index')
                    .default,
                })
              : require('../admin/components/PatientCheckinForm').default,
            exact: true,
          },
        ],
        authority: ['ROLE_ADMIN'],
      },
      {
        path: '/exception/403',
        name: 'not-permission',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__exception__403" */ '../exception/403'),
              LoadingComponent: require('/app/src/components/PageLoading/index')
                .default,
            })
          : require('../exception/403').default,
        hideInMenu: true,
        exact: true,
      },
      {
        path: '/exception/404',
        name: 'not-find',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__exception__404" */ '../exception/404'),
              LoadingComponent: require('/app/src/components/PageLoading/index')
                .default,
            })
          : require('../exception/404').default,
        hideInMenu: true,
        exact: true,
      },
      {
        path: '/exception/500',
        name: 'server-error',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__exception__500" */ '../exception/500'),
              LoadingComponent: require('/app/src/components/PageLoading/index')
                .default,
            })
          : require('../exception/500').default,
        hideInMenu: true,
        exact: true,
      },
      {
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__exception__404" */ '../exception/404'),
              LoadingComponent: require('/app/src/components/PageLoading/index')
                .default,
            })
          : require('../exception/404').default,
        exact: true,
      },
    ],
  },
];
window.g_routes = routes;
const plugins = require('umi/_runtimePlugin');
plugins.applyForEach('patchRoutes', { initialValue: routes });

export { routes };

export default class RouterWrapper extends React.Component {
  unListen() {}

  constructor(props) {
    super(props);

    // route change handler
    function routeChangeHandler(location, action) {
      plugins.applyForEach('onRouteChange', {
        initialValue: {
          routes,
          location,
          action,
        },
      });
    }
    this.unListen = history.listen(routeChangeHandler);
    // dva 中 history.listen 会初始执行一次
    // 这里排除掉 dva 的场景，可以避免 onRouteChange 在启用 dva 后的初始加载时被多执行一次
    const isDva =
      history.listen
        .toString()
        .indexOf('callback(history.location, history.action)') > -1;
    if (!isDva) {
      routeChangeHandler(history.location);
    }
  }

  componentWillUnmount() {
    this.unListen();
  }

  render() {
    const props = this.props || {};
    return (
      <RendererWrapper0>
        <Router history={history}>{renderRoutes(routes, props)}</Router>
      </RendererWrapper0>
    );
  }
}
