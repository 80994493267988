import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'dva';
import { formatMessage } from 'umi/locale';
import { SUBMIT_BUTTON } from '@/constants';
import { Modal, Tabs, notification } from '@/componentsX/UIElements';
import DentistInfoModalView from './components/DentistInfo/view';
import WorkingHoursView from './components/WorkingTimes/index';
import { NAMESPACE } from './constants';
import messages from './messages';

const { TabPane } = Tabs;

@connect(state => ({
  genders: state[NAMESPACE].genders,
  isSuccess: state[NAMESPACE].isSuccess,
  loading:
    state.loading.effects[`${NAMESPACE}/create`] || state.loading.effects[`${NAMESPACE}/update`],
}))
class DentistModalContainer extends PureComponent {
  static propTypes = {
    dentist: PropTypes.object,
    isOpen: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    isSuccess: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
    genders: PropTypes.array,
  };

  static defaultProps = {
    dentist: {},
    loading: false,
    genders: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      submitButton: '',
      dentistInfo: props.dentist,
    };
  }

  componentDidMount() {
    this.fetchInputData();
  }

  componentDidUpdate(prevProps) {
    const { isOpen, isSuccess, dispatch } = this.props;
    const { submitButton } = this.state;

    if (isOpen && !prevProps.isOpen) {
      dispatch({ type: `${NAMESPACE}/clear` });
      this.fetchInputData();
    }

    if (isOpen && isSuccess) {
      if (submitButton === SUBMIT_BUTTON.save || submitButton === SUBMIT_BUTTON.update) {
        this.handleCancel();
      } else if (submitButton === SUBMIT_BUTTON.saveAndNew) {
        this.handleClearData();
        this.fetchInputData();
      }
    }
  }

  fetchInputData = () => {
    const { dispatch } = this.props;
    dispatch({
      type: `${NAMESPACE}/initData`,
    });
  };

  handleClearData = () => {
    const { dispatch } = this.props;
    this.setState({
      dentistInfo: {},
    });
    dispatch({ type: `${NAMESPACE}/clear` });
  };

  handleCancel = () => {
    const { handleCancel } = this.props;
    handleCancel();
    this.handleClearData();
  };

  handleCallback = () => {
    const { callback } = this.props;
    this.handleClearData();
    callback();
  };

  handleSave = form => {
    const { dispatch } = this.props;

    form.validateFieldsAndScroll((err, values) => {
      if (err) return;
      this.setState({ submitButton: SUBMIT_BUTTON.save, dentistInfo: { ...values } }, () => {
        dispatch({
          type: `${NAMESPACE}/create`,
          payload: {
            dentist: { ...values },
            callback: () => {
              notification.success({ message: formatMessage(messages.dentistCreated) });
              this.handleCallback();
            },
          },
        });
      });
    });
  };

  handleSaveAndNew = form => {
    const { dispatch } = this.props;

    form.validateFieldsAndScroll((err, values) => {
      if (err) return;
      this.setState({ submitButton: SUBMIT_BUTTON.saveAndNew, dentistInfo: { ...values } }, () => {
        dispatch({
          type: `${NAMESPACE}/create`,
          payload: {
            dentist: { ...values },
            callback: () => {
              notification.success({ message: formatMessage(messages.dentistCreated) });
              this.handleCallback();
            },
          },
        });
      });
    });
  };

  handleUpdate = form => {
    const { dispatch } = this.props;

    form.validateFieldsAndScroll((err, values) => {
      if (err) return;
      const {
        dentist: { id },
      } = this.props;
      this.setState({ submitButton: SUBMIT_BUTTON.update, dentistInfo: { ...values } }, () => {
        dispatch({
          type: `${NAMESPACE}/update`,
          payload: {
            id,
            dentist: { ...values },
            callback: () => {
              notification.success({ message: formatMessage(messages.dentistUpdated) });
              this.handleCallback();
            },
          },
        });
      });
    });
  };

  render() {
    const { dentist, isOpen, isEdit, genders, loading } = this.props;
    const { submitButton, dentistInfo } = this.state;
    return (
      <Modal
        centered
        visible={isOpen}
        onCancel={this.handleCancel}
        destroyOnClose
        maskClosable={false}
        footer=""
        width="1000px"
      >
        <Tabs defaultActiveKey="1" onChange={this.handleTabsChange}>
          <TabPane tab={formatMessage(messages.dentistInfo)} key="1">
            <DentistInfoModalView
              dentist={dentistInfo}
              genders={genders}
              isOpen={isOpen}
              isEdit={isEdit}
              loading={loading}
              submitButton={submitButton}
              handleSave={this.handleSave}
              handleSaveAndNew={this.handleSaveAndNew}
              handleUpdate={this.handleUpdate}
              handleCancel={this.handleCancel}
            />
          </TabPane>
          {isEdit ? (
            <TabPane tab={formatMessage(messages.workingHours)} key="2">
              <WorkingHoursView dentist={dentist} />
            </TabPane>
          ) : (
            ''
          )}
        </Tabs>
      </Modal>
    );
  }
}

export default DentistModalContainer;

export { default as model } from './model';
export { NAMESPACE, DENTIST_FORM } from './constants';
