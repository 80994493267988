import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { debounce, isPlainObject } from 'lodash';
import { connect } from 'dva';
import { Select } from '@/componentsX/UIElements';
import DentistFilterView from './view';
import { NAMESPACE, DEBOUNCE_TIME } from './constants';

const { LabeledValue } = Select;

@connect(state => ({
  dentists: state[NAMESPACE].dentists,
  loading: state.loading.effects[`${NAMESPACE}/fetch`],
}))
class DentistFilterContainer extends PureComponent {
  static propTypes = {
    dentists: PropTypes.array.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.shape(LabeledValue),
      PropTypes.arrayOf(PropTypes.shape(LabeledValue)),
    ]),
    onChange: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    service: PropTypes.object,
    className: PropTypes.string,
    size: PropTypes.string,
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    service: undefined,
    loading: false,
    value: undefined,
    className: undefined,
    size: 'default',
    placeholder: undefined,
  };

  // memoization here
  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.value) {
      return {
        selectedDentist: props.value,
        value: props.value,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.fetchDentistsDebounce = debounce(this.fetchDentists, DEBOUNCE_TIME);
    this.state = {
      selectedDentist: props.value || undefined,
      value: props.value || undefined,
    };
  }

  componentDidMount() {
    this.fetchDentists();
  }

  componentDidUpdate(prevProps) {
    const { service } = this.props;
    if (service !== prevProps.service) {
      this.fetchDentists();
    }
  }

  fetchDentists = () => {
    const { dispatch, service } = this.props;
    const { search } = this;
    const majors = service ? [service.requiredMajor.id] : undefined;

    dispatch({
      type: `${NAMESPACE}/fetch`,
      payload: {
        search,
        majors,
      },
    });
  };

  handleValueChange = value => {
    const { onChange, dentists } = this.props;

    this.setState({
      selectedDentist: value,
    });

    let conductedValue;

    if (Array.isArray(value)) {
      conductedValue = value.map(v => {
        const dentist = dentists.find(p => p.id === Number(v.key));
        return {
          ...v,
          ...dentist,
        };
      });
    } else if (isPlainObject(value)) {
      const dentist = dentists.find(p => p.id === Number(value.key));

      conductedValue = {
        ...value,
        ...dentist,
      };
    }

    onChange(conductedValue);
  };

  handleSearch = search => {
    this.search = search;
    this.fetchDentistsDebounce();
  };

  render() {
    const { dentists, loading, className, placeholder, size, ...restProps } = this.props;
    const { selectedDentist } = this.state;
    return (
      <DentistFilterView
        {...restProps}
        dentists={dentists}
        selectedDentist={selectedDentist}
        loading={loading}
        size={size}
        onSearch={this.handleSearch}
        onChange={this.handleValueChange}
        className={className}
        placeholder={placeholder}
      />
    );
  }
}

export default DentistFilterContainer;

export { default as model } from './model';
export { NAMESPACE } from './constants';
