import { compose } from 'redux';
import formatLastName from './formatLastName';
import formatFirstName from './formatFirstName';
import formatSocialSecurityNumber from './formatSocialSecurityNumber';
import formatHomeAddress from './formatHomeAddress';
import formatSingle from './formatSingle';
import formatMarried from './formatMarried';
import formatMarriedHigherSingleRate from './formatMarriedHigherSingleRate';
import formatCityTownStateZipCode from './formatCityTownStateZipCode';
import formatLastNameDifferSsc from './formatLastNameDifferSsc';
import formatTotalAllowances from './formatTotalAllowances';
import formatAdditionalAmount from './formatAdditionalAmount';
import formatEmployerName from './formatEmployerName';
import formatFirstDateEmployment from './formatFirstDateEmployment';
import formatEmployerIdentificationNumber from './formatEmployerIdentificationNumber';
import formatPage from './formatPage';

export default function formatW4Form(w4Form) {
  const formatter = compose(
    formatPage('page_3A'),
    formatPage('page_3B'),
    formatPage('page_3C'),
    formatPage('page_3D'),
    formatPage('page_3E'),
    formatPage('page_3F'),
    formatPage('page_3G'),
    formatPage('page_3H'),
    formatEmployerIdentificationNumber,
    formatFirstDateEmployment,
    formatEmployerName,
    formatAdditionalAmount,
    formatTotalAllowances,
    formatLastNameDifferSsc,
    formatCityTownStateZipCode,
    formatMarriedHigherSingleRate,
    formatMarried,
    formatSingle,
    formatHomeAddress,
    formatSocialSecurityNumber,
    formatLastName,
    formatFirstName
  );
  const formattedW4Form = formatter(w4Form);
  return formattedW4Form;
}
