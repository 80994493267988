import request from '@/utils/request';
import { FORM, SHOULD_PAY } from './constants';
import { trimString } from '@/utils/utils';

export function mapToPostData(object) {
  const data = {};
  Object.keys(object).forEach(key => {
    const value = trimString(object[key]);
    if (key === FORM.shouldPay) {
      let vl = value;
      if (value === 'YES') {
        vl = true;
      } else {
        vl = false;
      }
      data[key] = vl;
    } else {
      data[key] = value;
    }
  });
  return data;
}

export async function createPolicy(policy) {
  const postData = mapToPostData(policy);
  return request('/policies', {
    method: 'POST',
    body: postData,
  });
}

export async function updatePolicy(id, policy) {
  const putData = mapToPostData(policy);
  return request(`/policies/${id}`, {
    method: 'PUT',
    body: putData,
  });
}

export function mapToFormData(policy = {}) {
  const currentService = {};
  Object.keys(FORM).forEach(key => {
    const property = FORM[key];
    const value = policy[key] || policy[property];
    if (property === FORM.shouldPay) {
      let shouldPay = value;
      if (value) {
        shouldPay = 'YES'
      } else {
        shouldPay = 'NO';
      }
      currentService[property] = shouldPay;
    } else {
      currentService[property] = value;
    }
  });
  return currentService;
}
