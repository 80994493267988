import { curry } from 'ramda';
import { NA } from '@/constants';
import renderTextColumn from './renderTextColumn';
import isNullOrEmpty from './isNullOrEmpty';

const renderColumnWithNA = renderTextColumn(NA);

const renderDateTimeColumn = curry((format, value) => {
  if (isNullOrEmpty(value)) {
    return renderColumnWithNA(value);
  }
  const formatted = value.format(format);
  return renderColumnWithNA(formatted);
});

export default renderDateTimeColumn;
