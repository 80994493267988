import { defineMessages } from 'umi/locale';
import globalMessages from '@/messages';
import formMessages from '@/messages/form';
import roleMessages from '@/messages/role';
import { NAMESPACE } from './constants'

const messages = defineMessages({
  labelRoleName: {
    id: `${NAMESPACE}.labelRoleName`,
    defaultMessage: 'Role Name',
  },
  roleNameRequired: {
    id: `${NAMESPACE}.roleNameRequired`,
    defaultMessage: 'This field is required',
  },
  roleNameMax: {
    id: `${NAMESPACE}.roleNameMax`,
    defaultMessage: 'Allowed is maximum text length of 50',
  },
  roleNameWhitespace: {
    id: `${NAMESPACE}.roleNameWhitespace`,
    defaultMessage: 'This field must not contain whitespace',
  },
  labelAssignedTo: {
    id: `${NAMESPACE}.labelAssignedTo`,
    defaultMessage: 'Assigned To',
  },
  labelDescription: {
    id: `${NAMESPACE}.labelDescription`,
    defaultMessage: 'Description',
  },
  labelFeatures: {
    id: `${NAMESPACE}.labelFeatures`,
    defaultMessage: 'Features',
  },
  labelAccess: {
    id: `${NAMESPACE}.labelAccess`,
    defaultMessage: 'Access',
  },
  labelFeatureNone: {
    id: `${NAMESPACE}.labelFeatureNone`,
    defaultMessage: 'None',
  },
  labelFeatureFull: {
    id: `${NAMESPACE}.labelFeatureFull`,
    defaultMessage: 'Full',
  },
  labelFeatureSome: {
    id: `${NAMESPACE}.labelFeatureSome`,
    defaultMessage: 'Some',
  },
  labelFeaturesRequired: {
    id: `${NAMESPACE}.labelFeaturesRequired`,
    defaultMessage: 'Please select at least one role!',
  },
});

export default {
  ...formMessages,
  ...roleMessages,
  ...messages,
  update: globalMessages.common.update,
  saveAndNew: globalMessages.common.saveAndNew,
  save: globalMessages.common.save,
  cancel: globalMessages.common.cancel,
};
