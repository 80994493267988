import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import { Popover } from '@/componentsX/UIElements';

import AppointmentCard from './AppointmentCard';
import AppointmentCreationCard from './AppointmentCreationCard';
import Styles from './AppointmentEventWrapper.less';

class AppointmentEventWrapper extends PureComponent {
  static propTypes = {
    event: propTypes.object.isRequired,
    highlighted: propTypes.bool.isRequired,
    onEditAppointment: propTypes.func,
    onDeleteAppointment: propTypes.func,
    onCreateAppointment: propTypes.func,
    onAppointmentPopoverClose: propTypes.func,
  };

  static defaultProps = {
    onEditAppointment: () => null,
    onDeleteAppointment: () => null,
    onCreateAppointment: () => null,
    onAppointmentPopoverClose: () => null,
  };

  constructor(props) {
    super(props);

    const {
      event: { draft },
    } = props;

    this.state = {
      visible: draft || false,
    };
  }

  getPopoverContent = () => {
    const { event, allowEdit } = this.props;
    const { draft } = event;

    return draft ? (
      <AppointmentCreationCard
        event={event}
        onClose={this.handleClose}
        onCreate={this.handleCreate}
      />
    ) : (
      <AppointmentCard
        allowEdit={allowEdit}
        event={event}
        onClose={this.handleClose}
        onEditAppointment={this.handleEdit}
        onDeleteAppointment={this.handleDelete}
        onMarkAsComplete={this.handleMarkAsComplete}
        onCancelAppointment={this.handleCancelAppointment}
      />
    );
  };

  handleVisibleChange = visible => {
    this.setState({ visible });

    const { onAppointmentPopoverClose } = this.props;
    if (!visible) {
      onAppointmentPopoverClose();
    }
  };

  handleClose = () => {
    this.handleVisibleChange(false);
  };

  handleCreate = () => {
    const { onCreateAppointment } = this.props;
    this.setState({ visible: false });
    onCreateAppointment();
  };

  handleEdit = () => {
    const { onEditAppointment, event } = this.props;
    this.handleClose();
    onEditAppointment(event);
  };

  handleDelete = () => {
    const { onDeleteAppointment, event } = this.props;
    this.handleClose();
    onDeleteAppointment(event);
  };

  handleMarkAsComplete = () => {
    const { onCompleteAppointment, event } = this.props;
    this.handleClose();
    onCompleteAppointment(event);
  };

  handleCancelAppointment = () => {
    const { onCancelAppointment, event } = this.props;
    this.handleClose();
    onCancelAppointment(event);
  };

  render() {
    const { children, event, highlighted } = this.props;
    const { visible } = this.state;
    const { draft, busy } = event;
    const shouldRenderEvent = (draft && visible) || !draft;
    const shouldRenderAsPopover = !busy;

    const wrappedCls = classNames(Styles.appointmentWrapper, {
      [Styles.highlight]: highlighted,
    });

    return (
      <div className={wrappedCls}>
        {shouldRenderAsPopover ? (
          <Popover
            visible={visible}
            trigger="click"
            content={this.getPopoverContent()}
            placement="left"
            onVisibleChange={this.handleVisibleChange}
          >
            {shouldRenderEvent && children}
          </Popover>
        ) : (
          children
        )}
      </div>
    );
  }
}

export default AppointmentEventWrapper;
