import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import { formatMessage } from 'umi/locale';
import moment from 'moment';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { isNil, isEmpty } from 'lodash';
import { getAvatarURL } from '@/utils/image';
import { generateRandomPassword } from '@/utils/utils';
import { PP_FORM_INPUT, SUBMIT_BUTTON, DEFAULT_AVATAR, SERVER_DATE_FORMAT } from '@/constants';
import { Button, Form, Row, Col, Input, DatePicker, Checkbox } from '@/componentsX/UIElements';
import PatientQRCode from '@/componentsX/_share/PatientQRCode';
import UploadAvatar from '@/componentsX/UploadAvatar';
import PhoneInput from '@/componentsX/PhoneInput';
import { mapToFormData } from '../../service';
import messages from '../../messages';
import { FORM } from '../../constants';
import Styles from './styles.less';

const { Item: FormItem, createFormField } = Form;
const { Password } = Input;

@Form.create({
  mapPropsToFields(props) {
    const { isEdit } = props;
    const user = mapToFormData(props.user);
    return Object.keys(user).reduce((acc, fieldName) => {
      if (isEdit) {
        acc[fieldName] = createFormField({ value: user[fieldName] });
        return acc;
      }
      const value = user[fieldName];
      if (FORM.password === fieldName && (isNil(value) || isEmpty(value))) {
        acc[fieldName] = createFormField({ value: generateRandomPassword() });
        return acc;
      }
      acc[fieldName] = createFormField({ value: user[fieldName] });
      return acc;
    }, {});
  },
})
class UserInfoView extends PureComponent {
  static propTypes = {
    user: propTypes.object.isRequired,
    isEdit: propTypes.bool.isRequired,
    loading: propTypes.bool.isRequired,
    initDataLoading: propTypes.bool.isRequired,
    submitButton: propTypes.string.isRequired,
    handleSave: propTypes.func.isRequired,
    handleCancel: propTypes.func.isRequired,
    handleSaveAndNew: propTypes.func.isRequired,
    handleUpdate: propTypes.func.isRequired,
  };

  state = {
    autoGeneratePassword: true,
  };

  componentDidUpdate() {
    const { form } = this.props;
    if (isEmpty(form.getFieldValue(FORM.avatar)) && this.avatarUpload) {
      this.avatarUpload.clearImage();
    }
  }

  handleCallback = callBack => {
    const { form } = this.props;
    const { isGeneratePassword } = this.state;
    // console.log(values)
    callBack(form, isGeneratePassword);
  };

  renderFooter = () => {
    const {
      isEdit,
      handleSave,
      handleSaveAndNew,
      handleUpdate,
      loading: loadingData,
      initDataLoading,
      submitButton,
      handleCancel,
    } = this.props;
    const loading = initDataLoading || loadingData;
    const handleSubmit = values => this.handleCallback(handleSave, values);
    const handleSubmitAndNew = values => this.handleCallback(handleSaveAndNew, values);
    const handleSubmitUpdate = values => this.handleCallback(handleUpdate, values);
    const submitButtons = isEdit
      ? [
        <Button
          key="submit"
          type="primary"
          loading={loading && submitButton === SUBMIT_BUTTON.update}
          onClick={handleSubmitUpdate}
        >
          {formatMessage(messages.update)}
        </Button>,
      ]
    : [
      <Button
        key="submitAndNew"
        loading={loading && submitButton === SUBMIT_BUTTON.saveAndNew}
        onClick={handleSubmitAndNew}
      >
        {formatMessage(messages.saveAndNew)}
      </Button>,
      <Button
        key="submit"
        type="primary"
        loading={loading && submitButton === SUBMIT_BUTTON.save}
        onClick={handleSubmit}
      >
        {formatMessage(messages.save)}
      </Button>,
    ];
    return [
      <Button key="cancel" onClick={handleCancel}>
        {formatMessage(messages.cancel)}
      </Button>,
      ...submitButtons,
    ];
  };

  disabledDob = date => {
    return moment(date).isAfter(moment());
  };

  validatePhoneInput = (rule, value, callback) => {
    if (value && !isValidPhoneNumber(value)) {
      callback(formatMessage(messages.invalidPhoneNumber));
    }
    callback();
  };

  handleUploadAvatar = file => {
    const { form } = this.props;
    form.setFieldsValue({ [FORM.avatar]: file });
  };

  handleAutoGeneratePasswordChange = event => {
    const {
      target: { checked: autoGeneratePassword },
    } = event;
    const {
      form: { setFieldsValue },
    } = this.props;
    this.setState({ autoGeneratePassword }, () => {
      if (!autoGeneratePassword) {
        setFieldsValue({
          [FORM.password]: '',
        });
        return;
      }
      setFieldsValue({
        [FORM.password]: generateRandomPassword(),
      });
    });
  };

  render() {
    const { user, form, isEdit } = this.props;
    const { autoGeneratePassword } = this.state;
    const { getFieldDecorator } = form;
    const { avatar, code } = user;
    const avatarSrc = user && !isEmpty(avatar) ? getAvatarURL(avatar) : '';
    const shouldClearAvatar = isEmpty(user);
    const passwordErrorMessage = 'Password is required';
    return (
      <Form layout="vertical" className={Styles.userResource}>
        <Row gutter={16}>
          <Col sm={24} md={4}>
            <FormItem>
              {getFieldDecorator(FORM.avatar, {
                rules: [
                  {
                    required: true,
                    message: formatMessage(messages.avatarRequired),
                  },
                ],
              })(
                <UploadAvatar
                  ref={avatarUpload => {
                    this.avatarUpload = avatarUpload;
                  }}
                  shouldClear={shouldClearAvatar}
                  image={avatarSrc}
                  handleUploadFile={this.handleUploadAvatar}
                  defaultIcon="person"
                  fallbackImage={DEFAULT_AVATAR}
                />
              )}
            </FormItem>
          </Col>
          <Col sm={24} md={8}>
            <FormItem label={formatMessage(messages.firstName)} colon={false}>
              {getFieldDecorator(FORM.firstName, {
                rules: [
                  {
                    required: true,
                    message: formatMessage(messages.firstNameError),
                    whitespace: true,
                  },
                ],
              })(
                <Input
                  className={PP_FORM_INPUT}
                  placeholder={formatMessage(messages.firstNamePlaceholder)}
                />
              )}
            </FormItem>
          </Col>
          <Col sm={24} md={8}>
            <FormItem label={formatMessage(messages.lastName)} colon={false}>
              {getFieldDecorator(FORM.lastName, {
                rules: [
                  {
                    required: true,
                    message: formatMessage(messages.lastNameError),
                    whitespace: true,
                  },
                ],
              })(
                <Input
                  className={PP_FORM_INPUT}
                  placeholder={formatMessage(messages.lastNamePlaceholder)}
                />
              )}
            </FormItem>
          </Col>
          <Col sm={24} md={4}>
            {isEdit && (
              <FormItem>
                <PatientQRCode code={code} size={60} />
              </FormItem>
            )}
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={24} md={12}>
            <FormItem label={formatMessage(messages.email)} colon={false}>
              {getFieldDecorator(FORM.email, {
                rules: [
                  {
                    type: 'email',
                    message: formatMessage(messages.emailInvalidError),
                  },
                  {
                    required: true,
                    message: formatMessage(messages.emailEmptyError),
                  },
                ],
              })(
                <Input
                  disabled={isEdit}
                  className={PP_FORM_INPUT}
                  placeholder={formatMessage(messages.emailPlaceholder)}
                />
              )}
            </FormItem>
          </Col>
          {!isEdit && (
            <Col sm={24} md={12}>
              <FormItem label={formatMessage(messages.password)} colon={false}>
                {getFieldDecorator(FORM.password, {
                  initialValue: generateRandomPassword(),
                  rules: [
                    {
                      required: !autoGeneratePassword,
                      message: passwordErrorMessage,
                    },
                    {
                      whitespace: !autoGeneratePassword,
                      message: passwordErrorMessage,
                    },
                  ],
                })(<Password disabled={autoGeneratePassword} className={PP_FORM_INPUT} />)}
              </FormItem>
            </Col>
          )}
        </Row>
        {!isEdit && (
          <Row gutter={16}>
            <Col md={{ span: 12, offset: 12 }}>
              <FormItem>
                <Checkbox
                  onChange={this.handleAutoGeneratePasswordChange}
                  checked={autoGeneratePassword}
                >
                  {formatMessage(messages.checkboxPassword)}
                </Checkbox>
              </FormItem>
            </Col>
          </Row>
        )}
        <Row gutter={16}>
          <Col sm={24} md={12}>
            <FormItem label={formatMessage(messages.dob)} colon={false}>
              {getFieldDecorator(FORM.dob, {
                rules: [
                  {
                    required: true,
                    message: formatMessage(messages.dobError),
                    type: 'object',
                  },
                ],
              })(
                <DatePicker
                  className={PP_FORM_INPUT}
                  format={SERVER_DATE_FORMAT}
                  placeholder={formatMessage(messages.dobPlaceholder)}
                  disabledDate={this.disabledDob}
                />
              )}
            </FormItem>
          </Col>
          <Col sm={24} md={12}>
            <FormItem label={formatMessage(messages.phone)} colon={false}>
              {getFieldDecorator(FORM.phoneNum, {
                rules: [
                  {
                    validator: this.validatePhoneInput,
                  },
                ],
              })(
                <PhoneInput
                  displayInitialValueAsLocalNumber
                  className={PP_FORM_INPUT}
                  placeholder={formatMessage(messages.phone)}
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={Styles.address}>{formatMessage(messages.address)}</div>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormItem colon={false}>
              {getFieldDecorator(FORM.address)(
                <Input className={PP_FORM_INPUT} placeholder={formatMessage(messages.address)} />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col className={Styles.footer}>{this.renderFooter()}</Col>
        </Row>
      </Form>
    );
  }
}

export default UserInfoView;
