import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'dva';
import { formatMessage } from 'umi/locale';
import { isEqual, isEmpty, get, findIndex } from 'lodash';
import { Row, Col, Radio, Checkbox } from '@/componentsX/UIElements';
import { ACCESS_TYPES, NAMESPACE } from '../constants';
import { initDefaultValue, setAll, mapRoleFeaturesToDataView } from './services';
import messages from '../messages';
import Styles from '../styles.less';

class Features extends Component {
  static propTypes = {
    features: PropTypes.array,
    roleFeatures: PropTypes.object,
  };

  static defaultProps = {
    features: [],
    roleFeatures: [],
  };

  static getDerivedStateFromProps(nextProps, nextState) {
    const { roleFeatures, features } = nextProps;

    const shouldUpdateRoleFeatures = !isEqual(roleFeatures, nextState.props.roleFeatures);
    const shouldUpdateFeatures = !isEqual(features, nextState.props.features);

    if (shouldUpdateRoleFeatures || shouldUpdateFeatures) {
      const newRoleFeatures = initDefaultValue(roleFeatures, features);
      return {
        props: {
          features,
          roleFeatures,
        },
        roleFeatures: newRoleFeatures,
        dataView: mapRoleFeaturesToDataView(newRoleFeatures, features),
      }
    }
    return null;
  }

  constructor(props) {
    super(props);
    const { features, roleFeatures } = this.props;
    this.state = {
      props: {
        features,
        roleFeatures,
      },
      roleFeatures: [],
      dataView: {},
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!isEqual(nextProps, this.props) || !isEqual(nextState, this.state)) {
      return true
    }
    return false
  }

  componentDidUpdate(_, prevState) {
    const { features } = this.props;
    const { roleFeatures } = this.state;
    if (!isEqual(prevState.roleFeatures, roleFeatures)) {
      const dataView = mapRoleFeaturesToDataView(roleFeatures, features);
      this.setState({ dataView }, this.dispatchFeatures);
    }
  }

  dispatchFeatures = () => {
    const { roleFeatures } = this.state;
    const { dispatch } = this.props;
    dispatch({
      type: `${NAMESPACE}/features`,
      selectedRoles: roleFeatures,
    })
  };

  handleRadioChange = (e, featureId) => {
    const { roleFeatures } = this.state;
    const { value } = e.target;
    const { features } = this.props;
    const { FULL, SOME, NONE } = ACCESS_TYPES;
    const newRoleFeature = this.getRoleFeatureByFeatureId(featureId);
    if (isEmpty(newRoleFeature)) return false; // push new object to roleFeatures

    delete newRoleFeature.registerd;
    if (value === FULL) {
      // do stuff
      newRoleFeature.subFeatures = setAll(features, featureId);
    }

    if (NONE === value) {
      // do stuff
      newRoleFeature.subFeatures = [];
    }

    if (SOME === value) {
      // do stuff
      newRoleFeature.registerd = SOME;
      newRoleFeature.subFeatures = [];
    }

    // Find item index using findIndex
    const newRoleFeatures = [ ...roleFeatures ] ;
    const index = findIndex(newRoleFeatures, { id: featureId });
    // Replace item at index using native splice
    newRoleFeatures.splice(index, 1, newRoleFeature);
    this.setState(prevState => ({
      ...prevState,
      roleFeatures: newRoleFeatures,
    }));
  };

  handleCheckboxChange = (e, featureId, subFeatureId) => {
    const { roleFeatures } = this.state;
    const { checked } = e.target;
    const roleFeature = this.getRoleFeatureByFeatureId(featureId);
    const subFeature = this.getSubFeatureBySubFeatureId(roleFeature, subFeatureId);
    let newRoleFeature = {};
    if (checked) {
      if (!subFeature) {
        newRoleFeature = Object.assign(roleFeature, {
          subFeatures: roleFeature.subFeatures.concat({ id: subFeatureId }),
        });
      }
      newRoleFeature = { ...roleFeature }
    } else {
      newRoleFeature = Object.assign(roleFeature, {
        subFeatures: roleFeature.subFeatures.filter(item => item.id !== subFeatureId),
      });
    }
    // Find item index using findIndex
    const newRoleFeatures = [ ...roleFeatures ] ;
    const index = findIndex(newRoleFeatures, { id: featureId });
    // Replace item at index using native splice
    newRoleFeatures.splice(index, 1, newRoleFeature);
    this.setState(prevState => ({
      ...prevState,
      roleFeatures: newRoleFeatures,
    }))
  };

  getRoleFeatureByFeatureId = (featureId) => {
    const { roleFeatures } = this.state;
    const result = roleFeatures.filter(item => item.id === featureId);
    if (result.length > 0) {
      return { ...result[0] };
    }
    return null;
  };

  getSubFeatureBySubFeatureId = (roleFeature, subFeatureId) => {
    const subFeatures = get(roleFeature, 'subFeatures', []);
    const result = subFeatures.filter(item => item.id === subFeatureId);
    if (result.length > 0) {
      return { ...result[0] };
    }
    return null;
  };

  renderHeaderCbxSection = (id, name = 'N/A') => {
    const { dataView } = this.state;
    const { SOME, FULL, NONE } = ACCESS_TYPES;
    const { labelFeatureFull, labelFeatureSome, labelFeatureNone } = messages;
    const type = get(dataView, `${id}.type`);

    return (
      <Row>
        <Col sm={24} md={12}>
          <p className={Styles.featureName}>{name}</p>
        </Col>
        <Col sm={24} md={12} className={Styles.featureFormItem}>
          <Radio.Group onChange={(e) => this.handleRadioChange(e, id)} value={type}>
            <Radio value={FULL}>
              {formatMessage(labelFeatureFull)}
            </Radio>
            <Radio value={SOME}>
              {formatMessage(labelFeatureSome)}
            </Radio>
            <Radio value={NONE}>
              {formatMessage(labelFeatureNone)}
            </Radio>
          </Radio.Group>
        </Col>
      </Row>
    );
  };

  renderBodyCbxSection = (subFeatures, id) => {
    const { dataView } = this.state;
    const { NONE, FULL } = ACCESS_TYPES;
    const isVisible = featureId => {
      return ![NONE, FULL].includes(
        get(dataView, `${featureId}.type`, '')
      );
    };

    return isVisible(id) ? (
      <Row key={id}>
        {
          subFeatures.map((subFeature) => {
            const path = `${subFeature.featureId}.${subFeature.id}`;
            const isChecked = !!get(dataView, path, false);
            return (
              <Col key={path} sm={24} md={12}>
                <Checkbox
                  checked={isChecked}
                  onChange={(e) => this.handleCheckboxChange(e, id, subFeature.id)}
                  className={Styles.subFeatureCheckbox}
                >
                  {subFeature.name}
                </Checkbox>
                <p className={Styles.subFeatureDescripton}>{subFeature.description}</p>
              </Col>
            )
          })
        }
      </Row>
    ) : '';
  };

  render() {
    const { features } = this.props;
    return features.map(item => {
      const { subFeatures, id, name } = item;
      return (
        <div key={id} className={Styles.featureRow}>
          {this.renderHeaderCbxSection(id, name)}
          {this.renderBodyCbxSection(subFeatures, id)}
        </div>
      )
    });
  }
}

export default connect()(Features)
