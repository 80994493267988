/* eslint-disable import/prefer-default-export */

import request from '@/utils/request';
import { mapPageToBE } from '@/utils/utils';

export async function getServices(search = null, majors = null, currentPage = 1) {
  return request('/treatments/search', {
    method: 'POST',
    body: {
      page_num: mapPageToBE(currentPage),
      page_size: 20,
      name: search,
      deleted: false,
      major_ids: majors,
    },
  });
}
