/* eslint-disable no-param-reassign */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'dva';
import { formatMessage } from 'umi/locale';
import { get } from 'lodash';
import { SUBMIT_BUTTON } from '@/constants';
import { notification, Tabs, Modal, Button } from '@/componentsX/UIElements';
import { generateRandomPassword } from '@/utils/utils';
import messages from './messages';
import UserInfoView from './components/UserInfo/view';
import AdditionalPropertiesView from './components/AdditionalProperties/view';
import WorkingHoursView from './components/WorkingTimes/index';
import W4Form from './components/W4Form';
import { NAMESPACE, TABS } from './constants';
import formatW4Form from './components/W4Form/utils/formatW4Form';

const { TabPane } = Tabs;

@connect(state => ({
  initData: state[NAMESPACE].initData,
  isSuccess: state[NAMESPACE].isSuccess,
  loading:
    state.loading.effects[`${NAMESPACE}/create`] ||
    state.loading.effects[`${NAMESPACE}/update`] ||
    state.loading.effects[`${NAMESPACE}/w4FormSubmit`],
  initDataLoading: state.loading.effects[`${NAMESPACE}/initData`],
  w4Form: state[NAMESPACE].w4Form,
}))
class ModalContainer extends PureComponent {
  footerRenderers = {
    [TABS.w4Form]: () => {
      const { loading } = this.props;
      return (
        <>
          <Button key="cancel" onClick={this.handleCancel}>
            Cancel
          </Button>
          <Button key="preview" onClick={this.handleW4FormPreview}>
            Preview
          </Button>
          <Button key="submit" loading={loading} type="primary" onClick={this.handleW4Submit}>
            Update
          </Button>
        </>
      );
    },
  };

  static propTypes = {
    initData: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    isSuccess: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
    initDataLoading: PropTypes.bool,
    w4Form: PropTypes.object,
  };

  static defaultProps = {
    loading: false,
    initDataLoading: false,
    w4Form: {},
  };

  state = {
    submitButton: '',
    user: {},
    tab: TABS.userInfo,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { isEdit, user } = nextProps;
    if (isEdit && get(user, 'id') !== get(prevState, 'user.id')) {
      return {
        user,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    const { isOpen, isSuccess, dispatch } = this.props;
    const { submitButton } = this.state;

    if (isOpen && !prevProps.isOpen) {
      dispatch({ type: `${NAMESPACE}/clear` });
      this.fetchInputData();
    }

    if (isOpen && isSuccess) {
      if (submitButton === SUBMIT_BUTTON.save || submitButton === SUBMIT_BUTTON.update) {
        this.handleCancel();
      } else if (submitButton === SUBMIT_BUTTON.saveAndNew) {
        this.handleClearData();
        this.fetchInputData();
      }
    }
  }

  fetchInputData = user => {
    const { dispatch } = this.props;
    dispatch({
      type: `${NAMESPACE}/initData`,
      user,
    });
  };

  handleClearData = () => {
    const { dispatch } = this.props;
    this.setState(
      {
        user: {},
      },
      () => dispatch({ type: `${NAMESPACE}/clear` })
    );
  };

  handleCancel = () => {
    const { handleCancel } = this.props;
    handleCancel();
    this.handleClearData();
  };

  handleCallback = () => {
    const { callback } = this.props;
    this.handleClearData();
    callback();
  };

  handleSave = (form, isGeneratePassword) => {
    const { dispatch } = this.props;

    form.validateFieldsAndScroll((err, values) => {
      if (err) return;
      this.setState({ submitButton: SUBMIT_BUTTON.save }, () => {
        if (isGeneratePassword) {
          values.password = generateRandomPassword();
        }
        dispatch({
          type: `${NAMESPACE}/create`,
          payload: {
            user: { ...values },
            callback: () => {
              notification.success({ message: formatMessage(messages.userCreated) });
              this.handleCallback();
            },
          },
        });
      });
    });
  };

  handleSaveAndNew = (form, isGeneratePassword) => {
    const { dispatch } = this.props;

    form.validateFieldsAndScroll((err, values) => {
      if (err) return;
      this.setState({ submitButton: SUBMIT_BUTTON.saveAndNew }, () => {
        if (isGeneratePassword) {
          values.password = generateRandomPassword();
        }
        dispatch({
          type: `${NAMESPACE}/create`,
          payload: {
            user: { ...values },
            callback: () => {
              notification.success({ message: formatMessage(messages.userCreated) });
              this.handleCallback();
            },
          },
        });
      });
    });
  };

  handleUpdate = form => {
    const { dispatch } = this.props;

    form.validateFieldsAndScroll((err, values) => {
      if (err) return;
      const {
        user: { id },
      } = this.props;
      this.setState({ submitButton: SUBMIT_BUTTON.update, user: { ...values } }, () => {
        dispatch({
          type: `${NAMESPACE}/update`,
          payload: {
            id,
            user: { id, ...values },
            callback: () => {
              notification.success({ message: formatMessage(messages.userUpdated) });
              this.handleCallback();
            },
          },
        });
      });
    });
  };

  handleUpdateAdditionalProperties = form => {
    const { dispatch } = this.props;

    form.validateFieldsAndScroll((err, values) => {
      if (err) return;
      const {
        user: { id },
      } = this.props;
      this.setState({ submitButton: SUBMIT_BUTTON.update, user: { ...values } }, () => {
        dispatch({
          type: `${NAMESPACE}/updateAdditionalProperties`,
          payload: {
            id,
            user: { id, ...values },
            callback: () => {
              notification.success({ message: formatMessage(messages.userUpdated) });
              this.handleCallback();
            },
          },
        });
      });
    });
  };

  saveW4Form = form => {
    this.w4Form = form;
  };

  handleTabChange = tab =>
    this.setState({ tab }, () => {
      this.tryClearW4FormIfNeeded();
      this.tryFetchW4FormIfNeeded();
    });

  tryClearW4FormIfNeeded = () => {
    const { tab } = this.state;
    if (tab === TABS.w4Form) {
      return;
    }
    if (!this.w4Form) {
      return;
    }
    this.w4Form.resetForm();
    const { dispatch } = this.props;
    dispatch({
      type: `${NAMESPACE}/clearW4Form`,
    });
  };

  tryFetchW4FormIfNeeded = () => {
    const { tab } = this.state;
    if (tab !== TABS.w4Form) {
      return;
    }
    const { dispatch, user } = this.props;
    const staffID = get(user, 'id');
    dispatch({
      type: `${NAMESPACE}/fetchW4Form`,
      payload: {
        staffID,
      },
    });
  };

  handleW4Submit = () => {
    if (!this.w4Form) {
      return;
    }
    this.w4Form.submitForm();
  };

  handleW4FormSubmit = (values, onSuccess, onError) => {
    const { dispatch, user } = this.props;
    const staffID = get(user, 'id');
    dispatch({
      type: `${NAMESPACE}/submitW4Form`,
      payload: {
        staffID,
        onError,
        w4Form: values,
        onSuccess: () => {
          this.handleCancel();
          if (!onSuccess) return;
          onSuccess();
        },
      },
    });
  };

  handleW4FormPreview = () => {
    if (!this.w4Form) {
      return;
    }
    const values = this.w4Form.getValues();
    const { dispatch, user } = this.props;
    const staffID = get(user, 'id');
    dispatch({
      type: `${NAMESPACE}/previewW4Form`,
      payload: {
        staffID,
        w4Form: values,
      },
    });
  };

  getW4FormValues = () => {
    const { user } = this.state;
    const { w4Form } = this.props;
    const { firstName, lastName, socialNo } = user;
    return formatW4Form({
      ...w4Form,
      firstName,
      lastName,
      socialSecurityNumber: socialNo,
    });
  }

  renderFooter() {
    const { tab } = this.state;
    if (!Object.prototype.hasOwnProperty.call(this.footerRenderers, tab)) {
      return null;
    }
    return this.footerRenderers[tab]();
  }

  render() {
    const { initData, isOpen, isEdit, loading, initDataLoading } = this.props;
    const { user, submitButton, tab } = this.state;
    const w4Form = this.getW4FormValues();
    return (
      <Modal
        centered
        visible={isOpen}
        onCancel={this.handleCancel}
        destroyOnClose
        maskClosable={false}
        footer={this.renderFooter()}
        width="1000px"
        bodyStyle={{
          maxHeight: '600px',
          overflow: 'auto',
        }}
      >
        <Tabs activeKey={tab} onChange={this.handleTabChange}>
          <TabPane tab={formatMessage(messages.userInfo)} key={TABS.userInfo}>
            <UserInfoView
              user={user}
              initData={initData}
              isOpen={isOpen}
              isEdit={isEdit}
              loading={loading}
              initDataLoading={initDataLoading}
              submitButton={submitButton}
              handleSave={this.handleSave}
              handleSaveAndNew={this.handleSaveAndNew}
              handleUpdate={this.handleUpdate}
              handleCancel={this.handleCancel}
            />
          </TabPane>
          {isEdit && (
            <TabPane
              tab={formatMessage(messages.additionalProperties)}
              key={TABS.additionalProperties}
            >
              <AdditionalPropertiesView
                user={user}
                initData={initData}
                isOpen={isOpen}
                isEdit={isEdit}
                loading={loading}
                initDataLoading={initDataLoading}
                submitButton={submitButton}
                handleSave={this.handleSave}
                handleSaveAndNew={this.handleSaveAndNew}
                handleUpdate={this.handleUpdateAdditionalProperties}
                handleCancel={this.handleCancel}
              />
            </TabPane>
          )}
          {isEdit && (
            <TabPane tab={formatMessage(messages.workingHours)} key={TABS.workingHours}>
              <WorkingHoursView user={user} />
            </TabPane>
          )}
          {isEdit && (
            <TabPane tab="W4 Form" key={TABS.w4Form}>
              <W4Form
                initialValues={w4Form}
                onSubmit={this.handleW4FormSubmit}
                wrappedComponentRef={this.saveW4Form}
              />
            </TabPane>
          )}
        </Tabs>
      </Modal>
    );
  }
}

export default ModalContainer;

export { default as model } from './model';
export { NAMESPACE } from './constants';
